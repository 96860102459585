import React from 'react';
import cn from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './Tabs.scss';

interface ITab {
  title: string;
  component: JSX.Element | string;
  disabled?: boolean;
  path?: string;
}

interface IProps {
  tabs: ITab[];
  className?: string;
}

const Tabs: React.FC<IProps> = ({ tabs, className }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = tabs.find((tab) => tab.path && location.pathname.includes(tab.path));

  React.useEffect(() => {
    if (!currentTab && tabs?.[0]?.path) {
      navigate(tabs[0].path);
    }
  }, [currentTab, tabs]);

  return (
    <div className={cn('custom-tabs', className)}>
      <ul className="custom-tabs__list">
        {tabs.map((tab) => (
          <li className="custom-tabs__list-item" key={tab.title}>
            <Link
              to={tab.path && !tab.disabled ? tab.path : ''}
              className={cn(
                'custom-tabs__list-item-link',
                currentTab?.path === tab.path && 'active',
                tab.disabled && 'disabled'
              )}
            >
              {tab.title}
            </Link>
          </li>
        ))}
      </ul>
      {currentTab?.component}
    </div>
  );
};
export default Tabs;
