import React from 'react';
import cn from 'classnames';
import RangeDatepicker from '@components/RangeDatepicker/RangeDatepicker';
import { ReactComponent as IconSearch } from '@shared/icons/search.svg';
import { TICKET_STATUSES_OPTIONS } from '@constants';
import Select from '@components/Select/Select';
import { Input } from '@components/Input/Input';
import { IDictionary } from '@models/base.model';
import { CallbackFunctionVariadic } from 'types';

interface IProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  setDateRange: CallbackFunctionVariadic;
  statuses: IDictionary[];
  setStatuses: CallbackFunctionVariadic;
  searchStr: string;
  setSearchStr: CallbackFunctionVariadic;
}

export const Filters: React.FC<IProps> = ({
  startDate,
  endDate,
  setDateRange,
  statuses,
  setStatuses,
  searchStr,
  setSearchStr,
}) => {
  const [_statuses, _setStatuses] = React.useState<IDictionary[]>(statuses);

  return (
    <div className={cn('custom-filters', 'support-page-filters')}>
      <RangeDatepicker
        wrapperClassName="custom-filters__range"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
      />

      <Select
        options={TICKET_STATUSES_OPTIONS}
        isMulti
        placeholder="Выберите статусы"
        value={_statuses}
        className="custom-filters__statuses"
        onChange={(s: unknown) => {
          _setStatuses(s as IDictionary[]);
        }}
        onMenuClose={() => {
          setStatuses(_statuses);
        }}
        isClearable
        onClear={() => {
          setStatuses([]);
        }}
      />

      <Input
        icon={<IconSearch />}
        type="number"
        className="custom-filters__search"
        value={searchStr}
        placeholder="Полный № обращения"
        onChange={(e: { target: { value: unknown } }) => {
          setSearchStr(e.target.value);
        }}
      />
    </div>
  );
};
