import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTableWithParams, useMounted } from '@hooks';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import { renderTicketStatus } from '@functions';
import {
  formatDateFromUTCtoMSC as formatDate,
  ONLY_DATE_FORMAT,
  ONLY_TIME_FORMAT,
} from '@date-time';
import LogsService from '@services/logs/logs.service';
import { ROUTE_LOGS } from '@services/logs/logs.const';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { ReactComponent as Incoming } from '@shared/icons/communicationsPage/incoming.svg';
import { ReactComponent as Outcoming } from '@shared/icons/communicationsPage/outcoming.svg';
import { ITicketItem } from '@models/support.model';

import { Filters } from './Filters/Filters';
import { TableCell } from 'types';

import './SupportPage.scss';

export const SupportPage: React.FC = () => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    LogsService.saveLogs(ROUTE_LOGS.SUPPORT);
  }, []);

  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    ...filterParams
  } = useTableWithParams<ITicketItem>('/common/tickets/list/', mounted, 'fetch', {
    date: 'create_ts',
    search: 'ticket_id',
  });

  return (
    <PageLayout className="support-page">
      <h3 className="support-page__title">Обращения в поддержку</h3>
      <div className="support-page__actions">
        {/* @ts-expect-error Types of properties are incompatible. */}
        <Filters {...filterParams} />
        <Button
          className="support-page__create-button"
          type="primary"
          onClick={() => {
            navigate(location.pathname + '/create');
          }}
        >
          Новое обращение
        </Button>
      </div>
      <Table
        columns={columns as TableTypes.Column[]}
        data={data}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
        onRowClick={(row) => {
          navigate(location.pathname + '/' + row.id);
        }}
      />
    </PageLayout>
  );
};

const columns = [
  {
    id: 'is_outgoing',
    width: 40,
    align: 'center',
    accessor: (data: Record<string, string>): TableCell =>
      data.fromOperator ? <Incoming /> : <Outcoming />,
  },
  {
    Header: '№ обр-я',
    width: 72,
    align: 'right',
    accessor: (data: Record<string, string>): TableCell => (
      <span style={{ fontWeight: data.isRead ? 'normal' : 'bold' }}>{data.id}</span>
    ),
  },
  {
    Header: 'Дата подачи',
    width: 160,
    align: 'right',
    accessor: (data: Record<string, string>): TableCell => {
      if (data.createTs === null) return '-';
      const date = new Date(data.createTs);
      return (
        <div className="date-time-cell">
          <div style={{ fontWeight: data.isRead ? 'normal' : 'bold' }}>
            {formatDate(date, ONLY_DATE_FORMAT)}
          </div>
          <div className="time">{formatDate(date, ONLY_TIME_FORMAT)}</div>
        </div>
      );
    },
  },
  {
    Header: 'Тема',
    width: 280,
    clipped: true,
    accessor: (data: Record<string, string>): TableCell => (
      <span style={{ fontWeight: data.isRead ? 'normal' : 'bold' }}>{data.title || '-'}</span>
    ),
  },
  {
    Header: 'Текст обращения',
    width: 512,
    clipped: true,
    accessor: (data: Record<string, string>): TableCell => (
      <span style={{ fontWeight: data.isRead ? 'normal' : 'bold' }}>{data.text}</span>
    ),
  },
  {
    Header: 'Статус',
    width: 100,
    accessor: (({ status }: { status: string }) => renderTicketStatus(status)) as unknown as string,
  },
];
