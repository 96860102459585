import type React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@components/Table';
import Filters from '@shared/components/SearchFilters/SearchFilters';
import Button from '@components/Button/Button';
import type * as TableTypes from '@components/Table/types';

import { useTableWithParams, useMounted } from '@hooks';
import { renderMemberStatus } from '@functions';
import type { ICustomerForAdmin } from '../../operator.model';

import './Customers.scss';

const Customers: React.FC = () => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    searchStr,
    setSearchStr,
  } = useTableWithParams('/operator/companies/customers/list/', mounted);

  return (
    <div className="admin-customers">
      <div className="admin-customers__actions">
        <Filters
          className="admin-customers__filters"
          searchStr={searchStr}
          setSearchStr={setSearchStr}
        />
        <Button
          type="primary"
          onClick={() => {
            navigate(location.pathname + '/create');
          }}
        >
          Добавить заказчика
        </Button>
      </div>
      <Table
        columns={columns as TableTypes.Column[]}
        data={data as Array<TableTypes.CustomRowData<ICustomerForAdmin>>}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
        onRowClick={(row) => {
          // @ts-expect-error check types
          navigate(location.pathname + '/' + row.original.customerId);
        }}
      />
    </div>
  );
};

export default Customers;

const columns = [
  {
    Header: 'Статус',
    accessor: ({ isBlocked }: { isBlocked: boolean }) => renderMemberStatus(isBlocked),
    align: 'left',
  },
  {
    Header: 'Название компании',
    accessor: 'customerName',
    align: 'left',
  },
  {
    Header: 'Пользователи',
    accessor: 'usersCount',
    align: 'right',
  },
  {
    Header: 'Участники (компании)',
    accessor: 'participantsCount',
    align: 'right',
  },
  {
    Header: 'Участники (пользователи)',
    accessor: 'participantMembersCount',
    align: 'right',
  },
  {
    Header: 'Количество запросов',
    accessor: 'requestsCount',
    align: 'right',
  },
];
