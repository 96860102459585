import React from 'react';
import { useLocation } from 'react-router-dom';
import Requests from '@shared/components/Requests/Requests';
import LogsService from '@services/logs/logs.service';
import { ROUTE_LOGS } from '@services/logs/logs.const';
import type * as ReactTableTypes from 'react-table';
import { useTableWithParams, useMounted } from '@hooks';
import {
  renderStatus,
  renderName,
  renderDate,
  renderMaxAmount,
  renderProposalsCount,
  renderCreatorPerson,
  renderMinMaxAmount,
  renderMaturity,
} from '@functions';
import { BOND_RATE_TYPES } from '@constants';
import { ROLES } from '@roles';
import CustomerService from '@services/customer/customer.service';

export const CustomerRequests: React.FC = () => {
  const { pathname } = useLocation();
  const mounted = useMounted();
  const requestType = pathname.includes('/deposit')
    ? 'deposit'
    : pathname.includes('/bond')
    ? 'bond'
    : 'credit';
  const table = useTableWithParams(CustomerService.getApiPath(requestType), mounted);

  React.useEffect(() => {
    let logsText;
    switch (requestType) {
      case 'deposit':
        logsText = ROUTE_LOGS.DEPOSIT_REQUESTS;
        break;
      case 'bond':
        logsText = ROUTE_LOGS.BOND_REQUESTS;
        break;
      case 'credit':
        logsText = ROUTE_LOGS.CREDIT_REQUESTS;
        break;
      default:
        break;
    }
    logsText && LogsService.saveLogs(logsText);
  }, []);

  return (
    <Requests
      table={table}
      columns={COLUMNS[requestType]}
      // @ts-expect-error check types
      role={ROLES.CUSTOMER}
      canCreate={true}
    />
  );
};

const customerCreditsColumns = [
  {
    Header: 'Название',
    accessor: renderName,
    clipped: true,
  },
  {
    Header: 'Статус',
    accessor: renderStatus('status'),
    align: 'center',
    verticalAlign: 'middle',
    width: 100,
  },
  {
    Header: 'Начало',
    accessor: renderDate('startTs'),
    align: 'right',
    width: 100,
  },
  {
    Header: 'Завершение',
    accessor: renderDate('endTs'),
    align: 'right',
    width: 100,
  },
  {
    Header: 'Макс. объем',
    accessor: renderMaxAmount,
    width: 100,
  },

  {
    Header: 'Предложений',
    accessor: renderProposalsCount,
    align: 'right',
    width: 100,
  },
  {
    Header: 'Запрос открыл(а)',
    accessor: renderCreatorPerson,
    width: 160,
  },
] as ReactTableTypes.ColumnInterface[];

const customerDepositsColumns = [...customerCreditsColumns] as ReactTableTypes.ColumnInterface[];

const customerBondsColumns = [
  {
    Header: 'Название',
    accessor: renderName,
    clipped: true,
  },
  {
    Header: 'Статус',
    accessor: renderStatus('status'),
    align: 'center',
    verticalAlign: 'middle',
    width: 100,
  },
  {
    Header: 'Начало',
    accessor: renderDate('startTs'),
    width: 100,
  },
  {
    Header: 'Завершение',
    accessor: renderDate('endTs'),
    width: 100,
  },
  {
    Header: 'Объем выпуска',
    accessor: renderMinMaxAmount,
    width: 160,
  },
  {
    Header: 'Лет до погашения',
    accessor: renderMaturity,
    align: 'right',
    width: 120,
  },
  {
    Header: 'Фикс/ Флоатер',
    accessor: ({ rateType }: { rateType: string }) => BOND_RATE_TYPES[rateType] ?? '-',
    width: 120,
  },
  {
    Header: 'Предложений',
    accessor: renderProposalsCount,
    align: 'right',
    width: 100,
  },
  {
    Header: 'Запрос открыл(а)',
    accessor: renderCreatorPerson,
    width: 160,
  },
] as ReactTableTypes.ColumnInterface[];

const COLUMNS = {
  credit: customerCreditsColumns,
  deposit: customerDepositsColumns,
  bond: customerBondsColumns,
};
