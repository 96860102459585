import type React from 'react';
import cn from 'classnames';

import type * as TableTypes from '../../types';

import './style.scss';

export const TableHead: React.FC<TableTypes.TableProps> = ({
  headerGroups = [],
  withColumnHeadSeparator,
  scrollBarSize,
  refTableHead,
}) => {
  return (
    <div className="custom-thead" style={{ marginRight: scrollBarSize }} ref={refTableHead}>
      {headerGroups.map((headerGroup) => {
        const { key: headerGroupKey, ...otherHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={headerGroupKey}
            className={cn('custom-thead__tr', {
              'custom-thead__tr--bordered': withColumnHeadSeparator,
            })}
            {...otherHeaderGroupProps}
          >
            {headerGroup.headers.map((column, j) => {
              const { verticalAlign = 'middle', align = 'left' } = column;
              const { key: headerKey, ...otherHeaderProps } = column.getHeaderProps();
              return (
                <div
                  key={headerKey}
                  className={cn(
                    'custom-thead__th',
                    'custom-cell',
                    withColumnHeadSeparator === true && 'custom-thead__th--bordered',
                    {
                      [`custom-cell--${align}`]: align,
                      [`custom-cell--${verticalAlign}`]: verticalAlign,
                    }
                  )}
                  {...otherHeaderProps}
                >
                  <span>{column.render('Header')}</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableHead;
