import AuthService from '@services/auth/auth.service';
import { JSX } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes';

export const NoRights = ({
  message = 'Нет доступа',
  canNavigate = true,
}: {
  message?: string;
  canNavigate?: boolean;
}): JSX.Element => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  async function onExit(): Promise<void> {
    await AuthService.logout(keycloak);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        gap: '32px',
      }}
    >
      <div>{message}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        {canNavigate && (
          <Button
            onClick={() => {
              navigate(ROUTES.DEFAULT, { replace: true });
            }}
          >
            Вернуться на главную
          </Button>
        )}
        <Button onClick={onExit}>Выйти из профиля</Button>
      </div>
    </div>
  );
};
