/* eslint-disable */
import React from 'react';
import * as ReactTable from 'react-table';
import { HttpClient } from '@api/HttpClient';
import Table from '@components/Table';
import Button from '@components/Button/Button';
import type * as TableTypes from '@components/Table/types';
import { renderAmount, renderRateLong } from '@functions';
import { useCurrentUserData } from '@services/user/userDataContext';
import { RateType } from '@models/base.model';
import { ReactComponent as IconChat } from '@shared/icons/chat.svg';

import type { IProposal } from '../position.model';

import './ProposalsTable.scss';

interface IProps {
  data: IProposal[];
  shouldRenderActions: boolean;
  onCancelProposal: (proposal_id: number) => void;
  onEditProposal: (proposal_id: number) => void;
  onStartCommunication(): void;
  requestType: 'deposit' | 'credit';
  rateType: RateType;
  hideForParticipants: boolean;
}

export const ProposalsTable: React.FC<IProps> = ({
  data,
  shouldRenderActions,
  rateType,
  requestType,
  onCancelProposal,
  onEditProposal,
  onStartCommunication,
  hideForParticipants,
}: IProps) => {
  const user = useCurrentUserData();
  const digits = data?.length.toString().length ?? 0;

  const COLUMNS = [
    {
      Header: hideForParticipants ? 'Позиция' : '№',
      accessor: (_: IProposal, i: number) => {
        return `${(i + 1).toString().padStart(digits, '0')}`;
      },
    },
    {
      Header: requestType === 'deposit' ? 'Процент' : 'Ставка',
      accessor: ({ rate }: { rate: number }) => renderRateLong({ rate, rateType }) || '-',
    },
    {
      Header: 'Объём',
      accessor: renderAmount || '-',
    },
    {
      Header: 'Комментарий участника (только Ваш)',
      accessor: ({ comment }: { comment: string }) => comment || '-',
    },
  ] as TableTypes.Column[];

  const columns = [...COLUMNS];
  if (shouldRenderActions)
    columns.push({
      Header: ' ',
      accessor: ({ id, company_id }: { id: number; company_id: number }) => {
        if (user?.company?.id === company_id)
          return (
            <div className="position-detail-proposals__grid-actions">
              <Button
                type="warning"
                onClick={() => {
                  onCancelProposal(id);
                }}
              >
                Отозвать
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onEditProposal(id);
                }}
              >
                Изменить
              </Button>
              <Button
                type="text"
                icon={<IconChat />}
                onClick={() => {
                  onStartCommunication();
                }}
              />
            </div>
          );
        return null;
      },
    } as ReactTable.Column);
  return <Table className="position-detail-proposals__grid" columns={columns} data={data} />;
};
