import React from 'react';
import type * as ReactTableTypes from 'react-table';
import { HttpClient } from '@api/HttpClient';
import Requests from '@shared/components/Requests/Requests';
import { BOND_RATE_TYPES } from '@constants';
import { ParticipantService } from '@services/paricipant/participant.service';
import { ROUTE_LOGS } from '@services/logs/logs.const';
import LogsService from '@services/logs/logs.service';
import { IRequest } from '@shared/components/Requests/requests.model';
import { useLocation, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { Modal } from '@components/Modal/Modal';
import Button from '@components/Button/Button';
import { ROLES } from '@roles';
import { useTableWithParams, useMounted } from '@hooks';
import { ROUTES } from '@routes';
import { Status } from '@models/base.model';
import {
  ModalRefuseRequests,
  RequestModalCancel,
} from '@shared/components/RequestModalCancel/RequestModalCancel'; // TODO: перенести
import { IRequestBondModel } from '@pages/participant/BondDetailPage/request-bond.model'; // TODO: перенести
import {
  renderStatus,
  renderCreatorCompany,
  renderName,
  renderDate,
  renderAmount,
  renderPeriod,
  renderRateType,
  renderProposalStatus,
  renderProposalsCount,
  renderMinMaxAmount,
  renderMaturity,
  renderCloseRequest,
} from '@functions';

export const ParticipantRequests: React.FC = () => {
  const { pathname } = useLocation();
  const requestType = pathname.includes('/deposit')
    ? 'deposit'
    : pathname.includes('/bond')
    ? 'bond'
    : 'credit';
  const mounted = useMounted();

  const table = useTableWithParams(ParticipantService.getApiPath(requestType), mounted);

  const hasNewRequests = (table.data as Array<IRequest | IRequestBondModel>).some(
    (item) =>
      (item as IRequest).proposalStatus !== Status.Cancelled &&
      (item as IRequest).proposalStatus !== Status.Sent &&
      (item as IRequestBondModel).status !== Status.Cancelled &&
      (item as IRequestBondModel).status !== Status.Sent &&
      (item as IRequestBondModel).status !== Status.Summarizing &&
      (item as IRequestBondModel).status !== Status.Completed &&
      (item as IRequest).requestStatus !== Status.Summarizing &&
      (item as IRequest).requestStatus !== Status.Completed
  );

  // TODO: При переходе на react-router-dom v.7 переписать
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const currentPathName = currentLocation.pathname;
    if (
      hasNewRequests &&
      (currentPathName === ROUTES.CREDITS + ROUTES.POSITIONS ||
        currentPathName === ROUTES.DEPOSITS + ROUTES.POSITIONS ||
        currentPathName === ROUTES.BONDS + ROUTES.REQUESTS)
    ) {
      const str = currentPathName.split('/').slice(0, 2).join('/');
      return !nextLocation.pathname.startsWith(str);
    } else {
      return false;
    }
  });

  React.useEffect(() => {
    let logsText;
    switch (requestType) {
      case 'deposit':
        logsText = ROUTE_LOGS.DEPOSIT_REQUESTS;
        break;
      case 'bond':
        logsText = ROUTE_LOGS.BOND_REQUESTS;
        break;
      case 'credit':
        logsText = ROUTE_LOGS.CREDIT_REQUESTS;
        break;
      default:
        break;
    }
    logsText && LogsService.saveLogs(logsText);
  }, []);

  const [showModalCancel, setShowModalCancel] = React.useState(false);
  const [showModalRefuseRequests, setShowModalRefuseRequests] = React.useState(false);
  const [rowData, setRowData] = React.useState<IRequest | null>(null);
  const [comment, setComment] = React.useState<string>('');

  const refuseRequest = async (allPositions: boolean): Promise<void> => {
    if (rowData) {
      await HttpClient.post<{ comment: string; all_positions: boolean }>(
        `participant/${requestType}/${requestType === 'bond' ? 'requests' : 'positions'}/${
          (rowData as unknown as { id: string }).id
        }/decline/`,
        { comment, all_positions: allPositions }
      );
    }
  };

  const refuseBondRequests = async (comment: string): Promise<void> => {
    if (rowData) {
      await HttpClient.post<{ comment: string; all_positions: boolean }>(
        `participant/bond/requests/${(rowData as unknown as { id: string }).id}/decline/`,
        { comment }
      );
    }
  };

  const cancelColumn: ReactTableTypes.ColumnInterface = {
    Header: ' ',
    Cell: ({ row }: { row: ReactTableTypes.Row<IRequest | IRequestBondModel> }) =>
      renderCloseRequest(row, () => {
        setShowModalCancel(true);
        // @ts-expect-error check
        setRowData(row);
      }),
    align: 'right',
    width: 20,
  } as ReactTableTypes.ColumnInterface;

  const participantCreditsColumns = [
    {
      Header: 'Статус',
      accessor: renderStatus('requestStatus'),
      align: 'center',
      verticalAlign: 'middle',
      width: 100,
    },
    {
      Header: 'Заказчик',
      accessor: renderCreatorCompany,
      width: 120,
    },
    {
      Header: 'Название',
      accessor: renderName,
      clipped: true,
    },
    {
      Header: 'Закрытие',
      accessor: renderDate('endTs'),
      width: 100,
    },
    {
      Header: 'Объем',
      accessor: renderAmount,
      width: 100,
    },
    {
      Header: 'Срок',
      accessor: renderPeriod,
      width: 100,
    },
    {
      Header: 'Ставка',
      accessor: renderRateType,
      width: 100,
    },
    {
      Header: 'Статус предложения',
      accessor: renderProposalStatus,
      width: 100,
    },
    cancelColumn,
  ] as ReactTableTypes.ColumnInterface[];

  const participantDepositsColumns = [
    ...participantCreditsColumns,
  ] as ReactTableTypes.ColumnInterface[];

  const participantBondsColumns = [
    {
      Header: 'Название',
      accessor: renderName,
      clipped: true,
    },
    {
      Header: 'Статус',
      accessor: renderStatus('status'),
      align: 'center',
      verticalAlign: 'middle',
      width: 100,
    },
    {
      Header: 'Заказчик',
      accessor: renderCreatorCompany,
      width: 120,
    },
    {
      Header: 'Завершение',
      accessor: renderDate('endTs'),
      width: 100,
    },
    {
      Header: 'Объем выпуска',
      accessor: renderMinMaxAmount,
      width: 100,
    },
    {
      Header: 'Лет до погашения',
      accessor: renderMaturity,
      align: 'right',
      width: 100,
    },
    {
      Header: 'Фикс/ Флоатер',
      accessor: ({ rateType }: { rateType: string }) => BOND_RATE_TYPES[rateType] ?? '-',
      width: 100,
    },
    {
      Header: 'Предложений',
      accessor: renderProposalsCount,
      align: 'right',
      width: 100,
    },
    {
      Header: 'Статус предложения',
      accessor: renderProposalStatus,
      width: 100,
    },
    cancelColumn,
  ] as ReactTableTypes.ColumnInterface[];

  const COLUMNS = {
    deposit: participantDepositsColumns,
    credit: participantCreditsColumns,
    bond: participantBondsColumns,
  };

  return (
    <>
      <Requests
        table={table}
        columns={COLUMNS[requestType]}
        // @ts-expect-error check types
        role={ROLES.PARTICIPANT}
        canCreate={false}
      />
      {showModalCancel && (
        <RequestModalCancel
          onConfirm={async (comment) => {
            setComment(comment);
            if (requestType === 'bond') {
              await refuseBondRequests(comment);
              table.fetchCurrentPage({ pageIndex: table.pageIndex, pageSize: table.pageSize });
              setShowModalCancel(false);
            } else {
              setShowModalRefuseRequests(true);
            }
          }}
          onCancel={() => {
            setShowModalCancel(false);
          }}
        />
      )}
      {showModalRefuseRequests && (
        <ModalRefuseRequests
          onConfirm={async () => {
            await refuseRequest(true);
            setShowModalRefuseRequests(false);
            setShowModalCancel(false);
            table.fetchCurrentPage({ pageIndex: table.pageIndex, pageSize: table.pageSize });
          }}
          onCancel={async () => {
            await refuseRequest(false);
            setShowModalCancel(false);
            setShowModalRefuseRequests(false);
            table.fetchCurrentPage({ pageIndex: table.pageIndex, pageSize: table.pageSize });
          }}
        />
      )}
      {blocker.state === 'blocked' && (
        <Modal isOpen className="request-modal-navigate-warning" title="Запросы без ответа">
          <p>
            В списке остались запросы, на которые вы еще не оставили своё предложение, рекомендуем
            обработать их.
          </p>
          <Modal.Actions>
            <Button
              fullWidth
              onClick={() => {
                blocker?.reset?.();
              }}
              type="primary"
            >
              Остаться
            </Button>
            <Button
              fullWidth
              onClick={() => {
                blocker?.proceed?.();
              }}
            >
              Уйти
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
