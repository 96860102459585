import React from 'react';
import cn from 'classnames';
import { AxiosError } from 'axios';
import { HttpClient, IHTTPResponseWithError } from '@api/HttpClient';
import { removeEmptyProps } from '@utils';
import { Formik, Form, FormikHelpers } from 'formik';
import { HTTP_ERROR_STATUS_CODES } from '@constants';
import { phoneMask } from '@masks';
import { IDictionary } from '@models/base.model';
import Button from '@components/Button/Button';
import Container from '@components/Container/Container';
import { Input } from '@components/Input/Input';
import Select from '@components/Select/Select';
import type { IUserForAdmin } from './user.model';
import { UserSchema } from './CreateUserForm.validation';
import AutoCheckForm from '@shared/components/AutoCheckForm';

import './CreateUserForm.scss';

interface IProps {
  data?: IUserForAdmin & { id?: number };
  onClose: (newData?: Partial<IUserForAdmin>) => void;
}
export const CreateUserForm: React.FC<IProps> = ({ data, onClose }) => {
  const isNew = !data;
  const [companies, setCompanies] = React.useState<IDictionary[]>([]);

  React.useEffect(() => {
    HttpClient.post<Record<string, unknown>, Array<{ id: number; name: string }>>(
      '/operator/companies/',
      {}
    ).then((res) => {
      const data = res.map((el) => ({ label: el.name, value: el.id }));
      setCompanies(data);
    });
  }, []);

  const requestTypes = React.useMemo(
    () => [
      { label: 'Кредиты', value: 'CREDIT' },
      { label: 'Депозиты', value: 'DEPOSIT' },
      { label: 'Облигации', value: 'BOND' },
    ],
    []
  );

  const initialData: IUserForAdmin = {
    last_name: '',
    first_name: '',
    patronymic: '',
    company: null,
    phone: '',
    position: '',
    email: '',
    favorite_request_type: null,
    ...data,
  };

  const handleSaveUser = (
    { company, favorite_request_type: favoriteRequestType, ...values }: IUserForAdmin,
    formikHelpers: FormikHelpers<IUserForAdmin>
  ): void => {
    const body = removeEmptyProps({
      ...values,
      favorite_request_type: favoriteRequestType?.value,
      company_id: company?.value,
    });

    (isNew
      ? HttpClient.post('/operator/users/', body)
      : HttpClient.patch(`/operator/users/${data?.id}/`, body)
    )
      .then(() => {
        onClose(body as Partial<IUserForAdmin>);
      })
      .catch((e: AxiosError<IHTTPResponseWithError>) => {
        if (
          e.response?.status === HTTP_ERROR_STATUS_CODES.Conflict ||
          e.response?.status === HTTP_ERROR_STATUS_CODES.Bad_request
        ) {
          const serviceMessage = e.response?.data?.detail.service_message ?? '';
          const _serviceMessage = JSON.parse(serviceMessage);
          if (serviceMessage.includes('email')) {
            formikHelpers.setFieldError(
              'email',
              _serviceMessage.detail?.includes('exists')
                ? 'Пользователь с таким email уже существует'
                : _serviceMessage.detail
            );
          }
          if (serviceMessage.includes('phone')) {
            formikHelpers.setFieldError(
              'phone',
              _serviceMessage.detail?.includes('exists')
                ? 'Пользователь с таким телефоном уже существует'
                : _serviceMessage.detail
            );
          }
        }
      });
  };

  return (
    <>
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        initialValues={initialData}
        validationSchema={UserSchema}
        onSubmit={handleSaveUser}
      >
        {({ values, handleChange, handleBlur, setFieldValue, errors, touched, dirty, isValid }) => {
          return (
            <>
              <Form>
                <Container
                  direction="vertical"
                  className={cn('new-user', !isNew && 'new-user--edited')}
                >
                  <Container.Header className="new-user__block-title">
                    Основные сведения
                  </Container.Header>
                  <div className="new-user__row">
                    <div className="new-user__col">
                      <Input
                        name="last_name"
                        size="large"
                        label="Фамилия*"
                        placeholder="Укажите фамилию"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        error={touched.last_name ? (errors.last_name as string) : undefined}
                      />
                      <Input
                        name="first_name"
                        size="large"
                        label="Имя*"
                        placeholder="Укажите имя"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        error={touched.first_name ? (errors.first_name as string) : undefined}
                      />
                      <Input
                        name="patronymic"
                        size="large"
                        label="Отчество"
                        placeholder="Укажите отчество, если есть"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.patronymic as string}
                        error={touched.patronymic ? (errors.patronymic as string) : undefined}
                      />
                      <Select
                        name="company"
                        size="large"
                        options={companies}
                        onChange={(e) => {
                          setFieldValue('company', e);
                        }}
                        label="Компания*"
                        placeholder="Выберите компанию из списка"
                        onBlur={handleBlur}
                        value={values.company}
                        error={touched.company ? (errors.company as string) : undefined}
                        isDisabled={!isNew}
                      />
                      <Input
                        name="position"
                        size="large"
                        label="Должность"
                        placeholder="Укажите должность сотрудника"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.position as string}
                        error={touched.position ? (errors.position as string) : undefined}
                      />
                      <Select
                        name="favorite_request_type"
                        isClearable
                        options={requestTypes}
                        size="large"
                        onChange={(e) => {
                          setFieldValue('favorite_request_type', e);
                        }}
                        label="Предпочтительный трек"
                        placeholder="Выберите трек из списка"
                        onBlur={handleBlur}
                        value={values.favorite_request_type}
                        error={
                          touched.favorite_request_type
                            ? (errors.favorite_request_type as string)
                            : undefined
                        }
                      />
                    </div>
                    <div className="new-user__col">
                      <Input
                        name="email"
                        size="large"
                        label="Электронная почта*"
                        placeholder="Укажите адрес электронной почты"
                        onChange={(e) => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        error={touched.email ? (errors.email as string) : undefined}
                      />
                      <Input
                        name="phone"
                        size="large"
                        label="Номер телефона*"
                        mask={phoneMask}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={touched.phone ? (errors.phone as string) : undefined}
                        disabled={!isNew}
                      />
                    </div>
                  </div>
                  <div className="new-user__required-hint">* Обязательные для заполнения поля</div>
                </Container>

                <div className="new-user__actions">
                  <Button
                    className="new-user__action-button"
                    type={dirty ? 'primary' : 'without-border'}
                    htmlType="submit"
                    size={isNew ? 'large' : 'medium'}
                    disabled={!dirty}
                  >
                    {isNew ? 'Создать пользователя' : 'Сохранить'}
                  </Button>
                  <Button
                    className="new-user__action-button"
                    size={isNew ? 'large' : 'medium'}
                    type={isNew ? 'warning' : undefined}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Отменить
                  </Button>
                </div>
                <AutoCheckForm
                  title={`Прервать ${isNew ? 'создание' : 'редактирование'} пользователя`}
                  message={`Вы уверены, что хотите прервать ${
                    isNew ? 'создание' : 'редактирование'
                  } пользователя? Все данные будут утеряны.`}
                  onConfirm={() => {
                    onClose();
                  }}
                  confirmBtnText="Прервать"
                  cancelBtnText="Отмена"
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
