import { JSX } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import Notification from '@components/Modal/Notification/Notification';
import AuthService from '@services/auth/auth.service';

import { ROUTES } from '@routes';
import ErrorHandlerProvider from '../../app/ErrorHandlerProvider';

export const Boundaried = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => {
        const navigate = useNavigate();
        const { keycloak } = useKeycloak();
        const onExit = async (): Promise<void> => {
          await AuthService.logout(keycloak);
        };

        return (
          <Notification
            isOpen
            title="Ошибка"
            message={error.toString()}
            confirmBtnText="Закрыть"
            onConfirm={() => {
              resetErrorBoundary();
              navigate(ROUTES.DEFAULT);
            }}
            cancelBtnText="Выйти из системы"
            onCancel={onExit}
          />
        );
      }}
    >
      <ErrorHandlerProvider>{props.children}</ErrorHandlerProvider>
    </ErrorBoundary>
  );
};
