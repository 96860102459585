import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type * as ReactTable from 'react-table';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { HttpClient } from '@api/HttpClient';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { download } from '@utils';
import { ROUTES } from '@routes';
import { renderAmount, renderProposalOffer } from '@functions';
import { formatDateFromUTCtoMSC as formatDate, ONLY_DATE_FORMAT } from '@date-time';
import { REQUEST_STATUSES_PARAMS, MIME_TYPES } from '@constants';
import Notification from '@components/Modal/Notification/Notification';
import Textarea from '@components/Textarea/Textarea';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import Pills from '@components/Pills/Pills';
import type * as TableTypes from '@components/Table/types';
import { RateType, Status } from '@models/base.model';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { MainInfo } from './MainInfo/MainInfo';
import { IRequestBondModel } from './detail.model';
import { useCurrentUserData } from '@services/user/userDataContext';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import { ReactComponent as IconChat } from '@shared/icons/chat.svg';
import { ReactComponent as Group } from '@shared/icons/communicationsPage/group.svg';
import { CreateDialogModal } from '@shared/components/CreateDialogModal/CreateDialogModal';
import { NoCommunicationsModal } from '@pages/customer/shared/NoCommunicationsModal/NoCommunicationsModal';
import { CommunicationService } from '@services/communication/communication.service';

import './BondDetailPage.scss';

export const CustomerBondDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const breadcrumbs = useBreadcrumbs();
  const user = useCurrentUserData();
  const isParticipantsTab = location.pathname.includes(ROUTES.PARTICIPANTS) ? 'participants' : '';
  const currentTabNumber = isParticipantsTab ? 1 : 0;
  const currentTabName: 'participants' | 'proposals' = location.pathname.includes(
    ROUTES.PARTICIPANTS
  )
    ? 'participants'
    : 'proposals';
  const [data, setData] = React.useState<IRequestBondModel>({} as IRequestBondModel);
  const [participantsData, setParticipantsData] = React.useState<ISimilarCompany[]>([]);

  const [isReminderNotificationOpen, openReminderNotification] = React.useState(false);
  const [isConfirmationCancelOpen, setConfirmationCancelOpen] = React.useState<boolean>(false);
  const [isConfirmationCompleteOpen, setConfirmationCompleteOpen] = React.useState<boolean>(false);
  const [closingMessageError, setClosingMessageError] = React.useState<string>('');
  const [confirmationCompleteCommentText, setConfirmationCompleteCommentText] =
    React.useState<string>('');
  const [isOpenNoCommunications, setIsOpenNoCommunications] = React.useState(false);
  const [communicationProps, setIsOpenCreateCommunication] = React.useState<Record<
    string,
    unknown
  > | null>(null);

  const isActive = data?.status === Status.Active;

  const loadData = (): void => {
    HttpClient.get<IRequestBondModel>(`customer/bond/requests/${requestId}/`).then(
      (bondRequest) => {
        bondRequest.proposals.forEach((proposal, index) => {
          proposal.index = index + 1;
          return proposal;
        });
        setData(bondRequest);
      }
    );
  };

  const loadParticipants = (): void => {
    HttpClient.get<ISimilarCompany[]>(`customer/bond/requests/${requestId}/participants/`).then(
      (bondRequest) => {
        setParticipantsData(bondRequest);
      }
    );
  };

  React.useEffect(() => {
    loadData();
    loadParticipants();
  }, [requestId]);

  const repeatRequest = async (): Promise<void> => {
    if (!data) return;

    const navigationState = {
      comment_int: data.comment_int,
      comment_ext: data.comment_ext,
      maturity_from: data.maturity_from,
      maturity_to: data.maturity_to,
      put_from: data.put_from,
      put_to: data.put_to,
      call_from: data.call_from,
      call_to: data.call_to,
      rate_type: data.rate_type,
      max_amount: data.max_amount,
      min_amount: data.min_amount,
      name: '',
      end_ts: '',
    };

    navigate(ROUTES.BONDS + ROUTES.REQUESTS + '/create', { state: navigationState });
  };

  const sendRemindersToParticipants = (): void => {
    HttpClient.get(`customer/bond/requests/${requestId}/remind/`);
  };

  const cancelRequest = (): void => {
    HttpClient.patch(`customer/bond/requests/${requestId}/cancel/`).then(() => {
      setData((prevData) => ({
        ...prevData,
        status: Status.Cancelled,
      }));
    });
  };

  const completeRequest = (): void => {
    const body = {
      comment_result: confirmationCompleteCommentText,
    };

    HttpClient.patch<
      {
        comment_result: string;
      },
      null
    >(`customer/bond/requests/${requestId}/complete/`, body).then(() => {
      setData((prevData) => ({
        ...prevData,
        comment_result: confirmationCompleteCommentText,
        status: Status.Completed,
      }));
    });
  };

  const downloadReport = () => async (): Promise<void> => {
    const file = await HttpClient.get<string>(`customer/bond/requests/${requestId}/report/`, {
      headers: {
        Accept: MIME_TYPES.Excel,
      },
      responseType: 'blob',
    });
    const blob = new Blob([file], {
      type: MIME_TYPES.Excel,
    });

    const fileName = `${data.name} от ${formatDate(data.start_ts, ONLY_DATE_FORMAT)}.xlsx`;

    download(blob, fileName);
  };

  const getDialogId = async (recipientCompany: ISimilarCompany): Promise<void> => {
    const result = await CommunicationService.searchActiveDialogByParams({
      recipient_company_id: recipientCompany.id,
      request_type: 'BOND',
      request_bond_id: requestId,
    });
    if (result.dialog_id) navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${result.dialog_id}`);
    else
      setIsOpenCreateCommunication({
        isGroup: false,
        recipientCompanies: [recipientCompany],
        requestBondId: requestId,
      });
  };

  const participantsColumns: ReactTable.Column[] = [
    {
      Header: 'Название',
      accessor: 'name',
    },
    {
      Header: 'ИНН',
      accessor: 'inn',
    },
    {
      id: 'id',
      accessor: (row: unknown): JSX.Element => (
        <Button
          type="text"
          icon={<IconChat />}
          onClick={() => {
            if (user?.canCommunicate) {
              getDialogId(row as ISimilarCompany);
            } else {
              setIsOpenNoCommunications(true);
            }
          }}
        />
      ),
      align: 'right',
    },
  ];

  const COLUMNS: TableTypes.Column[] = React.useMemo(
    () => [
      {
        Header: '№',
        accessor: 'index',
        width: 48,
      },
      {
        Header: 'Участник',
        accessor: 'company.name',
        width: 160,
      },
      {
        Header: 'Объём выпуска',
        accessor: (row: unknown) => renderAmount(row as { amount: number }),
        width: 120,
        align: 'right',
      },
      {
        Header: 'Объём гар. участия',
        accessor: (row: unknown) => renderAmount(row as { amount: number }),
        width: 120,
        align: 'right',
      },
      {
        Header: 'Лет до погашения',
        accessor: 'maturity',
        width: 105,
        align: 'right',
      },
      {
        Header: 'Оферта',
        width: 120,
        accessor: (row: unknown) => renderProposalOffer(row as { put: number; call: number }),
      },
      {
        Header: 'Спред, ‱',
        id: 'spread',
        width: 120,
        accessor: (({ spread_value: spreadValue }: { spread_value: string }) =>
          spreadValue || '-') as unknown as string,
        align: 'right',
      },
      {
        Header: 'Ставка, %',
        width: 120,
        accessor: (({ rate }: { rate: string }) => rate || '-') as unknown as string,
        align: 'right',
      },
      {
        Header: 'Комментарий',
        width: 200,
        accessor: 'comment',
      },
    ],
    [data]
  );

  const tabProps = {
    participants: {
      columns: participantsColumns,
      data: participantsData,
      className: 'customer-bond-detail__participants',
      notFoundMessage: 'Список участников пуст',
      notFoundDescription: 'К этому запросу пока не добавлено ни одного участника.',
    },
    proposals: {
      columns: COLUMNS,
      data: data?.proposals || [],
      className: 'customer-bond-detail__grid',
      notFoundMessage: 'Список предложений пуст',
      notFoundDescription: 'К этому запросу пока не оставлено ни одного предложения.',
      initialState: {
        hiddenColumns: data?.rate_type === RateType.Fix ? ['spread'] : [],
      },
    },
  };
  const tabs = [
    {
      title: 'Таблица предложений',
      path: location.pathname.replace('/participants', ''),
    },
    {
      title: 'Участники',
      path: `${breadcrumbs[3].key}/participants`,
    },
  ];

  return (
    <DetailLayout
      title="Детализация запроса"
      className="customer-bond-detail"
      badgeParams={data && REQUEST_STATUSES_PARAMS[data.status]}
      mainInfo={<MainInfo data={data} />}
      extraInfoClassName="customer-bond-detail__extra-info"
      extraInfo={
        data && (
          <>
            <div className="customer-bond-detail__title-row">
              <Pills currentTab={currentTabNumber} tabs={tabs} />
              <Button
                className="customer-bond-detail__communication"
                onClick={() => {
                  if (user?.canCommunicate) {
                    if (participantsData.length === 1) {
                      getDialogId(participantsData[0]);
                    } else
                      setIsOpenCreateCommunication({
                        positions: [data],
                        requestBondId: requestId,
                        isGroup: false,
                      });
                  } else setIsOpenNoCommunications(true);
                }}
                icon={<Group />}
              >
                Коммуникация
              </Button>
              <Button
                className="customer-bond-detail__download"
                onClick={downloadReport()}
                icon={<IconFile />}
              >
                Выгрузить
              </Button>
            </div>
            <Table {...tabProps[currentTabName]} />
          </>
        )
      }
    >
      <div className="request-detail-group__process-buttons">
        {isActive && (
          <Button
            className="request-detail-group__process-buttons-remind"
            onClick={() => {
              openReminderNotification(true);
            }}
            size="large"
            type="primary"
          >
            Напомнить
          </Button>
        )}
        {data?.status === Status.Summarizing && (
          <Button
            onClick={() => {
              setConfirmationCompleteOpen(true);
            }}
            size="large"
            type="primary"
          >
            Завершить запрос
          </Button>
        )}
        <Button onClick={repeatRequest} size="large">
          Повторить запрос
        </Button>
        {data?.status === Status.Active && (
          <Button
            onClick={() => {
              setConfirmationCancelOpen(true);
            }}
            size="large"
            type="warning"
          >
            Отменить запрос
          </Button>
        )}

        {isConfirmationCancelOpen && (
          <Notification
            isOpen
            title="Отменить запрос"
            message="Вы уверены, что хотите отменить запрос? Все связанные с запросом предложения будут аннулированы."
            onConfirm={() => {
              cancelRequest();
              setConfirmationCancelOpen(false);
            }}
            onCancel={() => {
              setConfirmationCancelOpen(false);
            }}
            confirmBtnText="Отменить"
            cancelBtnText="Не отменять"
          />
        )}
        {isReminderNotificationOpen && (
          <Notification
            isOpen
            title="Привлечь участников"
            message="Всем участникам, которые могут, но еще не оставили своё предложение к запросу, будет отправлено уведомление. Отправить?"
            onConfirm={() => {
              sendRemindersToParticipants();
              openReminderNotification(false);
            }}
            onCancel={() => {
              openReminderNotification(false);
            }}
            confirmBtnText="Отправить"
            cancelBtnText="Отмена"
          />
        )}
        {isConfirmationCompleteOpen && (
          <Notification
            isOpen
            title="Завершить запрос"
            message="Вы можете написать комментарий, который будет отправлен всем участникам данной коммуникации."
            onConfirm={() => {
              if (!closingMessageError) {
                completeRequest();
                setConfirmationCompleteOpen(false);
              }
            }}
            onCancel={() => {
              setConfirmationCompleteOpen(false);
            }}
            confirmBtnText="Завершить"
            cancelBtnText="Отмена"
          >
            <div className="request-detail-group__notification-warning-text">
              Внимание! Изменить текст после отправки невозможно.
            </div>
            <Textarea
              name="comment"
              placeholder="Напишите комментарий"
              value={confirmationCompleteCommentText}
              onChange={(e) => {
                setClosingMessageError('');
                setConfirmationCompleteCommentText(e.target.value);
                if (e.target.value.length > COMMENT_RESULT_MAX_LENGTH)
                  setClosingMessageError(
                    `Длина поля не более ${COMMENT_RESULT_MAX_LENGTH} символов`
                  );
              }}
              className="request-detail-group__notification-comment"
              wrapperClassName="request-detail-group__notification-comment-wrapper"
              size="large"
              error={closingMessageError}
            />
          </Notification>
        )}

        {communicationProps && (
          <CreateDialogModal
            requestType="bond"
            {...communicationProps}
            isGroup={communicationProps.isGroup as boolean}
            onClose={() => {
              setIsOpenCreateCommunication(null);
            }}
          />
        )}
        {isOpenNoCommunications && (
          <NoCommunicationsModal
            onClose={() => {
              setIsOpenNoCommunications(false);
            }}
            disabledNavigate={!data}
          />
        )}
      </div>
    </DetailLayout>
  );
};

const COMMENT_RESULT_MAX_LENGTH = 1000;
