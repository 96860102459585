import React from 'react';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import VirtualizedList, { ListRowRenderer } from 'react-virtualized/dist/es/List';
import cn from 'classnames';
import {
  CellMeasurerCache,
  CellMeasurer,
  MeasuredCellParent,
} from 'react-virtualized/dist/es/CellMeasurer';

import { Scrollbars as ScrollbarElement } from 'react-custom-scrollbars-2';
import Scrollbar from '../Scrollbar/Scrollbar';

import './List.scss';

interface IRowsRenderedHandlerData {
  overscanStartIndex: number;
  overscanStopIndex: number;
  startIndex: number;
  stopIndex: number;
}

export type RowsRenderedHandler = (data: IRowsRenderedHandlerData) => void;

interface RowRendererData {
  index: number;
  isScrolling: boolean;
  isVisible: boolean;
  key: React.Key;
  parent: MeasuredCellParent;
  style: React.CSSProperties;
}

export type IRowRenderer = (data: RowRendererData, reCalculate: () => void) => React.ReactNode;

export interface IProps {
  isRowLoaded: (data: { index: number }) => boolean;
  loadMoreRows: (data: { startIndex: number; stopIndex: number }) => Promise<unknown>;
  rowRenderer: IRowRenderer;
  items?: unknown[];
  totalCount: number;
  rowHeight?: number | (({ index }: { index: number }) => number);
  threshold?: number;
  overscanRowCount?: number;
  ref?: React.RefObject<List>;
  onRowsRendered?: RowsRenderedHandler;
  noRowsRenderer?: (() => JSX.Element) | undefined;
  scrollToIndex?: number;
  defaultRowHeight?: number;
  minRowHeight?: number;
  wrapperClassName: string;
}

export type ListComponent = List;

// TODO: переписать на функциональный компонент
// TODO: заменить библиотеку на Virtuoso
export class List extends React.Component<IProps> {
  static defaultProps = {
    loadMoreRows() {},
    isRowLoaded: () => true,
    threshold: 15,
    overscanRowCount: 2,
    defaultRowHeight: 70,
    startFromBottom: false,
  };

  readonly cache = new CellMeasurerCache({
    defaultHeight: this.props.defaultRowHeight || 60,
    minHeight: this.props.minRowHeight || 70,
    fixedWidth: true,
  });

  readonly scrollRef = React.createRef<ScrollbarElement>();
  private listRef: VirtualizedList | null = null;
  // @ts-expect-error check types
  private measure: () => unknown | null = null;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {
    const {
      threshold,
      overscanRowCount,
      isRowLoaded,
      totalCount,
      noRowsRenderer,
      wrapperClassName,
      scrollToIndex,
    } = this.props;

    return (
      <div className={cn('custom-list', wrapperClassName)}>
        <AutoSizer>
          {({ width, height }) =>
            width &&
            height && (
              <Scrollbar
                style={{ width, height }}
                onScroll={({ target }) => {
                  // @ts-expect-error check
                  const { scrollTop, scrollLeft } = target;
                  this.listRef?.Grid?.handleScrollEvent({ scrollTop, scrollLeft });
                }}
                scrollRef={this.scrollRef}
              >
                <InfiniteLoader
                  isRowLoaded={isRowLoaded}
                  loadMoreRows={this.loadMoreRows}
                  rowCount={totalCount}
                  threshold={threshold}
                >
                  {({ onRowsRendered, registerChild }) => {
                    return (
                      <VirtualizedList
                        ref={this.setListRef(registerChild)}
                        overscanRowCount={overscanRowCount}
                        width={width}
                        height={height}
                        deferredMeasurementCache={this.cache}
                        rowHeight={this.cache.rowHeight}
                        rowCount={totalCount}
                        rowRenderer={this.rowRender}
                        style={disableOverflow as unknown as React.CSSProperties}
                        onRowsRendered={this.handleRowsRendered(onRowsRendered)}
                        noRowsRenderer={noRowsRenderer}
                        scrollToAlignment="start"
                        scrollToIndex={scrollToIndex}
                        onScroll={({ scrollTop }) => {
                          this.scrollRef.current?.scrollTop(scrollTop);
                        }}
                      />
                    );
                  }}
                </InfiniteLoader>
              </Scrollbar>
            )
          }
        </AutoSizer>
      </div>
    );
  }

  readonly rowRender: ListRowRenderer = (data): JSX.Element => {
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={data.key}
        parent={data.parent}
        rowIndex={data.index}
        style={data.style}
      >
        {({ registerChild, measure }) => {
          this.measure = measure.bind(this);
          return (
            <div
              tabIndex={-1}
              // @ts-expect-error check types
              ref={registerChild}
              style={data.style}
            >
              {this.props.rowRenderer(data, () => {})}
            </div>
          );
        }}
      </CellMeasurer>
    );
  };

  componentDidUpdate(prevProps: IProps): void {
    if (this.props.items?.length !== prevProps.items?.length) {
      this.cache.clearAll();
      this.measure();
      this.listRef?.recomputeRowHeights();
    }
  }

  readonly handleScroll = (e: React.UIEvent<ScrollbarElement>): void => {
    const { scrollTop, scrollLeft } = e.target as Element;
    this.listRef?.Grid?.handleScrollEvent?.({ scrollTop, scrollLeft });
  };

  readonly loadMoreRows = async (data: {
    startIndex: number;
    stopIndex: number;
  }): Promise<void> => {
    await this.props.loadMoreRows(data);
  };

  readonly setListRef =
    (infLoaderRegisterChild: (registeredChild: unknown) => void) => (element: VirtualizedList) => {
      this.listRef = element;
      infLoaderRegisterChild(element);
    };

  readonly handleRowsRendered =
    (
      infLoaderOnRowsRendered: (registeredChild: { startIndex: number; stopIndex: number }) => void
    ) =>
    (data: IRowsRenderedHandlerData): void => {
      infLoaderOnRowsRendered(data);
      this.props.onRowsRendered?.(data);
    };
}

// https://github.com/bvaughn/react-virtualized/issues/692
const disableOverflow = {
  overflowX: false,
  overflowY: false,
};

export default List;
