import React from 'react';
import cn from 'classnames';
import * as ReactTable from 'react-table';
import NotFound from '@components/NotFound/NotFound';

import { defaultPageSizes, TableBody, TableHead, TablePagination } from './TableParts';
import type * as TableTypes from './types';

import './style.scss';

function getRowId(
  row: { id?: string },
  relativeIndex: number,
  parent?: TableTypes.Row
): string | number {
  // eslint-disable-next-line
  const currentRowId = row.id || relativeIndex;
  return parent !== undefined ? [parent.id, currentRowId].join('.') : currentRowId;
}

const Table: React.FC<TableTypes.CustomTableProps> = (props) => {
  const {
    data,
    selectedRowIds = {},
    onFetchData,
    pageIndex: pageIndexFromProps = 0,
    pageSize: pageSizeFromProps,
    isLoading,
    controlledPageCount,
    withPagination = false,
    withPreloadedData = onFetchData === undefined,
    wrapperClassName,
    styleWrapper,
    className,
    expanded,
    columns,
    notFoundMessage,
    notFoundDescription,
    initialState: customInitialState = {},
  } = props;

  const refTableScrollbar = React.useRef<HTMLDivElement>(null);
  const refTableHead = React.useRef<HTMLDivElement>(null);
  const refTableBody = React.useRef<HTMLDivElement>(null);

  const horizontalScrollPosition = React.useRef(0);

  const correctPageSizeFromProps =
    // eslint-disable-next-line
    pageSizeFromProps ||
    // eslint-disable-next-line
    (props.customPageSizes?.length && props.customPageSizes[0]) ||
    defaultPageSizes[0];

  const initialState: Partial<ReactTable.TableState> = {
    pageIndex: pageIndexFromProps,
    pageSize: correctPageSizeFromProps,
    selectedRowIds,
    ...customInitialState,
  };

  const argsForPreloadedData = [
    {
      columns,
      data,
      initialState,
      getRowId,
      autoResetExpanded: false,
    },
    ReactTable.useExpanded,
    ReactTable.usePagination,
    ReactTable.useFlexLayout,
    ReactTable.useRowSelect,
  ] as [ReactTable.TableOptions<object>, ...Array<ReactTable.PluginHook<object>>];

  const argsForFetchingData = [
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
      initialState,
      getRowId,
      autoResetExpanded: false,
    },
    ReactTable.useExpanded,
    ReactTable.useFlexLayout,
    ReactTable.usePagination,
    ReactTable.useRowSelect,
  ] as [ReactTable.TableOptions<object>, ...Array<ReactTable.PluginHook<object>>];

  const table = ReactTable.useTable(
    ...(withPreloadedData ? argsForPreloadedData : argsForFetchingData)
  );

  const commonProps = {
    ...props,
    ...table,
    refTableScrollbar,
    refTableHead,
    refTableBody,
  };

  const { pageIndex, pageSize } = table.state;

  React.useEffect(() => {
    !withPreloadedData && onFetchData && onFetchData(table.state);
  }, [withPreloadedData, pageIndex, pageSize]);

  React.useEffect(() => {
    table.toggleAllRowsExpanded(Boolean(expanded));
  }, [expanded]);

  React.useEffect(() => {
    if (pageIndexFromProps !== pageIndex) {
      table.gotoPage(pageIndexFromProps);
    }
  }, [pageIndexFromProps]);

  React.useEffect(() => {
    if (correctPageSizeFromProps !== pageSize) {
      table.setPageSize(correctPageSizeFromProps);
    }
  }, [correctPageSizeFromProps]);

  const handleHorizontalScroll: (event: Event) => void = (event: Event) => {
    if (refTableHead.current === null || !(event.currentTarget instanceof Element)) return;
    horizontalScrollPosition.current = event.currentTarget.scrollLeft;
    const tableHeaderRow = refTableHead.current;
    tableHeaderRow.style.marginLeft = `-${refTableBody.current?.scrollLeft}px`;
  };

  React.useEffect(() => {
    if (data.length === 0) {
      horizontalScrollPosition.current = 0;
      if (refTableHead.current !== null) {
        refTableHead.current.style.marginLeft = '0';
      }
    }

    if (isLoading === true || data?.length === 0 || refTableBody.current === null) return undefined;

    refTableBody.current.scrollLeft = horizontalScrollPosition.current;

    refTableBody.current.addEventListener('scroll', handleHorizontalScroll);

    return () => {
      refTableBody.current?.removeEventListener('scroll', handleHorizontalScroll);
    };
  }, [isLoading, data?.length]);

  const isShowNotFound = isLoading !== true && data?.length === 0;

  return (
    <div className={cn(wrapperClassName, 'custom-table__wrapper')} style={styleWrapper}>
      <div {...table.getTableProps({ className: cn('custom-table', className) })}>
        <div
          className={cn(
            'custom-table__content',
            data?.length === 0 && 'custom-table__content--no-data'
          )}
        >
          {!isShowNotFound && <TableHead {...commonProps} />}
          {isLoading === true ? (
            <div className="custom-table__loading">Загрузка...</div>
          ) : data?.length !== 0 ? (
            <TableBody ref={refTableBody} {...commonProps} />
          ) : null}

          {isShowNotFound && (
            <NotFound message={notFoundMessage} description={notFoundDescription} />
          )}
        </div>
      </div>
      {withPagination && <TablePagination {...commonProps} />}
    </div>
  );
};

export default React.memo(Table);

export * from './TableParts';
export * from './types';
