import React from 'react';
import Notification from '@components/Modal/Notification/Notification';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { useFormikContext } from 'formik';

const useBeforeUnload = ({ when, message }: { when: boolean; message: string }): void => {
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when, message]);
};

const AutoCheckForm = ({
  title,
  onConfirm,
  message,
  confirmBtnText,
  cancelBtnText,
}: {
  title: string;
  onConfirm: () => void;
  message: string;
  confirmBtnText: string;
  cancelBtnText: string;
}): JSX.Element => {
  const { dirty, isSubmitting } = useFormikContext();
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return dirty && !isSubmitting && currentLocation.pathname !== nextLocation.pathname;
  });

  useBeforeUnload({
    when: dirty && !isSubmitting,
    message,
  });

  return (
    <Notification
      isOpen={blocker.state === 'blocked'}
      title={title}
      message={message}
      onConfirm={() => {
        blocker?.proceed?.();
        onConfirm();
      }}
      onCancel={() => {
        blocker?.reset?.();
      }}
      confirmBtnText={confirmBtnText}
      cancelBtnText={cancelBtnText}
    />
  );
};

export default AutoCheckForm;
