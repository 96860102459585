export enum ROUTE_LOGS {
  INFO = 'Справка',
  SUPPORT = 'Поддержка',
  PROFILE = 'Профиль',
  ADMIN = 'Администрирование',

  CREDIT_REQUESTS = 'Кредиты - Запросы',
  CREDIT_PARTICIPANTS = 'Кредиты - Участники',
  CREDIT_CUSTOMERS = 'Кредиты - Заказчики',

  DEPOSIT_REQUESTS = 'Депозиты - Запросы',
  DEPOSIT_PARTICIPANTS = 'Депозиты - Участники',
  DEPOSIT_CUSTOMERS = 'Депозиты - Заказчики',

  BOND_REQUESTS = 'Облигации - Запросы',
  BOND_PARTICIPANTS = 'Облигации - Участники',
  BOND_CUSTOMERS = 'Облигации - Заказчики',
}
