import Button from '@components/Button/Button';
import NotFound from '@components/NotFound/NotFound';

export const EmptyProposals = ({
  hasFakeProposal,
  canEditMyProposal,
  onSendProposal,
  onRefuseProposal,
  message = 'Список предложений недоступен',
  description = 'Список станет доступен после того, как вы сделаете своё предложение',
}: {
  hasFakeProposal?: boolean;
  canEditMyProposal?: boolean;
  onSendProposal: () => void;
  onRefuseProposal: () => void;
  message?: string;
  description?: string;
}): JSX.Element => {
  return hasFakeProposal ? (
    <NotFound
      message="Вы отказались от запроса"
      description="Вы можете изменить решение и подать предложение"
    >
      {canEditMyProposal ? (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            onSendProposal();
          }}
        >
          Подать предложение
        </Button>
      ) : null}
    </NotFound>
  ) : (
    <NotFound message={message} description={description}>
      {canEditMyProposal ? (
        <div style={{ display: 'flex', gap: 16 }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              onSendProposal();
            }}
          >
            Подать предложение
          </Button>
          <Button
            size="large"
            onClick={() => {
              onRefuseProposal();
            }}
          >
            Отказаться от запроса
          </Button>
        </div>
      ) : null}
    </NotFound>
  );
};
