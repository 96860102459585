import type Keycloak from 'keycloak-js';
import { type IUserInfo } from './auth.model';

/* todo for evolution SPA and appearance open pages
import { INVALID_AUTHORITY, MIN_SEC_VALIDITY, SKIP_AUTH_PATHS } from './auth.const';
import { CustomError } from '../../../shared/utils/customError.util'; */

export class AuthService {
  private static isValidKeycloakInstance(keycloak: Keycloak): boolean {
    return !!keycloak;
  }

  public static isAuthenticated(keycloak: Keycloak): boolean {
    return AuthService.isValidKeycloakInstance(keycloak) && !!keycloak.authenticated;
  }

  public static getUserInfo(keycloak: Keycloak): IUserInfo | null {
    return AuthService.isValidKeycloakInstance(keycloak)
      ? {
          fullName: keycloak.tokenParsed?.name,
          name: keycloak.tokenParsed?.given_name,
          lastName: keycloak.tokenParsed?.family_name,
          email: keycloak.tokenParsed?.email,
          groups: keycloak.tokenParsed?.groups,
        }
      : null;
  }

  public static async login(keycloak: Keycloak): Promise<void> {
    AuthService.isValidKeycloakInstance(keycloak) && (await keycloak.login());
  }

  public static async logout(keycloak: Keycloak): Promise<void> {
    AuthService.isValidKeycloakInstance(keycloak) && (await keycloak.logout());
  }

  public static async fetch<T>(
    /*  keycloak: Keycloak, */
    path: string,
    fetchOptions?: Record<string, unknown>
  ): Promise<T | undefined> {
    try {
      return {} as T;

      /* after connect axios library
      return await $fetch(path, {
        ...fetchOptions,
        headers: {
          ...keycloak?.token ? { Authorization: `Bearer ${keycloak.token}` } : {}
        }
      }); */

      /* todo for evolution SPA and appearance open pages
        if (AuthService.isValidAuthority(keycloak, path)) {
          await AuthService.updateToken(keycloak);
          return {} as T; // after connect axios library - return await $fetch(path, fetchOptions);
        }
        throw new CustomError(INVALID_AUTHORITY, 'Ошибка проверки валидности авторизации'); */
    } catch (error) {
      console.error(error);

      /* todo for evolution SPA and appearance open pages
      (error as CustomError)?.name === INVALID_AUTHORITY && (await AuthService.logout(keycloak)); */
    }
  }

  /* todo for evolution SPA and appearance open pages
  private static async updateToken(
    keycloak: Keycloak,
    minSecValidity = MIN_SEC_VALIDITY
  ): Promise<void> {
    if (
      !AuthService.isValidKeycloakInstance(keycloak) ||
      !keycloak?.isTokenExpired(minSecValidity)
    ) {
      return;
    }

    try {
      await keycloak.updateToken(minSecValidity);
    } catch (error) {
      console.error('Update token failed', error);
      throw new CustomError(INVALID_AUTHORITY, 'Ошибка обновления токена');
    }
  }

  private static getRefreshTokenExpiredSec(keycloak: Keycloak): number {
    if (!AuthService.isValidKeycloakInstance(keycloak) || !keycloak?.refreshTokenParsed?.exp) {
      return 0;
    }
    return Math.round((keycloak.refreshTokenParsed.exp + (keycloak.timeSkew || 0) - new Date().getTime() / 1000));
  }

  private static isValidAuthority(keycloak: Keycloak, path: string): boolean {
    console.log(AuthService.getRefreshTokenExpiredSec(keycloak))
    if (SKIP_AUTH_PATHS.includes(path)) {
      return true;
    }

    return (
      AuthService.isValidKeycloakInstance(keycloak) &&
      !!keycloak.token &&
      !!keycloak.refreshToken &&
      !!AuthService.getRefreshTokenExpiredSec(keycloak)
    );
  } */
}

export default AuthService;
