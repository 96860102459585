import React from 'react';
import ReactModal, { type Styles } from 'react-modal';
import cn from 'classnames';

import './Modal.scss';

const getStyle = (
  overlayStyle?: React.CSSProperties,
  contentStyle?: React.CSSProperties,
  overlayColor?: string
): Styles => {
  if (!overlayStyle && !contentStyle && !overlayColor) return defaultStyle as Styles;

  return {
    overlay: {
      ...defaultOverlayStyles,
      ...overlayStyle,
      backgroundColor: overlayColor ?? defaultOverlayStyles.backgroundColor,
    },
    // @ts-expect-error Types are not assignable
    content: {
      ...defaultContentStyles,
      ...contentStyle,
    },
  };
};

interface IHeaderProps {
  title?: JSX.Element | string;
  onCancel?: () => void;
  className?: string;
  titleClassName?: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

interface IActionsProps {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

interface IProps extends ReactModal.Props {
  title?: string;
  onCancel?: () => void;
  actions?: React.ReactNode | React.ReactNodeArray;
  overlayColor?: string;
  overlayStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

export class Modal extends React.Component<IProps> {
  static Header: React.FunctionComponent<IHeaderProps> = (props): JSX.Element => (
    <div className={cn('custom-modal-header', props.className)}>
      {props.title && (
        <h2 className={cn('custom-modal-title', props.titleClassName)}>{props.title}</h2>
      )}
      {props.children}
    </div>
  );

  static Actions: React.FunctionComponent<IActionsProps> = (props): JSX.Element => {
    const actionsClass = cn('custom-modal-actions', props.className);
    return <div className={actionsClass}>{props.children}</div>;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {
    const {
      children,
      title,
      actions,
      onCancel,
      className,
      overlayColor,
      overlayStyle,
      contentStyle = {},
      ...restProps
    } = this.props;

    const { top, left, right, ...restContentStyle } = contentStyle;
    const contentClass = cn('custom-modal-content', className as cn.Argument);
    const modalStyle = getStyle(overlayStyle, restContentStyle, overlayColor);
    return (
      <ReactModal {...restProps} style={modalStyle}>
        <div className={contentClass}>
          {title && <Modal.Header title={title} onCancel={onCancel} />}
          {children}
        </div>
        {actions && <Modal.Actions>{actions}</Modal.Actions>}
      </ReactModal>
    );
  }
}

const defaultContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  padding: '32px',
  borderRadius: '12px',
  border: 'none',
  boxShadow: '0px 18px 60px -4px rgba(34, 34, 34, 0.06), 0px 8px 20px -6px rgba(34, 34, 34, 0.06)',
};
const defaultOverlayStyles = {
  backdropFilter: 'blur(3px)',
  backgroundColor: 'rgba(250, 250, 250, 0.8)',
  zIndex: 'var(--modal-overlay-z-index, 2000)',
};

const defaultStyle = { overlay: defaultOverlayStyles, content: defaultContentStyles };
