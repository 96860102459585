import React from 'react';

import { BOND_RATE_TYPES, BOND_SPREAD_TYPES_TITLES, BOND_FLOATERS_TITLES } from '@constants';
import {
  renderMinMaxAmount,
  renderMaturity,
  renderOfferPutCallFromTo,
  renderCreatorCompany,
} from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { RateType } from '@models/base.model';
import type { IRequestBondModel } from '../request-bond.model';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';

interface IProps {
  data?: IRequestBondModel;
}

export const MainInfo: React.FC<IProps> = ({ data }: IProps) => {
  if (!data) return null;

  const dataForBox = [
    [
      {
        label: 'Заказчик',
        value: renderCreatorCompany({ creatorCompany: data.company_creator }),
      },
      {
        label: 'Объём выпуска',
        value: renderMinMaxAmount({ minAmount: data.min_amount, maxAmount: data.max_amount }),
      },
      {
        label: 'Срок облигации до погашения',
        value: renderMaturity({ maturityFrom: data.maturity_from, maturityTo: data.maturity_to }),
      },
      {
        label: 'Оферта',
        value: renderOfferPutCallFromTo(data),
      },
      {
        label: 'Купон',
        value:
          BOND_RATE_TYPES[data.rate_type] +
          (data.rate_type !== RateType.Fix ? ` (${BOND_FLOATERS_TITLES[data.rate_type]})` : ''),
      },
      {
        label: 'Спред к кривой ОФЗ',
        value: data.spread_type && BOND_SPREAD_TYPES_TITLES[data.spread_type],
        hidden: data.rate_type !== 'OFZ',
      },
      {
        label: 'Амортизация',
        value: data.has_amortization ? 'Есть' : 'Нет',
      },
    ],
    [
      {
        label: 'Услуга',
        value: 'Облигации',
      },
      { label: 'Комментарий', value: data.comment_ext },
      {
        label: 'Даты размещения запроса',
        value: `с ${formatDate(data.start_ts)} по ${formatDate(data.end_ts)}`,
      },
    ],
  ];

  return <InfoBox title={data.name} data={dataForBox} />;
};
