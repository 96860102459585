import React from 'react';
import { HttpClient } from '@api/HttpClient';
import Container from '@components/Container/Container';
import cn from 'classnames';

import './Hint.scss';

export const Hint = ({
  className,
  config,
  onLoad,
}: {
  className?: string;
  config?: string;
  onLoad?: () => Promise<string>;
}): JSX.Element => {
  const [html, setHtml] = React.useState('<div></div>');

  const loadHintByConfig = (): void => {
    HttpClient.get<{ value: string }>(`common/configs/${config}/`).then((data) => {
      setHtml(data?.value);
    });
  };

  const load = async (): Promise<void> => {
    if (!onLoad) return;

    try {
      const data = await onLoad();
      setHtml(data);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (config) loadHintByConfig();
    else if (onLoad) load();
  }, []);

  return (
    <Container direction="vertical" className={cn('custom-hint', className)} fullWidth={false}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
};

export default Hint;
