import { type CSSProperties } from 'react';
import cn from 'classnames';

import './Checkbox.scss';

export interface IProps {
  label?: React.ReactNode;
  labelPosition?: 'left' | 'right';
  size?: number;
  error?: string;
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: unknown;
  labelClassName?: string;
  readOnly?: boolean;
  name: string;
}

const Checkbox: React.FC<IProps> = ({
  label,
  labelPosition = 'left',
  size,
  className,
  labelClassName,
  error,
  indeterminate,
  checked,
  disabled,
  readOnly,
  onChange,
  name,
}: IProps) => {
  const style = size ? ({ '--size': `${size}px` } as CSSProperties) : {};

  const checkboxClass = cn('custom-checkbox', className as never, {
    'custom-checkbox--left': labelPosition === 'left',
    'custom-checkbox--right': labelPosition === 'right',
    'custom-checkbox--error': error,
  });

  return (
    <div className={checkboxClass}>
      <input
        name={name}
        id={name}
        type="checkbox"
        style={style}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
      />
      <label className={labelClassName} htmlFor={name}>
        {label && <span className="custom-checkbox__text">{label}</span>}
      </label>
      {error && <span className="custom-checkbox__error">{error}</span>}
    </div>
  );
};

export default Checkbox;
