import React from 'react';
import cn from 'classnames';

import './Container.scss';

interface IProps {
  className?: string;
  fullWidth?: boolean;
  direction?: 'vertical' | 'horizontal';
  children?: React.ReactNode;
}
class Container extends React.Component<IProps> {
  static Header = ({
    children,
    className,
  }: {
    children: JSX.Element | string;
    className?: string;
  }): JSX.Element => <div className={cn('custom-container-title', className)}>{children}</div>;

  static Field = ({
    label,
    value = '-',
  }: {
    label: string;
    value: string | null | undefined | number | JSX.Element;
  }): JSX.Element => (
    <div className="custom-container-field">
      <div className="custom-container-field__row">
        {label}
        <div className="custom-container-field__row-value">{value}</div>
      </div>
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {
    const { className, children, fullWidth = true, direction = 'horizontal' } = this.props;
    return (
      <div
        className={cn(className, 'custom-container', `custom-container--${direction}`, {
          'custom-container--full-width': fullWidth,
        })}
      >
        {children}
      </div>
    );
  }
}

export default Container;
