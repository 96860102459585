import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { HttpClient } from '@api/HttpClient';
import RangeDatepicker from '@components/RangeDatepicker/RangeDatepicker';
import { ReactComponent as Search } from '@shared/icons/search.svg';
import Select from '@components/Select/Select';
import { Input } from '@components/Input/Input';
import { IDictionary, IDictionaryCompany } from '@models/base.model';
import { IRequestUserInfo } from '@models/user.model';
import { AuthUtil } from '@services/auth/auth.util';
import { CallbackFunctionVariadic } from 'types';

import './Filters.scss';

export interface IFiltersProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  setDateRange: CallbackFunctionVariadic;
  actions: string[];
  setActions: CallbackFunctionVariadic;
  userNames: IDictionaryCompany[];
  setUserNames: CallbackFunctionVariadic;
  requestId: string;
  setRequestId: CallbackFunctionVariadic;
}

const Filters: React.FC<IFiltersProps> = ({
  className,
  startDate,
  endDate,
  setDateRange,
  actions,
  setActions,
  requestId,
  setRequestId,
  userNames,
  setUserNames,
}) => {
  const [users, setUsers] = useState<IDictionary[]>([]);
  const [actionTypes, setActionTypes] = useState<IDictionary[]>([]);

  const [_actions, _setActions] = React.useState<string[]>(actions);
  const [_userNames, _setUserNames] = React.useState<IDictionaryCompany[]>(userNames);

  useEffect(() => {
    HttpClient.get<IRequestUserInfo[]>('operator/users/filters/').then((resp) => {
      const users = resp.map((item) => ({
        label: AuthUtil.getFullNameAbbreviation(
          item.last_name ?? '',
          item.first_name ?? '',
          item.patronymic ?? ''
        ),
        value: item.id,
      }));
      setUsers(users);
    });

    HttpClient.post<undefined, object>('operator/logs/actions/list/').then((resp) => {
      const actionTypes = Object.values(resp).map((item, index) => ({
        label: item,
        value: index,
      }));
      setActionTypes(actionTypes);
    });
  }, []);

  return (
    <div className={cn('operator-filters', className)}>
      <RangeDatepicker
        wrapperClassName="operator-filters__range"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
      />
      <Select
        options={actionTypes}
        isMulti
        isClearable
        placeholder="Выберите событие"
        value={_actions}
        className="operator-filters__actions"
        onChange={(s: unknown) => {
          _setActions(s as string[]);
        }}
        onMenuClose={() => {
          setActions(_actions);
        }}
        onClear={() => {
          setActions([]);
        }}
      />
      <Select
        options={users}
        isMulti
        isClearable
        placeholder="Выберите пользователей"
        value={_userNames}
        className="operator-filters__user-names"
        onChange={(s: unknown) => {
          _setUserNames(s as IDictionaryCompany[]);
        }}
        onMenuClose={() => {
          setUserNames(_userNames);
        }}
        onClear={() => {
          setUserNames([]);
        }}
      />
      <Input
        icon={<Search />}
        type="number"
        className="operator-filters__request-id"
        value={requestId}
        placeholder="Полный ID запроса"
        onChange={(e: { target: { value: unknown } }) => {
          setRequestId(e.target.value);
        }}
      />
    </div>
  );
};

export default Filters;
