import React from 'react';
import cn from 'classnames';
import { useRadioContext, type TRadioContext } from './RadioContext';
import type { TRadioValue } from './index';

type TProps = {
  id?: string;
  value: TRadioValue;
  disabled?: boolean;
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

const RadioButton: React.FC<TProps> = ({
  id,
  value,
  children,
  disabled: buttonDisabled,
  className,
  ...rest
}) => {
  const {
    selectedValue,
    onChange,
    name,
    disabled: groupDisabled,
  } = useRadioContext() as TRadioContext;

  const checked = value === selectedValue;
  const disabled = groupDisabled ?? buttonDisabled;

  const inputId = React.useRef(id);

  return (
    <div className="custom-radio-button">
      <input
        id={inputId.current}
        name={name}
        value={value}
        checked={checked}
        type="radio"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="custom-radio-button__input"
        disabled={disabled}
        {...rest}
      />
      <label className={cn('custom-radio-button__label', className)} htmlFor={inputId.current}>
        {children}
      </label>
    </div>
  );
};

export default RadioButton;
