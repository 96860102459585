import React from 'react';
import { Formik, Form } from 'formik';
import { Modal } from '@components/Modal/Modal';
import AsyncSelect from '@components/Select/AsyncSelect';
import { Textarea } from '@components/Textarea/Textarea';
import Button from '@components/Button/Button';
import { CreateDialogSchema } from './CreateDialogModal.validation';

import { IDictionary } from '@models/base.model';
import CommunicationService from '@services/communication/communication.service';

import './CreateDialogModal.scss';

interface IProps {
  readonly?: boolean;
  onSave: (newDialogId: number) => void;
  onClose: () => void;
}

interface IValues {
  topic: string;
  recipient: IDictionary | null;
}
export const CreateDialogModal: React.FC<IProps> = (props) => {
  const promiseOptions = async (inputValue: string): Promise<IDictionary[]> => {
    const resp = await CommunicationService.getCompaniesForDialog(inputValue);
    return resp.map((el) => ({ label: el.name, value: el.id }));
  };

  const createDialog = async ({ recipient, topic }: IValues): Promise<void> => {
    const newDialog = await CommunicationService.createDialog({
      recipient_company_ids: recipient?.value ? [+recipient?.value] : [],
      title: topic,
    });

    props.onSave(newDialog[0]?.id);
  };

  return (
    <Modal isOpen className="create-dialog-modal">
      <Modal.Header>
        <h3 className="create-dialog-modal__title">Создать коммуникацию</h3>
      </Modal.Header>
      <Formik<IValues>
        enableReinitialize={false}
        initialValues={{ topic: '', recipient: null }}
        validateOnBlur
        validateOnChange
        validationSchema={CreateDialogSchema}
        onSubmit={createDialog}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="create-dialog-modal__content">
                Чтобы начать новый диалог, выберите адресата и укажите тему коммуникации.
                <AsyncSelect
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  value={values.recipient}
                  placeholder="Выберите адресата*"
                  onChange={async (e) => await setFieldValue('recipient', e)}
                  size="large"
                  error={errors.recipient}
                />
                <Textarea
                  label="Тема коммуникации"
                  value={values.topic}
                  placeholder="Укажите тему"
                  size="large"
                  isClearable
                  onChange={(e) => {
                    setFieldValue('topic', e.target?.value);
                  }}
                  error={errors.topic}
                />
              </div>
              <Modal.Actions>
                <Button type="primary" size="large" htmlType="submit" fullWidth>
                  Создать
                </Button>
                <Button size="large" onClick={props.onClose} fullWidth>
                  Отмена
                </Button>
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
