import React from 'react';
import Container from '@components/Container/Container';
import type * as ReactTable from 'react-table';
import Button from '@components/Button/Button';
import Table, { ExpandableRow } from '@components/Table';
import { cloneDeep } from '@utils';
import { getFullname } from '@functions';
import { MEMBER_STATUSES_PARAMS } from '@constants';
import type * as TableTypes from '@components/Table/types';
import Badge from '@components/Badge/Badge';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Plus } from '@shared/icons/plus.svg';
import { ReactComponent as IconTrash } from '@shared/icons/trash.svg';
import {
  requestTypeIndexByCode,
  renderPermissionCheckbox,
  PERMISSIONS,
} from '@pages/operator/utils';
import OperatorService from '@services/operator/operator.service';
import { IDictionary, IDictionaryCompany } from '@models/base.model';
import { IParticipantOfCustomerForAdmin, IRequestType } from '../../operator.model';
import AddCompanyModal from '../../components/AddCompanyModal/AddCompanyModal';

import './ParticipantsTable.scss';

interface IProps {
  data?: IParticipantOfCustomerForAdmin[];
  onEdit: (data: IParticipantOfCustomerForAdmin[]) => void;
}

export const ParticipantsTable: React.FC<IProps> = ({ data = [], onEdit }: IProps) => {
  const getInitialData = (): IParticipantOfCustomerForAdmin[] => addSubRows(cloneDeep(data));
  const [isEdited, setEdited] = React.useState(false);
  const [editedData, setEditedData] = React.useState<IParticipantOfCustomerForAdmin[]>([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const [participants, setParticipants] = React.useState<IDictionary[]>([]);

  const loadParticipants = async (): Promise<void> => {
    try {
      const existedParticipantsIds = data.map((el) => el.id.toString());
      const responseData = await OperatorService.getParticipants();
      setParticipants(
        responseData
          ?.filter((el) => !existedParticipantsIds.includes(el.id))
          ?.map((el: IDictionaryCompany) => ({ label: el.name, value: el.id }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    setEditedData(getInitialData());
    loadParticipants();
  }, [data]);

  const handleChangePermission = (
    participantIndex: number,
    code: IRequestType['code'],
    newVal: boolean
  ): void => {
    if (!isEdited) return;
    const columnIndex = requestTypeIndexByCode(editedData[participantIndex].request_types, code);
    editedData[participantIndex].request_types[columnIndex].is_active = newVal;

    setEditedData([...editedData]);
  };

  const handleAddRow = (row: Partial<IParticipantOfCustomerForAdmin>): void => {
    // @ts-expect-error eslint-disable-next-line Вставляем неполную строку И сразу сохраняем
    onEdit([...data, row]);
  };

  const handleRemoveRow = (rowId: number): void => {
    setEditedData(editedData.filter((row) => row.id !== rowId));
  };

  const handleSwitchEdit = (): void => {
    if (isEdited) {
      // Отмена редактирования без сохр
      setEditedData(getInitialData());
    }

    setEdited((prevState) => !prevState);
  };

  const handleSave = (): void => {
    isEdited && onEdit(editedData);
    setEdited(false);
  };

  const handleCancel = (): void => {
    setEditedData(getInitialData());
    setEdited(false);
  };

  const columns: TableTypes.Column[] = React.useMemo(
    () =>
      [
        {
          Header: 'Название',
          accessor: ({ name, inn, is_blocked: isBlocked }: IParticipantOfCustomerForAdmin) => {
            return (
              <div className="customer-detail-participants-table__member">
                <div className="customer-detail-participants-table__name">{name}</div>
                <div className="customer-detail-participants-table__inn">{inn}</div>
                {isBlocked && <Badge {...MEMBER_STATUSES_PARAMS.BLOCKED} size="tiny" />}
              </div>
            );
          },
          width: 200,
        },
        {
          Header: 'К-во участников',
          accessor: 'users_count',
          width: 128,
          align: 'right',
        },
        {
          Header: 'Кредиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.CREDIT, isEdited),
          width: 80,
          align: 'center',
        },
        {
          Header: 'Депозиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.DEPOSIT, isEdited),
          width: 80,
          align: 'center',
        },
        {
          Header: 'Облигации',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.BOND, isEdited),
          width: 80,
          align: 'center',
        },
        {
          id: 'remove',
          Cell: ({ row }: { row: ReactTable.Row<IParticipantOfCustomerForAdmin> }) => (
            <Button
              type="text"
              onClick={() => {
                handleRemoveRow(row.original.id);
              }}
              className="customer-detail-participants-table__remove-button"
              icon={<IconTrash />}
              disabled={!isEdited}
            />
          ),
          width: 300,
          align: 'right',
        },
        {
          id: 'expand',
          Cell: ({ row }: { row: ReactTable.Row<IParticipantOfCustomerForAdmin> }) => (
            <ExpandableRow row={row} />
          ),
          width: 40,
          align: 'right',
        },
      ] as TableTypes.Column[],
    [editedData, isEdited]
  );

  return (
    <>
      <Container direction="vertical" className="customer-detail-participants-table">
        <Container.Header className="customer-detail-participants-table__header">
          Компании-участники
        </Container.Header>
        <Table
          data={editedData}
          columns={columns}
          withAlternating
          className="customer-detail-participants-table__grid"
        />
        <div className="customer-detail-participants-table__actions">
          <Button
            type="without-border"
            icon={<Plus />}
            onClick={() => {
              setIsOpenModal(true);
            }}
          />
          {(editedData?.length && (
            <Button
              type="without-border"
              icon={<Edit />}
              title={isEdited ? 'Отменить' : 'Редактировать'}
              onClick={handleSwitchEdit}
            />
          )) ||
            null}
        </div>

        {isEdited && (
          <div className="customer-detail-participants-table__edit-actions">
            <Button type="primary" onClick={handleSave}>
              Сохранить
            </Button>
            <Button type="warning" onClick={handleCancel}>
              Отменить
            </Button>
          </div>
        )}
      </Container>

      {isOpenModal && (
        <AddCompanyModal
          title="Добавить компанию-участника"
          description="Выберите компанию участника из списка, а также определите базовые права доступа по типам
        запросов"
          selectLabel="Компании-участники"
          companies={participants}
          onSubmit={(company: Partial<IParticipantOfCustomerForAdmin>) => {
            handleAddRow(company);
            setIsOpenModal(false);
          }}
          onCancel={() => {
            setIsOpenModal(false);
          }}
        />
      )}
    </>
  );
};

const addSubRows = (
  participantsData: IParticipantOfCustomerForAdmin[]
): IParticipantOfCustomerForAdmin[] =>
  participantsData.map((el) => ({
    ...el,
    ...(el.users?.length && {
      subRows: [
        {
          render: () => (
            <div key={el.id} className="custom-tbody__tr participants-table-expandable-row">
              <div className="participants-table-expandable-row__title">Участники</div>
              {el.users.map((user, index) => (
                <div className="participants-table-expandable-row__user" key={index}>
                  {getFullname(user.last_name, user.first_name, user.patronymic)}
                  {user.status === 'DELETED' ? ' (Удален)' : ''}
                </div>
              ))}
            </div>
          ),
        },
      ],
    }),
  }));
