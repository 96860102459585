import { type IUserInfo } from '@models/user.model';
import { HttpClient, BaseRepository } from './HttpClient';

class CommonRepository extends BaseRepository {
  async getCurrentUser(): Promise<IUserInfo> {
    return await this.client.get(this.getUrl('users/current/'));
  }

  async getConfigByName(name: string): Promise<{ name: string; value: string }> {
    return await this.client.get(this.getUrl(`configs/${name}/`));
  }
}

export default new CommonRepository(HttpClient, 'common/');
