import React from 'react';
import cn from 'classnames';
import { useParams, useLocation, useNavigate, To } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useCurrentUserData } from '@services/user/userDataContext';
import { HttpClient } from '@api/HttpClient';
import { ROUTES } from '@routes';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Notification from '@components/Modal/Notification/Notification';
import { CONFIG_NAMES, REQUEST_STATUSES_PARAMS } from '@constants';
import Pills from '@components/Pills/Pills';
import { Modal } from '@components/Modal/Modal';
import Button from '@components/Button/Button';
import { NoRights } from '@shared/components/NoRights';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as IconChat } from '@shared/icons/chat.svg';
import { ReactComponent as Backward } from '@shared/icons/backward.svg';
import { CreateDialogModal } from '@shared/components/CreateDialogModal/CreateDialogModal';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';

import { EmptyProposals } from '../components/EmptyProposals/EmptyProposals';
import { ProposalsTable } from './ProposalsTable/ProposalsTable';
import { CreateProposal } from '../CreateProposal/CreateProposal';
import { MainInfo } from './MainInfo/MainInfo';
import { MyProposal } from './MyProposal/MyProposal';
import type { IPositionDetail, IProposal } from './position.model';
import type { IProposalResponse } from '../CreateProposal/proposal.model';
import {
  ModalRefuseRequests,
  RequestModalCancel,
} from '@shared/components/RequestModalCancel/RequestModalCancel';
import { CommunicationService } from '@services/communication/communication.service';

import './PositionDetailPage.scss';

export const PositionDetailPage: React.FC = () => {
  const { requestPositionId, proposalId } = useParams();
  const currentUser = useCurrentUserData();

  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = React.useState<IPositionDetail>({} as IPositionDetail);
  const [myProposal, setMyProposal] = React.useState<IProposal>({
    id: undefined,
  } as unknown as IProposal);
  const [proposalEditCount, setProposalEditCount] = React.useState<number>(0);
  const [proposalEditMaxCount, setProposalEditMaxCount] = React.useState<number>(0);
  const [proposalModalText, setProposalModalText] = React.useState<{
    headerText: string;
    descriptionText: string;
  }>({ headerText: '', descriptionText: '' });
  const [isConfirmationCancelOpen, setConfirmationCancelOpen] = React.useState<boolean>(false);
  const [isOpenDialogModal, setIsOpenDialogModal] = React.useState(false);
  const [showModalCancel, setShowModalCancel] = React.useState(false);
  const [showModalRefuseRequests, setShowModalRefuseRequests] = React.useState(false);
  const [comment, setComment] = React.useState<string>('');
  const [errorStatus, setErrorStatus] = React.useState<number | null>(null);

  const breadcrumbs = useBreadcrumbs();
  let prevLink = breadcrumbs[3]?.key as unknown;

  if (!prevLink) prevLink = -1;
  const requestType = location.pathname.includes('/deposit') ? 'deposit' : 'credit';

  const hasProposals = data.proposals && data.proposals?.filter((el) => !el.fake).length !== 0;

  const hasFakeProposal = data.proposals?.some((item) => item.fake);

  const tabState = React.useMemo(() => {
    if (
      location.pathname.includes('/create') ||
      (location.pathname.includes('/edit') && hasProposals)
    )
      return 'form';
    else if (proposalId) return 'my_proposal';
    else if (hasProposals) return 'table';
    return 'empty';
  }, [location.pathname, data]);

  const canEditMyProposal = data.request?.status === 'ACTIVE';

  const loadHistoryCount = (): void => {
    HttpClient.post<number>(`/participant/proposal/${requestPositionId}/get_history/count/`).then(
      (data) => {
        setProposalEditCount(data);
      }
    );
  };

  const loadHistoryMaxCount = (): void => {
    HttpClient.get<{ name: string; value: number }>(
      `common/configs/${CONFIG_NAMES.MAX_CHANGES_PROPOSAL}/`
    ).then((data) => {
      setProposalEditMaxCount(data.value);
    });
  };

  const refuseRequests = async ({ allPositions }: { allPositions: boolean }): Promise<void> => {
    await HttpClient.post<{ comment: string; all_positions: boolean }>(
      `participant/${requestType}/positions/${requestPositionId}/decline/`,
      { comment, all_positions: allPositions }
    );
    loadData();
  };

  const loadData = async (): Promise<void> => {
    try {
      const data = await HttpClient.get<IPositionDetail>(
        `participant/${requestType}/positions/${requestPositionId}/`
      );
      setData(data);

      const myProposal = (data.proposals?.find(
        (el) => el.company_id === currentUser?.company.id
      ) ?? {
        id: undefined,
      }) as IProposal;

      setMyProposal(myProposal);
    } catch (e) {
      // @ts-expect-error check types
      setErrorStatus(e?.response?.status);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [requestPositionId, requestType, tabState]);

  React.useEffect(() => {
    loadHistoryCount();
    loadHistoryMaxCount();
  }, [myProposal]);

  const tabs = [
    {
      title: data?.request?.hide_for_participants ? 'Рейтинг предложений' : 'Список предложений',
      path: breadcrumbs[3]?.key,
      disabled: !hasProposals,
    },
    {
      title: 'Моё предложение',
      path: myProposal?.id
        ? `${breadcrumbs[3]?.key}/proposal/${myProposal.id}`
        : breadcrumbs[3]?.key,
    },
  ];

  const handleStartCommunication = async (): Promise<void> => {
    const result = await CommunicationService.searchActiveDialogByParams({
      recipient_company_id: data.request.creator_company.id,
      request_type: requestType.toUpperCase(),
      request_position_id: data.id,
    });
    if (result.dialog_id) navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${result.dialog_id}`);
    else setIsOpenDialogModal(true);
  };

  const ProposalCmp: () => JSX.Element = React.useMemo(
    // eslint-disable-next-line react/display-name
    () => () => {
      if (tabState === 'form') {
        const initData = myProposal.id
          ? myProposal
          : {
              id: myProposal.id,
              rate: data.proposals?.reduce((sum, proposal) => sum + proposal.rate, 0),
              amount: data.amount,
            };

        return (
          <>
            <div className="position-detail-proposals__row">
              <Pills tabs={tabs} currentTab={1} />
              <div className="position-detail-proposals__count">
                {`Осталось попыток редактирования/подачи предложения: ${
                  proposalEditMaxCount - proposalEditCount < 0
                    ? 0
                    : proposalEditMaxCount - proposalEditCount
                }`}
              </div>
            </div>
            <CreateProposal
              proposalEditCount={proposalEditCount}
              proposalEditMaxCount={proposalEditMaxCount}
              data={initData}
              requestId={data.request?.id}
              requestPositionId={data.id}
              requestType={requestType}
              rateType={data.rate_type}
              maxAmount={data.amount}
              canEditMyProposal={canEditMyProposal}
              onCancel={() => {
                navigate(prevLink as To);
              }}
              onSave={(proposal: IProposal) => {
                setMyProposal(proposal);
                navigate(prevLink as To);
              }}
            />
          </>
        );
      }

      if (tabState === 'my_proposal')
        return (
          <>
            <div className="position-detail-proposals__row">
              <Pills tabs={tabs} currentTab={1} />
              {canEditMyProposal && (
                <div className="position-detail-proposals__nav-actions">
                  <Button
                    icon={<Edit />}
                    type="without-border"
                    onClick={() => {
                      if (proposalEditCount >= proposalEditMaxCount) {
                        setProposalModalText({
                          headerText: 'Редактирование невозможно',
                          descriptionText: 'Отредактировать это',
                        });
                      } else {
                        navigate(`${breadcrumbs[3]?.key}/proposal/${myProposal.id}/edit`);
                      }
                    }}
                  />
                  <Button
                    icon={<Backward />}
                    type="without-border"
                    onClick={() => {
                      setConfirmationCancelOpen(true);
                    }}
                  />
                  <Button
                    type="text"
                    icon={<IconChat />}
                    onClick={() => {
                      handleStartCommunication();
                    }}
                  />
                </div>
              )}
            </div>
            <MyProposal data={myProposal} rateType={data.rate_type} />
          </>
        );

      if (tabState === 'table')
        return hasFakeProposal ? (
          <EmptyProposals
            hasFakeProposal={hasFakeProposal}
            onRefuseProposal={() => {
              setShowModalCancel(true);
            }}
            canEditMyProposal={canEditMyProposal}
            onSendProposal={() => {
              if (proposalEditCount >= proposalEditMaxCount) {
                setProposalModalText({
                  headerText: 'Подача невозможна',
                  descriptionText: 'Подать новое',
                });
              } else {
                navigate(location.pathname + '/create');
              }
            }}
          />
        ) : (
          <>
            <div className="position-detail-proposals__row">
              <Pills tabs={tabs} currentTab={0} />
            </div>
            <ProposalsTable
              requestType={requestType}
              rateType={data.rate_type}
              hideForParticipants={data.request.hide_for_participants}
              data={data.proposals}
              shouldRenderActions={canEditMyProposal}
              onEditProposal={handleEditProposal}
              onCancelProposal={() => {
                setConfirmationCancelOpen(true);
              }}
              onStartCommunication={handleStartCommunication}
            />
          </>
        );

      if (tabState === 'empty')
        return (
          <EmptyProposals
            hasFakeProposal={hasFakeProposal}
            onRefuseProposal={() => {
              setShowModalCancel(true);
            }}
            canEditMyProposal={canEditMyProposal}
            onSendProposal={() => {
              if (proposalEditCount >= proposalEditMaxCount) {
                setProposalModalText({
                  headerText: 'Подача невозможна',
                  descriptionText: 'Подать новое',
                });
              } else {
                navigate(location.pathname + '/create');
              }
            }}
          />
        );

      return <></>;
    },
    [data, myProposal, proposalEditCount]
  );

  const handleEditProposal = (proposalId: number): void => {
    navigate(`${location.pathname}/proposal/${proposalId}/edit`);
  };

  const handleCancelProposal = async (): Promise<void> => {
    const proposalId = myProposal?.id;
    if (!proposalId) return;

    const canceledProposal = await HttpClient.patch<IProposalResponse>(
      `participant/${requestType}/proposal/${proposalId}/cancel/`
    );

    setData((prevData) => ({
      ...prevData,
      proposals: prevData.proposals.filter((proposal) => proposal.id !== canceledProposal.id),
    }));

    setMyProposal({
      id: undefined,
    } as IProposal);
  };

  if (errorStatus) return <NoRights />;
  return (
    <>
      <DetailLayout
        title="Детализация запроса"
        className="position-detail"
        mainInfoClassName="position-detail-info"
        extraInfoClassName={cn(
          'position-detail-proposals',
          tabState === 'empty' && 'position-detail-proposals--empty'
        )}
        badgeParams={data && REQUEST_STATUSES_PARAMS[data.request?.status]}
        mainInfo={<MainInfo data={data} requestType={requestType} />}
        extraInfo={<ProposalCmp />}
      />

      {isConfirmationCancelOpen && (
        <Notification
          isOpen
          title="Отозвать предложение"
          message="Вы уверены, что хотите отозвать предложение? Для повторной подачи необходимо подать новое предложение."
          onConfirm={() => {
            handleCancelProposal().then(() => {
              setConfirmationCancelOpen(false);
              navigate(prevLink as To);
            });
          }}
          onCancel={() => {
            setConfirmationCancelOpen(false);
          }}
          confirmBtnText="Отозвать"
          cancelBtnText="Отмена"
        />
      )}

      {proposalModalText.headerText && (
        <Modal isOpen className="modal-proposal-count-max" title={proposalModalText.headerText}>
          <p className="modal-proposal-count-max-text">
            {`${proposalModalText.descriptionText} предложение невозможно, поскольку был превышен лимит количества попыток подачи/редактирования.`}
          </p>
          <Button
            onClick={() => {
              setProposalModalText({ headerText: '', descriptionText: '' });
            }}
            type="primary"
          >
            Закрыть
          </Button>
        </Modal>
      )}

      {isOpenDialogModal && (
        <CreateDialogModal
          requestType={requestType}
          isGroup={false}
          requestPositionId={data.id}
          recipientCompanies={[data.request.creator_company] as ISimilarCompany[]}
          onClose={() => {
            setIsOpenDialogModal(false);
          }}
        />
      )}

      {showModalCancel && (
        <RequestModalCancel
          onConfirm={(comment) => {
            setComment(comment);
            setShowModalRefuseRequests(true);
          }}
          onCancel={() => {
            setShowModalCancel(false);
          }}
        />
      )}
      {showModalRefuseRequests && (
        <ModalRefuseRequests
          onConfirm={() => {
            refuseRequests({ allPositions: true });
            setShowModalRefuseRequests(false);
            setShowModalCancel(false);
          }}
          onCancel={() => {
            refuseRequests({ allPositions: false });
            setShowModalRefuseRequests(false);
            setShowModalCancel(false);
          }}
        />
      )}
    </>
  );
};
