import React from 'react';
import type * as ReactTable from 'react-table';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { HttpClient } from '@api/HttpClient';
import { AuthUtil } from '@services/auth/auth.util';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import { download } from '@utils';
import type * as TableTypes from '@components/Table/types';
import { ROUTES } from '@routes';
import {
  REQUEST_TYPE_CODES,
  REQUEST_STATUSES_PARAMS,
  RATE_TYPES_TITLE,
  REPORT_TYPES,
  MIME_TYPES,
} from '@constants';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { ReactComponent as IconChevronRight } from '@shared/icons/arrow-right-8-16.svg';
import { renderAmount, renderPeriodLong } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { mutateDataForGroups } from '@shared/helpers/requestDetail';
import type {
  IRequestDetailParticipants,
  IParticipantCustomColumn,
  IOperatorRequestDetail,
  IPosition,
} from '@models/request.model';
import Pills from '@components/Pills/Pills';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';

import './RequestDetailPage.scss';

export const OperatorRequestDetailPage: React.FC = () => {
  const { requestId, customerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const [data, setData] = React.useState<IOperatorRequestDetail | IRequestDetailParticipants>();
  const [detailsColumns, setDetailsColumns] = React.useState<TableTypes.Column[]>([]);
  const [detailsData, setDetailsData] = React.useState<IParticipantCustomColumn[]>([]);
  const [participantsData, setParticipantsData] = React.useState<ISimilarCompany[]>([]);

  const isDetailsTab = location.pathname.includes('/details') ? 'details' : '';
  const isParticipantsTab = location.pathname.includes(ROUTES.PARTICIPANTS) ? 'participants' : '';
  const currentTabNumber = isDetailsTab ? 1 : isParticipantsTab ? 2 : 0;

  const prevLink = (breadcrumbs.at(isDetailsTab ? -5 : -4)?.key as string) || -1;

  const requestType = location.pathname.includes('/deposit') ? 'deposit' : 'credit';

  React.useEffect(() => {
    HttpClient.get<IOperatorRequestDetail>(
      `operator/${requestType}/${customerId}/requests/${requestId}/`
    ).then((data) => {
      setData(data);
    });
  }, [requestId, requestType]);

  React.useEffect(() => {
    if (isDetailsTab) {
      HttpClient.get<IRequestDetailParticipants>(
        `operator/${requestType}/${customerId}/requests/${requestId}/${isDetailsTab}/`
      ).then((data) => {
        const { detailsColumns, detailsData } = mutateDataForGroups(data);
        setDetailsColumns(detailsColumns);
        setDetailsData(detailsData);
      });
    }
  }, [requestId, requestType, isDetailsTab]);

  React.useEffect(() => {
    if (isParticipantsTab) {
      HttpClient.get<ISimilarCompany[]>(
        `operator/${requestType}/${customerId}/requests/${requestId}/${isParticipantsTab}/`
      ).then((data) => {
        setParticipantsData(data);
      });
    }
  }, [requestId, requestType, isParticipantsTab]);

  const downloadData = async (): Promise<void> => {
    const data = await HttpClient.get<string>(
      `operator/requests/${requestId}/reports/${
        isDetailsTab ? REPORT_TYPES.MATRIX : REPORT_TYPES.SUMMARY
      }/`,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );
    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });

    const fileName = `${isDetailsTab ? 'Матрица_предложений' : 'Сводный_отчет'}_${requestId}.xlsx`;
    download(blob, fileName);
  };

  const downloadReference = async (): Promise<void> => {
    const data = await HttpClient.get<string>(
      `operator/requests/${requestId}/reports/${REPORT_TYPES.COMPLETED}/`,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );
    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });
    download(blob, `Справка_${requestId}.xlsx`);
  };

  const navToRequestAtomicPage = (row: { id: string }): void => {
    const positionId = row.id;
    if (!positionId || isDetailsTab) return;

    const currentUrl = location.pathname.replace('/details', '');
    navigate(`${currentUrl}/positions/${positionId}/`);
  };

  const navButton = (row: unknown): JSX.Element => (
    <Button
      className="request-operator-detail-group-table__button-next"
      type="text"
      icon={<IconChevronRight />}
      onClick={() => {
        navToRequestAtomicPage(row as { id: string });
      }}
    />
  );
  const positionsColumns = React.useMemo(() => COLUMNS(navButton), []);
  const tabs = [
    {
      title: 'Позиции запроса',
      path: location.pathname.replace(/\/details|\/participants/g, ''),
    },
    {
      title: 'Таблица предложений',
      path: `${breadcrumbs[4]?.key}/details`,
    },
    {
      title: 'Участники',
      path: `${breadcrumbs[4].key}/participants`,
    },
  ];

  const dataForBox = data
    ? [
        [
          {
            label: 'Компания',
            value: (data as IOperatorRequestDetail).company_creator.name,
          },
          {
            label: 'Услуга',
            value: REQUEST_TYPE_CODES[data.request_type?.code],
          },
          {
            label: 'Даты размещения запроса',
            value: `с ${formatDate(data.start_ts)} по ${formatDate(data.end_ts)}`,
          },
          {
            label: 'Максимальный объём',
            value: renderAmount({ amount: data.max_amount }),
          },
        ],
        [
          {
            label: 'Комментарий (внешний)',
            value: data.comment_ext,
          },
          {
            label: 'Комментарий (внутренний)',
            value: data.comment_int,
          },
          {
            label: 'Создан',
            value: `${AuthUtil.getFullNameAbbreviation(
              data.creator?.last_name || '',
              data.creator?.first_name || '',
              data.creator?.patronymic || ''
            )} 
          ${formatDate(data.create_ts)}`,
          },
        ],
      ]
    : [[]];

  if (data?.status === 'COMPLETED')
    dataForBox[1].push({ label: 'Комментарий при завершении', value: data.comment_result });

  const participantsColumns: ReactTable.Column[] = [
    {
      Header: 'Название',
      accessor: 'name',
    },
    {
      Header: 'ИНН',
      accessor: 'inn',
    },
  ];

  const tabProps = {
    details: {
      columns: detailsColumns,
      data: detailsData,
      onRowClick: undefined,
      className: 'request-operator-detail-group-table__grid__details',
      notFoundMessage: 'Список предложений пуст',
      notFoundDescription: 'К этому запросу пока не оставлено ни одного предложения.',
    },
    positions: {
      columns: positionsColumns,
      data: (data as IOperatorRequestDetail)?.positions || [],
      onRowClick: navToRequestAtomicPage,
      className: 'request-operator-detail-group-table__grid',
      notFoundMessage: 'Список позиций пуст',
      notFoundDescription: 'К этому запросу пока не добавлено ни одной позиции.',
    },
    participants: {
      columns: participantsColumns,
      data: participantsData,
      cclassName: 'request-operator-detail-group-table__grid__participants',
      onRowClick: undefined,
      notFoundMessage: 'Список участников пуст',
      notFoundDescription: 'К этому запросу пока не добавлено ни одного участника.',
    },
  };
  const currentTabName: 'details' | 'participants' | 'positions' = location.pathname.includes(
    '/details'
  )
    ? 'details'
    : location.pathname.includes(ROUTES.PARTICIPANTS)
    ? 'participants'
    : 'positions';

  return (
    <DetailLayout
      title="Детализация группирующего запроса"
      className="request-operator-detail-group"
      mainInfoClassName="request-operator-detail-group-info"
      extraInfoClassName="request-operator-detail-group-table"
      badgeParams={data && REQUEST_STATUSES_PARAMS[data.status]}
      prevLink={prevLink}
      mainInfo={
        data && (
          <InfoBox
            title={(data as IRequestDetailParticipants).request_name || data.name || '-'}
            data={dataForBox}
          />
        )
      }
      extraInfo={
        data && (
          <>
            <div className="request-operator-detail-group-table__row">
              <Pills currentTab={currentTabNumber} tabs={tabs} />
              <Button
                className="request-operator-detail-group-table__download"
                onClick={downloadData}
                icon={<IconFile />}
              >
                Выгрузить
              </Button>
            </div>
            <Table
              wrapperClassName="request-operator-detail-group-table"
              {...tabProps[currentTabName]}
            />
          </>
        )
      }
    >
      <>
        <div className="request-operator-detail-group__process-buttons">
          {data?.status === 'COMPLETED' && (
            <Button onClick={downloadReference} size="large" icon={<IconFile />}>
              Справка по запросу
            </Button>
          )}
        </div>
      </>
    </DetailLayout>
  );
};

export const COLUMNS = (navButton: (row: unknown) => JSX.Element): TableTypes.Column[] => [
  {
    Header: 'Срок',
    accessor: (row: unknown) => {
      const _row = row as IPosition;

      return renderPeriodLong({
        period: _row.period as number,
        periodType: _row.period_type,
        endDatePeriod: _row.end_date_period as string,
      });
    },
  },
  {
    Header: 'Объём',
    accessor: (row: unknown) => renderAmount(row as IPosition),
    align: 'right',
  },
  {
    Header: 'Ставка',
    accessor: (row: unknown) => <>{RATE_TYPES_TITLE[(row as IPosition).rate_type] || '-'}</>,
  },
  {
    Header: 'Предложения',
    accessor: 'proposals_count',
    align: 'right',
  },
  {
    id: 'id',
    accessor: navButton,
    align: 'right',
  },
];
