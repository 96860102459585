import { HttpClient } from '@api/HttpClient';
import { IDictionaryCompany } from '@models/base.model';
import { IUserPermissions } from '@pages/operator/operator.model'; // TODO: перенести

export class OperatorService {
  public static getApiPath = (requestType: string): string => {
    if (requestType !== 'bond') return `/operator/${requestType}/requests/list/`;
    return '/operator/bonds/requests/list/';
  };

  public static getParticipants = async (): Promise<IDictionaryCompany[]> => {
    return await HttpClient.get('/operator/companies/type/PARTICIPANT/');
  };

  public static getParticipantCustomers = async (
    participantId: number | string
  ): Promise<IDictionaryCompany[]> => {
    return await HttpClient.get(`/operator/companies/participants/${participantId}/customers/`);
  };

  public static putParticipantUserTypePermissions = async (
    permissions: IUserPermissions[]
  ): Promise<string> => {
    return await HttpClient.put<IUserPermissions[], string>(
      'operator/users/participant/request_type_permissions/',
      permissions
    );
  };
}

export default OperatorService;
