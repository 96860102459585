import React from 'react';
import cn from 'classnames';
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import Button from '@components/Button/Button';
import Textarea from '@components/Textarea/Textarea';
import { Input } from '@components/Input/Input';
import { Modal } from '@components/Modal/Modal';
import { HttpClient } from '@api/HttpClient';
import { setFloatFieldValue } from '@utils';
import { amountProposalMask, fixRateMask, floatRateMask } from '@masks';
import type { IProposalResponse } from './proposal.model';
import { getProposalSchema } from './CreateProposal.validation';
import AutoCheckForm from '@shared/components/AutoCheckForm';

import './CreateProposal.scss';

interface IProps {
  proposalEditCount: number;
  proposalEditMaxCount: number;
  rateType: string;
  maxAmount: number;
  data?: {
    id?: number;
    rate?: number | null;
    amount?: number | null;
    comment?: string | null;
  };
  requestId: number;
  requestPositionId: number;
  requestType: 'deposit' | 'credit';
  canEditMyProposal?: boolean;
  onCancel: () => void;
  onSave: (proposal: IProposalResponse) => void;
}

export function CreateProposal({
  proposalEditCount,
  proposalEditMaxCount,
  rateType,
  maxAmount,
  data,
  requestId,
  requestPositionId,
  requestType,
  canEditMyProposal,
  onSave,
  onCancel,
}: IProps): JSX.Element {
  const location = useLocation();
  const isCreditsTab = location.pathname.startsWith('/credits');
  const initialValues = {
    rate: data?.rate || 0,
    amount: data?.amount || 0,
    comment: data?.comment || '',
  };
  const [isError, setError] = React.useState('');
  const [proposalModalText, setProposalModalText] = React.useState<{
    headerText: string;
    descriptionText: string;
  }>({ headerText: '', descriptionText: '' });

  const ProposalSchema = React.useMemo(() => getProposalSchema(maxAmount), [maxAmount]);
  const rateMask = React.useMemo(
    () => (rateType === 'FLOAT' ? floatRateMask : rateType === 'FIX' ? fixRateMask : undefined),
    [rateType]
  );

  const handleSaveProposal = async (values: IProps['data']): Promise<void> => {
    if (!values) return;

    if (proposalEditCount >= proposalEditMaxCount) {
      setProposalModalText({
        headerText: 'Редактирование невозможно',
        descriptionText: 'Отредактировать это',
      });
    } else {
      const proposalId = data?.id;

      const body = {
        request_id: requestId,
        request_position_id: requestPositionId,
        amount: values.amount,
        rate: values.rate,
        comment: values.comment,
      };
      try {
        const proposal = await (proposalId
          ? HttpClient.patch<Nullable<IProposalResponse>, IProposalResponse>(
              `participant/${requestType}/proposal/${proposalId}/`,
              body
            )
          : HttpClient.post<Nullable<IProposalResponse>, IProposalResponse>(
              `participant/${requestType}/proposal/`,
              body
            ));

        if (proposal) onSave(proposal);
      } catch (e) {
        setError((e as AxiosError).message);
      }
    }
  };

  return (
    <Formik
      enableReinitialize={false}
      validateOnBlur
      validateOnChange
      initialValues={initialValues}
      onSubmit={handleSaveProposal}
      validationSchema={ProposalSchema}
    >
      {({ values, handleChange, errors, handleBlur, touched }) => {
        return (
          <Form>
            <div className="create-proposal">
              <div className="create-proposal__row">
                <div className="create-proposal__col">
                  <Input
                    name="rate"
                    size="large"
                    label="Процентная ставка*"
                    className={cn(rateType === 'FLOAT' && 'create-proposal__input-with-mask')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={setFloatFieldValue(values.rate)}
                    error={(touched.rate && errors.rate) as string}
                    mask={rateMask}
                  />
                  <Input
                    name="amount"
                    size="large"
                    label="Объем*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={setFloatFieldValue(values.amount)}
                    error={(touched.amount && errors.amount) as string}
                    mask={amountProposalMask}
                  />
                </div>
                <div className="create-proposal__col">
                  <Textarea
                    name="comment"
                    label="Комментарий"
                    placeholder="Напишите ваш комментарий"
                    value={values.comment as string}
                    error={(touched.comment && errors.comment) as string}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isClearable
                    className="create-proposal__comment"
                    wrapperClassName="create-proposal__comment-wrapper"
                    size="large"
                    resizable
                  />
                </div>
              </div>

              {canEditMyProposal && (
                <div className="create-proposal__required-hint">
                  * Обязательные для заполнения поля
                </div>
              )}
              <div className="create-proposal__actions">
                {canEditMyProposal && (
                  <Button type="primary" htmlType="submit">
                    {data?.id ? 'Сохранить' : 'Опубликовать'}
                  </Button>
                )}
                <Button onClick={onCancel} type="warning">
                  Отменить
                </Button>
              </div>
            </div>
            <AutoCheckForm
              title="Прервать создание предложения"
              message="Вы уверены, что хотите прервать создание предложения? Все данные будут утеряны."
              onConfirm={() => {
                onCancel();
              }}
              confirmBtnText="Прервать"
              cancelBtnText="Отмена"
            />

            {proposalModalText.headerText && (
              <Modal
                isOpen
                className="modal-proposal-count-max"
                title={proposalModalText.headerText}
              >
                <p className="modal-proposal-count-max-text">
                  {`${proposalModalText.descriptionText} предложение невозможно, поскольку был превышен лимит количества попыток подачи/редактирования.`}
                </p>
                <Button
                  onClick={() => {
                    setProposalModalText({ headerText: '', descriptionText: '' });
                  }}
                  type="primary"
                >
                  Закрыть
                </Button>
              </Modal>
            )}

            {isError && (
              <Modal isOpen className="modal-error" title="Невозможно сохранить">
                <p className="modal-text">
                  {`Невозможно сохранить или опубликовать предложение, так как ставка не может быть
                  ${isCreditsTab ? 'выше' : 'ниже'} указанной ранее.`}
                </p>
                <Modal.Actions>
                  <Button
                    fullWidth
                    size="large"
                    onClick={() => {
                      setError('');
                    }}
                    type="primary"
                  >
                    Закрыть
                  </Button>
                </Modal.Actions>
              </Modal>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
