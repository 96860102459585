import React from 'react';
import { hasOperatorRole, hasCustomerRole, hasParticipantRole } from '@roles';
import { OperatorBondDetailPage } from '../operator/BondDetailPage/BondDetailPage';
import { CustomerBondDetailPage } from '../customer/BondDetailPage/BondDetailPage';
import { ParticipantBondDetailPage } from '../participant/BondDetailPage/BondDetailPage';
import { useCurrentUserData } from '@services/user/userDataContext';

export const BondDetailPage: React.FC = () => {
  const userData = useCurrentUserData();

  const isOperator = hasOperatorRole(userData?.roles ?? []);
  const isCustomer = hasCustomerRole(userData?.roles ?? []);
  const isParticipant = hasParticipantRole(userData?.roles ?? []);

  if (isOperator) return <OperatorBondDetailPage />;
  if (isCustomer) return <CustomerBondDetailPage />;
  if (isParticipant) return <ParticipantBondDetailPage />;

  return null;
};
