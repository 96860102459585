import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '@api/HttpClient';
import { useCurrentUserData } from '@services/user/userDataContext';
import Container from '@components/Container/Container';
import Button from '@components/Button/Button';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { ROUTES } from '@routes';
import { ReactComponent as Decor } from '@shared/icons/welcomePage/decor.svg';
import { ReactComponent as Mockup } from '@shared/icons/welcomePage/mockup.svg';
import { CONFIG_NAMES } from '@constants';

import './WelcomePage.scss';

export const WelcomePage: React.FC = () => {
  const userData = useCurrentUserData();
  const [html, setHtml] = React.useState('<div></div>');

  const loadWelcomeMessage = (): void => {
    HttpClient.get<{ value: string }>(`common/configs/${CONFIG_NAMES.WELCOME}/`).then((data) => {
      setHtml(data?.value);
    });
  };
  React.useEffect(() => {
    loadWelcomeMessage();
  }, []);

  // TODO: сопоставить треки и пути через словарь
  const favoritePath = userData?.favoriteRequestType
    ? ROUTES[userData.favoriteRequestType + 'S']
    : userData?.requestTypes?.length
    ? ROUTES[userData.requestTypes[0] + 'S']
    : ROUTES.INFO;

  const navigate = useNavigate();
  return (
    <PageLayout className="main-page">
      <Container direction="vertical">
        <div className="main-page__decor">
          <Mockup className="main-page__decor-mockup" />
          <Decor className="main-page__decor-icon" />
        </div>
        <div className="main-page__content">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
          <Button
            className="main-page__content-action"
            size="large"
            onClick={() => {
              navigate(favoritePath);
            }}
            type="primary"
          >
            Начать работу
          </Button>
        </div>
      </Container>
    </PageLayout>
  );
};
