import React from 'react';
import PageLayout from '@layouts/PageLayout/PageLayout';
import OperatorRequests from './OperatorRequests';
import { useLocation } from 'react-router-dom';

import './index.scss';

export const OperatorMainPage: React.FC = () => {
  const location = useLocation();

  const requestType = location.pathname.includes('/deposit')
    ? 'deposit'
    : location.pathname.includes('/bond')
    ? 'bond'
    : 'credit';

  return (
    <PageLayout className="main-page">
      <OperatorRequests key={requestType} requestType={requestType} />
    </PageLayout>
  );
};
