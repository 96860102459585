import { components } from 'react-select';
import Checkbox from '../Checkbox/Checkbox';
import { ReactComponent as Arrow } from '@shared/icons/arrow-down.svg';

export const MultiValueContainer =
  // @ts-expect-error eslint-disable-next-line

  (isChips) =>
    // @ts-expect-error eslint-disable-next-line
    function MultiValueContainerCmp({ selectProps, data: propsData, children, ...rest }) {
      if (isChips)
        return (
          // @ts-expect-error eslint-disable-next-line
          <components.MultiValueContainer {...rest} selectProps={selectProps} data={propsData}>
            {children}
          </components.MultiValueContainer>
        );
      else {
        const values = selectProps.value;
        if (values) {
          return (
            <span className="">
              {values[values.length - 1].label === propsData.label
                ? propsData.label
                : propsData.label + ', '}
            </span>
          );
        } else return <></>;
      }
    };

// @ts-expect-error eslint-disable-next-line
export const ClearIndicator = (props): JSX.Element => {
  const { onMouseDown = () => {} } = props.innerProps;
  // @ts-expect-error eslint-disable-next-line
  props.innerProps.onMouseDown = (e): void => {
    props.selectProps.onClear?.();
    onMouseDown(e);
  };

  return <components.ClearIndicator {...props} />;
};

// @ts-expect-error eslint-disable-next-line
export const DropdownIndicator = (menuIsOpen) =>
  // @ts-expect-error eslint-disable-next-line
  function DropdownIndicatorCmp(indicatorProps) {
    return (
      <components.DropdownIndicator {...indicatorProps}>
        <div
          style={{
            transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'transform 0.3s ease',
          }}
        >
          <Arrow />
        </div>
      </components.DropdownIndicator>
    );
  };

// @ts-expect-error eslint-disable-next-line
export const Option = ({ children, ...props }): JSX.Element => {
  return (
    // @ts-expect-error eslint-disable-next-line
    <components.Option {...props}>
      {props.isMulti ? (
        <div className="custom-select__option custom-select__option--multi">
          <Checkbox checked={props.isSelected} readOnly name={props.label} />
          <div className="custom-select-multi-option__text">{children}</div>
        </div>
      ) : (
        <div className="custom-select__option">{children}</div>
      )}
    </components.Option>
  );
};

export const EmptyData = (): string => 'Нет данных';
export const LoadingMessage = (): string => 'Загрузка...';
