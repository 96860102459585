import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { HttpClient } from '@api/HttpClient';
import { useParams } from 'react-router-dom';
import { MEMBER_STATUSES_PARAMS } from '@constants';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Notification from '@components/Modal/Notification/Notification';
import { MainInfo } from './MainInfo/MainInfo';
import { UsersTable } from './UsersTable/UsersTable';
import { CustomersTable } from './CustomersTable/CustomersTable';
import {
  ICompanyParticipantMainInfoForAdmin,
  IUserOfParticipantForAdmin,
  ICustomerCompanyForAdmin,
  IParticipantRequestTypePermission,
} from '../operator.model';

import './CompanyParticipantDetailPage.scss';

export const CompanyParticipantDetailPage: React.FC = () => {
  const { participantId } = useParams();
  const breadcrumbs = useBreadcrumbs();
  const [isBlockModalOpen, setIsBlockModalOpen] = React.useState<boolean>(false);

  const [mainData, setMainData] = React.useState<ICompanyParticipantMainInfoForAdmin>(
    {} as ICompanyParticipantMainInfoForAdmin
  );
  const [users, setUsers] = React.useState<IUserOfParticipantForAdmin[]>([]);
  const [customers, setCustomers] = React.useState<ICustomerCompanyForAdmin[]>([]);

  const prevLink = breadcrumbs.at(-2)?.key;

  const loadCustomers = (): void => {
    HttpClient.get<ICustomerCompanyForAdmin[]>(
      `operator/companies/participants/${participantId}/customers/`
    ).then(setCustomers);
  };

  const loadMainInfo = (): void => {
    HttpClient.get<ICompanyParticipantMainInfoForAdmin>(
      `operator/companies/participants/${participantId}/base/`
    ).then(setMainData);
  };

  const loadUsers = (): void => {
    HttpClient.get<IUserOfParticipantForAdmin[]>(
      `operator/companies/participants/${participantId}/users/`
    ).then(setUsers);
  };

  React.useEffect(() => {
    loadMainInfo();
    loadUsers();
    loadCustomers();
  }, [participantId]);

  const handleSaveCustomersTable = (data: ICustomerCompanyForAdmin[]): void => {
    if (!participantId) return;

    const dataForUpdatePermission: IParticipantRequestTypePermission[] = [];

    data.forEach((company) => {
      company.request_types.forEach((type) => {
        dataForUpdatePermission.push({
          customer_company_id: company.id,
          participant_company_id: +participantId,
          request_type: type.code,
          is_active: type.is_active,
        });
      });
    });

    HttpClient.put<IParticipantRequestTypePermission[]>(
      'operator/companies/request_type_permissions/',
      dataForUpdatePermission
    ).then(() => {
      setCustomers(data);
    });
  };

  const handleBlockCompany = (): void => {
    const companyId = mainData.id;
    HttpClient.patch(`operator/companies/${companyId}/BLOCKED/`).then(() => {
      setMainData({ ...mainData, is_blocked: true });
    });
  };

  const handleUnblockCompany = (): void => {
    const companyId = mainData.id;
    HttpClient.patch(`operator/companies/${companyId}/ACTIVE/`).then(() => {
      setMainData({ ...mainData, is_blocked: false });
    });
  };

  const participantStatus = mainData.is_blocked ? 'BLOCKED' : 'ACTIVE';

  return (
    <>
      <DetailLayout
        title="Просмотр компании-участника"
        className="company-participant-detail"
        mainInfoClassName="company-participant-detail-info"
        extraInfoClassName="company-participant-detail-users-table"
        badgeParams={MEMBER_STATUSES_PARAMS[participantStatus]}
        prevLink={prevLink}
        mainInfo={
          <MainInfo
            data={mainData}
            onEdit={loadMainInfo}
            onBlock={() => {
              setIsBlockModalOpen(true);
            }}
            onActivate={() => {
              handleUnblockCompany();
            }}
          />
        }
        extraInfo={<UsersTable data={users} />}
      >
        <CustomersTable data={customers} onEdit={handleSaveCustomersTable} />
      </DetailLayout>

      {isBlockModalOpen && (
        <Notification
          isOpen
          title="Блокировка компании"
          message="Вы уверены, что хотите заблокировать компанию? Блокировка затронет всех привязанных пользователей."
          confirmBtnText="Заблокировать"
          cancelBtnText="Отмена"
          onConfirm={() => {
            handleBlockCompany();
            setIsBlockModalOpen(false);
          }}
          onCancel={() => {
            setIsBlockModalOpen(false);
          }}
        />
      )}
    </>
  );
};
