import type React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@components/Table';
import Filters from '@shared/components/SearchFilters/SearchFilters';
import Button from '@components/Button/Button';
import type * as TableTypes from '@components/Table/types';
import { COMPANY_ROLES } from '@constants';
import { useTableWithParams, useMounted } from '@hooks';
import { formatPhone, getFullname, renderUserStatus } from '@functions';
import type { IUserForAdmin } from './user.model';

import './Users.scss';

const Users: React.FC = () => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    searchStr,
    setSearchStr,
  } = useTableWithParams('/operator/users/', mounted, 'get');

  return (
    <div className="admin-users">
      <div className="admin-users__actions">
        <Filters
          className="admin-users__filters"
          searchStr={searchStr}
          setSearchStr={setSearchStr}
        />
        <Button
          type="primary"
          onClick={() => {
            navigate(location.pathname + '/create');
          }}
        >
          Добавить пользователя
        </Button>
      </div>
      <Table
        columns={columns as TableTypes.Column[]}
        data={data as Array<TableTypes.CustomRowData<IUserForAdmin>>}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
        onRowClick={(row) => {
          // @ts-expect-error check types
          navigate(location.pathname + '/' + row.original.id + '/details');
        }}
      />
    </div>
  );
};

export default Users;

const columns = [
  {
    Header: 'Статус',
    accessor: ({ status }: { status: string }) => renderUserStatus(status),
    align: 'left',
  },
  {
    Header: 'ФИО',
    accessor: ({
      firstName,
      lastName,
      patronymic,
    }: {
      firstName: string;
      lastName: string;
      patronymic: string;
    }) => getFullname(lastName, firstName, patronymic),
  },
  {
    Header: 'Номер телефона',
    accessor: ({ phone }: { phone: string }) => formatPhone(phone),
  },
  {
    Header: 'Электронная почта',
    accessor: 'email',
  },
  {
    Header: 'Компания',
    accessor: ({ companyName, position }: { companyName: string; position: string }) => (
      <div>
        <div>{companyName || '-'}</div>
        <div className="admin-users__position">{position || '-'}</div>
      </div>
    ),
  },
  {
    Header: 'Роль компании',
    accessor: ({ companyRole }: { companyRole: string }) => COMPANY_ROLES[companyRole],
  },
];
