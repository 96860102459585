import React from 'react';
import { startOfToday, set as setDateTime, isEqual } from 'date-fns';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';
import ru from 'date-fns/locale/ru/index.js';
import cn from 'classnames';
import { formatLocaleDate, DATEPICKER_DATETIME_FORMAT } from '@date-time';
import Datepicker from '../Datepicker/Datepicker';
import Button from '../Button/Button';
import calendar from '@shared/icons/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './RangeDatepicker.scss';

type TDate = Date | null | undefined;
type CustomRangeDatepickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
  onChange: (range: TDate[]) => void;
  customWrapperClassName?: string;
  label?: string;
  labelClassName?: string;
  size?: 'large' | 'medium';
  error?: string;
  errorClassName?: string;
};

const RangeDatepicker: React.FC<CustomRangeDatepickerProps> = ({
  wrapperClassName,
  popperClassName,
  dateFormat,
  isClearable = true,
  onChange,
  customWrapperClassName,
  label,
  labelClassName,
  size,
  error: externalError,
  errorClassName,
  ...rest
}) => {
  const ref = React.useRef<ReactDatepicker>(null);
  const initialState = React.useRef<TDate[]>([rest.startDate, rest.endDate]);
  const [startDate, setStartDate] = React.useState<TDate>(rest.startDate);
  const [endDate, setEndDate] = React.useState<TDate>(rest.endDate);
  const startTimeRef = React.useRef<TDate>(rest.startDate);
  const endTimeRef = React.useRef<TDate>(rest.endDate);
  const [error, setError] = React.useState('');

  const validate = (): void => {
    const isValid = !startDate || !endDate || startDate < endDate;
    setError(isValid ? '' : 'Неверный период');
  };

  const handleSave = (): void => {
    if (error) return;

    const _startDate = startTimeRef.current
      ? getCurrentDateForTime(startTimeRef.current, startDate)
      : startDate;
    const _endDate = endTimeRef.current
      ? getCurrentDateForTime(endTimeRef.current, endDate)
      : endDate;

    if (_startDate && _endDate) {
      if (_startDate >= _endDate) return;

      if (
        rest.startDate &&
        isEqual(_startDate, rest.startDate) &&
        rest.endDate &&
        isEqual(_endDate, rest.endDate)
      )
        return;
    }

    onChange([_startDate, _endDate]);
    ref.current?.setOpen(false);
  };

  const handleCancel = (): void => {
    setStartDate(initialState.current[0]);
    setEndDate(initialState.current[1]);
    startTimeRef.current = null;
    endTimeRef.current = null;
    setError('');
    onChange([]);
    ref.current?.setOpen(false);
  };

  const handleChange =
    (prop: 'startDate' | 'endDate' | 'startTime' | 'endTime') => (date: TDate) => {
      if (prop === 'startDate') {
        if (startTimeRef.current && date) {
          date = getCurrentDateForTime(startTimeRef.current, date);
        }

        setStartDate(date);
      }
      if (prop === 'endDate') {
        if (endTimeRef.current && date) {
          date = getCurrentDateForTime(endTimeRef.current, date);
        }

        setEndDate(date);
      }

      if (prop === 'startTime') {
        startTimeRef.current = date;
      }

      if (prop === 'endTime') {
        endTimeRef.current = date;
      }
    };

  const handleSetTime = (prop: 'start' | 'end') => (): void => {
    if (prop === 'start') {
      handleChange('startDate')(
        !startDate && startTimeRef.current
          ? getCurrentDateForTime(startTimeRef.current)
          : startTimeRef.current || startDate
      );
    }

    if (prop === 'end') {
      handleChange('endDate')(
        !endDate && endTimeRef.current
          ? getCurrentDateForTime(endTimeRef.current)
          : endTimeRef.current || endDate
      );
    }
  };

  const openDatepicker = (): void => ref?.current?.setOpen(true);

  React.useEffect(validate, [startDate, endDate]);

  React.useEffect(() => {
    setStartDate(rest.startDate);
    startTimeRef.current = rest.startDate;

    setEndDate(rest.endDate);
    endTimeRef.current = rest.endDate;
  }, [rest.startDate, rest.endDate]);

  const Calendar = (): JSX.Element => (
    <div className="custom-calendar-container">
      <div className="custom-calendar-container__block custom-calendar-container__block--spaced">
        <div className="custom-calendar-container__label">Дата и время начала</div>
        <Datepicker
          selectsStart
          selected={startDate}
          value={startDate}
          maxDate={endDate}
          onChange={handleChange('startDate')}
          autoComplete="off"
        />
        <Datepicker
          selectsStart
          showCustomTimeInputOnly
          selected={startDate}
          value={startDate}
          onChange={handleChange('startTime')}
          onCalendarClose={handleSetTime('start')}
          onClickOutside={handleSetTime('start')}
          autoComplete="off"
        />
      </div>
      <div className="custom-calendar-container__block custom-calendar-container__block--spaced">
        <div className="custom-calendar-container__label">Дата и время завершения</div>
        <Datepicker
          selectsEnd
          selected={endDate}
          value={endDate}
          minDate={startDate}
          onChange={handleChange('endDate')}
          autoComplete="off"
          error={error}
        />
        <Datepicker
          selectsEnd
          showCustomTimeInputOnly
          selected={endDate}
          value={endDate}
          onChange={handleChange('endTime')}
          onCalendarClose={handleSetTime('end')}
          onClickOutside={handleSetTime('end')}
          autoComplete="off"
          error={error}
        />
      </div>
      <div className="custom-calendar-container__actions custom-calendar-container__actions--divider">
        <Button type="text" className="custom-calendar-container__button" onClick={handleCancel}>
          Отменить
        </Button>
        <Button type="text" className="custom-calendar-container__button" onClick={handleSave}>
          Сохранить
        </Button>
      </div>
    </div>
  );

  /** TODO: маска на rangedatepicker с debounce вне компонента */
  return (
    <div
      className={cn('custom-rangedatepicker-wrapper', customWrapperClassName, {
        'custom-rangedatepicker-wrapper--has-error': externalError,
      })}
    >
      <span className={cn('custom-rangedatepicker-wrapper__label', labelClassName)}>{label}</span>
      <ReactDatepicker
        placeholderText="Дата и время начала и завершения"
        ref={ref}
        wrapperClassName={cn('custom-rangedatepicker', wrapperClassName, {
          'custom-rangedatepicker--large': size === 'large',
        })}
        popperClassName={cn('custom-rangedatepicker-popper', popperClassName)}
        locale={ru}
        dateFormat={DATEPICKER_DATETIME_FORMAT}
        selectsRange
        showIcon
        icon={<img src={calendar} alt="" onClick={openDatepicker} />}
        isClearable={isClearable}
        onCalendarClose={handleSave}
        calendarContainer={Calendar}
        // @ts-expect-error eslint-disable-next-line
        onChange={onChange}
        {...rest}
        value={
          (startDate ? formatLocaleDate(startDate) : '') +
          (endDate ? ' - ' + formatLocaleDate(endDate) : '')
        }
      />
      <span className={cn('custom-rangedatepicker-wrapper__error', errorClassName)}>
        {externalError}
      </span>
    </div>
  );
};

const getCurrentDateForTime = (time: Date, date?: TDate): Date =>
  setDateTime(date || startOfToday(), { hours: time.getHours(), minutes: time.getMinutes() });

export default RangeDatepicker;
