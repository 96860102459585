import React from 'react';
import cn from 'classnames';

import { Button } from '../Button/Button';
import { ReactComponent as Close } from '@shared/icons/close.svg';
import IMask, { type InputMask, type FactoryArg } from 'imask';

import './Input.scss';

export interface IChangeEvent<N> extends React.ChangeEvent<HTMLInputElement> {
  target: React.ChangeEvent<HTMLInputElement>['target'] & { name: N };
}

export enum FixedDigits {
  decimal = 1,
  hundredth = 2,
}

export interface IProps<N extends string = string> {
  name?: N;
  label?: string;
  type?: string;
  mask?: FactoryArg;
  wrapperClassName?: string;
  containerClassName?: string;
  floatingLabelClassName?: string;
  labelClassName?: string;
  fixedDigits?: FixedDigits;
  withoutClear?: boolean;
  onChange: (event: IChangeEvent<N>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  positionError?: 'left' | 'right';
  useNativePlaceholder?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  value: string;
  placeholder?: string;
  className?: string;
  size?: 'medium' | 'large';
  icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
}

interface IState {
  isFocused: boolean;
}

export class Input<N extends string = string> extends React.Component<IProps<N>, IState> {
  static defaultProps = {
    onChange() {},
  };

  private readonly inputRef = React.createRef<HTMLInputElement>();
  maskObj: InputMask<FactoryArg> | null = null;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  componentDidMount() {
    if (this.props.mask && this.inputRef?.current) {
      this.maskObj = IMask(this.inputRef?.current, this.props.mask);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  focus = () => {
    this.inputRef.current?.focus();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clearInput = () => {
    const inputNode = this.inputRef.current;
    if (!inputNode?.value) return;

    const nativeValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    )?.set;
    if (!nativeValueSetter) return;
    nativeValueSetter.call(inputNode, '');
    const event = new CustomEvent('input', { bubbles: true });
    inputNode.dispatchEvent(event);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handleClearBtnMouseDown = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onInputChange = (e: IChangeEvent<N>) => {
    const { mask, onChange } = this.props;
    if (mask && e.target.value) {
      // @ts-expect-error check types
      e.target.value = this.maskObj?.unmaskedValue;
    }
    onChange(e);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {
    const {
      className,
      placeholder,
      useNativePlaceholder,
      value,
      name,
      type,
      containerClassName,
      floatingLabelClassName,
      labelClassName,
      withoutClear,
      disabled,
      autoFocus,
      required,
      icon,
      label,
      wrapperClassName,
      error,
      mask,
      onChange,
      size = 'medium',
      positionError = 'right',
      ...restProps
    } = this.props;

    const isEmpty = value === null || value === undefined || value === '';
    const wrapperClass = cn(`custom-input custom-input--${size}`, wrapperClassName, {
      'custom-input--has-error': error,
    });
    const containerClass = cn('custom-input__container', containerClassName, {
      'custom-input__container--has-left-icon': icon,
      'custom-input__container--required': required,
      'custom-input__container--empty': isEmpty,
      'custom-input__container--has-label': placeholder,
      'custom-input__container--disabled': disabled,
    });
    const inputClass = cn('custom-input__field', className);
    const labelClass = cn('custom-input__floating-label', floatingLabelClassName, {
      'custom-input__floating-label--after-icon': icon,
      'custom-input__floating-label--focused': !isEmpty,
      'custom-input__floating-label--transparent': useNativePlaceholder && isEmpty,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const clearSuffix = (
      <>
        {!isEmpty && !disabled && !withoutClear && (
          <Button
            size={size}
            fullWidth={false}
            onMouseDown={this.handleClearBtnMouseDown}
            onClick={this.clearInput}
            className="custom-input__clear-button "
            icon={<Close />}
          />
        )}
      </>
    );

    // eslint-disable-next-line
    const visibleValue = this.maskObj?.value || value;

    return (
      <div className={wrapperClass}>
        <label className={cn('custom-input__label', labelClassName)} htmlFor={name}>
          {label}
        </label>
        <div className={containerClass}>
          {icon && <div className="custom-input__custom-icon">{icon}</div>}
          {!!placeholder && !value && <span className={labelClass}>{placeholder}</span>}
          <input
            type={type}
            ref={this.inputRef}
            autoFocus={autoFocus}
            name={name}
            placeholder={useNativePlaceholder ? placeholder : undefined}
            className={inputClass}
            value={visibleValue}
            disabled={disabled}
            {...restProps}
            onInput={this.onInputChange}
          />
          {clearSuffix}
        </div>{' '}
        {error && (
          <span
            className={
              positionError === 'right' ? 'custom-input__error' : 'custom-input__error-left'
            }
          >
            {error}
          </span>
        )}
      </div>
    );
  }
}
