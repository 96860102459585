import type React from 'react';
import cn from 'classnames';

import './Badge.scss';

interface IProps {
  className?: string;
  title: string;
  color: string;
  backgroundColor: string;
  size: 'large' | 'small' | 'tiny';
  border?: string;
}

const Badge: React.FC<IProps> = ({
  className,
  title = 'Открыт',
  color = 'var(--primary-text-color)',
  backgroundColor = 'var(--gray-4-color)',
  border = 'none',
  size = 'large',
}: IProps) => (
  <div
    style={{ color, backgroundColor, border }}
    className={cn('custom-badge', `custom-badge--${size}`, className)}
  >
    {title}
  </div>
);

export default Badge;
