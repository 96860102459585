import React, { useEffect, useCallback } from 'react';
import { HttpClient } from '@api/HttpClient';
import { removeEmptyProps, transformObjectKeys } from '@utils';
import { formatDateFromMSCtoUTC } from '@date-time';
import type * as TableTypes from '@components/Table/types';
import { IDictionary, IDictionaryCompany } from '@models/base.model';
import { GlobalContext } from '@services/GlobalContext';

const INITIAL_PAGINATION = { page: 0, size: 10, pages: 0, total: 0 };
const DELAY = 800;

export const useMounted = (): boolean => {
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted.current;
};

export interface IUseTableWithParams<T = unknown> {
  startDate: Date | undefined | null;
  endDate: Date | undefined | null;
  setDateRange: (range: Array<Date | undefined | null>) => void;
  statuses: IDictionary[];
  setStatuses: (statuses: IDictionary[]) => void;
  companies: IDictionaryCompany[];
  setCompanies: (statuses: IDictionaryCompany[]) => void;
  searchStr: string;
  setSearchStr: (str: string) => void;
  data: T[];
  fetchCurrentPage: (tableState: TableTypes.DataFetcherParams) => void;
  isLoading: boolean;
  controlledPageCount: number;
  pageIndex: number;
  totalCount: number;
  pageSize: number;
}

const defaultRequestBodyAssociate = {
  status: 'status',
  search: 'search',
  date: 'date',
} as const;

export function useTableWithParams<T = unknown>(
  url: string,
  mounted: boolean,
  method: string = 'fetch',
  bodyProps?: object
): IUseTableWithParams<T> {
  const _bodyProps = { ...defaultRequestBodyAssociate, ...bodyProps };

  const [loadedData, setData] = React.useState<{ data: T[]; isLoading: boolean }>({
    data: [],
    isLoading: false,
  });

  const [dateRange, setDateRange] = React.useState<Array<Date | null | undefined>>([null, null]);
  const [statuses, setStatuses] = React.useState<IDictionary[]>([]);
  const [companies, setCompanies] = React.useState<IDictionaryCompany[]>([]);
  const [startDate, endDate] = dateRange;
  const [searchStr, setSearchStr] = React.useState('');

  const pagination = React.useRef(INITIAL_PAGINATION);

  const fetchData = ({ pageIndex, pageSize }: TableTypes.DataFetcherParams): void => {
    setData({ ...loadedData, isLoading: true });

    const body = removeEmptyProps({
      [_bodyProps.status]: statuses.map((s) => s.value),
      [_bodyProps.search]: searchStr,
      [_bodyProps.date]: {
        start: formatDateFromMSCtoUTC(startDate),
        end: formatDateFromMSCtoUTC(endDate),
      },
      customer_company_ids: companies.map(({ id }) => id),
    });

    let caller = HttpClient.fetch.bind(HttpClient);

    if (method === 'get') caller = HttpClient.get.bind(HttpClient);

    caller(url, {
      params: {
        page: pageIndex + 1,
        page_size: pageSize,
      },
      body,
    })
      .then((resp: TableTypes.GetTablePagesResponse<unknown>) => {
        // @ts-expect-error Types of parameters are incompatible.
        const prepared = resp.items.map(transformObjectKeys);
        setData({ isLoading: false, data: prepared as T[] });
        pagination.current = { ...resp, page: resp.page - 1 };
      })
      .catch((e) => {
        setData({ isLoading: false, data: [] });
        pagination.current = INITIAL_PAGINATION;
      });
  };

  const fetchCurrentPage = (tableState: TableTypes.DataFetcherParams): void => {
    pagination.current.page = tableState.pageIndex;
    pagination.current.size = tableState.pageSize;
    fetchData(tableState);
  };

  const fetchFirstPage = (): void => {
    if (!mounted) return;
    // начинаем поиск с первой страницы
    pagination.current.page = 0;
    fetchData({ pageIndex: 0, pageSize: pagination.current.size });
  };

  React.useEffect(fetchFirstPage, [statuses, startDate, endDate, companies]);
  useDebounce(fetchFirstPage, [searchStr], DELAY);

  return {
    startDate,
    endDate,
    setDateRange,
    statuses,
    setStatuses,
    companies,
    setCompanies,
    searchStr,
    setSearchStr,
    data: loadedData.data,
    fetchCurrentPage,
    isLoading: loadedData.isLoading,
    controlledPageCount: pagination.current.pages,
    pageIndex: pagination.current.page,
    totalCount: pagination.current.total,
    pageSize: pagination.current.size,
  };
}

type CallbackFunctionVariadic = (...args: unknown[]) => void;

export default function useDebounce(
  effect: CallbackFunctionVariadic,
  dependencies: React.DependencyList,
  delay: number
): void {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [callback, delay]);
}

interface IParsedGlobalContext {
  setShowLoaderHandler: (isLoading: boolean) => void;
  showLoader: boolean;
}

interface IGlobalContext {
  showLoader: [boolean, (isLoading: boolean) => void];
}
export const useGlobalContext = (): IParsedGlobalContext => {
  // @ts-expect-error check types
  const context = React.useContext<IGlobalContext>(GlobalContext);
  if (!context) {
    throw new Error('Global Context can only be used within the Global Provider');
  }
  const { showLoader } = context;
  const [_showLoader, setShowLoader] = showLoader;

  const setShowLoaderHandler = (value: boolean): void => {
    setShowLoader(value);
  };
  return {
    setShowLoaderHandler,
    showLoader: _showLoader,
  };
};
