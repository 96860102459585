import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import Button from '@components/Button/Button';
import Filters from '@shared/components/SearchFilters/SearchFilters';
import { useTableWithParams, useMounted } from '@hooks';
import { renderMemberStatus } from '@functions';
import type { ICompanyParticipantForAdmin } from '../../operator.model';

import './CompaniesParticipants.scss';

const CompaniesParticipants: React.FC = () => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    ...filterParams
  } = useTableWithParams<ICompanyParticipantForAdmin>(
    'operator/companies/participants/list/',
    mounted
  );

  return (
    <div className="companies-participants">
      <div className="companies-participants__actions">
        <Filters className="companies-participants__filters" {...filterParams} />
        <Button
          type="primary"
          onClick={() => {
            navigate(location.pathname + '/create');
          }}
        >
          Добавить участника
        </Button>
      </div>
      <Table
        columns={columns}
        data={data}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
        onRowClick={(row) => {
          navigate(
            location.pathname + '/' + (row.original as ICompanyParticipantForAdmin).participantId
          );
        }}
      />
    </div>
  );
};

const columns: TableTypes.Column[] = [
  {
    Header: 'Статус',
    accessor: (({ isBlocked }: { isBlocked: boolean }) =>
      renderMemberStatus(isBlocked)) as unknown as string,
    align: 'left',
  },
  {
    Header: 'Название компании',
    accessor: 'participantName',
    align: 'left',
  },
  {
    Header: 'Пользователи',
    accessor: 'usersCount',
    align: 'right',
  },
  {
    Header: 'Компании-заказчики',
    accessor: 'customersCount',
    align: 'right',
  },
  {
    Header: 'Количество предложений',
    accessor: 'proposalsCount',
    align: 'right',
  },
];

export default CompaniesParticipants;
