import type * as ReactTable from 'react-table';
import cn from 'classnames';
import { renderAmount, renderPeriod, renderPeriodLong, renderRate } from '@functions';
import type { IParticipantCustomColumn, IRequestDetailParticipants } from '@models/request.model';
import { RateType } from '@models/base.model';

export const COLUMNS_DETAIL: ReactTable.Column[] = [
  {
    id: 'name',
    accessor: (({ name }: { name: string }) => (
      <span className="request-detail-group-table__detail-cell-participant">{name}</span>
    )) as unknown as string,
    align: 'center',
  },
  {
    id: 'label',
    Header: () => <span className="request-detail-group-table__label">Период и объём</span>,
    align: 'center',
    verticalAlign: 'middle',
    columns: [],
  },
];

/** @desc сгенерировать колонки и разложить данные в массив для таблицы */
export const mutateDataForGroups = (
  data: IRequestDetailParticipants
): { detailsColumns: ReactTable.Column[]; detailsData: IParticipantCustomColumn[] } => {
  // Колонки для библы таблицы
  const participantsColumns: Record<
    string,
    { id: string; header: () => JSX.Element; priority: number }
  > = {};
  // Строки для библы таблицы
  const participantsData: IParticipantCustomColumn[] = [];

  // Перебираем участников
  for (const participant of data.participants) {
    // Создать пустую табличную строку для дальнейшего заполнения
    const participantsDataItemFix = {
      id: participant.id + '_fix',
      name: participant.name,
      columns: [] as string[],
    };
    // Предложения могут дублироваться с разным типом ставки
    const participantsDataItemFloat = {
      id: participant.id + '_float',
      name: participant.name,
      columns: [] as string[],
    };
    // Перебираем предложения
    for (const proposal of participant.proposals) {
      // Составляем наименования колонок таблицы  из периодов
      const headerCell = {
        period: proposal.period as number,
        periodType: proposal.period_type,
        endDatePeriod: proposal.end_date_period as string,
      };

      const columnHeader = renderPeriod(headerCell) as string;

      const columnHeaderRenderer = (): JSX.Element => (
        <span className="request-detail-group-table__label">{renderPeriodLong(headerCell)}</span>
      );

      const columnKey =
        proposal.period_type === 'DATE'
          ? (proposal.end_date_period as string)
          : proposal.period + '' + proposal.period_type;
      // Приоритет сортировки для типов периода
      let priority = +(proposal.period as number);
      if (proposal.period_type === 'MONTH') {
        priority += 1000;
      } else if (proposal.period_type === 'YEAR') {
        priority += 1000000;
      } else if (proposal.period_type === 'DATE') {
        priority = new Date(proposal.end_date_period as string).getTime();
        priority += 1000000000;
      }
      // связка тип+период (тип+дата_окончания) должны быть уникальны
      participantsColumns[columnKey] = {
        id: columnHeader,
        header: columnHeaderRenderer,
        priority,
      };
      // Ячейка строки для ключа колонки
      const cell = (
        <div
          key={proposal.proposal_id}
          className={cn(
            'request-detail-group-table__detail-cell-value',
            proposal.is_best_rate && 'request-detail-group-table__detail-cell-value--best'
          )}
        >
          {proposal.amount ? renderAmount(proposal) : '-'}
          <div
            className={cn(
              'request-detail-group-table__detail-cell-rate',
              proposal.is_best_rate && 'request-detail-group-table__detail-cell-rate--best'
            )}
          >
            {renderRate({ rate: proposal.rate, rateType: proposal.rate_type })}
          </div>
        </div>
      );

      // Добавить в строку ячейку под ключем колонки
      if (proposal.rate_type === RateType.Fix && proposal.amount) {
        // @ts-expect-error eslint-disable-next-line
        participantsDataItemFix[columnKey] = participantsDataItemFix[columnKey]
          ? // @ts-expect-error eslint-disable-next-line
            [...participantsDataItemFix[columnKey], cell]
          : [cell];
        participantsDataItemFix.columns.push(columnKey);
      }
      if (proposal.rate_type === RateType.Float && proposal.amount) {
        // @ts-expect-error eslint-disable-next-line
        participantsDataItemFloat[columnKey] = participantsDataItemFloat[columnKey]
          ? // @ts-expect-error eslint-disable-next-line
            [...participantsDataItemFloat[columnKey], cell]
          : [cell];
        participantsDataItemFloat.columns.push(columnKey);
      }
    }
    // Вставляем заполненную строку в массив строк
    // @ts-expect-error eslint-disable-next-line
    participantsData.push(participantsDataItemFix);
    // @ts-expect-error eslint-disable-next-line
    participantsData.push(participantsDataItemFloat);
  }

  // Удалить строки таблицы у которых все колонки пустые
  const participantsDataWithoutEmpty = participantsData.filter((item) => !!item.columns.length);

  // Сортировка колонок по увеличению периода(даты)
  const sortedParticipantsColumns = Object.entries(participantsColumns)
    .sort((a, b) => a[1].priority - b[1].priority)
    .map(([colKey, col]) => ({
      id: col.id,
      Header: col.header,
      align: 'center',
      // @ts-expect-error eslint-disable-next-line
      accessor: (row) => row[colKey] || '-',
    }));

  // Вставка сгенерированных в дефолтные
  const columns = [...COLUMNS_DETAIL];
  // @ts-expect-error eslint-disable-next-line
  columns[1].columns = sortedParticipantsColumns;
  // Возвращаем новые колонки и строки для них
  return { detailsColumns: columns, detailsData: participantsDataWithoutEmpty };
};
