import React from 'react';
import { COMPANY_ROLES } from '@constants';
import { HttpClient } from '@api/HttpClient';
import Notification from '@components/Modal/Notification/Notification';
import { ISimilarCompany } from './SimilarCompaniesChecker.modal';

import './SimilarCompaniesChecker.scss';

interface IProps {
  inn?: string;
  onSubmit: () => void;
}
const SimilarCompaniesChecker: React.FC<IProps> = ({ inn, onSubmit }) => {
  const [similarCompanies, setSimilarCompanies] = React.useState<ISimilarCompany[]>([]);

  const loadSimilarCompanies = async (): Promise<void> => {
    try {
      const companiesWithSimilarInn = await HttpClient.post<{ inn: string }, ISimilarCompany[]>(
        '/operator/companies/',
        {
          inn,
        }
      );

      companiesWithSimilarInn?.length ? setSimilarCompanies(companiesWithSimilarInn) : onSubmit();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    inn && loadSimilarCompanies();
  }, [inn]);

  return similarCompanies.length ? (
    <Notification
      isOpen
      title="Совпадение ИНН"
      message="Компания с указанным ИНН уже присутствует в системе. Все равно добавить?"
      onConfirm={() => {
        setSimilarCompanies([]);
        onSubmit();
      }}
      onCancel={() => {
        setSimilarCompanies([]);
      }}
      confirmBtnText="Добавить"
      cancelBtnText="Вернуться"
    >
      <div className="similar-companies-modal">
        <div className="similar-companies-modal__content">
          {similarCompanies?.map((company) => (
            <div key={company.id} className="similar-companies-modal__company">
              <div className="similar-companies-modal__name">{company.name}</div>
              <div className="similar-companies-modal__inn">{`ИНН: ${company.inn}`}</div>
              <div className="similar-companies-modal__role">{`Роль: ${
                COMPANY_ROLES[company.type] ?? '-'
              }`}</div>
            </div>
          ))}
        </div>
      </div>
    </Notification>
  ) : null;
};

export default SimilarCompaniesChecker;
