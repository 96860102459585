import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { HttpClient } from '@api/HttpClient';
import { AuthUtil } from '@services/auth/auth.util';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import {
  REQUEST_TYPE_CODES,
  REQUEST_STATUSES_PARAMS,
  RATE_TYPES_TITLE,
  REPORT_TYPES,
  MIME_TYPES,
} from '@constants';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { renderAmount, renderPeriod, renderRate } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import type { IAtomicProposal, IRequestAtomicDataWithCompany } from '@models/atomic.model';
import { RateType } from '@models/base.model';
import { download } from '@utils';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';

import './RequestAtomicPage.scss';

export const OperatorRequestAtomicPage: React.FC = () => {
  const [data, setData] = React.useState<IRequestAtomicDataWithCompany>();
  const { requestId, positionId } = useParams();
  const location = useLocation();
  const requestType = location.pathname.includes('/deposit') ? 'deposit' : 'credit';

  React.useEffect(() => {
    HttpClient.get<IRequestAtomicDataWithCompany>(
      `operator/requests/${requestId}/positions/${positionId}/`
    ).then(setData);
  }, [requestId, positionId, requestType]);

  const downloadData = async (): Promise<void> => {
    const data = await HttpClient.get<string>(
      `operator/requests/${requestId}/positions/${positionId}/reports/${REPORT_TYPES.SUMMARY}/`,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );
    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });
    download(blob, `Позиция_запроса_${requestId}_${positionId}.xlsx`);
  };

  const dataForBox = data
    ? [
        [
          {
            label: 'Компания',
            value: data.company_creator.name,
          },
          {
            label: 'Услуга',
            value: REQUEST_TYPE_CODES[data.request_type?.code],
          },
          {
            label: 'Даты размещения запроса',
            value: `с ${formatDate(data.start_ts)} по ${formatDate(data.end_ts)}`,
          },
          {
            label: 'Максимальный объём',
            value: renderAmount({ amount: data.max_amount }),
          },
          {
            label: 'Ставка',
            value: RATE_TYPES_TITLE[data.rate_type],
          },
          {
            label: 'Срок',
            value: renderPeriod({
              period: data.period as number,
              periodType: data.period_type,
              endDatePeriod: data.end_date_period as string,
            }),
          },
        ],
        [
          {
            label: 'Комментарий (внешний)',
            value: data.comment_ext,
          },
          {
            label: 'Комментарий (внутренний)',
            value: data.comment_int,
          },
          {
            label: 'Создан',
            value: `${AuthUtil.getFullNameAbbreviation(
              data.creator?.last_name || '',
              data.creator?.first_name || '',
              data.creator?.patronymic || ''
            )} 
          ${formatDate(data.create_ts)}`,
          },
        ],
      ]
    : [[]];

  if (data?.status === 'COMPLETED')
    dataForBox[1].push({ label: 'Комментарий при завершении', value: data.comment_result });

  return (
    <DetailLayout
      title="Детализация запроса"
      className="operator-request-atomic"
      mainInfoClassName="operator-request-atomic-info"
      extraInfoClassName="operator-request-atomic-table"
      badgeParams={data && REQUEST_STATUSES_PARAMS[data.status]}
      mainInfo={data && <InfoBox title={data.request_name ?? '-'} data={dataForBox} />}
      extraInfo={
        data && (
          <>
            <div className="operator-request-atomic-table__row">
              <div className="operator-request-atomic-table__title">Предложения</div>

              <Button
                className="operator-request-atomic-table__download"
                onClick={downloadData}
                icon={<IconFile />}
              >
                Выгрузить
              </Button>
            </div>
            <Table
              className="operator-request-atomic-table__grid"
              columns={COLUMNS(data.rate_type)}
              data={data.proposals || []}
              notFoundMessage="Список предложений пуст"
              notFoundDescription="К этому запросу пока не оставлено ни одного предложения."
            />
          </>
        )
      }
    >
      <div className="request-atomic__empty-box" />
    </DetailLayout>
  );
};

const COLUMNS = (rateType: RateType): TableTypes.Column[] => [
  {
    Header: '№',
    accessor: 'id',
  },
  {
    Header: 'Ставка',
    accessor: (row: unknown) => (
      <>{renderRate({ rate: (row as IAtomicProposal).rate, rateType })}</>
    ),
  },
  {
    Header: 'Объём',
    accessor: (row: unknown) => renderAmount(row as IAtomicProposal),
    align: 'right',
  },
  {
    Header: 'Участник',
    accessor: 'company.name',
  },
  {
    Header: 'Комментарий участника',
    accessor: 'comment',
  },
];
