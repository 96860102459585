import React, { type JSX } from 'react';

const GlobalContext = React.createContext({ showLoader: [false, () => {}] });

// @ts-expect-error check types
const GlobalProvider = (props): JSX.Element => {
  const [showLoader, setShowLoader] = React.useState(false);

  const obj = {
    showLoader: [showLoader, setShowLoader],
  };

  return (
    <>
      {/** @ts-expect-error check types */}
      <GlobalContext.Provider value={obj}>{props.children}</GlobalContext.Provider>
    </>
  );
};

export { GlobalContext, GlobalProvider };
