import React from 'react';
import { NoRights } from '@shared/components/NoRights';
import { useKeycloak } from '@react-keycloak/web';
import { recursiveTimeout, transformObjectKeys } from '@utils';
import type { IUserInfo } from '@models/user.model';
import CommonRepository from '@api/CommonRepository';
import { HTTP_ERROR_STATUS_CODES } from '@constants';
import { HttpClient } from '@api/HttpClient';
import { CommunicationService } from '@services/communication/communication.service';

const fetchInterval = parseInt(process.env.REACT_APP_FETCH_INTERVAL || '', 10);

export interface IUserContext extends IUserInfo {
  unreadDialogsCount: number;
  canCommunicate: boolean;
  updateUser: () => void;
  updateOperatorMessagesCount: () => void;
  updateUserMessagesCount: () => void;
  unreadOperatorMessagesCount: number;
}
const UserDataContext = React.createContext<IUserContext | null>(null);

export function UserDataContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { keycloak } = useKeycloak();
  const [userData, setUserData] = React.useState<IUserInfo | null>(null);
  const [unreadDialogsCount, setUnreadDialogsCount] = React.useState<number>(0);
  const [errorStatus, setErrorStatus] = React.useState<number | null>(null);
  const [unreadOperatorMessagesCount, setUnreadOperatorMessagesCount] = React.useState<number>(0);

  const root = React.useMemo(() => document.querySelector(':root'), []);

  const loadCurrentUser = async (): Promise<void> => {
    try {
      const resp = await CommonRepository.getCurrentUser();
      const userData = transformObjectKeys(resp as unknown as Record<string, string>);
      setUserData((userData as unknown as IUserInfo) || null);
    } catch (e) {
      // @ts-expect-error check types
      setErrorStatus(e?.response?.status);
    }
  };

  const loadUnreadDialogsCount = async (): Promise<void> => {
    try {
      const resp = await CommunicationService.loadUnreadDialogsCount();
      setUnreadDialogsCount(resp.communication_dialogs || 0);
    } catch (e) {
      // @ts-expect-error check types
      setErrorStatus(e?.response?.status);
    }
  };

  const loadUnreadOperatorMessagesCount = async (): Promise<void> => {
    try {
      const resp = await HttpClient.post<{ support_tickets: number }>('common/tickets/count/');
      setUnreadOperatorMessagesCount(resp.support_tickets || 0);
    } catch (e) {
      setUnreadOperatorMessagesCount(0);
    }
  };

  React.useEffect(() => {
    if (!keycloak?.token) return;
    loadCurrentUser();
  }, [keycloak.sessionId]);

  React.useEffect(() => {
    if (userData) {
      loadUnreadOperatorMessagesCount();
      loadUnreadDialogsCount();

      recursiveTimeout(loadUnreadOperatorMessagesCount, fetchInterval);
      recursiveTimeout(loadUnreadDialogsCount, fetchInterval);
    }
  }, [userData]);

  React.useEffect(() => {
    const mainColor = userData?.company?.mainColor;
    if (mainColor) {
      // @ts-expect-error check types
      root?.style?.setProperty('--primary-color', `#${mainColor}`);
    }
    const additionalColor = userData?.company?.additionalColor;
    if (additionalColor)
      // @ts-expect-error check types
      root?.style?.setProperty('--secondary-color', `#${additionalColor}`);
  }, [userData]);

  const value = userData
    ? {
        ...userData,
        updateUser: loadCurrentUser,
        unreadDialogsCount,
        unreadOperatorMessagesCount,
        updateOperatorMessagesCount: loadUnreadOperatorMessagesCount,
        updateUserMessagesCount: loadUnreadDialogsCount,
        // TODO: в перечисление
        canCommunicate:
          (userData.roles.includes('customer') &&
            userData.requestTypes.includes('COMMUNICATION')) ||
          userData.roles.includes('participant'),
      }
    : null;

  const message =
    errorStatus === HTTP_ERROR_STATUS_CODES.Forbidden
      ? 'Нет доступа'
      : errorStatus === HTTP_ERROR_STATUS_CODES.Unauthorized
      ? 'Нет доступа: email не был подтвержден'
      : '';

  const canNavigate = errorStatus === HTTP_ERROR_STATUS_CODES.Forbidden;

  return (
    <UserDataContext.Provider value={value}>
      {!errorStatus ? children : <NoRights message={message} canNavigate={canNavigate} />}
    </UserDataContext.Provider>
  );
}

export function useCurrentUserData(): IUserContext | null {
  return React.useContext(UserDataContext);
}
