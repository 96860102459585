import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@components/Button/Button';
import { List } from '@components/List2/List';
import NotFound from '@components/NotFound/NotFound';
import { ReactComponent as Archive } from '@shared/icons/communicationsPage/archive.svg';
import { ReactComponent as External } from '@shared/icons/communicationsPage/external.svg';
import { ReactComponent as Bubble } from '@shared/icons/communicationsPage/empty-chat.svg';
import { ReactComponent as Arrow } from '@shared/icons/arrow-up.svg';
import Container from '@components/Container/Container';
import { useCurrentUserData } from '@services/user/userDataContext';
import { hasCustomerRole } from '@roles';
import { IMessage, IDialogDetails } from '@models/communication.model';
import { ChatHeader } from './ChatHeader/ChatHeader';
import { ChatFooter } from './ChatFooter/ChatFooter';
import { IReversedLazyList } from '../ActiveDialogsList/useLazyList';
import { Message } from './Message/Message';

import './Chat.scss';

interface IProps {
  readonly?: boolean;
  dialogDetails?: IDialogDetails;
  list: IReversedLazyList<IMessage>;
  onSend: (newMessage: IMessage) => void;
  onArchive: (dialogId?: number) => Promise<void>;
  emptyDialog: JSX.Element;
  notChosenDialog: JSX.Element;
}

export const Chat: React.FC<IProps> = ({
  list,
  dialogDetails,
  readonly,
  onArchive,
  notChosenDialog,
  emptyDialog,
  onSend,
}) => {
  const { id } = useParams();

  const isEmptyOpenedDialog = !list?.items.length;
  const isNotChosenDialog = !id;
  const userInfo = useCurrentUserData();

  const isCustomer = hasCustomerRole(userInfo?.roles ?? []);

  let link = '';
  if (dialogDetails && (dialogDetails.request_id || dialogDetails.position_ids?.length)) {
    if (isCustomer) {
      // Заказчик, группирующий запрос, "Кредиты/депозиты" - ссылка ведет на группирующий запрос
      link = dialogDetails.is_group
        ? `/${dialogDetails?.request_type}s/requests/${dialogDetails.request_id}`?.toLowerCase()
        : // Заказчик, атомарный запрос (is_group = null, в массиве одна позиция), "Кредиты/депозиты" - ссылка ведет на позицию запроса
        dialogDetails.position_ids
        ? `/${dialogDetails?.request_type}s/requests/${dialogDetails.request_id}/positions/${dialogDetails.position_ids[0]}`?.toLowerCase()
        : // Заказчик, атомарный запрос (is_group = null, positions = null), "Облигации" - ссылка ведет на атомарный запрос
          `/bonds/requests/${dialogDetails.request_id}`;
    } else {
      // Для Участника ссылку на группирующий запрос не создаем
      // Список атомарных запросов будет в разворачивающемся списке
      // Обрабатываем случай одной атомарной позиции
      // "Депозиты/кредиты"
      if (dialogDetails.position_ids)
        link =
          `/${dialogDetails?.request_type}s/positions/${dialogDetails.position_ids[0]}`?.toLowerCase();
      // "Облигации"
      else link = `/bonds/requests/${dialogDetails.request_id}`;
    }
  }

  const rowRenderer = (index: number): JSX.Element => {
    if (!list.items[index]) return <>Нет данных</>; // нельзя возвращать null!
    return (
      <div key={list.items[index]?.id} className="chat__message-wrapper">
        <Message data={list.items[index]} />
      </div>
    );
  };

  const actions = (
    <div className="actions">
      {dialogDetails && link ? (
        <Button
          className="external"
          type="without-border"
          icon={<External />}
          title={`Перейти к ${dialogDetails.is_group ? 'группирующему ' : ' '}запросу`}
          onClick={() => {
            window.open(link, '_blank');
          }}
        />
      ) : null}
      {!readonly && isCustomer && (
        <Button
          className="archive"
          type="without-border"
          icon={<Archive />}
          title="Отправить в архив"
          onClick={() => {
            onArchive(dialogDetails?.id);
          }}
        />
      )}
      {dialogDetails?.is_group && (
        <>
          <input type="checkbox" id="arrow-check" style={{ display: 'none' }}></input>
          <label htmlFor="arrow-check">
            <Arrow />
          </label>
        </>
      )}
    </div>
  );

  return (
    <Container direction="vertical" className="chat">
      {isNotChosenDialog ? (
        notChosenDialog
      ) : (
        <>
          {actions}
          <ChatHeader data={dialogDetails} />
          {isEmptyOpenedDialog ? (
            emptyDialog
          ) : (
            <List
              items={list.items}
              loadMoreRows={list.loadMoreRows}
              totalCount={list.totalItemsCount}
              // @ts-expect-error check
              rowRenderer={rowRenderer}
              noRowsRenderer={() => (
                <NotFound
                  icon={<Bubble />}
                  message="Диалог пуст"
                  description="Вы пока не написали ни одного сообщения"
                />
              )}
              wrapperClassName="chat__messages"
              loadedStartIndex={list.loadedStartIndex}
              itemsPerPage={list.itemsPerPage}
            />
          )}
          {!readonly && <ChatFooter dialogId={+(id || -1)} onSend={onSend} />}
        </>
      )}
    </Container>
  );
};
