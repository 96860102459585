import { IRecipient } from '@pages/operator/AdminPage/Operators/operator.model';
import { useMemo } from 'react';
import type * as TableTypes from '@components/Table/types';
import Tooltip from '@components/Tooltip/Tooltip';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import { useMounted } from '@hooks';
import { IRequest } from '@shared/components/Requests/requests.model';
import { renderDate, renderRecipients } from '@functions';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { INamedEntity } from '@models/base.model';
import { MAX_COUNT_ROWS_FOR_DOWLOAD, MIME_TYPES } from '@constants';
import { HttpClient } from '@api/HttpClient';
import { download } from '@utils';
import { formatDateFromUTCtoMSC as formatDate, formatDateFromMSCtoUTC } from '@date-time';
import Filters, { IFiltersProps } from './Filters/Filters';
import { IFiltesrForRequests, useTableWithParams } from './hook';

import './Log.scss';

const API = 'operator/logs/list/';

const Log: React.FC = () => {
  const mounted = useMounted();
  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    prepareFiltersForRequests,
    ...filterParams
  } = useTableWithParams(API, mounted);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Дата и время',
        accessor: renderDate('createTs'),
        width: 100,
        align: 'right',
      },
      {
        Header: 'Пользователь',
        accessor: ({ username, userCompany }: { username: string; userCompany: INamedEntity }) =>
          username || userCompany ? (
            <div className="creator">
              <span>{username}</span>
              <span className="creator__company">{userCompany?.name}</span>
            </div>
          ) : (
            'Система'
          ),
        width: 190,
      },
      {
        Header: 'Действие',
        accessor: ({ action }: { action: string }) => action ?? '-',
        width: 205,
      },
      {
        Header: 'Трек',
        accessor: ({ requestType }: { requestType: string }) => requestType ?? '-',
        width: 205,
      },
      {
        Header: 'ID запроса',
        accessor: ({ requestId }: { requestId: string }) => requestId ?? '-',
        width: 205,
      },
      {
        Header: 'Детали',
        accessor: ({ details, recipients }: { details: string; recipients: IRecipient[] }) =>
          details ?? renderRecipients(recipients),
        width: 205,
      },
    ],
    []
  );

  const downloadData = async (): Promise<void> => {
    const filters = prepareFiltersForRequests();
    const data = await HttpClient.post<IFiltesrForRequests, string>(
      'operator/logs/reports/',
      filters,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );

    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });

    const date = filters.create_ts?.end ?? formatDateFromMSCtoUTC(new Date());
    download(blob, `Журнал_событий_${formatDate(date)}.xlsx`);
  };

  const canDownloadLog = totalCount > MAX_COUNT_ROWS_FOR_DOWLOAD;

  return (
    <div className="log">
      <div className="log__actions">
        <Filters className="log__filters" {...(filterParams as unknown as IFiltersProps)} />
        <Tooltip
          isShowing={canDownloadLog}
          text={`Доступна выгрузка не более ${MAX_COUNT_ROWS_FOR_DOWLOAD} строк. Примените фильтрацию`}
        >
          <Button
            className="log__download-btn"
            disabled={canDownloadLog}
            onClick={downloadData}
            icon={<IconFile />}
          >
            Выгрузить
          </Button>
        </Tooltip>
      </div>
      <Table
        columns={COLUMNS as TableTypes.Column[]}
        data={data as Array<TableTypes.CustomRowData<IRequest>>}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
      />
    </div>
  );
};

export default Log;

export interface Ilog {
  id: number;
  create_ts: string;
  username: string;
  user_company: INamedEntity;
  action: string;
  details: string;
  recipients: string[];
  request_type: string;
  request_id: number;
}
