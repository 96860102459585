import React from 'react';
import Button from '../Button/Button';
import { ReactComponent as ArrowUp } from '@shared/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '@shared/icons/arrow-down.svg';

import './NumberInput.scss';

type NumberInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: (value: string | number) => void;
};

const NumberInput: React.FC<NumberInputProps> = ({ onChange, min, max, ...rest }) => {
  const ref = React.useRef<HTMLInputElement>(null);

  const handleStepDown = (): void => {
    if (!ref.current) return;
    ref.current.stepDown();
    onChange && onChange(ref.current.value);
  };

  const handleStepUp = (): void => {
    if (!ref.current) return;
    ref.current.stepUp();
    onChange && onChange(ref.current.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let currentValue: string | number = e.currentTarget.value;
    if (max && currentValue > max) {
      currentValue = max;
    }
    if (min && currentValue < min) {
      currentValue = min;
    }
    onChange && onChange(currentValue);
  };

  return (
    <div className="custom-number-input">
      <Button onClick={handleStepUp} icon={<ArrowUp />} className="custom-number-input__button" />
      <input
        ref={ref}
        className="custom-number-input__quantity"
        name="quantity"
        type="number"
        max={max}
        min={min}
        onChange={handleChange}
        {...rest}
      />
      <Button
        onClick={handleStepDown}
        icon={<ArrowDown />}
        className="custom-number-input__button"
      />
    </div>
  );
};

export default NumberInput;
