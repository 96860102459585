import React, { type JSX } from 'react';
import type * as ReactTable from 'react-table';
import Table, { ExpandableRow } from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import Checkbox from '@components/Checkbox/Checkbox';
import { HttpClient } from '@api/HttpClient';
import { getFullname } from '@functions';
import { useCurrentUserData } from '@services/user/userDataContext';
import Filters from '@shared/components/SearchFilters/SearchFilters';
import type { IParticipant } from './participants.model';

import './Participants.scss';
import { ROUTE_LOGS } from '@services/logs/logs.const';
import LogsService from '@services/logs/logs.service';

export function Participants(): JSX.Element {
  const [data, setData] = React.useState<IParticipant[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchStr, setSearchStr] = React.useState<string>('');

  const userInfo = useCurrentUserData();
  const role = userInfo?.roles[0];
  const requestType = location.pathname.includes('/deposit')
    ? 'deposit'
    : location.pathname.includes('/bond')
    ? 'bond'
    : 'credit';

  React.useEffect(() => {
    let logsText;
    switch (requestType) {
      case 'deposit':
        logsText = ROUTE_LOGS.DEPOSIT_PARTICIPANTS;
        break;
      case 'bond':
        logsText = ROUTE_LOGS.BOND_PARTICIPANTS;
        break;
      case 'credit':
        logsText = ROUTE_LOGS.CREDIT_PARTICIPANTS;
        break;
      default:
        break;
    }
    logsText && LogsService.saveLogs(logsText);
  }, []);

  const loadData = async (): Promise<void> => {
    setIsLoading(true);
    const data = await HttpClient.get<IParticipant[]>(
      `customer/${requestType}/participants/${searchStr ? '?search=' + searchStr : ''}`
    );

    // TODO: вынести в общие компоненты
    if (data) {
      // Вставка объединенное открывающейся строки
      setData(
        data.map((el) => ({
          ...el,
          ...(el.users?.length && {
            subRows: [
              {
                render: () => (
                  <div
                    key={el.company_id}
                    className="custom-tbody__tr participants-table-expandable-row"
                  >
                    <div className="participants-table-expandable-row__title">Участники</div>
                    {el.users.map((user, index) => (
                      <div className="participants-table-expandable-row__user" key={index}>
                        {getFullname(user.last_name, user.first_name, user.patronymic)}
                        {user?.status === 'DELETED' ? ' (Удален)' : ''}
                      </div>
                    ))}
                  </div>
                ),
              },
            ],
          }),
        }))
      );
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [requestType, searchStr]);

  const customerColumns: TableTypes.Column[] = [
    {
      Header: 'Доступ к запросам',
      accessor: (row: unknown) => (
        <Checkbox
          name={`${(row as IParticipant).company_id}`}
          checked={!(row as IParticipant).is_blocked_by_customer}
          onChange={async () => {
            const action = (row as IParticipant).is_blocked_by_customer ? 'unblock' : 'block';
            await HttpClient.patch<IParticipant[]>(
              `customer/${requestType}/participants/${(row as IParticipant).company_id}/${action}/`
            );
            loadData();
          }}
        />
      ),
    },
    {
      Header: 'Название компании',
      accessor: 'company_name',
    },
    {
      Header: 'Количество участников',
      accessor: 'users_count',
      align: 'right',
    },
    {
      id: 'id',
      Cell: ({ row }: { row: ReactTable.Row }) => {
        return <ExpandableRow row={row} />;
      },
      align: 'right',
    },
  ];

  const participantColumns: TableTypes.Column[] = [
    {
      Header: 'Название компании',
      accessor: 'company_name',
    },
  ];

  let _columns: TableTypes.Column[] = [];
  if (role === 'customer') {
    _columns = customerColumns;
  } else if (role === 'participant') {
    _columns = participantColumns;
  }

  return (
    <div className="participants">
      <Filters
        className="participants__actions"
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <Table columns={_columns} data={data} withAlternating isLoading={isLoading} />
    </div>
  );
}
