import React from 'react';
import cn from 'classnames';
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars-2';
import {
  renderTrackVerticalDefault,
  renderThumbVerticalDefault,
} from 'react-custom-scrollbars-2/lib/Scrollbars/defaultRenderElements';

import './Scrollbar.scss';

export interface IProps extends ScrollbarProps {
  scrollRef?: React.RefObject<Scrollbars>;
}

export const Scrollbar: React.FC<IProps> = React.memo(
  ({ scrollRef, className, children, ...otherProps }) => (
    <Scrollbars
      {...otherProps}
      className={cn('custom-scrollbar', className)}
      ref={scrollRef}
      renderTrackVertical={(prop) =>
        renderTrackVerticalDefault({ ...prop, className: 'custom-scrollbar__vertical-track' })
      }
      renderThumbVertical={(prop) =>
        renderThumbVerticalDefault({ ...prop, className: 'custom-scrollbar__thumb' })
      }
      renderTrackHorizontal={(prop) => (
        <div {...prop} className="custom-scrollbar__horizontal-track" />
      )}
      renderThumbHorizontal={(prop) => <div {...prop} className="custom-scrollbar__thumb" />}
      hideTracksWhenNotNeeded
    >
      {children}
    </Scrollbars>
  )
);

Scrollbar.displayName = 'Scrollbar';

export default Scrollbar;
