import { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { HttpClient } from '@api/HttpClient';

const ErrorHandlerProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { showBoundary } = useErrorBoundary();
  useEffect(() => {
    // TODO:  проверить наличие подписки
    HttpClient.setErrorHandler(showBoundary);
  }, []);
  return children;
};

export default ErrorHandlerProvider;
