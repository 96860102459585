import React from 'react';
import cn from 'classnames';

import './Tooltip.scss';

interface TooltipProps {
  children: React.ReactNode;
  text: string | React.ReactNode;
  position?: 'top' | 'left' | 'right' | 'bottom';
  width?: number;
  space?: number;
  isCentered?: boolean;
  className?: string;
  isShowing?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  position = 'bottom',
  width = 180,
  space = 16,
  isCentered,
  isShowing = true,
  className,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const showTooltip = (event: React.MouseEvent): void => {
    const dimensions = event.currentTarget.getBoundingClientRect();
    const style: React.CSSProperties = {
      width,
      textAlign: isCentered ? 'center' : undefined,
    };
    switch (position) {
      case 'top':
        style.left = dimensions.left + dimensions.width / 2 - width / 2;
        style.bottom = window.innerHeight - dimensions.top + space;
        break;
      case 'left':
        style.left = dimensions.left - width - space;
        style.top = dimensions.top - dimensions.height;
        break;
      case 'right':
        style.left = dimensions.right + space;
        style.top = dimensions.top - dimensions.height;
        break;
      default:
        style.left = dimensions.left + dimensions.width / 2 - width / 2;
        if (dimensions.top < window.innerHeight / 2) {
          style.top = dimensions.top + dimensions.height + space;
        } else {
          style.bottom = window.innerHeight - dimensions.top + space;
        }
        break;
    }
    setIsVisible(true);
    setTooltipStyle(style);
  };
  const hideTooltip = (): void => {
    setIsVisible(false);
  };
  const [tooltipStyle, setTooltipStyle] = React.useState<React.CSSProperties>({});
  return (
    <div
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
      className={cn('custom-tooltip', className)}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {isVisible && isShowing && (
        <div className="tooltip-body" style={tooltipStyle}>
          {text}
        </div>
      )}
    </div>
  );
};
export default Tooltip;
