import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@components/Table';
import Button from '@components/Button/Button';
import type * as TableTypes from '@components/Table/types';
import { ROLES } from '@roles';
import type * as ReactTableTypes from 'react-table';
import Filters from '@shared/components/Filters/Filters';
import type { IRequest } from './requests.model';
import type { IUseTableWithParams } from '@hooks';

import './Requests.scss';

export interface IProps {
  table: IUseTableWithParams<unknown>;
  columns: ReactTableTypes.ColumnInterface[];
  canCreate?: boolean;
  role: keyof typeof ROLES;
}

const Requests: React.FC<IProps> = ({ columns, canCreate, role, table }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    ...filterParams
  } = table;
  const handleRowClick = (row: ReactTableTypes.Row<object>): void => {
    if (role === ROLES.OPERATOR) {
      navigate(
        location.pathname +
          (location.pathname.at(-1) === '/' ? '' : '/') +
          (row.original as { companyCreator: { id: string } })?.companyCreator?.id +
          '/requests/' +
          row.id
      );
    } else {
      navigate(location.pathname + '/' + row.id);
    }
  };

  return (
    <div className="requests">
      <div className="requests__actions">
        {/* @ts-expect-error check types */}
        <Filters className="request__filters" {...filterParams} />
        {canCreate && (
          <Button
            type="primary"
            onClick={() => {
              navigate(location.pathname + '/create');
            }}
          >
            Создать запрос
          </Button>
        )}
      </div>
      <Table
        columns={columns as TableTypes.Column[]}
        data={table.data as Array<TableTypes.CustomRowData<IRequest>>}
        onFetchData={table.fetchCurrentPage}
        withPagination
        isLoading={table.isLoading}
        controlledPageCount={table.controlledPageCount}
        pageIndex={table.pageIndex}
        totalCount={table.totalCount}
        withAlternating
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default Requests;
