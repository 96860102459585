import React from 'react';
import AnchorList, { ILink } from './AnchorList';

export const ScrollArea: React.FC<{ html: string }> = ({ html }) => {
  const scrollElementRef = React.useRef<HTMLDivElement>(null);
  const dangerHtmlRef = React.useRef<HTMLDivElement>(null);
  const [anchorList, setAnchorList] = React.useState<ILink[]>([]);
  const [activeLinkIndex, setActiveLinkIndex] = React.useState(0);

  const registerScrollElement = (): void => {
    const element = document.getElementById('scrollable');
    // @ts-expect-error eslint-disable-next-line
    if (element) scrollElementRef.current = element;
  };

  const registerHeaders = (): void => {
    if (!html || !dangerHtmlRef.current) return;

    const nodes = dangerHtmlRef.current.querySelectorAll('[id]');
    const links: ILink[] = [];

    nodes.forEach((node) => {
      const link: ILink = {
        // @ts-expect-error eslint-disable-next-line
        offsetTop: node.offsetTop || node.getBoundingClientRect().top,
        text: node.textContent as string,
      };
      links.push(link);
    });

    setAnchorList(links);
  };

  const registerScrollAreaListener = (): void => {
    scrollElementRef.current?.addEventListener('wheel', (e) => {
      let lastIndex = 0;
      anchorList.forEach((link, index) => {
        // калибровать
        if (e.offsetY - 60 > link.offsetTop) {
          lastIndex = index;
        }
      });

      if (activeLinkIndex !== lastIndex) {
        setActiveLinkIndex(lastIndex);
      }
    });
  };

  const onLinkClick = (offsetTop: number, linkIndex: number): void => {
    if (!scrollElementRef.current) return;

    scrollElementRef.current.scroll({ top: offsetTop, behavior: 'smooth' });
    setActiveLinkIndex(linkIndex);
  };

  React.useEffect(registerScrollElement, []);

  React.useEffect(() => {
    registerHeaders();
  }, [html, dangerHtmlRef.current]);

  React.useEffect(() => {
    registerScrollAreaListener();
  }, [anchorList]);

  return (
    <>
      <div
        ref={dangerHtmlRef}
        className="info-page__html"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <AnchorList list={anchorList} activeIndex={activeLinkIndex} onLinkClick={onLinkClick} />
    </>
  );
};

export default ScrollArea;
