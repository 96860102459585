import { Button } from '@components/Button/Button';
import { Modal } from '@components/Modal/Modal';
import { Textarea } from '@components/Textarea/Textarea';
import { Form, Formik } from 'formik';

import './RequestModalCancel.scss';

interface IProps {
  onConfirm: (comment: string) => void;
  onCancel: () => void;
}

export const RequestModalCancel = ({ onConfirm, onCancel }: IProps): JSX.Element => {
  return (
    <Modal isOpen className="request-modal-cancel-warning" title="Отказаться от запроса">
      <Formik<{ comment: string }>
        enableReinitialize={false}
        initialValues={{ comment: '' }}
        validateOnBlur
        validateOnChange
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <p>Вы уверены, что хотите отказаться? Решение можно изменить в любой момент</p>
              <Textarea
                label="Комментарий для заказчика"
                value={values.comment}
                placeholder="Напишите ваш комментарий"
                className="request-modal-cancel-warning__comment"
                size="large"
                isClearable
                onChange={(e) => {
                  setFieldValue('comment', e.target?.value);
                }}
              />
              <Modal.Actions>
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    onConfirm(values.comment);
                  }}
                  type="primary"
                >
                  Отказаться
                </Button>
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Отмена
                </Button>
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export const ModalRefuseRequests = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}): JSX.Element => {
  return (
    <Modal isOpen className="request-modal-cancel-warning" title="Отказаться от всех запросов">
      <p>Отказаться также от всех подобных запросов этого заказчика?</p>
      <Modal.Actions>
        <Button
          fullWidth
          size="large"
          onClick={() => {
            onConfirm();
          }}
          type="primary"
        >
          Отказаться
        </Button>
        <Button
          fullWidth
          size="large"
          onClick={() => {
            onCancel();
          }}
        >
          Отмена
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
