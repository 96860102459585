import type React from 'react';
import cn from 'classnames';

import './Avatar.scss';

interface IProps {
  className?: string;
  wrapperClassName?: string;
  backgroundColor?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  src?: string;
  title?: string;
  description?: string;
}

export const DEFAULT_AVATAR =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMiAxMUMxNC4yMDkxIDExIDE2IDkuMjA5MTQgMTYgN0MxNiA0Ljc5MDg2IDE0LjIwOTEgMyAxMiAzQzkuNzkwODYgMyA4IDQuNzkwODYgOCA3QzggOS4yMDkxNCA5Ljc5MDg2IDExIDEyIDExWiIgZmlsbD0iIzIyMjIyMiIvPg0KPHBhdGggZD0iTTEyIDIxQzE1Ljg2NiAyMSAxOSAxOS4yMDkxIDE5IDE3QzE5IDE0Ljc5MDkgMTUuODY2IDEzIDEyIDEzQzguMTM0MDEgMTMgNSAxNC43OTA5IDUgMTdDNSAxOS4yMDkxIDguMTM0MDEgMjEgMTIgMjFaIiBmaWxsPSIjMjIyMjIyIi8+DQo8L3N2Zz4NCg==';

const Avatar: React.FC<IProps> = ({
  className,
  wrapperClassName,
  titleClassName,
  descriptionClassName,
  backgroundColor = '#ebebeb',
  title,
  description,
  src,
}: IProps) => (
  <div className={cn('custom-avatar', wrapperClassName)}>
    {src ? (
      <img className={cn('custom-avatar__img', className)} src={src} alt="avatar" title={title} />
    ) : (
      <div className={cn('custom-avatar__circle', className)} style={{ backgroundColor }} />
    )}
    <div className="custom-avatar__titles">
      <div className={cn('custom-avatar__title', titleClassName)}>{title}</div>
      <div className={cn('custom-avatar__description', descriptionClassName)}>{description}</div>
    </div>
  </div>
);

export default Avatar;
