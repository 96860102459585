import Keycloak, { type KeycloakConfig } from 'keycloak-js';

export const KEYCLOAK_CONFIG: Keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_HOST,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
} as KeycloakConfig);

/*
todo for evolution SPA and appearance open pages
export const SKIP_AUTH_PATHS: string[] = [];

export const INVALID_AUTHORITY = 'INVALID_AUTHORITY';
export const MIN_SEC_VALIDITY = 60; // Время меньше которого токен считается истекшим и его можно обновить (время жизни access токена)
*/
