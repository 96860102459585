/* eslint-disable react/prop-types */
import cn from 'classnames';
import { Button } from '../../Button/Button';
import { Modal } from '../Modal';

import './Notification.scss';

export interface IProps {
  title: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  message?: string | JSX.Element;
  confirmBtnText?: string;
  cancelBtnText?: string;
  getParent?: () => HTMLElement;
  modalStyle?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
  className?: string;
  children?: JSX.Element | JSX.Element[] | null;
}

export const Notification: React.FC<IProps> = (props) => {
  const {
    isOpen,
    title,
    onCancel,
    message,
    onConfirm,
    children,
    confirmBtnText,
    cancelBtnText,
    className,
    getParent,
    modalStyle,
    overlayStyle,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      className={cn('custom-notification', className)}
      parentSelector={getParent}
      contentStyle={modalStyle}
      overlayStyle={overlayStyle}
      title={title}
    >
      {message && <p className="custom-notification__message">{message}</p>}
      {children}
      <Modal.Actions className="custom-notification__actions">
        {confirmBtnText && (
          <Button type="primary" size="large" onClick={onConfirm ?? onCancel} fullWidth>
            {confirmBtnText}
          </Button>
        )}
        {cancelBtnText && (
          <Button size="large" onClick={onCancel} fullWidth>
            {cancelBtnText}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default Notification;
