import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

export const CreateCustomerSchema = Yup.object().shape({
  name: Yup.string()
    .test(
      '1-100 letters',
      'От 1 до 100 символов',
      // @ts-expect-error check types
      (item) => {
        return item && item?.toString().length <= 100 && item?.toString().length >= 1;
      }
    )
    .test(
      'only letters',
      'Допустим ввод букв и спецсимволов',
      // @ts-expect-error check types
      (item) => {
        return item && !/\d/.test(item);
      }
    )
    .required(REQUIRED_MESSAGE),
  inn: Yup.string()
    .test(
      '10 numbers',
      'Поле из 10 цифр',
      // @ts-expect-error check types
      (item) => {
        return item && /^[\d+]{10}$/.test(item);
      }
    )
    .required(REQUIRED_MESSAGE),
  comment: Yup.string()
    .nullable()
    .test('less whan 100 letters', 'Максимум 100 символов', (item) => {
      return item ? item.length <= 100 : true;
    }),
  main_color: Yup.string()
    .nullable()
    .test('is hex color', 'Неверный формат', (item) => {
      return item ? /^[A-Fa-f0-9]{6}$/.test(item) : true;
    }),
  additional_color: Yup.string()
    .nullable()
    .test('is hex color', 'Неверный формат', (item) => {
      return item ? /^[A-Fa-f0-9]{6}$/.test(item) : true;
    }),
});
