import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes';
import Button from '@components/Button/Button';
import { Modal } from '@components/Modal/Modal';

import './NoCommunicationsModal.scss';

export const NoCommunicationsModal: React.FC<{
  disabledNavigate: boolean;
  onClose: () => void;
}> = ({ disabledNavigate, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen className="no-communications-modal">
      <Modal.Header>
        <h3 className="no-communications-modal__title">Коммуникация недоступна</h3>
      </Modal.Header>
      <p>
        Для начала коммуникации необходимо подключить соответствующую опцию. Для подключения
        обратитесь в тех. поддержку.
      </p>
      <Modal.Actions className="no-communications-modal__actions">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            navigate(ROUTES.SUPPORT + ROUTES.TICKETS + '/create');
          }}
          disabled={disabledNavigate}
          fullWidth
        >
          Обратиться
        </Button>
        <Button size="large" onClick={onClose} fullWidth>
          Закрыть
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
