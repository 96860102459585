import React from 'react';
import cn from 'classnames';

import { RadioContext } from './RadioContext';

import './style.scss';

export type TRadioValue = string | number;

interface TRadioGroupProps {
  name: string;
  value: TRadioValue;
  onChange: (value: TRadioValue) => void;
  disabled?: boolean;
  className?: string;
  mode?: 'custom' | 'native';
  wrapperClassName?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  label?: string;
  error?: string;
}

const RadioGroup: React.FC<TRadioGroupProps> = ({
  label,
  labelClassName,
  wrapperClassName,
  children,
  name,
  value,
  disabled,
  className,
  onChange,
  error,
  mode = 'custom',
}) => {
  const [selectedValue, setSelectedValue] = React.useState<TRadioValue>(value);

  const onValueChange = (newValue: TRadioValue): void => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const wrapperClass = cn(
    wrapperClassName,
    'custom-radio-group-wrapper',
    `custom-radio-group-wrapper--${mode}`,
    {
      'custom-radio-group-wrapper--has-error': error,
    }
  );

  const classRadio = cn('custom-radio-group', className, `custom-radio-group--${mode}`, {
    'custom-radio-group--has-error': error,
  });

  return (
    <div className={wrapperClass}>
      <div className={cn('custom-radio-group-label', labelClassName)}>{label}</div>
      <RadioContext.Provider value={{ selectedValue, onChange: onValueChange, name, disabled }}>
        <div className={classRadio}>{children}</div>
      </RadioContext.Provider>
      {error && <div className="custom-radio-group__error">{error}</div>}
    </div>
  );
};

export default RadioGroup;
