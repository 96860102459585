import React from 'react';
import { hasOperatorRole, hasCustomerRole, hasParticipantRole } from '@roles';
import { OperatorMainPage } from '../operator/MainPage';
import { CustomerMainPage } from '../customer/MainPage';
import { ParticipantMainPage } from '../participant/MainPage';
import { useCurrentUserData } from '@services/user/userDataContext';

export const EntryPage: React.FC = () => {
  const userData = useCurrentUserData();

  const isOperator = hasOperatorRole(userData?.roles ?? []);
  const isCustomer = hasCustomerRole(userData?.roles ?? []);
  const isParticipant = hasParticipantRole(userData?.roles ?? []);

  if (isOperator) return <OperatorMainPage />;
  if (isCustomer) return <CustomerMainPage />;
  if (isParticipant) return <ParticipantMainPage />;

  return null;
};
