import { JSX } from 'react';
import cn from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import plural from 'plural-ru';
import Container from '@components/Container/Container';
import Textarea from '@components/Textarea/Textarea';
import Button from '@components/Button/Button';
import Avatar, { DEFAULT_AVATAR } from '@components/Avatar/Avatar';
import { formatDateFromUTCtoMSC as formatDate, READABLE_DATETIME_FORMAT } from '@date-time';
import { IMessage } from '../../../types';
import managerAvatar from '@shared/icons/manager-avatar.png';
import AutoCheckForm from '@shared/components/AutoCheckForm';
import { useCurrentUserData } from '@services/user/userDataContext';
import { AuthUtil } from '@services/auth/auth.util';

import './Chat.scss';

const Message = ({ message }: { message: IMessage }): JSX.Element => {
  const currentUser = useCurrentUserData();

  return (
    <article className="custom-message">
      <Avatar
        className="custom-message__avatar"
        titleClassName={cn('custom-message__author', {
          'custom-message__author--operator': message.creator?.is_operator,
        })}
        src={message.creator.is_operator ? managerAvatar : DEFAULT_AVATAR}
        title={
          message?.author ||
          (message.creator.is_operator
            ? 'Оператор'
            : currentUser?.id === message.creator_id
            ? 'Вы'
            : AuthUtil.getFullNameAbbreviation(
                message.creator?.last_name || '',
                message.creator?.first_name || '',
                message.creator?.patronymic || ''
              ))
        }
        description={formatDate(message.create_ts, READABLE_DATETIME_FORMAT)}
      />
      <div className="custom-message__text">{message.text}</div>
    </article>
  );
};

const MIN_MESSAGE_LENGTH = 1;
const MAX_MESSAGE_LENGTH = 1000;

const schema = Yup.object().shape({
  my_comment: Yup.string()
    .required('Поле обязательное')
    .trim()
    .min(
      MIN_MESSAGE_LENGTH,
      `Комментарий должен содержать не менее ${MIN_MESSAGE_LENGTH} ${plural(
        MIN_MESSAGE_LENGTH,
        '%d символа',
        '%d символов'
      )}`
    )
    .max(MAX_MESSAGE_LENGTH, `Максимум ${MAX_MESSAGE_LENGTH} символов`),
});

const MessageForm = ({
  onSendMessage,
}: {
  onSendMessage: (text: string) => Promise<void>;
}): JSX.Element => {
  return (
    <Formik
      enableReinitialize={false}
      validateOnBlur
      validateOnChange
      validationSchema={schema}
      initialValues={{ my_comment: '' }}
      onSubmit={({ my_comment: myComment }, { resetForm }) => {
        onSendMessage(myComment).then(() => {
          resetForm();
        });
      }}
    >
      {({ values, handleChange, handleBlur, errors, dirty, touched }) => {
        return (
          <Form>
            <div className="custom-chat__form">
              <Textarea
                size="large"
                className="custom-chat__textarea"
                name="my_comment"
                isClearable
                placeholder="Напишите Ваш комментарий"
                error={touched?.my_comment ? (errors.my_comment as string) : undefined}
                value={values.my_comment}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button
                disabled={!dirty}
                type="primary"
                className="custom-chat__submit"
                htmlType="submit"
              >
                Отправить
              </Button>
            </div>
            <AutoCheckForm
              title="Прервать создание комментария"
              message="Вы уверены, что хотите прервать создание комментария? Все данные будут утеряны."
              onConfirm={() => {}}
              confirmBtnText="Прервать"
              cancelBtnText="Отмена"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export function Chat({
  messages,
  onSendMessage,
  className,
  noMessagesText = 'К этому обращению пока не оставлено ни одного комментария',
  closingMessage,
}: {
  messages: IMessage[];
  onSendMessage: (text: string) => Promise<void>;
  className?: string;
  noMessagesText?: string;
  closingMessage?: string;
}): JSX.Element {
  return (
    <div className={cn('custom-chat', className)}>
      <Container.Header className="custom-chat__title">Комментарии</Container.Header>
      {!messages ||
        (!messages.length && <div className="custom-chat__no-messages">{noMessagesText}</div>)}
      {!closingMessage && <MessageForm onSendMessage={onSendMessage} />}
      {messages && (
        <ul className="custom-chat__messages">
          {messages.map((m) => (
            <Message message={m} key={m.id} />
          ))}
        </ul>
      )}
      {closingMessage && <div className="custom-chat__closing">{closingMessage}</div>}
    </div>
  );
}
