import React, { useState } from 'react';
import { CSSObject } from '@emotion/react';
import { CSSObjectWithLabel, GroupBase } from 'react-select';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import cn from 'classnames';
import {
  MultiValueContainer,
  ClearIndicator,
  DropdownIndicator,
  Option,
  EmptyData,
  LoadingMessage,
} from './components';

import './Select.scss';

const AsyncSelect: React.FC<
  AsyncProps<unknown, boolean, GroupBase<unknown>> & {
    label?: string;
    size?: string;
    wrapperClassName?: string;
    labelClassName?: string;
    error?: string;
    onClear?: () => void;
    isChips?: boolean;
  }
> = (params) => {
  const {
    wrapperClassName,
    labelClassName,
    className,
    label,
    size = 'medium',
    error,
    getOptionLabel,
    value,
    isMulti,
    onMenuOpen,
    onMenuClose,
    isChips = false,
    ...rest
  } = params;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const labelId = `${rest.name}-label`;

  // TODO: использовать styles
  return (
    <div
      className={cn(wrapperClassName, 'custom-select-wrapper', {
        'custom-select-wrapper--has-error': error,
        'custom-select--is-multi': isMulti,
      })}
    >
      <label className={cn('custom-select-label', labelClassName)} id={labelId}>
        {label}
      </label>
      <ReactAsyncSelect
        classNamePrefix="custom-select"
        className={cn(
          'custom-select',
          `custom-select--${size}`,
          { 'custom-select--is-chips': isChips },
          className
        )}
        noOptionsMessage={EmptyData}
        loadingMessage={LoadingMessage}
        hideSelectedOptions={false}
        isClearable={false}
        menuPlacement="auto"
        aria-labelledby={labelId}
        getOptionLabel={getOptionLabel}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        value={value}
        closeMenuOnSelect={!isMulti}
        components={{
          Option,
          DropdownIndicator: DropdownIndicator(menuIsOpen),
          ClearIndicator,
          MultiValueContainer: MultiValueContainer(isChips),
        }}
        onMenuOpen={() => {
          setMenuIsOpen(true);
          onMenuOpen?.();
        }}
        onMenuClose={() => {
          setMenuIsOpen(false);
          onMenuClose?.();
        }}
        styles={{
          control: (base) =>
            ({
              ...base,
              outline: menuIsOpen ? 'none' : base.outline,
              boxShadow: menuIsOpen ? 'none' : base.boxShadow,
            }) as CSSObjectWithLabel,
          option: (provided, state) =>
            ({
              ...provided,
              backgroundColor: state.isSelected ? '#22222205' : '#fff',
              color: state.isSelected ? '#222222' : '#222222',
            }) as CSSObject,
        }}
        {...rest}
      />
      {error && <span className="custom-select-wrapper__error">{error}</span>}
    </div>
  );
};

export default AsyncSelect;
