import type React from 'react';
import cn from 'classnames';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link } from 'react-router-dom';
import { ROUTES_WITH_BREADCRUMBS } from '@routes';

import { ReactComponent as Chevron } from '@shared/icons/chevron.svg';

import './Breadcrumbs.scss';

interface IProps {
  className?: string;
}
export const Breadcrumbs: React.FC<IProps> = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const breadcrumbs = useBreadcrumbs(ROUTES_WITH_BREADCRUMBS, { disableDefaults: true });
  const lastIndex = breadcrumbs.length - 1;

  return (
    <ul className={cn('custom-breadcrumbs', className)}>
      {breadcrumbs.map((item, index) => (
        <li key={index}>
          <Link className={cn(index === lastIndex && 'custom-breadcrumbs--inactive')} to={item.key}>
            {item.breadcrumb}
          </Link>
          {index !== lastIndex && <Chevron className="custom-breadcrumbs__chevron" />}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
