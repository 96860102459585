import React from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowRight } from '@shared/icons/arrow-right-8-16.svg';

interface IProps {
  list: ILink[];
  activeIndex: number;
  onLinkClick: (offsetTop: number, linkIndex: number) => void;
}
export interface ILink {
  offsetTop: number;
  text: string;
}
export const AnchorList: React.FC<IProps> = ({ list, activeIndex, onLinkClick }) => (
  <nav className="info-page-menu-bar">
    <ul>
      {list.map((link, index) => {
        const isActive = activeIndex === index;
        return (
          <li
            key={index}
            onClick={() => {
              onLinkClick(link.offsetTop, index);
            }}
            className={cn(
              'info-page-menu-bar__item',
              isActive && 'info-page-menu-bar__item--active'
            )}
          >
            {isActive && <ArrowRight className="info-page-menu-bar__item-icon" />}
            {link.text}
          </li>
        );
      })}
    </ul>
  </nav>
);

export default AnchorList;
