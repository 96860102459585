import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthLayout from '@layouts/AuthLayout/AuthLayout';
import Button from '@components/Button/Button';
import { Input } from '@components/Input/Input';
import { phoneMask } from '@masks';
import Checkbox from '@components/Checkbox/Checkbox';

import './SetPassword.scss';
import {
  EOtpType,
  EState,
  EXPIRATION_TIME,
  INCORRECT_PROFILE_PHONE,
  KEY_INCORRECT,
  KEY_NOT_FOUND,
  PASSWORD_REG_EXP,
  TOO_MANY_SMS,
} from '@pages/common/SetPassword/setPassword.const';
import {
  IConfirmPassword,
  ICreatePassword,
  IForgotPassword,
  IPasswordSuccess,
  IRegister,
  ISendEmailRequest,
  ISendSmsRequest,
  ISentLink,
  ISetPassword,
  ISetPasswordRequest,
} from '@pages/common/SetPassword/setPassword.model';
import { IHTTPResponseWithError, HttpClient } from '@api/HttpClient';
import { AxiosError } from 'axios';

const ForgotPassword = ({ onSend, onToLogin }: IForgotPassword): JSX.Element => {
  const [email, setEmail] = React.useState('');
  const [uncaughtError, setUncaughtError] = React.useState<string | undefined>('');
  const [isFocusEmail, setIsFocusEmail] = React.useState(false);

  function isInvalidEmail(): boolean {
    return email.length < 6 || email.length > 100;
  }

  function getEmailErrorMessage(): string | undefined {
    if (!email || isFocusEmail) {
      return undefined;
    }
    if (isInvalidEmail()) {
      return 'Неверный формат почты';
    }
    return uncaughtError;
  }

  async function onSubmit(): Promise<void> {
    if (isInvalidEmail()) {
      return;
    }

    try {
      await HttpClient.post<ISendEmailRequest, string>('/public/recovery_password/', {
        email,
      });
      onSend(email);
    } catch (e) {
      setUncaughtError('Неверный формат почты');
      console.error(e);
    }
  }

  return (
    <>
      <div className="reset-password__block">
        <h3>Забыли пароль?</h3>
        <div>
          Если указанный адрес зарегистрирован в системе, на него придет ссылка для восстановления
          пароля
        </div>
      </div>

      <div className="reset-password__block reset-password__block--start">
        <Input
          name="email"
          size="large"
          wrapperClassName="reset-password__input"
          positionError="left"
          value={email}
          error={getEmailErrorMessage()}
          onBlur={() => {
            setIsFocusEmail(false);
          }}
          onFocus={() => {
            setIsFocusEmail(true);
          }}
          onChange={(e) => {
            setEmail(e.target?.value);
            setUncaughtError(undefined);
          }}
        />
      </div>

      <div className="reset-password__block">
        <Button
          type="brand"
          size="large"
          onClick={async () => {
            await onSubmit();
          }}
        >
          Отправить ссылку
        </Button>

        <Button
          size="large"
          className="reset-password__btn"
          onClick={() => {
            onToLogin();
          }}
        >
          Вернуться к входу
        </Button>
      </div>
    </>
  );
};

const SentLink = ({ email, onToLogin }: ISentLink): JSX.Element => {
  async function sendEmail(): Promise<void> {
    try {
      await HttpClient.post<ISendEmailRequest, string>('/public/recovery_password/', {
        email,
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <div className="reset-password__block">
        <h3>Ссылка отправлена</h3>
        <div>
          <p>Если адрес зарегистрирован в системе, на него было отправлено письмо со ссылкой.</p>
          <p>Не забудьте проверить папку “Спам”.</p>
        </div>
      </div>

      <div className="reset-password__block">
        <Button
          type="brand"
          size="large"
          onClick={() => {
            onToLogin();
          }}
        >
          Вернуться к входу
        </Button>

        <Button
          size="large"
          className="reset-password__btn"
          onClick={async () => {
            await sendEmail();
          }}
        >
          Отправить повторно
        </Button>
      </div>
    </>
  );
};

const CreatePassword = ({
  otp,
  isRegistration,
  onCreatePassword,
}: ICreatePassword): JSX.Element => {
  const [isFocusPassword, setIsFocusPassword] = React.useState(false);
  const [isFocusConfirmPassword, setIsFocusConfirmPassword] = React.useState(false);
  const [isFocusPhone, setIsFocusPhone] = React.useState(false);
  const [incorrectProfilePhoneError, setIncorrectProfilePhoneError] = React.useState(false);
  const [tooManySmsError, setTooManySmsError] = React.useState(false);
  const [uncaughtError, setUncaughtError] = React.useState<string | undefined>(undefined);
  const [userId, setUserId] = React.useState('');
  const [state, setState] = React.useState({
    password: '',
    confirm_password: '',
    phone: '',
    error_page: false,
  });

  function isDisabledCreate(): boolean {
    return (
      isInvalidPassword(state.password) ||
      isInvalidPassword(state.confirm_password) ||
      isDifferentPasswords() ||
      isInvalidPhone()
    );
  }

  function isDifferentPasswords(): boolean {
    if (!state.password || !state.confirm_password) {
      return false;
    }
    return state.password !== state.confirm_password;
  }

  function isInvalidPassword(password: string): boolean {
    return !password || !PASSWORD_REG_EXP.test(password);
  }

  function isInvalidPhone(): boolean {
    return !state.phone || state.phone.length < 11;
  }

  function getPasswordErrorMessage(): string | undefined {
    if (!state.password || isFocusPassword) {
      return undefined;
    }
    if (isInvalidPassword(state.password)) {
      return 'Неверный формат пароля';
    }
  }

  function getConfirmPasswordErrorMessage(): string | undefined {
    if (!state.confirm_password || isFocusConfirmPassword) {
      return undefined;
    }
    if (isDifferentPasswords()) {
      return 'Пароли не совпадают';
    }
  }

  function getPhoneErrorMessage(): string | undefined {
    if (!state.phone || isFocusPhone) {
      return undefined;
    }
    if (isInvalidPhone()) {
      return 'Неверный формат номера';
    }
    if (incorrectProfilePhoneError) {
      return 'Номер не совпадает с указанным в профиле';
    }
    if (tooManySmsError) {
      return 'Превышен лимит запросов на СМС в день';
    }
    return uncaughtError;
  }

  React.useEffect(() => {
    initProcessCreate();
  }, []);

  async function initProcessCreate(): Promise<void> {
    const otpType = isRegistration ? EOtpType.SetPassEmail : EOtpType.ChangePassEmail;
    try {
      const response = await HttpClient.get<{ data: string } | string>(
        `/public/${otpType}/${otp}/`
      );
      const correctUserId = (response as { data: string })?.data ?? response;
      setUserId(correctUserId);
    } catch (e) {
      setState((prevState) => ({ ...prevState, error_page: true }));
    }
  }

  async function onSubmit(): Promise<void> {
    if (isDisabledCreate()) {
      return;
    }

    try {
      await HttpClient.post<ISendSmsRequest, string>('/public/sms_send/', {
        phone: state.phone,
        user_id: userId,
      });
      onCreatePassword(userId, state.password, state.phone);
    } catch (e) {
      const errorMessage = (e as AxiosError<IHTTPResponseWithError<string>>)?.response?.data
        ?.detail;
      setIncorrectProfilePhoneError(errorMessage === INCORRECT_PROFILE_PHONE);
      setTooManySmsError(errorMessage === TOO_MANY_SMS);
      !incorrectProfilePhoneError && !tooManySmsError && setUncaughtError('Непредвиденная ошибка');
      console.error(e);
    }
  }

  return (
    <>
      <div className="reset-password__block">
        <h3>{!state.error_page ? 'Создание пароля' : 'Ошибка!'}</h3>
        <div>
          {!state.error_page
            ? 'Пароль должен быть не короче 8 символов, должен содержать хотя бы одну цифру, одну заглавную букву, одну строчную и один специальный символ.'
            : 'Ссылка больше не действительна, запросите новую или обратитесь в поддержку.'}
        </div>
      </div>

      {!state.error_page && (
        <div className="reset-password__block reset-password__block--start">
          <Input
            name="password"
            type="password"
            size="large"
            wrapperClassName="reset-password__input"
            placeholder="Новый пароль"
            positionError="left"
            error={getPasswordErrorMessage()}
            value={state.password}
            onBlur={() => {
              setIsFocusPassword(false);
            }}
            onFocus={() => {
              setIsFocusPassword(true);
            }}
            onChange={(e) => {
              setState((prevState) => ({ ...prevState, password: e.target?.value }));
            }}
          />

          <Input
            name="confirm_password"
            type="password"
            size="large"
            wrapperClassName="reset-password__input"
            placeholder="Повторите пароль"
            positionError="left"
            error={getConfirmPasswordErrorMessage()}
            value={state.confirm_password}
            onBlur={() => {
              setIsFocusConfirmPassword(false);
            }}
            onFocus={() => {
              setIsFocusConfirmPassword(true);
            }}
            onChange={(e) => {
              setState((prevState) => ({ ...prevState, confirm_password: e.target?.value }));
            }}
          />

          <Input
            mask={phoneMask}
            name="phone"
            size="large"
            wrapperClassName="reset-password__input"
            positionError="left"
            error={getPhoneErrorMessage()}
            value={state.phone}
            onBlur={() => {
              setIsFocusPhone(false);
            }}
            onFocus={() => {
              setIsFocusPhone(true);
            }}
            onChange={(e) => {
              setIncorrectProfilePhoneError(false);
              setUncaughtError(undefined);
              setState((prevState) => ({ ...prevState, phone: e.target?.value }));
            }}
          />
        </div>
      )}

      {!state.error_page && (
        <div className="reset-password__block">
          <Button
            type="brand"
            size="large"
            onClick={async () => {
              await onSubmit();
            }}
          >
            Создать пароль
          </Button>
        </div>
      )}
    </>
  );
};

const ConfirmPassword = ({
  userId,
  phone,
  password,
  isRegistration,
  onBack,
  onConfirmPassword,
}: IConfirmPassword): JSX.Element => {
  const codeMask = {
    mask: Number,
    lazy: false,
    min: 0,
    max: 999999,
    scale: 0,
  };

  const timerRef = React.useRef<NodeJS.Timer | null>(null);

  const [code, setCode] = React.useState('');
  const [time, setTime] = React.useState(EXPIRATION_TIME);
  const [isFocusCode, setIsFocusCode] = React.useState(false);
  const [incorrectCodeError, setIncorrectCodeError] = React.useState(false);
  const [outdatedCodeError, setOutdatedCodeError] = React.useState(false);
  const [uncaughtError, setUncaughtError] = React.useState<string | undefined>(undefined);
  const [tooManySmsError, setTooManySmsError] = React.useState(false); // TODO реализовать на бэке и поддержать установку ошибки

  function isInvalidCode(): boolean {
    return !code || code.length < 6;
  }

  function getCodeErrorMessage(): string | undefined {
    if (tooManySmsError) {
      return 'Превышен лимит запросов на СМС в день';
    }
    if (isFocusCode || !code) {
      return undefined;
    }
    if (isInvalidCode()) {
      return 'Неверный формат кода';
    }
    if (incorrectCodeError) {
      return 'Неправильный код';
    }
    if (outdatedCodeError) {
      return 'Код больше не действителен, запросите новый';
    }
    return uncaughtError;
  }

  function getMinutes(time: number): number {
    return Math.floor(time / 60);
  }

  function getSeconds(time: number): number {
    return time % 60;
  }

  function setter(): void {
    if (time > 0) {
      setTime((prevTime) => prevTime - 1);
    }
  }

  function startTimer(): void {
    timerRef.current = setInterval(setter, 1000);
  }

  function clearTimer(): void {
    clearTimeout(timerRef?.current ?? undefined);
  }

  React.useEffect(() => {
    startTimer();
  }, []);

  async function sendSms(): Promise<void> {
    try {
      await HttpClient.post<ISendSmsRequest, string>('/public/sms_send/', {
        phone,
        user_id: userId,
      });
    } catch (e) {
      const errorMessage = (e as AxiosError<IHTTPResponseWithError<string>>)?.response?.data
        ?.detail;
      setTooManySmsError(errorMessage === TOO_MANY_SMS);
      !tooManySmsError && setUncaughtError('Непредвиденная ошибка');
      console.error(e);
    }
  }

  async function onSubmit(): Promise<void> {
    if (isInvalidCode()) {
      return;
    }

    try {
      await HttpClient.post<ISetPasswordRequest, string>('/public/reset_password/', {
        rules_confirm: true,
        password,
        user_id: userId,
        sms_otp: Number(code),
      });
      clearTimer();
      onConfirmPassword();
    } catch (e) {
      const errorMessage = (e as AxiosError<IHTTPResponseWithError<string>>)?.response?.data
        ?.detail;
      setIncorrectCodeError(JSON.stringify(errorMessage)?.includes(KEY_INCORRECT) || false);
      setOutdatedCodeError(JSON.stringify(errorMessage)?.includes(KEY_NOT_FOUND) || false);
      setTooManySmsError(JSON.stringify(errorMessage)?.includes(TOO_MANY_SMS) || false);
      !incorrectCodeError && !outdatedCodeError && setUncaughtError('Непредвиденная ошибка');
      console.error(e);
    }
  }

  return (
    <>
      <div className="reset-password__block">
        <h3>Подтверждение пароля</h3>
        <div>На указанный номер был отправлен код подтверждения. Введите его в поле ниже.</div>
      </div>

      <div className="reset-password__block reset-password__block--start">
        <Input
          name="sms"
          size="large"
          wrapperClassName="reset-password__input"
          placeholder="Код из СМС"
          positionError="left"
          error={getCodeErrorMessage()}
          mask={codeMask}
          value={code}
          onBlur={() => {
            setIsFocusCode(false);
          }}
          onFocus={() => {
            setIsFocusCode(true);
          }}
          onChange={(e) => {
            setIncorrectCodeError(false);
            setOutdatedCodeError(false);
            setTooManySmsError(false);
            setUncaughtError(undefined);
            setCode(e.target?.value);
          }}
        />
      </div>

      <div className="reset-password__block">
        <Button
          type="brand"
          size="large"
          onClick={async () => {
            await onSubmit();
          }}
        >
          Подтвердить пароль
        </Button>

        <Button
          size="large"
          onClick={async () => {
            await sendSms();
            clearTimer();
            setTime(EXPIRATION_TIME);
            startTimer();
          }}
          disabled={time > 0}
        >
          {time > 0
            ? `Новый код через ${getMinutes(time)} мин ${getSeconds(time)} сек`
            : 'Запросить новый код'}
        </Button>

        <Button
          size="large"
          onClick={() => {
            clearTimer();
            onBack();
          }}
          className="reset-password__btn"
        >
          Вернуться назад
        </Button>
      </div>
    </>
  );
};

const PasswordSuccess = ({ onToLogin }: IPasswordSuccess): JSX.Element => {
  return (
    <>
      <div className="reset-password__block">
        <h3>Пароль установлен</h3>
        Вы можете выполнить вход с новым паролем
      </div>

      <Button
        type="brand"
        size="large"
        onClick={() => {
          onToLogin();
        }}
      >
        Перейти ко входу
      </Button>
    </>
  );
};

const Register = ({ onRegister }: IRegister): JSX.Element => {
  const [state, setState] = React.useState({
    privacy_policy: false,
    personal_policy: false,
    terms_of_use: false,
  });

  function isDisabledRegistration(): boolean {
    return !state.personal_policy || !state.terms_of_use; /* || !state.privacy_policy */
  }

  function onSubmit(): void {
    if (isDisabledRegistration()) {
      return;
    }
    onRegister();
  }

  return (
    <>
      <div className="register-page__block">
        <h3>Добро пожаловать в Finsage Info!</h3>
        <div>Для регистрации необходимо прочесть и принять основные условия системы.</div>
      </div>

      <div className="register-page__block register-page__block--start">
        Я прочёл и принимаю:
        {/* <Checkbox
          checked={state.privacy_policy}
          name="privacy-policy"
          label={
            <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank" rel="noreferrer">
              политику конфиденциальности
            </a>
          }
          onChange={() => {
            setState((prevState) => ({ ...prevState, privacy_policy: !prevState.privacy_policy }));
          }}
        /> */}
        <Checkbox
          checked={state.personal_policy}
          name="perconal-policy"
          label={
            <a
              href={process.env.REACT_APP_PERSONAL_DATA_PROCESSING_POLICY}
              target="_blank"
              rel="noreferrer"
            >
              политику обработки персональных данных
            </a>
          }
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              personal_policy: !prevState.personal_policy,
            }));
          }}
        />
        <Checkbox
          checked={state.terms_of_use}
          name="terms_of_use"
          label={
            <a href={process.env.REACT_APP_TERMS_OF_USE} target="_blank" rel="noreferrer">
              условия использования
            </a>
          }
          onChange={() => {
            setState((prevState) => ({ ...prevState, terms_of_use: !prevState.terms_of_use }));
          }}
        />
      </div>

      <Button
        type="brand"
        size="large"
        onClick={() => {
          onSubmit();
        }}
      >
        Регистрация
      </Button>
    </>
  );
};

export function SetPassword({ initialState = EState.Register }: ISetPassword): JSX.Element {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [state, setState] = React.useState(initialState);

  const [recoveryEmail, setRecoveryEmail] = React.useState('');

  const [userId, setUserId] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [phone, setPhone] = React.useState('');

  function isRegistration(): boolean {
    return initialState === EState.Register;
  }

  function toLoginPage(): void {
    navigate('/');
  }

  const Components: Record<EState, JSX.Element> = {
    [EState.ForgotPassword]: (
      <ForgotPassword
        onToLogin={() => {
          toLoginPage();
        }}
        onSend={(email) => {
          setRecoveryEmail(email);
          setState(EState.SentLink);
        }}
      />
    ),
    [EState.SentLink]: (
      <SentLink
        email={recoveryEmail}
        onToLogin={() => {
          toLoginPage();
        }}
      />
    ),
    [EState.Register]: (
      <Register
        onRegister={() => {
          setState(EState.CreatePassword);
        }}
      />
    ),
    [EState.Agreement]: (
      <Register
        onRegister={() => {
          setState(EState.CreatePassword);
        }}
      />
    ),
    [EState.CreatePassword]: (
      <CreatePassword
        otp={String(uuid)}
        isRegistration={isRegistration()}
        onCreatePassword={(userId, password, phone) => {
          setPhone(phone);
          setUserId(userId);
          setPassword(password);
          setState(EState.ConfirmPassword);
        }}
      />
    ),
    [EState.ConfirmPassword]: (
      <ConfirmPassword
        userId={userId}
        phone={phone}
        password={password}
        isRegistration={isRegistration()}
        onConfirmPassword={async () => {
          setState(EState.PasswordSuccess);
        }}
        onBack={() => {
          setState(EState.CreatePassword);
        }}
      />
    ),
    [EState.PasswordSuccess]: (
      <PasswordSuccess
        onToLogin={() => {
          toLoginPage();
        }}
      />
    ),
  };

  const classNames: Record<EState, string> = {
    [EState.ForgotPassword]: 'reset-password',
    [EState.SentLink]: 'reset-password',
    [EState.ConfirmPassword]: 'reset-password',
    [EState.CreatePassword]: 'reset-password',
    [EState.PasswordSuccess]: 'reset-password',
    [EState.Register]: 'register-page',
    [EState.Agreement]: 'register-page',
  };

  return (
    <AuthLayout className={state ? classNames[state] : ''}>
      {state ? Components[state] : null}
    </AuthLayout>
  );
}
