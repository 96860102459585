import { ReactComponent as Cross } from '@shared/icons/close.svg';
import Button from '@components/Button/Button';
import Datepicker from '@components/Datepicker/Datepicker';
import { Input } from '@components/Input/Input';
import Select from '@components/Select/Select';
import { PERIOD_TYPES } from '@constants';
import { amountMask, positiveNumberMask } from '@masks';
import { setNumberOrEmpty } from '@utils';
import type { IPosition } from '../request.model';

import './PositionRow.scss';

interface IProps {
  index: number;
  position: IPosition;
  onRemove: (index: number) => void;
  onChange: (index: number, field: string, value: unknown) => Promise<void>;
  onBlur: (e: unknown) => void;
  error?: Record<string, string>;
}

export const PositionRow: React.FC<IProps> = ({
  index,
  position,
  onRemove,
  onChange,
  onBlur,
  error,
}: IProps): JSX.Element => {
  return (
    <li key={index} className="new-request__position-row">
      <div className="new-request__position-content">
        <div className="new-request__position-block">
          <span>Срок предоставления:</span>
          <Select
            name="period_type"
            size="large"
            wrapperClassName="new-request__position-period_type"
            placeholder="Тип срока"
            options={periodOptions}
            value={position.period_type}
            error={error?.period_type}
            onChange={async (value) => {
              onChange(index, 'period_type', value);
              if (position.period_type?.value !== 'DATE')
                await onChange(index, 'end_date_period', undefined);
              // TODO: оптимизировать через useEffect?
              else await onChange(index, 'period', '');
            }}
            onBlur={onBlur}
          />
          {position.period_type && position.period_type.value === 'DATE' ? (
            <Datepicker
              size="large"
              name="end_date_period"
              wrapperClassName="new-request__position-date"
              value={position.end_date_period as unknown as Date}
              selected={position.end_date_period as unknown as Date}
              error={error?.end_date_period}
              onChange={(e) => {
                onChange(index, 'end_date_period', e);
              }}
              onBlur={onBlur}
              portalId="datepicker-portal"
            />
          ) : (
            <Input
              size="large"
              name="period"
              mask={positiveNumberMask}
              wrapperClassName="new-request__position-period"
              placeholder="Укажите срок"
              value={position.period as string}
              error={error?.period}
              onChange={(e) => {
                onChange(index, 'period', e.target?.value);
              }}
              onBlur={onBlur}
            />
          )}
        </div>
        <div className="new-request__position-block">
          <span>Объём:</span>
          <Input
            name="amount"
            size="large"
            wrapperClassName="new-request__position-amount"
            mask={amountMask}
            value={position.amount as string}
            error={error?.amount}
            onChange={(e) => {
              onChange(index, 'amount', setNumberOrEmpty(e.target?.value));
            }}
            onBlur={onBlur}
          />
          <Button
            className="new-request__position-clear"
            icon={<Cross />}
            onClick={() => {
              onRemove(index);
            }}
          />
        </div>
      </div>
    </li>
  );
};

const periodOptions = Object.keys(PERIOD_TYPES).map((el) => ({
  label: PERIOD_TYPES[el],
  value: el,
}));
