import React from 'react';
import Tabs from '@components/Tabs/Tabs';
import { useLocation } from 'react-router-dom';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { ROUTES } from '@routes';
import type { ITab } from '../../../../types';
import { CustomerRequests } from './CustomerRequests';
import { Participants } from './Participants/Participants';

import './MainPage.scss';

export const CustomerMainPage: React.FC = () => {
  const { pathname } = useLocation();

  const requestType = pathname.includes('/deposit')
    ? 'deposit'
    : pathname.includes('/bond')
    ? 'bond'
    : 'credit';

  const path = `/${requestType}s`;

  return (
    <PageLayout className="main-page">
      <Tabs tabs={getTabs(path)} />
    </PageLayout>
  );
};

const getTabs = (path: string): ITab[] => {
  return [
    {
      title: 'Запросы',
      component: <CustomerRequests />,
      path: path + ROUTES.REQUESTS,
    },
    {
      title: 'Участники',
      component: <Participants />,
      path: path + ROUTES.PARTICIPANTS,
    },
  ];
};
