import React from 'react';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { type To, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { CreateCompanyParticipantForm } from '../CreateCompanyParticipantForm/CreateCompanyParticipantForm';

import './CreateCompanyParticipant.scss';

export const CreateCompanyParticipant: React.FC = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();
  let prevLink = breadcrumbs.at(-2)?.key as unknown;
  if (!prevLink) prevLink = -1;

  return (
    <DetailLayout
      title="Добавление компании-участника"
      className="new-company-participant"
      showFooter
    >
      <CreateCompanyParticipantForm
        onClose={() => {
          navigate(prevLink as To);
        }}
      />
    </DetailLayout>
  );
};
