import React from 'react';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import cn from 'classnames';
import { Virtuoso } from 'react-virtuoso';
import Scrollbar from '../Scrollbar/Scrollbar';

import './List.scss';

interface IRowsRenderedHandlerData {
  startIndex: number;
  stopIndex: number;
}

export type RowsRenderedHandler = (data: IRowsRenderedHandlerData) => void;

interface RowRendererData {
  index: number;
  isScrolling: boolean;
  isVisible: boolean;
  key: React.Key;
  style: React.CSSProperties;
}

export type IRowRenderer = (data?: RowRendererData) => React.ReactNode;

export interface IProps {
  loadMoreRows: (data: { startIndex: number; stopIndex: number }) => Promise<unknown>;
  rowRenderer: IRowRenderer;
  noRowsRenderer: IRowRenderer;
  items?: unknown[];
  totalCount: number;
  wrapperClassName: string;
  loadedStartIndex: number;
  itemsPerPage: number;
}

// @ts-expect-error check
export const List: React.FC<IProps> = ({
  items = [],
  totalCount,
  wrapperClassName,
  loadedStartIndex,
  itemsPerPage,
  loadMoreRows,
  rowRenderer,
  noRowsRenderer,
}) => {
  if (!items?.length) return noRowsRenderer();
  return (
    <div className={cn('custom-virtuoso-list', wrapperClassName)}>
      <AutoSizer>
        {({ width, height }) =>
          width &&
          height && (
            // обязателен скроллбар
            <Scrollbar style={{ width, height }}>
              <Virtuoso
                style={{ flex: 1 }}
                initialTopMostItemIndex={items?.length - 1}
                followOutput={true}
                className="custom-virtuoso-list__list"
                // @ts-expect-error check
                itemContent={rowRenderer}
                data={items.filter(Boolean)}
                alignToBottom={true}
                firstItemIndex={Math.max(0, loadedStartIndex)}
                startReached={(index) => {
                  if (loadedStartIndex !== 0)
                    loadMoreRows({
                      startIndex: totalCount - index > 0 ? totalCount - index : 0,
                      stopIndex: totalCount - index + itemsPerPage,
                    });
                }}
              />
            </Scrollbar>
          )
        }
      </AutoSizer>
    </div>
  );
};

export default List;
