import { createContext, useContext } from 'react';
import type { TRadioValue } from './index';

export interface TRadioContext {
  selectedValue: TRadioValue;
  onChange: (value: TRadioValue) => void;
  name: string;
  disabled?: boolean;
}

export const RadioContext = createContext<TRadioContext | null>(null);

export const useRadioContext = (): Error | TRadioContext => {
  const context = useContext(RadioContext);
  if (!context) {
    throw new Error('Radio Context can only be used within the radio group');
  }
  return context;
};
