import type React from 'react';
import cn from 'classnames';
import { ReactComponent as Search } from '@shared/icons/search.svg';
import { Input } from '@components/Input/Input';

import './SearchFilters.scss';

interface IProps {
  className?: string;
  searchStr: string;
  setSearchStr: (value: string) => void;
}

const Filters: React.FC<IProps> = ({ className, searchStr, setSearchStr }) => {
  return (
    <div className={cn('custom-search-filters', className)}>
      <Input
        icon={<Search />}
        type="text"
        className="custom-search-filters__search"
        value={searchStr}
        disabled
        placeholder="Поиск"
        onChange={(e: { target: { value: string } }) => {
          setSearchStr(e.target.value);
        }}
      />
    </div>
  );
};

export default Filters;
