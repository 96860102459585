import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@components/Container/Container';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import { getFullname } from '@functions';
import { BLOCKED_STATUSES, MEMBER_STATUSES_PARAMS } from '@constants';
import Badge from '@components/Badge/Badge';
import type * as TableTypes from '@components/Table/types';
import { ReactComponent as IconChevronRight } from '@shared/icons/arrow-right-8-16.svg';
import type { IUserOfParticipantForAdmin } from '../../operator.model';

import './UsersTable.scss';

interface IProps {
  data?: IUserOfParticipantForAdmin[];
}

export const UsersTable: React.FC<IProps> = ({ data }: IProps) => {
  if (!data) return null;
  const navigate = useNavigate();

  const navigateToUser = (row: { id: string }): void => {
    const userId = row.id;
    if (!userId) return;

    navigate(`/administration/users/${userId}/details`, { replace: true });
  };

  const navButton = (row: unknown): JSX.Element => (
    <Button
      className="company-participant-detail-users-table__button-next"
      type="text"
      icon={<IconChevronRight />}
      onClick={() => {
        navigateToUser(row as { id: string });
      }}
    />
  );
  const columns: TableTypes.Column[] = React.useMemo(
    () =>
      [
        {
          Header: 'Пользователь',
          accessor: (item: IUserOfParticipantForAdmin) => {
            const {
              first_name: firstName,
              last_name: lastName,
              patronymic,
              position,
              status,
            } = item;
            const isBlocked = BLOCKED_STATUSES.includes(status);

            return (
              <div className="company-participant-detail-users-table__person">
                <div className="company-participant-detail-users-table__name">
                  {getFullname(lastName, firstName, patronymic)}
                  {isBlocked && <Badge {...MEMBER_STATUSES_PARAMS[status]} size="small" />}
                </div>
                <div className="company-participant-detail-users-table__position">{position}</div>
              </div>
            );
          },
        },
        {
          id: 'id',
          accessor: navButton,
          align: 'right',
        },
      ] as TableTypes.Column[],
    []
  );

  return (
    <>
      <Container.Header className="company-participant-detail-users-table__header">
        Пользователи
      </Container.Header>
      <Table
        data={data}
        columns={columns}
        withAlternating
        className="company-participant-detail-users-table__grid"
      />
    </>
  );
};
