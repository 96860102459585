import React from 'react';

import { REQUEST_TYPE_CODES, RATE_TYPES_TITLE } from '@constants';
import { renderAmount } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import type { IPositionDetail } from '../position.model';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';

import './MainInfo.scss';

interface IProps {
  data?: IPositionDetail;
  requestType: string;
}

export const MainInfo: React.FC<IProps> = ({ data, requestType }: IProps) => {
  if (!data) return null;

  const dataForBox = [
    [
      {
        label: 'Заказчик',
        value: data.request?.creator_company?.name,
      },
      {
        label: 'Услуга',
        value: REQUEST_TYPE_CODES[requestType.toUpperCase()],
      },
      {
        label: 'Срок',
        value:
          data.period_type === 'DATE'
            ? formatDate(data.end_date_period)
            : `${data.period} ${data.period_type === 'MONTH' ? 'месяцев' : 'лет'}`,
      },
      {
        label: 'Даты размещения запроса',
        value: `с ${formatDate(data.request?.start_ts)} по ${formatDate(data.request?.end_ts)}`,
      },
    ],
    [
      {
        label: 'Комментарий',
        value: data.request?.comment_ext,
      },
      { label: 'Ставка', value: RATE_TYPES_TITLE[data.rate_type] },
      { label: 'Объем', value: renderAmount(data) },
    ],
  ];

  return <InfoBox title={data.request?.name} data={dataForBox} />;
};
