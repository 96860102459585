import React, { type JSX } from 'react';
import { useLocation } from 'react-router-dom';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import { HttpClient } from '@api/HttpClient';
import Filters from '@shared/components/SearchFilters/SearchFilters';
import type { ICustomer } from './customers.model';

import './Customers.scss';
import { ROUTE_LOGS } from '@services/logs/logs.const';
import LogsService from '@services/logs/logs.service';

export function Customers(): JSX.Element {
  const [data, setData] = React.useState<ICustomer[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchStr, setSearchStr] = React.useState<string>('');
  const { pathname } = useLocation();
  const requestType = pathname.includes('/deposit')
    ? 'deposit'
    : pathname.includes('/bond')
    ? 'bond'
    : 'credit';

  React.useEffect(() => {
    let logsText;
    switch (requestType) {
      case 'deposit':
        logsText = ROUTE_LOGS.DEPOSIT_CUSTOMERS;
        break;
      case 'bond':
        logsText = ROUTE_LOGS.BOND_CUSTOMERS;
        break;
      case 'credit':
        logsText = ROUTE_LOGS.CREDIT_CUSTOMERS;
        break;
      default: break;
    }
    logsText && LogsService.saveLogs(logsText);
  }, []);

  const loadData = async (): Promise<void> => {
    setIsLoading(true);
    const data = await HttpClient.get<ICustomer[]>(`participant/${requestType}/customers/list/`);
    if (data) {
      setData(data);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [requestType]);

  return (
    <div className="customers">
      <Filters className="customers__actions" searchStr={searchStr} setSearchStr={setSearchStr} />
      <Table columns={columns} data={data} withAlternating isLoading={isLoading} />
    </div>
  );
}

const columns: TableTypes.Column[] = [
  {
    Header: 'Название компании',
    accessor: 'name',
  },
];
