/* eslint-disable react/prop-types */
import cn from 'classnames';

import './NotFound.scss';

interface IProps {
  message?: string;
  description?: string;
  className?: string;
  children?: JSX.Element | null;
  icon?: JSX.Element;
}

export const NotFound: React.FC<IProps> = ({
  message = 'К сожалению, данные не найдены',
  description = 'Попробуйте изменить параметры поиска',
  className,
  children,
  icon,
}) => (
  <div className={cn('custom-not-found', className, icon && 'custom-not-found--with-icon')}>
    <div className="custom-not-found__icon">{icon}</div>
    <h4 className="custom-not-found__message">{message}</h4>
    <p className="custom-not-found__description">{description}</p>
    {children}
  </div>
);

export default NotFound;
