import { getMSCNow } from '@date-time';
import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';
const CURRENT_TIME_MESSAGE = 'Дата не может быть меньше или равной текущей дате';

export const RequestSchema = Yup.object().shape({
  name: Yup.string().trim().max(50, 'Ввод более 50 символов запрещен').required(REQUIRED_MESSAGE),
  comment_ext: Yup.string().trim().max(300, 'Ввод более 300 символов запрещен'),
  comment_int: Yup.string().trim().max(300, 'Ввод более 300 символов запрещен'),
  end_ts: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('wrong current date', CURRENT_TIME_MESSAGE, (item) => new Date(item) > getMSCNow()),
  rate_type: Yup.string().required(REQUIRED_MESSAGE),
  max_amount: Yup.number()
    .test('is empty', REQUIRED_MESSAGE, (item) => !!item)
    .test(
      'max 15 letters',
      'Максимум 15 символов',
      (item) => !!item && item > 0 && item < 1000000000000000
    )
    .required(REQUIRED_MESSAGE),
  positions: Yup.array()
    .of(
      Yup.object().shape({
        period_type: Yup.object().required(REQUIRED_MESSAGE),
        end_date_period: Yup.string()
          .nullable()
          .test('need date', REQUIRED_MESSAGE, (value, context) => {
            const periodType = context.parent.period_type;
            if (!periodType) return true;
            if (periodType.value === 'DATE' && !value) {
              return false;
            }
            return true;
          })
          .test('need date', CURRENT_TIME_MESSAGE, (value, context) => {
            const periodType = context.parent.period_type;
            if (!periodType || !value) return true;
            if (periodType.value === 'DATE') {
              return new Date(value) > getMSCNow();
            }
            return true;
          }),
        period: Yup.number().when('period_type', {
          is: (periodType?: { value: string }) => periodType && periodType.value === 'DATE',
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema.test(
              '1-3 letters',
              'Поле от 1 до 3 символов',
              (item) => Number.isNaN(item as number) || (!!item && +item > 0 && +item < 1000)
            ),
        }),
        amount: Yup.number()
          .test(
            'max',
            'Объём превосходит максимальный объём по запросу',
            (value, context) => !!value && value <= context.options.context?.max_amount
          )
          .required(REQUIRED_MESSAGE),
      })
    )
    .min(1, 'Заполните позиции'),
});
