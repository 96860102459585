import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import type * as TableTypes from '@components/Table/types';
import Table from '@components/Table';
import { Button } from '@components/Button/Button';
import { useMounted } from '@hooks';
import { IRequest } from '@shared/components/Requests/requests.model';
import { renderDate, renderTicketStatus } from '@functions';
import { INamedEntity } from '@models/base.model';
import { AuthUtil } from '@services/auth/auth.util';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { ReactComponent as Incoming } from '@shared/icons/communicationsPage/incoming.svg';
import { ReactComponent as Outcoming } from '@shared/icons/communicationsPage/outcoming.svg';
import { useTableWithParams } from './hook';
import Filters, { IFiltersProps } from './Filters/Filters';

import './TicketsPage.scss';

const API = 'operator/tickets/list/';

const Tickets: React.FC = () => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    ...filterParams
  } = useTableWithParams(API, mounted);

  const COLUMNS = useMemo(
    () => [
      {
        id: 'is_outgoing',
        width: 40,
        align: 'center',
        accessor: (data: Record<string, string>) =>
          data.fromOperator ? <Outcoming /> : <Incoming />,
      },
      {
        Header: '№ Обр-я',
        accessor: 'id',
        width: 100,
        align: 'right',
      },
      {
        Header: 'Дата подачи',
        accessor: renderDate('createTs'),
        width: 160,
      },
      {
        Header: 'Тема',
        width: 200,
        clipped: true,
        accessor: ({ title }: { title: string }) => title || '-',
      },
      {
        Header: 'Текст обращения',
        accessor: 'text',
        width: 444,
        clipped: true,
      },
      {
        Header: 'Отправил',
        accessor: ({
          creator,
          creatorCompany,
        }: {
          creator: { lastName: string; firstName: string; patronymic: string };
          creatorCompany: INamedEntity;
        }) => (
          <div className="creator">
            <span>
              {AuthUtil.getFullNameAbbreviation(
                creator.lastName ?? '',
                creator.firstName ?? '',
                creator.patronymic ?? ''
              )}
            </span>
            <span className="creator__company">{creatorCompany?.name}</span>
          </div>
        ),
        width: 180,
      },
      {
        Header: 'Статус',
        accessor: ({ status }: { status: string }) => renderTicketStatus(status),
        width: 100,
      },
    ],
    []
  );

  return (
    <div className="tickets">
      <div className="tickets__actions">
        <Filters className="tickets__filters" {...(filterParams as unknown as IFiltersProps)} />
        <Button
          type="primary"
          onClick={() => {
            navigate(location.pathname + '/create');
          }}
        >
          Создать обращение
        </Button>
      </div>
      <Table
        columns={COLUMNS as TableTypes.Column[]}
        data={data as Array<TableTypes.CustomRowData<IRequest>>}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        onRowClick={(row) => {
          navigate(location.pathname + '/' + row.id);
        }}
        totalCount={totalCount}
        withAlternating
      />
    </div>
  );
};

export const TicketsPage: React.FC = () => (
  <PageLayout className="tickets-page">
    <h1>Обращения в поддержку</h1>
    <Tickets />
  </PageLayout>
);
