import React, { JSX } from 'react';
import {
  Route,
  Routes,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import {
  WelcomePage,
  EntryPage,
  TicketsPage,
  TicketDetailPage,
  UserTicketDetailPage,
  InfoPage,
  ProfilePage,
  AdminPage,
  SupportPage,
  CreateTicketPage,
  RequestDetailGroupPage,
  RequestAtomicPage,
  TermsPage,
  PrivacyPolicyPage,
  PersonalPolicyPage,
  SetPassword,
  CreateRequestPage,
  CreateBondRequestPage,
  PositionDetailPage,
  CreateCompanyParticipant,
  CompanyParticipantDetailPage,
  CreateCustomerPage,
  CustomerDetailPage,
  UserDetailPage,
  CreateUserPage,
  OperatorRequestDetailPage,
  OperatorRequestAtomicPage,
  BondDetailPage,
  CreateOperatorTicketPage,
  ReportsPage,
} from '@pages';
import { ROUTES } from '@routes';
import AuthRoute from '@shared/components/AuthRoute';
import { Boundaried } from '@shared/components/Boundaried';

import { ROLES } from '@roles';
import { EState } from '@pages/common/SetPassword/setPassword.const';
import { CommunicationsPage } from '@pages/common/CommunicationsPage/CommunicationsPage';

const AdminRoutes = (): React.ReactElement | null => (
  <Routes>
    <Route path={ROUTES.DEFAULT} element={<AdminPage />} />
    {[ROUTES.CUSTOMERS, ROUTES.PARTICIPANTS, ROUTES.USERS, ROUTES.OPERATORS, ROUTES.LOG].map(
      (path) => (
        <Route path={path} key={path} element={<AdminPage />} />
      )
    )}
    <Route path={ROUTES.PARTICIPANTS + '/create'} element={<CreateCompanyParticipant />} />
    <Route path={ROUTES.CUSTOMERS + '/create'} element={<CreateCustomerPage />} />
    <Route
      path={ROUTES.PARTICIPANTS + '/:participantId'}
      element={<CompanyParticipantDetailPage />}
    />
    <Route
      path={ROUTES.CUSTOMERS + '/:customerId'}
      element={
        <AuthRoute roles={[ROLES.OPERATOR]}>
          <CustomerDetailPage />
        </AuthRoute>
      }
    />
    {[ROUTES.USERS + '/:userId/details', ROUTES.USERS + '/:userId/edit'].map((path) => (
      <Route path={path} key={path} element={<UserDetailPage />} />
    ))}
    <Route
      path={ROUTES.USERS + '/create'}
      element={
        <AuthRoute roles={[ROLES.OPERATOR]}>
          <CreateUserPage />
        </AuthRoute>
      }
    />
  </Routes>
);

const RequestsRoutes = (): React.ReactElement | null => (
  <Routes>
    <Route path={ROUTES.DEFAULT} element={<EntryPage />} />

    {[ROUTES.PARTICIPANTS, ROUTES.CUSTOMERS].map((path) => (
      <Route path={path} key={path} element={<EntryPage key={path} />} />
    ))}

    <Route
      path={ROUTES.REQUESTS + '/*'}
      element={
        <AuthRoute roles={[ROLES.CUSTOMER]}>
          <CustomerRequestsRoutes />
        </AuthRoute>
      }
    />
    <Route path="/:customerId" element={<Navigate to=".." relative="path" />} />
    <Route
      path={ROUTES.POSITIONS + '/*'}
      element={
        <AuthRoute roles={[ROLES.PARTICIPANT]}>
          <ParticipantPositionsRoutes />
        </AuthRoute>
      }
    />

    {[
      '/:customerId' + ROUTES.REQUESTS + '/:requestId',
      '/:customerId' + ROUTES.REQUESTS + '/:requestId/details',
      '/:customerId' + ROUTES.REQUESTS + '/:requestId/participants',
    ].map((path) => (
      <Route
        path={path}
        key={path}
        element={
          <AuthRoute roles={[ROLES.OPERATOR]}>
            <OperatorRequestDetailPage />
          </AuthRoute>
        }
      />
    ))}
    {[
      '/:customerId' + ROUTES.REQUESTS + '/:requestId' + ROUTES.POSITIONS + '/:positionId',
      '/:customerId' +
        ROUTES.REQUESTS +
        '/:requestId' +
        ROUTES.POSITIONS +
        '/:positionId/participants',
    ].map((path) => (
      <Route
        path={path}
        key={path}
        element={
          <AuthRoute roles={[ROLES.OPERATOR]}>
            <OperatorRequestAtomicPage />
          </AuthRoute>
        }
      />
    ))}
  </Routes>
);

const CustomerRequestsRoutes = (): React.ReactElement | null => (
  <Routes>
    <Route path={ROUTES.DEFAULT} element={<EntryPage />} />
    {['/:requestId', '/:requestId/details', '/:requestId', '/:requestId/participants'].map(
      (path) => (
        <Route path={path} key={path} element={<RequestDetailGroupPage />} />
      )
    )}

    <Route
      path={'/:requestId' + ROUTES.POSITIONS + '/:positionId'}
      element={<RequestAtomicPage />}
    />

    <Route path={'/create'} element={<CreateRequestPage />} />
  </Routes>
);

const ParticipantPositionsRoutes = (): React.ReactElement | null => (
  <Routes>
    <Route path={ROUTES.DEFAULT} element={<EntryPage />} />
    {[
      '/:requestPositionId',
      '/:requestPositionId/proposal/:proposalId',

      '/:requestPositionId/proposal/:proposalId/edit',
      '/:requestPositionId/create',
    ].map((path) => (
      <Route
        path={path}
        key={path}
        element={
          <AuthRoute roles={[ROLES.PARTICIPANT]}>
            <PositionDetailPage />
          </AuthRoute>
        }
      />
    ))}
  </Routes>
);

const BondsRoutes = (): React.ReactElement | null => (
  <Routes>
    <Route path={ROUTES.DEFAULT} element={<EntryPage />} />
    <Route path="/:customerId" element={<Navigate to={ROUTES.BONDS} />} />
    {[ROUTES.REQUESTS, ROUTES.PARTICIPANTS, ROUTES.CUSTOMERS].map((path) => (
      <Route path={path} key={path} element={<EntryPage key={path} />} />
    ))}
    <Route
      path={ROUTES.REQUESTS + '/create'}
      element={
        <AuthRoute roles={[ROLES.CUSTOMER]}>
          <CreateBondRequestPage />
        </AuthRoute>
      }
    />
    {[
      ROUTES.REQUESTS + '/:requestId',
      ROUTES.REQUESTS + '/:requestId/proposals',
      ROUTES.REQUESTS + '/:requestId/participants',
      ROUTES.REQUESTS + '/:requestId/proposals/:proposalId/edit',
      ROUTES.REQUESTS + '/:requestId/proposals/:proposalId',
      ROUTES.REQUESTS + '/:requestId/proposals/create',
      '/:customerId' + ROUTES.REQUESTS + '/:requestId',
      '/:customerId' + ROUTES.REQUESTS + '/:requestId/details',
      '/:customerId' + ROUTES.REQUESTS + '/:requestId/participants',
    ].map((path) => (
      <Route path={path} key={path} element={<BondDetailPage />} />
    ))}
  </Routes>
);

const OperatorSupportRoutes = (): React.ReactElement | null => {
  return (
    <Routes>
      <Route path={ROUTES.DEFAULT} element={<Navigate to={ROUTES.INBOX + ROUTES.TICKETS} />} />
      <Route path={ROUTES.TICKETS} element={<TicketsPage />} />
      <Route path={ROUTES.TICKETS + '/create'} element={<CreateOperatorTicketPage />} />
      <Route path={ROUTES.TICKETS + '/:ticketId'} element={<TicketDetailPage />} />
    </Routes>
  );
};

const UserSupportRoutes = (): React.ReactElement | null => {
  return (
    <Routes>
      <Route path={ROUTES.DEFAULT} element={<Navigate to={ROUTES.SUPPORT + ROUTES.TICKETS} />} />
      <Route path={ROUTES.TICKETS} element={<SupportPage />} />
      <Route path={ROUTES.TICKETS + '/:ticketId'} element={<UserTicketDetailPage />} />
    </Routes>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={ROUTES.DEFAULT}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.OPERATOR, ROLES.PARTICIPANT]}>
              <WelcomePage />
            </AuthRoute>
          </Boundaried>
        }
      />
      {[ROUTES.CREDITS + '/*', ROUTES.DEPOSITS + '/*'].map((path) => (
        <Route
          path={path}
          key={path}
          element={
            <Boundaried>
              <AuthRoute roles={[ROLES.CUSTOMER, ROLES.OPERATOR, ROLES.PARTICIPANT]}>
                <RequestsRoutes />
              </AuthRoute>
            </Boundaried>
          }
        />
      ))}
      <Route
        path={ROUTES.ADMINISTRATION + '/*'}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.OPERATOR]}>
              <AdminRoutes />
            </AuthRoute>
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.BONDS + '/*'}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.OPERATOR, ROLES.PARTICIPANT]}>
              <BondsRoutes />
            </AuthRoute>
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.INBOX + '/*'}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.OPERATOR]}>
              <OperatorSupportRoutes />
            </AuthRoute>
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.SUPPORT + '/*'}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT]}>
              <UserSupportRoutes />
            </AuthRoute>
          </Boundaried>
        }
      />

      <Route
        path={ROUTES.INFO}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT, ROLES.OPERATOR]}>
              <InfoPage />
            </AuthRoute>
          </Boundaried>
        }
      />

      <Route
        path={ROUTES.SUPPORT + ROUTES.TICKETS + '/create'}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT]}>
              <CreateTicketPage />
            </AuthRoute>
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.TERMS_OF_USE}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT, ROLES.OPERATOR]}>
              <TermsPage />
            </AuthRoute>
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.PRIVACY_POLICY}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT, ROLES.OPERATOR]}>
              <PrivacyPolicyPage />
            </AuthRoute>
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.PERSONAL_DATA_PROCESSING_POLICY}
        element={
          <Boundaried>
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT, ROLES.OPERATOR]}>
              <PersonalPolicyPage />
            </AuthRoute>
          </Boundaried>
        }
      />

      <Route
        path={ROUTES.RESET_PASSWORD}
        element={
          <Boundaried>
            <SetPassword initialState={EState.ForgotPassword} />
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.SET_PASSWORD + '/:uuid'}
        element={
          <Boundaried>
            <SetPassword />
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.RESET_PASSWORD + '/:uuid'}
        element={
          <Boundaried>
            <SetPassword initialState={EState.Agreement} />
          </Boundaried>
        }
      />
      <Route
        path={ROUTES.PROFILE}
        element={
          <AuthRoute roles={[ROLES.OPERATOR, ROLES.CUSTOMER, ROLES.PARTICIPANT]}>
            <Boundaried>
              <ProfilePage />
            </Boundaried>
          </AuthRoute>
        }
      />
      {[
        ROUTES.COMMUNICATIONS,
        ROUTES.COMMUNICATIONS + ROUTES.DIALOGS,
        ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + '/:id',
        ROUTES.COMMUNICATIONS + ROUTES.ARCHIVE,
        ROUTES.COMMUNICATIONS + ROUTES.ARCHIVE + '/:id',
        ROUTES.COMMUNICATIONS + ROUTES.REPORT,
        ROUTES.COMMUNICATIONS + ROUTES.INFO_FIELD,
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <AuthRoute roles={[ROLES.CUSTOMER, ROLES.PARTICIPANT]}>
              <Boundaried>
                <CommunicationsPage />
              </Boundaried>
            </AuthRoute>
          }
        />
      ))}
      {[ROUTES.REPORTS, ROUTES.REPORTS + ROUTES.PROPOSALS_LIST_REPORT].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <AuthRoute roles={[ROLES.CUSTOMER]}>
              <Boundaried>
                <ReportsPage />
              </Boundaried>
            </AuthRoute>
          }
        />
      ))}
      <Route path="*" element={<Navigate to={ROUTES.DEFAULT} />} />
    </>
  )
);

function Router(): JSX.Element {
  return <RouterProvider router={router} />;
}

export default Router;
