import React from 'react';
import { HttpClient } from '@api/HttpClient';
import { CONFIG_NAMES } from '@constants';
import { hasOperatorRole, hasCustomerRole, hasParticipantRole } from '@roles';
import Container from '@components/Container/Container';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { useCurrentUserData } from '@services/user/userDataContext';
import ScrollArea from './ScrollArea';

import './InfoPage.scss';
import LogsService from '@services/logs/logs.service';
import { ROUTE_LOGS } from '@services/logs/logs.const';

export const InfoPage: React.FC = () => {
  const [htmlContent, setHtmlContent] = React.useState('');

  const userData = useCurrentUserData();

  let configName = '';
  if (hasOperatorRole(userData?.roles || [])) {
    configName = CONFIG_NAMES.OPERATOR_INFO;
  } else if (hasCustomerRole(userData?.roles || [])) {
    configName = CONFIG_NAMES.CUSTOMER_INFO;
  } else if (hasParticipantRole(userData?.roles || [])) {
    configName = CONFIG_NAMES.PARTICIPANT_INFO;
  }

  React.useEffect(() => {
    if (!hasOperatorRole(userData?.roles || [])) {
      LogsService.saveLogs(ROUTE_LOGS.INFO);
    }
  }, []);

  React.useEffect(() => {
    HttpClient.get<{ value: string }>(`common/configs/${configName}/`).then((data) => {
      setHtmlContent(data.value);
    });
  }, [configName]);

  return (
    <PageLayout>
      <Container className="info-page">
        <ScrollArea html={htmlContent} />
      </Container>
    </PageLayout>
  );
};
