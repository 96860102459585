import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@components/Button/Button';
import Tabs from '@components/Tabs/Tabs';
import NotFound from '@components/NotFound/NotFound';
import { HttpClient } from '@api/HttpClient';
import { useCurrentUserData } from '@services/user/userDataContext';
import { ReactComponent as Bubble } from '@shared/icons/communicationsPage/empty-chat.svg';
import { ReactComponent as Decor } from '@shared/icons/communicationsPage/decor.svg';
import { ReactComponent as Bubbles } from '@shared/icons/communicationsPage/bubbles.svg';
import { ReactComponent as EmptyArchive } from '@shared/icons/communicationsPage/empty-archive.svg';
import Container from '@components/Container/Container';
import type { ITab } from '../../../../types';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { ROUTES } from '@routes';
import { CONFIG_NAMES, DIALOG_STATUSES } from '@constants';
import { Dialogs } from './Dialogs/Dialogs';
import { DialogsReport } from './DialogsReport/DialogsReport';

import './CommunicationsPage.scss';

export const CommunicationsPage: React.FC = () => {
  const user = useCurrentUserData();

  return (
    <PageLayout className="communications-page">
      {user?.canCommunicate ? <Tabs tabs={getTabs()} /> : <NoModule />}
    </PageLayout>
  );
};

const getTabs = (): ITab[] => {
  return [
    {
      title: 'Диалоги',
      component: (
        <Dialogs
          title="Активные диалоги"
          parameter={DIALOG_STATUSES.ACTIVE}
          path={ROUTES.DIALOGS}
          emptyDialogsList={
            <NotFound
              icon={<Bubble />}
              message="Список диалогов пуст"
              description="Нажмите +, чтобы начать коммуникацию"
            />
          }
          emptyDialog={
            <NotFound
              icon={<Bubble />}
              message="Диалог пуст"
              description="Вы пока не написали ни одного сообщения"
            />
          }
          notChosenDialog={
            <NotFound
              icon={<Bubble />}
              message="Диалог не выбран"
              description="Выберите диалог или начните коммуникацию"
            />
          }
        />
      ),
      path: ROUTES.COMMUNICATIONS + ROUTES.DIALOGS,
    },
    {
      title: 'Архив',
      component: (
        <Dialogs
          title="Архив"
          readonly
          parameter={DIALOG_STATUSES.ARCHIVE}
          path={ROUTES.ARCHIVE}
          emptyDialogsList={
            <NotFound
              icon={<EmptyArchive />}
              message="Архив пуст"
              description="В вашем архиве пока нет диалогов"
            />
          }
          emptyDialog={
            <NotFound
              icon={<EmptyArchive />}
              message="Архив пуст"
              description="В вашем архиве пока нет диалогов"
            />
          }
          notChosenDialog={
            <NotFound
              icon={<EmptyArchive />}
              message="Диалог не выбран"
              description="Выберите диалог или начните коммуникацию"
            />
          }
        />
      ),
      path: ROUTES.COMMUNICATIONS + ROUTES.ARCHIVE,
    },
    {
      title: 'Отчет',
      component: <DialogsReport />,
      path: ROUTES.COMMUNICATIONS + ROUTES.REPORT,
    },
    {
      title: 'Инфополе',
      component: <>Инфополе</>,
      path: ROUTES.COMMUNICATIONS + ROUTES.INFO_FIELD,
      disabled: true,
    },
  ];
};

const NoModule: React.FC = () => {
  const [html, setHtml] = React.useState('<div></div>');
  const navigate = useNavigate();

  const loadWelcomeMessage = (): void => {
    HttpClient.get<{ value: string }>(`common/configs/${CONFIG_NAMES.COMMUNICATIONS}/`).then(
      (data) => {
        setHtml(data?.value);
      }
    );
  };

  React.useEffect(() => {
    loadWelcomeMessage();
  }, []);

  return (
    <Container direction="vertical" className="no-dialogs-module">
      <div className="communications-page__decor">
        <Bubbles className="communications-page__decor-mockup" />
        <Decor className="communications-page__decor-icon" />
      </div>
      <div className="communications-page__content">
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
        <Button
          className="communications-page__content-action"
          size="large"
          onClick={() => {
            navigate(ROUTES.SUPPORT + ROUTES.TICKETS + '/create');
          }}
          type="primary"
        >
          Обратиться
        </Button>
      </div>
    </Container>
  );
};
