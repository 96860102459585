import AuthService from '@services/auth/auth.service';
import { useCurrentUserData } from '@services/user/userDataContext';
import { JSX } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { NoRights } from './NoRights';
import { useLocation } from 'react-router-dom';
import { ROUTES, useRequestType } from '@routes';
import { hasCustomerRole } from '@roles';
interface Props {
  children: JSX.Element;
  roles: string[];
}

function AuthRoute({ children, roles }: Props): JSX.Element | null {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();
  const userInfo = useCurrentUserData();

  const hasTrack = [ROUTES.DEPOSITS, ROUTES.CREDITS, ROUTES.BONDS].some((route) =>
    pathname.includes(route)
  );

  if (AuthService.isAuthenticated(keycloak)) {
    if (userInfo?.roles?.some((role) => roles.includes(role))) {
      if (hasCustomerRole(userInfo?.roles) && hasTrack) {
        const requestType = useRequestType(pathname);
        if (userInfo.requestTypes.includes(requestType)) return children;
        else return <NoRights />;
      } else return children;
    } else return <NoRights />;
  }
  initialized && AuthService.login(keycloak);
  return null;
}

export default AuthRoute;
