import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

const PartOfNameSchema = Yup.string()
  .test(
    '1-50 letters',
    'От 1 до 50 символов',
    // @ts-expect-error check types
    (item) => {
      return item && item?.toString().length <= 50 && item?.toString().length >= 1;
    }
  )
  .test(
    'only letters',
    'Допустим ввод букв кириллицы и тире в середине',
    // @ts-expect-error check types
    (item) => {
      return item && /^[а-яА-ЯЁё]+(-[а-яА-ЯЁё-]+)?$/.test(item);
    }
  )
  .required(REQUIRED_MESSAGE);

export const UserSchema = Yup.object().shape({
  last_name: PartOfNameSchema,
  first_name: PartOfNameSchema,
  patronymic: Yup.string()
    .test('1-50 letters', 'От 1 до 50 символов', (item) => {
      return item ? item.toString().length <= 50 && item.toString().length >= 0 : true;
    })
    .test('only letters and dash', 'Допустим ввод букв кириллицы и тире в середине', (item) => {
      return item ? /^[а-яА-ЯЁё]+(-[а-яА-ЯЁё-]+)?$/.test(item) : true;
    }).nullable(),
  company: Yup.object().required(REQUIRED_MESSAGE),
  favorite_request_type: Yup.object().nullable(),
  position: Yup.string()
    .test('1-100 letters', 'От 0 до 100 символов', (item) => {
      return item ? item.toString().length <= 100 && item.toString().length >= 0 : true;
    })
    .test(
      'letters, numbers, brackets, dash',
      'Допустим ввод букв, цифр, скобок и дефисов',
      (item) => {
        return item ? /^[a-zA-Zа-яА-ЯЁё0-9()\-\s]+$/.test(item) : true;
      }
    ).nullable(),
  email: Yup.string()
    .test('3-100 letters', 'От 3 до 100 символов', (item) => {
      // @ts-expect-error check types
      return item?.toString().length <= 100 && item?.toString().length >= 3;
    })
    .test(
      'correct email',
      'Неверный формат',
      // @ts-expect-error check types
      (item) => {
        return item && /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/.test(item);
      }
    )
    .required(REQUIRED_MESSAGE),
  phone: Yup.string()
    .test('11 letters', 'Неверный формат', (item) => {
      return item?.toString().length === 11;
    })
    .required(REQUIRED_MESSAGE),
});
