import { useKeycloak } from '@react-keycloak/web';
import cn from 'classnames';
import AuthService from '@services/auth/auth.service';
import Button from '@components/Button/Button';
import Container from '@components/Container/Container';
import { REQUEST_TYPES_OPTION, TITLES } from '@constants';
import { phoneMask } from '@masks';
import { formatPhone } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { IUserInfo } from '@models/user.model';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { HttpClient } from '@api/HttpClient';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { IUserContext } from '@services/user/userDataContext';
import { useMemo, useState } from 'react';
import { IDictionary } from '@models/base.model';
import { Input } from '@components/Input/Input';
import Select from '@components/Select/Select';
import { Formik, Form } from 'formik';
import { UserSchema } from './validation';
import { removeEmptyProps } from '@utils';
import { ROLES } from '@roles';

import './PersonalBlock.scss';

interface IProps {
  userInfo: IUserContext | null;
}

interface IUserInfoForm extends Omit<IUserInfo, 'favoriteRequestType'> {
  favoriteRequestType: IDictionary;
}

export const PersonalBlock: React.FC<IProps> = ({ userInfo }) => {
  const { keycloak } = useKeycloak();
  const [isEditing, setEdit] = useState(false);
  const isCustomer = userInfo?.roles?.includes(ROLES.CUSTOMER);

  const noAvailableTypes = !userInfo?.requestTypes.length && isCustomer;

  const requestTypeOptions = useMemo(() => {
    if (noAvailableTypes)
      return [
        {
          label: 'Нет доступных треков для выбранной компании',
          value: 0,
        },
      ];
    if (isCustomer)
      return REQUEST_TYPES_OPTION.map((item) => {
        return {
          ...item,
          disabled: !userInfo?.requestTypes.includes(item.value),
        };
      });
    return REQUEST_TYPES_OPTION;
  }, [userInfo?.requestTypes, noAvailableTypes]);

  const getFavoriteRequestTypeTitle = (): string => {
    if (!userInfo?.favoriteRequestType || noAvailableTypes) return '-';
    return TITLES[userInfo.favoriteRequestType.toLowerCase()];
  };

  const dataForBox = userInfo
    ? [
        [
          {
            label: 'Дата регистрации',
            value: formatDate(userInfo.rulesConfirmTs),
          },
          {
            label: 'Номер телефона',
            value: formatPhone(userInfo.phone ?? ''),
          },
          {
            label: 'Электронная почта',
            value: userInfo.email,
          },
          {
            label: 'Должность',
            value: userInfo.position,
          },
          {
            label: 'Предпочтительный трек',
            value: getFavoriteRequestTypeTitle(),
          },
        ],
      ]
    : [[]];

  const onExit = async (): Promise<void> => {
    await AuthService.logout(keycloak);
  };

  const handleSubmit = (values: IUserInfoForm): void => {
    HttpClient.patch(
      '/common/users/',
      removeEmptyProps({
        last_name: values.lastName,
        first_name: values.firstName,
        patronymic: values.patronymic,
        position: values.position,
        favorite_request_type: values.favoriteRequestType?.value,
      })
    ).then((resp) => {
      userInfo?.updateUser();
      setEdit(false);
    });
  };

  const formData = useMemo(
    () =>
      ({
        ...userInfo,
        favoriteRequestType: REQUEST_TYPES_OPTION.find(
          (item) => item.value === userInfo?.favoriteRequestType
        ),
      }) as IUserInfoForm,
    [userInfo]
  );

  return (
    <Container direction="vertical" className="profile-page__box personal-info">
      {!isEditing ? (
        <InfoBox title="Личные данные" data={dataForBox} />
      ) : (
        <Formik<IUserInfoForm>
          enableReinitialize={false}
          validateOnBlur
          validateOnChange
          initialValues={formData}
          validationSchema={UserSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors, touched, dirty }) => {
            return (
              <>
                <Form className="user-form">
                  <Container.Header className="user-form__title">Личные данные</Container.Header>
                  <div className="user-form__row">
                    <Input
                      name="lastName"
                      size="large"
                      label="Фамилия*"
                      placeholder="Укажите фамилию"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      error={touched.lastName ? (errors.lastName as string) : undefined}
                    />
                    <Input
                      name="firstName"
                      size="large"
                      label="Имя*"
                      placeholder="Укажите имя"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      error={touched.firstName ? (errors.firstName as string) : undefined}
                    />
                  </div>
                  <Input
                    name="patronymic"
                    size="large"
                    label="Отчество"
                    placeholder="Укажите отчество, если есть"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.patronymic}
                    error={touched.patronymic ? (errors.patronymic as string) : undefined}
                  />
                  <Input
                    name="position"
                    size="large"
                    label="Должность"
                    placeholder="Укажите должность сотрудника"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.position as string}
                    error={touched.position ? (errors.position as string) : undefined}
                  />
                  <Select
                    name="favoriteRequestType"
                    className={cn('user-form__request-type-select', {
                      'user-form__request-type-select--no-option': noAvailableTypes,
                    })}
                    options={requestTypeOptions}
                    size="large"
                    onChange={(e) => {
                      if (noAvailableTypes) return;
                      setFieldValue('favoriteRequestType', e);
                    }}
                    isOptionDisabled={(option) => !!(option as { disabled?: boolean }).disabled}
                    label="Предпочтительный трек"
                    placeholder="Выберите трек из списка"
                    onBlur={handleBlur}
                    isClearable
                    value={noAvailableTypes ? null : values.favoriteRequestType}
                    error={
                      touched.favoriteRequestType
                        ? (errors.favoriteRequestType as string)
                        : undefined
                    }
                  />
                  <div className="user-form__disabled-fields">
                    <div className="user-form__row">
                      <Input
                        name="email"
                        size="large"
                        disabled
                        label="Электронная почта*"
                        placeholder="Укажите адрес электронной почты"
                        value={values.email}
                      />
                      <Input
                        name="phone"
                        size="large"
                        label="Номер телефона*"
                        mask={phoneMask}
                        value={values.phone}
                        disabled
                      />
                    </div>
                    <span className="user-form__warning">Требуется обращение в поддержку</span>
                  </div>

                  <div className="user-form__actions">
                    <Button
                      className="user-form__action-button"
                      type={dirty ? 'primary' : 'without-border'}
                      htmlType="submit"
                      disabled={!dirty}
                    >
                      Сохранить
                    </Button>
                    <Button
                      className="user-form__action-button"
                      size="medium"
                      type={undefined}
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      Отменить
                    </Button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}

      {!isEditing && (
        <>
          <Button className="personal-info__exit" onClick={onExit}>
            Выйти из профиля
          </Button>
          <div className="personal-info__edit">
            <Button
              type="without-border"
              icon={<Edit />}
              onClick={() => {
                setEdit((prev) => !prev);
              }}
            />
          </div>
        </>
      )}
    </Container>
  );
};
