import React from 'react';
import cn from 'classnames';

import Container from '@components/Container/Container';
import type { TableCell } from '../../../types';

import './InfoBox.scss';

interface IProps {
  title?: string | JSX.Element;
  data?: Array<Array<{ label: string; value: TableCell; hidden?: boolean }>>;
  className?: string;
}

export const InfoBox: React.FC<IProps> = ({ data, title, className }: IProps) => {
  if (!data) return null;

  return (
    <div className={cn('custom-info-box', className)}>
      {title && <Container.Header className="custom-info-box__title">{title}</Container.Header>}
      <div className="custom-info-box__row">
        {data.map((columnData, index) => (
          <div className="custom-info-box__col" key={index}>
            {columnData.map(
              (field) =>
                !field.hidden && (
                  <Container.Field
                    key={field.label}
                    label={field.label}
                    value={field.value || '-'}
                  />
                )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
