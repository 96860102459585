import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { GetTablePagesResponse } from '@components/Table/types';
import { ROUTES } from '@routes';
import { formatDateFromMSCtoUTC } from '@date-time';
import useDebounce, { useMounted } from '@hooks';
import { IDictionary } from '@models/base.model';
import { useCurrentUserData } from '@services/user/userDataContext';
import { ActiveDialogsList } from './ActiveDialogsList/ActiveDialogsList';
import { Chat } from './Chat/Chat';
import { CreateDialogModal } from './CreateDialogModal/CreateDialogModal';
import Filters from './Filters/Filters';
import { IMessage, IDialog, IDialogDetails } from '@models/communication.model';
import { CommunicationService } from '@services/communication/communication.service';
import { useLazyList, useReversedLazyList } from './ActiveDialogsList/useLazyList';

import './Dialogs.scss';

export const Dialogs: React.FC<{
  title: string;
  parameter: string;
  path: string;
  readonly?: boolean;
  emptyDialogsList: JSX.Element;
  emptyDialog: JSX.Element;
  notChosenDialog: JSX.Element;
}> = ({ title, parameter, path, readonly, emptyDialogsList, emptyDialog, notChosenDialog }) => {
  const [dialogDetails, setDialogDetails] = useState<IDialogDetails>({} as IDialogDetails);

  // TODO:  вынести фильтры?
  const [companions, setCompanions] = useState<IDictionary[]>([]);
  const [filterCompanions, setFilterCompanions] = useState<IDictionary[]>([]);
  const [requestTypesForFilters, setRequestTypesForFilters] = useState<IDictionary[]>([]);
  const [dateRange, setDateRange] = React.useState<Array<Date | undefined>>([undefined, undefined]);
  const [startDate, endDate] = dateRange;
  const [searchStr, setSearchStr] = useState('');

  const user = useCurrentUserData();
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const loadData = async ({
    pageNumber,
    maxRowCount,
  }: {
    pageNumber: number;
    maxRowCount: number;
  }): Promise<GetTablePagesResponse<IDialog>> => {
    const data = await CommunicationService.getDialogs(
      { pageNumber, maxRowCount },
      {
        status: parameter,
        company_ids: filterCompanions.map((el) => +el.value) || undefined,
        create_ts: {
          start: formatDateFromMSCtoUTC(startDate),
          end: formatDateFromMSCtoUTC(endDate),
        },
        request_types:
          (!!requestTypesForFilters.length &&
            requestTypesForFilters.map((item) => item.value as string)) ||
          undefined,
        search: searchStr,
      }
    );
    return data;
  };

  const loadMessages = useCallback(
    async ({
      pageNumber,
      maxRowCount,
    }: {
      pageNumber: number;
      maxRowCount: number;
    }): Promise<GetTablePagesResponse<IMessage>> => {
      if (!id) {
        return { items: [], page: 0, size: 0, pages: 0, total: 0 };
      }
      const data = await CommunicationService.getDialogMessages(id, { maxRowCount, pageNumber });

      if (!dialogDetails.is_read) {
        await CommunicationService.setDialogRead(id);
        const markedDialog = list.items?.find((el) => el.id.toString() === id);
        if (markedDialog) {
          markedDialog.is_read = true;
          list.updateItems();
          user?.updateUserMessagesCount();
        }
      }
      return data;
    },
    [id]
  );

  const list = useLazyList<IDialog>(loadData);
  const messagesList = useReversedLazyList<IMessage>(loadMessages);

  const loadDialogDetails = async (): Promise<void> => {
    const data = await CommunicationService.getDialogDetails(id || '');
    setDialogDetails(data);
  };

  const mounted = useMounted();

  useDebounce(
    () => {
      if (mounted) {
        list.loadMoreRows();
      }
    },
    [searchStr],
    1000 * 2
  );

  useEffect(() => {
    getCompanions();
  }, []);

  useEffect(() => {
    if (mounted) list.loadMoreRows();
    // TODO: убрать из роута id
  }, [parameter, startDate, endDate, filterCompanions, requestTypesForFilters]);

  useEffect(() => {
    if (id && mounted) {
      messagesList.loadMoreRows();
    }
  }, [id]);

  useEffect(() => {
    if (id) loadDialogDetails();
  }, [id]);

  const onSaveDialog = async (id: number): Promise<void> => {
    list.loadMoreRows();
    navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${id}`);
    setIsOpen(false);
  };

  const getCompanions = async (): Promise<void> => {
    const companies = await CommunicationService.getCompaniesForDialog();
    setCompanions(companies.map((el) => ({ label: el.name, value: el.id })));
  };

  const onArchiveDialog = async (id?: number): Promise<void> => {
    if (!id) return;

    const dialog = await CommunicationService.setDialogArchive(id);
    // идет автоматический переход в архив, поэтому массив активных диалогов актуализировать не нужно
    navigate(ROUTES.COMMUNICATIONS + ROUTES.ARCHIVE + `/${dialog.id}`);
  };

  const onSendMessage = (newMessage: IMessage): void => {
    messagesList.loadMoreRows();
  };

  return (
    <div className="dialogs">
      <Filters
        companions={companions}
        filterCompanions={filterCompanions}
        setCompanions={setFilterCompanions}
        requestTypes={requestTypesForFilters}
        setRequestTypes={setRequestTypesForFilters}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <div className="dialogs__content">
        <ActiveDialogsList
          emptyDialogsList={emptyDialogsList}
          readonly={readonly}
          title={title}
          path={path}
          list={list}
          onStartCreate={() => {
            setIsOpen(true);
          }}
        />
        <Chat
          readonly={readonly}
          dialogDetails={dialogDetails}
          list={messagesList}
          onSend={onSendMessage}
          onArchive={onArchiveDialog}
          emptyDialog={emptyDialog}
          notChosenDialog={notChosenDialog}
        />
      </div>
      {isOpen && (
        <CreateDialogModal
          onSave={onSaveDialog}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};
