import React from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Incoming } from '@shared/icons/communicationsPage/incoming.svg';
import { ReactComponent as Outcoming } from '@shared/icons/communicationsPage/outcoming.svg';
import { IDialog } from '@models/communication.model';

import './ListItem.scss';

interface IListItemProps {
  data: IDialog;
  style?: React.CSSProperties;
  path: string;
}

export const ListItem: React.FC<IListItemProps> = (props) => {
  const { pathname } = useLocation();
  const { data, style, path } = props;
  if (!data) return <div style={style}>Нет данных</div>;
  const itemPath = path + `/${data.id}`;

  return (
    <div className="active-dialogs__list-item-wrapper" key={path} style={style}>
      <Link
        to={itemPath !== pathname ? itemPath : ''}
        className={cn('active-dialogs__list-item-link', itemPath === pathname && 'active')}
      >
        <div className="active-dialogs__list-item">
          {data.is_outgoing ? <Outcoming /> : <Incoming />}
          <div className="active-dialogs__list-item-content">
            <div
              className={cn(
                'active-dialogs__list-item-name',
                !data.is_read && 'active-dialogs__list-item-name--marked'
              )}
            >
              {data.conversation_partner?.name}
            </div>
            <div className="active-dialogs__list-item-topic">{data?.title || 'Без темы'}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
