import React from 'react';
import cn from 'classnames';
import { HttpClient } from '@api/HttpClient';
import { Formik, Form } from 'formik';
import Container from '@components/Container/Container';
import { Input } from '@components/Input/Input';
import Button from '@components/Button/Button';
import SimilarCompaniesChecker from '../components/SimilarCompaniesChecker/SimilarCompaniesChecker';
import { CompanyParticipantSchema } from './CreateCompanyParticipantForm.validation';
import type { ICreateCompanyParticipantForAdmin } from '../operator.model';
import AutoCheckForm from '@shared/components/AutoCheckForm';

import './CreateCompanyParticipantForm.scss';

interface IProps {
  data?: ICreateCompanyParticipantForAdmin & { id?: number };
  onClose: () => void;
}
export const CreateCompanyParticipantForm: React.FC<IProps> = ({ data, onClose }) => {
  const isNew = !data;

  const [selectedInn, setSelectedInn] = React.useState<string>();

  const initialData: ICreateCompanyParticipantForAdmin = {
    name: '',
    inn: '',
    comment: '',
    ...data,
  };

  const saveCustomerCompany = (values: ICreateCompanyParticipantForAdmin): void => {
    const body: ICreateCompanyParticipantForAdmin = {
      ...values,
    };

    (isNew
      ? HttpClient.post('operator/companies/participant/', body)
      : HttpClient.patch(`operator/companies/participant/${data.id}/`, body)
    ).then(() => {
      onClose();
    });
  };

  const handleSubmit = (values: ICreateCompanyParticipantForAdmin): void => {
    if (initialData.inn !== values.inn) {
      setSelectedInn(values.inn);
    } else {
      saveCustomerCompany(values);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        validationSchema={CompanyParticipantSchema}
        initialValues={initialData}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, dirty, touched }) => (
          <>
            <Form>
              <Container
                direction="vertical"
                className={cn(
                  'company-participant-form__box',
                  !isNew && 'company-participant-form__box--edited'
                )}
              >
                <div className="company-participant-form__fields-wrap">
                  <h4 className="company-participant-form__title">Основные сведения</h4>

                  <Input
                    name="name"
                    size="large"
                    label="Название*"
                    placeholder="Укажите краткое название компании"
                    onChange={handleChange}
                    value={values.name}
                    error={(touched.name && errors.name) as string}
                  />
                  <Input
                    name="inn"
                    size="large"
                    label="ИНН*"
                    placeholder="Укажите ИНН"
                    onChange={handleChange}
                    value={values.inn}
                    error={(touched.inn && errors.inn) as string}
                  />
                  <Input
                    name="comment"
                    size="large"
                    label="Комментарий"
                    placeholder="Напишите ваш комментарий"
                    onChange={handleChange}
                    value={values.comment}
                    error={(touched.comment && errors.comment) as string}
                  />

                  <div className="company-participant-form__hint">
                    * Обязательные для заполнения поля
                  </div>
                </div>
              </Container>

              <div className="company-participant-form__actions">
                <Button
                  className="company-participant-form__action-button"
                  size={isNew ? 'large' : 'medium'}
                  type={dirty ? 'primary' : 'without-border'}
                  htmlType="submit"
                  disabled={!dirty}
                >
                  {isNew ? 'Добавить компанию' : 'Сохранить'}
                </Button>
                <Button
                  className="company-participant-form__action-button"
                  size={isNew ? 'large' : 'medium'}
                  type={isNew ? 'warning' : undefined}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Отменить
                </Button>
              </div>
              <AutoCheckForm
                title={`Прервать ${isNew ? 'создание' : 'редактирование'} компании`}
                message={`Вы уверены, что хотите прервать ${
                  isNew ? 'создание' : 'редактирование'
                } компании? Все данные будут утеряны.`}
                onConfirm={() => {
                  onClose();
                }}
                confirmBtnText="Прервать"
                cancelBtnText="Отмена"
              />
            </Form>

            <SimilarCompaniesChecker
              inn={selectedInn}
              onSubmit={() => {
                saveCustomerCompany(values);
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
};
