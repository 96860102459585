import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { HttpClient } from '@api/HttpClient';
import Container from '@components/Container/Container';
import { Input } from '@components/Input/Input';
import Textarea from '@components/Textarea/Textarea';
import Button from '@components/Button/Button';
import { Hint } from '@components/Hint/Hint';
import { TicketSchema } from './CreateTicketPage.validation';
import { CONFIG_NAMES } from '@constants';
import AutoCheckForm from '@shared/components/AutoCheckForm';

import './CreateTicketPage.scss';

export const CreateTicketPage: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const prevLink = pathname.replace('/create', '');
  const goToTickets = (): void => {
    navigate(prevLink);
  };

  const initialData: ICreateTicketBody = {
    title: '',
    text: '',
  };

  const handleSubmit = (values: ICreateTicketBody): void => {
    HttpClient.post('common/tickets/', values).then(() => {
      goToTickets();
    });
  };

  return (
    <DetailLayout title="Новое обращение" className="new-ticket-page" prevLink={prevLink}>
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        validationSchema={TicketSchema}
        initialValues={initialData}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur, errors, dirty, touched }) => {
          return (
            <Form className="new-ticket-page__form">
              <Container className="new-ticket-page__details-box">
                <div className="new-ticket-page__fields-wrap">
                  <h4 className="new-ticket-page__block-title">Детали обращения</h4>
                  <div className="new-ticket-page__message">
                    Для того, чтобы мы могли быстрее помочь вам, пожалуйста, укажите детали вашего
                    обращения как можно более развернуто.
                  </div>
                  <Input
                    name="title"
                    size="large"
                    label="Тема обращения"
                    placeholder="Напишите тему обращения"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={(touched.title && errors.title) as string}
                  />
                  <Textarea
                    name="text"
                    size="large"
                    label="Полный текст обращения *"
                    placeholder="Напишите текст обращения"
                    className="new-ticket-page__textarea"
                    autoSize={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.text}
                    error={(touched.text && errors.text) as string}
                  />
                  <div>* Обязательные для заполнения поля</div>
                </div>
                <Hint config={CONFIG_NAMES.TICKET} className="new-ticket-page__hint" />
              </Container>
              <div className="new-ticket-page__actions">
                <Button type="primary" htmlType="submit" size="large">
                  Отправить обращение
                </Button>
                <Button
                  size="large"
                  type="warning"
                  onClick={() => {
                    goToTickets();
                  }}
                >
                  Отменить
                </Button>
              </div>
              <AutoCheckForm
                title="Прервать создание обращения"
                message="Вы уверены, что хотите прервать создание обращения? Все данные будут утеряны."
                onConfirm={() => {
                  goToTickets();
                }}
                confirmBtnText="Прервать"
                cancelBtnText="Отмена"
              />
            </Form>
          );
        }}
      </Formik>
    </DetailLayout>
  );
};

interface ICreateTicketBody {
  title: string;
  text: string;
}
