import React from 'react';
import cn from 'classnames';
import RangeDatepicker from '@components/RangeDatepicker/RangeDatepicker';
import { ReactComponent as Search } from '@shared/icons/search.svg';
import Select from '@components/Select/Select';
import { Input } from '@components/Input/Input';
import { STATUSES } from '@constants';
import { IDictionary } from '@models/base.model';

import './Filters.scss';

const options = Object.keys(STATUSES).map((key) => ({
  value: key,
  label: STATUSES[key as keyof typeof STATUSES],
}));

type CallbackFunctionVariadic = (...args: unknown[]) => void;

interface IProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  setDateRange: CallbackFunctionVariadic;
  statuses: IDictionary[];
  setStatuses: CallbackFunctionVariadic;
  searchStr: string;
  setSearchStr: CallbackFunctionVariadic;
}

const Filters: React.FC<IProps> = ({
  className,
  startDate,
  endDate,
  setDateRange,
  statuses,
  setStatuses,
  searchStr,
  setSearchStr,
}) => {
  const [_statuses, _setStatuses] = React.useState<IDictionary[]>(statuses);

  return (
    <div className={cn('custom-filters', className)}>
      <RangeDatepicker
        wrapperClassName="custom-filters__range"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
      />
      <Select
        options={options}
        isMulti
        placeholder="Выберите статусы"
        value={_statuses}
        className="custom-filters__statuses"
        onChange={(s: unknown) => {
          _setStatuses(s as IDictionary[]);
        }}
        onMenuClose={() => {
          setStatuses(_statuses);
        }}
        isClearable
        onClear={() => {
          setStatuses([]);
        }}
      />
      <Input
        icon={<Search />}
        type="text"
        className="custom-filters__search"
        value={searchStr}
        disabled
        placeholder="Поиск"
        onChange={(e: { target: { value: unknown } }) => {
          setSearchStr(e.target.value);
        }}
      />
    </div>
  );
};

export default Filters;
