import React from 'react';
import cn from 'classnames';
import { Modal } from '@components/Modal/Modal';
import Select from '@components/Select/Select';
import Button from '@components/Button/Button';
import Checkbox from '@components/Checkbox/Checkbox';
import type { IDictionary } from '@models/base.model';
import { cloneDeep } from '@utils';
import type { IParticipantOfCustomerForAdmin, IRequestType } from '@pages/operator/operator.model';
import { requestTypeIndexByCode, PERMISSION_LIST, PERMISSION_DEFAULT_DATA } from '../../utils';

import './AddCompanyModal.scss';

interface IProps {
  title: string;
  description: string;
  selectLabel: string;
  onSubmit: (company: IParticipantOfCustomerForAdmin) => void;
  onCancel: () => void;
  companies: IDictionary[];
}
const AddParticipantModal: React.FC<IProps> = ({
  onSubmit,
  onCancel,
  companies,
  title,
  description,
  selectLabel,
}) => {
  const [data, setData] = React.useState<IParticipantOfCustomerForAdmin>();

  const handleSelect = (value: IDictionary): void => {
    setData(
      value
        ? ({
            id: value.value as number,
            name: value.label,
            request_types: cloneDeep(PERMISSION_DEFAULT_DATA),
          } as IParticipantOfCustomerForAdmin)
        : undefined
    );
  };

  const handleChangePermission = (code: IRequestType['code']): void => {
    if (!data?.request_types) return;
    const columnIndex = requestTypeIndexByCode(data.request_types, code);
    const requestType = data.request_types[columnIndex];

    requestType.is_active = !requestType.is_active;

    setData(
      (prevData) =>
        ({
          ...prevData,
          request_types: data.request_types,
        }) as IParticipantOfCustomerForAdmin
    );
  };

  return (
    <Modal
      className="add-company-modal"
      isOpen
      title={title}
      onCancel={() => {
        onCancel();
      }}
    >
      <div>
        <div className="add-company-modal__content">
          {description}
          <Select
            size="large"
            label={selectLabel}
            options={companies}
            value={data ? ({ label: data.name, value: data.id } as IDictionary) : null}
            onChange={(value) => {
              handleSelect(value as IDictionary);
            }}
            placeholder="Выберите компанию"
            labelClassName="add-company-modal__select-label"
          />
          <div className="add-company-modal__label">Права доступа к трекам</div>
          <div
            className={cn(
              'add-participant-modal__list',
              !data && 'add-participant-modal__list--disabled'
            )}
          >
            {PERMISSION_LIST.map((el) => {
              const _code = el.value;

              const columnIndex = requestTypeIndexByCode(data?.request_types || [], _code);

              return (
                <Checkbox
                  onChange={() => {
                    handleChangePermission(_code);
                  }}
                  name={el.value}
                  checked={data?.request_types[columnIndex]?.is_active}
                  label={el.label}
                  key={el.value}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Modal.Actions className="add-company-modal__actions">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            data && onSubmit(data);
          }}
          disabled={!data || data?.request_types.every((el) => !el.is_active)}
          fullWidth
        >
          Добавить
        </Button>
        <Button size="large" onClick={onCancel} fullWidth>
          Отмена
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default AddParticipantModal;
