import type React from 'react';
import cn from 'classnames';
import { ReactComponent as FinsageLogo } from '../../logo.svg';

import './AuthLayout.scss';

interface IPageLayout {
  className?: string;
  children?: React.ReactNode;
}

const AuthLayout: React.FC<IPageLayout> = ({ children, className }) => {
  return (
    <div className={cn('auth-layout', className)}>
      <div className="auth-layout-header">
        <FinsageLogo />
      </div>
      <div className="auth-layout__content">
        <div className="auth-layout__container">{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
