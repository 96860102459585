import { useEffect, useState } from 'react';

import Button from '@components/Button/Button';
import { Modal } from '@components/Modal/Modal';
import Checkbox from '@components/Checkbox/Checkbox';
import { HttpClient } from '@api/HttpClient';
import { IParticipant } from '@pages/customer/MainPage/Participants/participants.model';

import './ModalCheckParticipants.scss';

export interface IProps {
  isOpen: boolean;
  type: string;
  onConfirm: (data: number[]) => void;
  onCancel: () => void;
}

export const ModalCheckParticipants: React.FC<IProps> = ({ isOpen, type, onCancel, onConfirm }) => {
  const [data, setData] = useState<IParticipant[] | null>(null);

  const loadCompanies = async (): Promise<void> => {
    const res = await HttpClient.get<IParticipant[]>(`customer/${type}/participants/`);
    setData(res);
  };

  const handleCheckboxChange = (index: number): void => {
    setData((prevState) => {
      if (!prevState) return null;
      const newData = [...prevState];
      newData[index] = {
        ...newData[index],
        is_blocked_by_customer: !newData[index].is_blocked_by_customer,
      };
      return newData;
    });
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  return (
    <Modal isOpen={isOpen} className={'check-participants'} title="Публикация запроса">
      <p className="check-participants__message">
        Проверьте корректность списка участников и при необходимости внесите изменения.
      </p>
      <div className="check-participants__list">
        {data?.map((item, index) => (
          <Checkbox
            key={index}
            checked={!item?.is_blocked_by_customer}
            name={item.company_name}
            label={item.company_name}
            onChange={() => {
              handleCheckboxChange(index);
            }}
          />
        ))}
      </div>
      <Modal.Actions className="check-participants__actions">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          onClick={() => {
            data &&
              onConfirm(
                data.filter((item) => !item.is_blocked_by_customer).map((item) => item.company_id)
              );
          }}
          fullWidth
          disabled={!data?.some((item) => !item.is_blocked_by_customer)}
        >
          Опубликовать
        </Button>
        <Button size="large" onClick={onCancel} fullWidth>
          Отмена
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalCheckParticipants;
