import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { HttpClient } from '@api/HttpClient';
import Container from '@components/Container/Container';
import { Input } from '@components/Input/Input';
import Textarea from '@components/Textarea/Textarea';
import Button from '@components/Button/Button';
import AsyncSelect from '@components/Select/AsyncSelect';
import { IDictionary } from '@models/base.model';
import { TicketSchema } from './CreateOperatorTicketPage.validation';
import AutoCheckForm from '@shared/components/AutoCheckForm';
import { ISimilarCompany } from '../components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';

import './CreateOperatorTicketPage.scss';

export const CreateOperatorTicketPage: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const prevLink = pathname.replace('/create', '');
  const goToTickets = (): void => {
    navigate(prevLink);
  };

  const initialData: ICreateTicketBody = {
    title: '',
    text: '',
    companies: [],
  };

  const handleSubmit = (values: ICreateTicketBody): void => {
    const ticketForSave = {
      title: values.title,
      text: values.text,
      company_ids: values.companies.map((item) => (item as { value: string; label: string }).value),
    };

    HttpClient.post('operator/tickets/', ticketForSave).then(() => {
      goToTickets();
    });
  };

  const promiseOptions = async (inputValue: string): Promise<IDictionary[]> => {
    const resp = await HttpClient.post<Record<string, unknown>, ISimilarCompany[]>(
      'operator/companies/ticket/list/',
      { search: inputValue }
    );
    return resp.map((el) => ({ label: el.name, value: el.id }));
  };

  return (
    <DetailLayout title="Новое обращение" className="new-ticket-page" prevLink={prevLink}>
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        validationSchema={TicketSchema}
        initialValues={initialData}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setValues,
          errors,
          dirty,
          touched,
        }) => {
          return (
            <div className="new-ticket-page">
              <Form className="new-ticket-page__form">
                <Container className="new-ticket-page__details-box">
                  <div className="new-ticket-page__fields-wrap">
                    <h4 className="new-ticket-page__block-title">Детали обращения</h4>
                    <Input
                      name="title"
                      size="large"
                      label="Тема обращения"
                      placeholder="Напишите тему обращения"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={(touched.title && errors.title) as string}
                    />
                    <Textarea
                      name="text"
                      size="large"
                      label="Полный текст обращения *"
                      placeholder="Напишите текст обращения"
                      className="new-ticket-page__textarea"
                      autoSize={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.text}
                      error={(touched.text && errors.text) as string}
                    />
                    <div>* Обязательные для заполнения поля</div>
                  </div>
                  <div className="new-ticket-page__companies">
                    <AsyncSelect
                      name="companies"
                      isClearable
                      cacheOptions
                      defaultOptions
                      loadOptions={promiseOptions}
                      value={values.companies}
                      label="Получатели обращения *"
                      placeholder="Выберите получателя"
                      onChange={(value) => {
                        setFieldValue('companies', value);
                      }}
                      size="large"
                      error={touched.companies ? (errors.companies as string) : undefined}
                      onBlur={handleBlur}
                      isMulti
                      isChips
                    />
                  </div>
                </Container>
                <div className="new-ticket-page__actions">
                  <Button type="primary" htmlType="submit" size="large" disabled={!values.text}>
                    Отправить обращение
                  </Button>
                  <Button
                    size="large"
                    type="warning"
                    onClick={() => {
                      goToTickets();
                    }}
                  >
                    Отменить
                  </Button>
                </div>
                <AutoCheckForm
                  title="Прервать создание обращения"
                  message="Вы уверены, что хотите прервать создание обращения? Все данные будут утеряны."
                  onConfirm={() => {
                    goToTickets();
                  }}
                  confirmBtnText="Прервать"
                  cancelBtnText="Отмена"
                />
              </Form>
            </div>
          );
        }}
      </Formik>
    </DetailLayout>
  );
};

interface ICreateTicketBody {
  title: string;
  text: string;
  companies: ISimilarCompany[] | IDictionary[];
}
