import React from 'react';
import type * as ReactTable from 'react-table';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { download } from '@utils';
import { HttpClient } from '@api/HttpClient';
import { AuthUtil } from '@services/auth/auth.util';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import Notification from '@components/Modal/Notification/Notification';
import Textarea from '@components/Textarea/Textarea';
import type * as TableTypes from '@components/Table/types';
import { ROUTES } from '@routes';
import {
  REQUEST_TYPE_CODES,
  REQUEST_STATUSES_PARAMS,
  REPORT_TYPES,
  MIME_TYPES,
  RATE_TYPES_TITLE,
} from '@constants';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { ReactComponent as Group } from '@shared/icons/communicationsPage/group.svg';
import { ReactComponent as IconChevronRight } from '@shared/icons/arrow-right-8-16.svg';
import { renderAmount, renderPeriodLong } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { mutateDataForGroups } from '@shared/helpers/requestDetail';
import { Status } from '@models/base.model';
import type {
  IRequestDetailParticipants,
  IPosition,
  IRequestDetail,
  IParticipantCustomColumn,
} from './detail.model';
import Pills from '@components/Pills/Pills';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { useCurrentUserData } from '@services/user/userDataContext';
import { CreateDialogModal } from '@shared/components/CreateDialogModal/CreateDialogModal';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import { ReactComponent as IconChat } from '@shared/icons/chat.svg';
import { NoCommunicationsModal } from '@pages/customer/shared/NoCommunicationsModal/NoCommunicationsModal';
import { CommunicationService } from '@services/communication/communication.service';

import './RequestDetailGroupPage.scss';

export const RequestDetailGroupPage: React.FC = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const user = useCurrentUserData();

  const isDetailsTab = location.pathname.includes('/details') ? 'details' : '';
  const isParticipantsTab = location.pathname.includes(ROUTES.PARTICIPANTS) ? 'participants' : '';
  const currentTabNumber = isDetailsTab ? 1 : isParticipantsTab ? 2 : 0;

  const currentTabName: 'details' | 'participants' | 'positions' = location.pathname.includes(
    '/details'
  )
    ? 'details'
    : location.pathname.includes(ROUTES.PARTICIPANTS)
    ? 'participants'
    : 'positions';
  const requestType = location.pathname.includes('/deposit') ? 'deposit' : 'credit';

  const [data, setData] = React.useState<IRequestDetail | IRequestDetailParticipants>();
  const [detailsColumns, setDetailsColumns] = React.useState<TableTypes.Column[]>([]);
  const [detailsData, setDetailsData] = React.useState<IParticipantCustomColumn[]>([]);
  const [participantsData, setParticipantsData] = React.useState<ISimilarCompany[]>([]);
  const [isReminderNotificationOpen, openReminderNotification] = React.useState(false);
  const [isConfirmationCancelOpen, setConfirmationCancelOpen] = React.useState<boolean>(false);
  const [isConfirmationCompleteOpen, setConfirmationCompleteOpen] = React.useState<boolean>(false);
  const [closingMessageError, setClosingMessageError] = React.useState<string>('');
  const [confirmationCompleteCommentText, setConfirmationCompleteCommentText] =
    React.useState<string>('');
  const [isOpenNoCommunications, setIsOpenNoCommunications] = React.useState(false);

  const [communicationProps, setIsOpenCreateCommunication] = React.useState<Record<
    string,
    unknown
  > | null>(null);

  React.useEffect(() => {
    HttpClient.get<IRequestDetail>(`customer/${requestType}/requests/${requestId}/`).then(
      (data) => {
        setData(data);
      }
    );
  }, [requestId, requestType]);

  React.useEffect(() => {
    if (isDetailsTab) {
      HttpClient.get<IRequestDetailParticipants>(
        `customer/${requestType}/requests/${requestId}/${isDetailsTab}/`
      ).then((data) => {
        const { detailsColumns, detailsData } = mutateDataForGroups(data);
        setDetailsColumns(detailsColumns);
        setDetailsData(detailsData);
      });
    }
  }, [requestId, requestType, isDetailsTab]);

  React.useEffect(() => {
    if (isParticipantsTab) {
      HttpClient.get<ISimilarCompany[]>(
        `customer/${requestType}/requests/${requestId}/${isParticipantsTab}/`
      ).then((data) => {
        setParticipantsData(data);
      });
    }
  }, [requestId, requestType, isParticipantsTab]);

  const repeatRequest = async (): Promise<void> => {
    if (!data) return;

    let _positions: IPosition[] = (data as IRequestDetail).positions;

    // нужно продублировать данные со страницы
    if (!(data as IRequestDetail).positions) {
      const dataWithPosition = await HttpClient.get<IRequestDetail>(
        `customer/${requestType}/requests/${requestId}/`
      );

      _positions = dataWithPosition.positions;
    }
    // @ts-expect-error eslint-disable-next-line
    let rateType = data.rate_type;
    // rate_type брать из position , притом если встречаются оба, то "BOTH"
    // если встречаются оба типа то значит записи дублируются и надо удалять дубли request_position
    if (!rateType) {
      for (const position of _positions) {
        if (!!rateType && position.rate_type !== rateType) {
          rateType = 'BOTH';
          break;
        }
        rateType = position.rate_type;
      }
    }

    _positions = _positions.filter(
      (pos, index, arr) =>
        arr.findIndex(
          (position) =>
            position.period_type === pos.period_type &&
            position.period === pos.period &&
            position.end_date_period === pos.end_date_period
        ) === index
    );

    const navigationState = {
      max_amount: data.max_amount,
      positions: _positions,
      rate_type: rateType,
      end_ts: '',
    };

    navigate(`/${requestType}s/requests/create`, { state: navigationState });
  };

  const sendRemindersToParticipants = (): void => {
    HttpClient.get(`customer/${requestType}/requests/${requestId}/remind/`);
  };

  const cancelRequest = (): void => {
    HttpClient.patch(`customer/${requestType}/requests/${requestId}/cancel/`).then(() => {
      setData((prevData) => ({
        ...(prevData as IRequestDetail),
        status: 'CANCELLED' as Status.Cancelled,
      }));
    });
  };

  const completeRequest = (): void => {
    const body = {
      comment_result: confirmationCompleteCommentText,
    };

    HttpClient.patch<
      {
        comment_result: string;
      },
      null
    >(`customer/${requestType}/requests/${requestId}/complete/`, body).then(() => {
      setData((prevData) => ({
        ...(prevData as IRequestDetail),
        comment_result: confirmationCompleteCommentText,
        status: 'COMPLETED' as Status.Completed,
      }));
    });
  };

  const downloadData = async (): Promise<void> => {
    const data = await HttpClient.get<string>(
      `customer/${requestType}/requests/${requestId}/reports/${
        isDetailsTab ? REPORT_TYPES.MATRIX : REPORT_TYPES.SUMMARY
      }/`,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );

    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });

    const fileName = `${isDetailsTab ? 'Матрица_предложений' : 'Сводный_отчет'}_${requestId}.xlsx`;

    download(blob, fileName);
  };

  const downloadReference = async (): Promise<void> => {
    const data = await HttpClient.get<string>(
      `customer/${requestType}/requests/${requestId}/reports/${REPORT_TYPES.COMPLETED}/`,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );

    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });

    download(blob, `Справка_${requestId}.xlsx`);
  };

  const navToRequestAtomicPage = (row: { id: string }): void => {
    const positionId = row.id;
    if (!positionId || isDetailsTab) return;

    const currentUrl = location.pathname.replace('/details', '');
    navigate(`${currentUrl}/positions/${positionId}/`);
  };

  const navButton = (row: unknown): JSX.Element => (
    <Button
      className="request-detail-group-table__button-next"
      type="text"
      icon={<IconChevronRight />}
      onClick={() => {
        navToRequestAtomicPage(row as { id: string });
      }}
    />
  );
  const positionsColumns = React.useMemo(() => COLUMNS(navButton), []);

  const getDialogId = async (row: ISimilarCompany): Promise<void> => {
    const result = await CommunicationService.searchActiveDialogByParams({
      recipient_company_id: row.id,
      request_type: requestType.toUpperCase(),
      request_id: requestId,
    });
    if (result.dialog_id) navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${result.dialog_id}`);
    else setIsOpenCreateCommunication({ recipientCompanies: [row], isGroup: false, requestId });
  };

  const participantsColumns: ReactTable.Column[] = [
    {
      Header: 'Название',
      accessor: 'name',
    },
    {
      Header: 'ИНН',
      accessor: 'inn',
    },
    {
      id: 'id',
      // @ts-expect-error check type
      accessor: (row): JSX.Element => (
        <Button
          type="text"
          icon={<IconChat />}
          onClick={() => {
            if (user?.canCommunicate) {
              getDialogId(row);
            } else {
              setIsOpenNoCommunications(true);
            }
          }}
        />
      ),
      align: 'right',
    },
  ];

  const tabProps = {
    details: {
      columns: detailsColumns,
      data: detailsData,
      className: 'request-detail-group-table__details',
      onRowClick: undefined,
      notFoundMessage: 'Список предложений пуст',
      notFoundDescription: 'К этому запросу пока не оставлено ни одного предложения.',
    },
    participants: {
      columns: participantsColumns,
      data: participantsData,
      className: 'request-detail-group-table__participants',
      onRowClick: undefined,
      notFoundMessage: 'Список участников пуст',
      notFoundDescription: 'К этому запросу пока не добавлено ни одного участника.',
    },
    positions: {
      columns: positionsColumns,
      data: (data as IRequestDetail)?.positions || [],
      className: 'request-detail-group-table__grid',
      onRowClick: navToRequestAtomicPage,
      notFoundMessage: 'Список позиций пуст',
      notFoundDescription: 'К этому запросу пока не добавлено ни одной позиции.',
    },
  };

  const tabs = [
    {
      title: 'Позиции запроса',
      path: location.pathname.replace(/\/details|\/participants/g, ''),
    },
    {
      title: 'Таблица предложений',
      path: `${breadcrumbs[3].key}/details`,
    },
    {
      title: 'Участники',
      path: `${breadcrumbs[3].key}/participants`,
    },
  ];

  const dataForBox = data
    ? [
        [
          {
            label: 'Услуга',
            value: REQUEST_TYPE_CODES[data.request_type?.code],
          },
          {
            label: 'Даты размещения запроса',
            value: `с ${formatDate(data.start_ts)} по ${formatDate(data.end_ts)}`,
          },
          {
            label: 'Максимальный объем',
            value: renderAmount({ amount: data.max_amount }),
          },
        ],
        [
          {
            label: 'Комментарий (внешний)',
            value: data.comment_ext,
          },
          {
            label: 'Комментарий (внутренний)',
            value: data.comment_int,
          },
          {
            label: 'Создан',
            value: `${AuthUtil.getFullNameAbbreviation(
              data.creator?.last_name || '',
              data.creator?.first_name || '',
              data.creator?.patronymic || ''
            )} 
        ${formatDate(data.create_ts)}`,
          },
        ],
      ]
    : [[], []];

  const isCompleted = data?.status === Status.Completed;
  const isActive = data?.status === Status.Active;

  if (isCompleted) {
    dataForBox[1].push({
      label: 'Завершен',
      value: formatDate(data.end_ts),
    });
    dataForBox[1].push({
      label: 'Комментарий при завершении',
      value: data.comment_result,
    });
  }

  return (
    <DetailLayout
      title="Детализация группирующего запроса"
      className="request-detail-group"
      mainInfoClassName="request-detail-group-info"
      extraInfoClassName="request-detail-group-table"
      badgeParams={data && REQUEST_STATUSES_PARAMS[data.status]}
      mainInfo={
        data && (
          <InfoBox
            title={(data as IRequestDetailParticipants).request_name || data.name || '-'}
            data={dataForBox}
          />
        )
      }
      extraInfo={
        data && (
          <>
            <div className="request-detail-group-table__row">
              <Pills currentTab={currentTabNumber} tabs={tabs} />
              <Button
                className="request-detail-group-table__communication"
                onClick={() => {
                  if (user?.canCommunicate)
                    setIsOpenCreateCommunication({
                      // @ts-expect-error check types
                      positions: data.positions,
                      requestId,
                      isGroup: true,
                      groupRequestName: data.name,
                    });
                  else setIsOpenNoCommunications(true);
                }}
                icon={<Group />}
              >
                Коммуникация
              </Button>
              <Button
                className="request-detail-group-table__download"
                onClick={downloadData}
                icon={<IconFile />}
              >
                Выгрузить
              </Button>
            </div>
            <Table
              wrapperClassName="request-detail-group-table"
              withColumnBodySeparator={!!isDetailsTab}
              withColumnHeadSeparator={!!isDetailsTab}
              {...tabProps[currentTabName]}
            />
          </>
        )
      }
    >
      <>
        <div className="request-detail-group__process-buttons">
          {isActive && (
            <Button
              className="request-detail-group__process-buttons-remind"
              onClick={() => {
                openReminderNotification(true);
              }}
              size="large"
              type="primary"
            >
              Напомнить
            </Button>
          )}
          {data?.status === Status.Summarizing && (
            <Button
              onClick={() => {
                setConfirmationCompleteOpen(true);
              }}
              size="large"
              type="primary"
            >
              Завершить запрос
            </Button>
          )}
          <Button onClick={repeatRequest} size="large">
            Повторить запрос
          </Button>
          {isActive && (
            <Button
              onClick={() => {
                setConfirmationCancelOpen(true);
              }}
              size="large"
              type="warning"
            >
              Отменить запрос
            </Button>
          )}
          {isCompleted && (
            <Button onClick={downloadReference} size="large" icon={<IconFile />}>
              Справка по запросу
            </Button>
          )}
        </div>
        {isConfirmationCancelOpen && (
          <Notification
            isOpen
            title="Отменить запрос"
            message="Вы уверены, что хотите отменить запрос? Все связанные с запросом предложения будут аннулированы."
            onConfirm={() => {
              cancelRequest();
              setConfirmationCancelOpen(false);
            }}
            onCancel={() => {
              setConfirmationCancelOpen(false);
            }}
            confirmBtnText="Отменить"
            cancelBtnText="Не отменять"
          />
        )}
        {isReminderNotificationOpen && (
          <Notification
            isOpen
            title="Привлечь участников"
            message="Всем участникам, которые могут, но еще не оставили своё предложение к запросу, будет отправлено уведомление. Отправить?"
            onConfirm={() => {
              sendRemindersToParticipants();
              openReminderNotification(false);
            }}
            onCancel={() => {
              openReminderNotification(false);
            }}
            confirmBtnText="Отправить"
            cancelBtnText="Отмена"
          />
        )}
        {isConfirmationCompleteOpen && (
          <Notification
            isOpen
            title="Завершить запрос"
            message="Вы можете написать комментарий, который будет отправлен всем участникам данной коммуникации."
            onConfirm={() => {
              if (!closingMessageError) {
                completeRequest();
                setConfirmationCompleteOpen(false);
              }
            }}
            onCancel={() => {
              setConfirmationCompleteOpen(false);
            }}
            confirmBtnText="Завершить"
            cancelBtnText="Отмена"
          >
            <div className="request-detail-group__notification-warning-text">
              Внимание! Изменить текст после отправки невозможно.
            </div>
            <Textarea
              name="comment"
              placeholder="Напишите комментарий"
              value={confirmationCompleteCommentText}
              onChange={(e) => {
                setClosingMessageError('');
                setConfirmationCompleteCommentText(e.target.value);
                if (e.target.value.length > COMMENT_RESULT_MAX_LENGTH)
                  setClosingMessageError(
                    `Длина поля не более ${COMMENT_RESULT_MAX_LENGTH} символов`
                  );
              }}
              className="request-detail-group__notification-comment"
              wrapperClassName="request-detail-group__notification-comment-wrapper"
              size="large"
              error={closingMessageError}
            />
          </Notification>
        )}
        {communicationProps && (
          <CreateDialogModal
            requestType={requestType}
            {...communicationProps}
            isGroup={communicationProps.isGroup as boolean}
            onClose={() => {
              setIsOpenCreateCommunication(null);
            }}
          />
        )}
        {isOpenNoCommunications && (
          <NoCommunicationsModal
            onClose={() => {
              setIsOpenNoCommunications(false);
            }}
            disabledNavigate={!data}
          />
        )}
      </>
    </DetailLayout>
  );
};

const COMMENT_RESULT_MAX_LENGTH = 1000;

export const COLUMNS = (navButton: (row: unknown) => JSX.Element): TableTypes.Column[] => [
  {
    Header: 'Срок',
    accessor: (row: unknown) => {
      const _row = row as IPosition;

      return renderPeriodLong({
        period: _row.period as number,
        periodType: _row.period_type,
        endDatePeriod: _row.end_date_period as string,
      });
    },
  },
  {
    Header: 'Объём',
    accessor: (row: unknown) => renderAmount(row as IPosition),
    align: 'right',
  },
  {
    Header: 'Ставка',
    accessor: (row: unknown) => <>{RATE_TYPES_TITLE[(row as IPosition).rate_type] || '-'}</>,
  },
  {
    Header: 'Предложения',
    accessor: 'proposals_count',
    align: 'right',
  },
  {
    id: 'id',
    accessor: navButton,
    align: 'right',
  },
];
