import React from 'react';
import Container from '@components/Container/Container';
import { RateType } from '@models/base.model';
import { renderAmount, renderRateLong } from '../../../../../functions';
import type { IProposal } from '../position.model';

import './MyProposal.scss';

interface IProps {
  data?: IProposal;
  rateType: RateType;
}

export const MyProposal: React.FC<IProps> = ({ data, rateType }: IProps) => {
  if (!data) return null;

  return (
    <>
      <div className="position-detail-proposals__my-proposal">
        <Container.Field label="Процентная ставка" value={renderRateLong({ ...data, rateType })} />
        <Container.Field label="Объём" value={renderAmount(data)} />
        <Container.Field label="Комментарий" value={data.comment ?? '-'} />
      </div>
    </>
  );
};
