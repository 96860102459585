import React from 'react';
import cn from 'classnames';
import { useLocation, Link } from 'react-router-dom';

import './Pills.scss';

export interface ITab {
  id?: string | number;
  title: string | JSX.Element;
  disabled?: boolean;
  path?: string;
}

interface IProps {
  tabs: ITab[];
  className?: string;
  currentTab: number;
  vertical?: boolean;
}

const Pills: React.FC<IProps> = ({ tabs, className, currentTab, vertical }) => {
  const { pathname } = useLocation();
  return (
    <div className={cn('custom-pills', className, { 'custom-pills--vertical': vertical })}>
      <ul className="custom-pills__list">
        {tabs.map((tab) => (
          <li className="custom-pills__list-item" key={tab.path}>
            <Link
              to={tab.path && !tab.disabled && tab.path !== pathname ? tab.path : ''}
              className={cn(
                'custom-pills__list-item-link',
                tabs[currentTab]?.path === tab.path && 'active',
                tab.disabled && 'disabled'
              )}
            >
              {tab.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Pills;
