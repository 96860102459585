import { HttpClient } from '@api/HttpClient';
import type { GetTablePagesResponse } from '@components/Table/types'; // FIXME: перенести типы
import {
  IDialogsReport,
  IDialog,
  IDialogDetails,
  IExtendedDialog,
  IMessage,
} from '@models/communication.model';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';

interface IGetDialogsParams {
  status: string;
  company_ids: number[];
  create_ts?: { start?: string; end?: string };
  request_types?: string[];
  search: string;
}

interface ICreateDialogParams {
  recipient_company_ids: Array<string | number>;
  title?: string;
  request_type?: string;
  request_id?: string | number;
  request_position_id?: string | number;
  request_bond_id?: string | number;
  message?: string;
}

interface ISearchDialogParams {
  recipient_company_id: string | number;
  request_type: string;
  request_id?: string | number;
  request_position_id?: string | number;
  request_bond_id?: string | number;
}

interface IPagination {
  pageNumber: number;
  maxRowCount: number;
}

export class CommunicationService {
  public static async getDialogs(
    pagination: IPagination,
    params?: IGetDialogsParams
  ): Promise<GetTablePagesResponse<IDialog>> {
    return await HttpClient.post<IGetDialogsParams, GetTablePagesResponse<IDialog>>(
      `communication/list/?page=${pagination.pageNumber}&page_size=${pagination.maxRowCount}`,
      params
    );
  }

  public static async loadUnreadDialogsCount(): Promise<{ communication_dialogs: number }> {
    return await HttpClient.post<{ communication_dialogs: number }>('communication/count/new/', {});
  }

  public static async getDialogsReport(): Promise<IDialogsReport> {
    return await HttpClient.get<IDialogsReport>('communication/get_dialogs_report/');
  }

  public static async getDialogDetails(id: string): Promise<IDialogDetails> {
    return await HttpClient.get<IDialogDetails>(`communication/${id}/`);
  }

  public static async getCompaniesForDialog(search?: string): Promise<ISimilarCompany[]> {
    return await HttpClient.post<{ search: string }, ISimilarCompany[]>(
      'communication/companies/list/',
      { search }
    );
  }

  public static async createDialog(params?: ICreateDialogParams): Promise<IExtendedDialog[]> {
    return await HttpClient.post<ICreateDialogParams, IExtendedDialog[]>('communication/', params);
  }

  public static async searchActiveDialogByParams(
    params?: ISearchDialogParams
  ): Promise<{ dialog_id: number | null }> {
    return await HttpClient.post<ISearchDialogParams, { dialog_id: null | number }>(
      'communication/search/',
      params
    );
  }

  public static async createMessage(params?: {
    communication_dialog_id: string | number;
    text: string;
  }): Promise<IMessage> {
    return await HttpClient.post<
      {
        communication_dialog_id: string | number;
        text: string;
      },
      IMessage
    >('communication/messages/', params);
  }

  public static async getDialogMessages(
    id: string | number,
    pagination: IPagination
  ): Promise<GetTablePagesResponse<IMessage>> {
    return await HttpClient.get<GetTablePagesResponse<IMessage>>(
      `communication/messages/${id}/list/?page=${pagination.pageNumber}&page_size=${pagination.maxRowCount}`
    );
  }

  public static async setDialogRead(id: string | number): Promise<void> {
    await HttpClient.patch(`communication/${id}/read/`);
  }

  public static async setDialogArchive(id: string | number): Promise<IExtendedDialog> {
    return await HttpClient.patch<IExtendedDialog>(`communication/${id}/archive/`);
  }
}

export default CommunicationService;
