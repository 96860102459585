/* eslint-disable */

import React from 'react';
import cn from 'classnames';
import Tooltip from '@components/Tooltip/Tooltip';

import './ClippedText.scss';

interface IProps {
  content: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
}

interface IState {
  isOverflowed?: boolean;
}

export class ClippedText extends React.Component<IProps, IState> {
  state: IState = {};

  contentRef = React.createRef<HTMLDivElement>();
  wrapperRef = React.createRef<HTMLDivElement>();

  intersectionObserver?: IntersectionObserver;

  componentDidMount() {
    if (this.contentRef.current) {
      this.setState({
        isOverflowed: this.contentRef.current.offsetWidth < this.contentRef.current.scrollWidth,
      });
    }
  }

  componentDidUpdate() {
    if (!this.intersectionObserver) {
      this.observeIntersection();
      return;
    }

    // Если DOM-ноды были заменены React'ом, нужно переподписаться
    const records = this.intersectionObserver.takeRecords();
    if (
      this.wrapperRef.current !== this.intersectionObserver.root ||
      (records.length && this.contentRef.current !== records[0].target)
    ) {
      this.observeIntersection();
    }
  }

  componentWillUnmount() {
    this.unobserveIntersection();
  }

  render() {
    const { content, className, wrapperClassName } = this.props;
    const { isOverflowed } = this.state;
    // Если контент не влезает, делаю его прозрачным (чтобы скрыть, но при этом сохранить переполнение)
    // и добавляю такой же с обрезанным текстом и троеточием.
    return (
      <div
        className={cn(
          'custom-clipped-text',
          isOverflowed && 'custom-clipped-text--overflown',
          className,
          wrapperClassName
        )}
        ref={this.wrapperRef}
      >
        <div
          ref={this.contentRef}
          className={cn(
            'custom-clipped-text__content',
            isOverflowed === undefined && 'custom-clipped-text__content--clipped'
          )}
        >
          {content}
        </div>
        {isOverflowed && (
          <Tooltip isShowing text={content} className="custom-clipped-text__tooltip-content">
            <div className="custom-clipped-text__content--clipped">{content}</div>
          </Tooltip>
        )}
      </div>
    );
  }

  unobserveIntersection = () => {
    this.intersectionObserver && this.intersectionObserver.disconnect();
  };

  observeIntersection = () => {
    this.unobserveIntersection();

    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        // В Chrome может быть 0,997..., хотя визуально содержимое полностью внутри контейнера
        this.setOverflownValue(entries[0].intersectionRatio < 0.99);
      },
      {
        root: this.wrapperRef.current,
        threshold: 1,
      }
    );

    this.intersectionObserver.observe(this.contentRef?.current!);
  };

  setOverflownValue = (value: boolean) => {
    if (this.state.isOverflowed !== value) {
      this.setState({ isOverflowed: value });
    }
  };
}

export default ClippedText;
