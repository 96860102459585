import React from 'react';
import { To, useNavigate, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import { HttpClient } from '@api/HttpClient';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { ROUTES } from '@routes';
import { MEMBER_STATUSES_PARAMS, COMPANY_ROLES, TITLES } from '@constants';
import { formatPhone, getFullname } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import Notification from '@components/Modal/Notification/Notification';
import Button from '@components/Button/Button';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { UserStatus } from '@models/user.model';
import { ReactComponent as Phone } from '@shared/icons/phone.svg';
import { ReactComponent as Email } from '@shared/icons/email.svg';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Password } from '@shared/icons/password.svg';
import { ReactComponent as Block } from '@shared/icons/block.svg';
import { ReactComponent as Activate } from '@shared/icons/activate.svg';
import { ReactComponent as Trash } from '@shared/icons/trash.svg';
import { CreateUserForm } from '../CreateUserForm/CreateUserForm';
import { Rights } from './Rights/Rights';
import { IUserForAdmin, IUserPermissions } from '@pages/operator/operator.model';
import OperatorService from '@services/operator/operator.service';

import './UserDetailPage.scss';

const ACTIONS = {
  BLOCK: 'BLOCK',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DELETE: 'DELETE',
};

export const UserDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userId } = useParams();
  const isEdited = pathname.includes('/edit');
  const prevLink =
    ROUTES.ADMINISTRATION + ROUTES.USERS + (isEdited ? '/' + userId + '/details' : '');
  const initialData = {} as IUserForAdmin;

  const [modalState, setModalState] = React.useState<string | null>(null);
  const [data, setData] = React.useState<IUserForAdmin>(initialData);

  const loadUser = (): void => {
    HttpClient.get<IUserForAdmin>(`operator/users/${userId}/`).then(setData);
  };

  const updateUserStatus = (status: UserStatus): void => {
    HttpClient.patch<IUserForAdmin>(`operator/users/${userId}/${status}/`).then(setData);
  };

  const resetPassword = (): void => {
    HttpClient.post<{ email: string }>('public/recovery_password/', {
      email: data.email,
    });
  };

  React.useEffect(() => {
    loadUser();
  }, [userId]);

  const notificationParams = {
    [ACTIONS.DELETE]: {
      title: 'Удаление пользователя',
      message:
        'Вы уверены, что хотите удалить пользователя? Это действие нельзя отменить, пользователь потеряет доступ к системе навсегда.',
      onConfirm: () => {
        updateUserStatus(UserStatus.DELETED);
        setModalState(null);
      },
      confirmBtnText: 'Удалить',
      cancelBtnText: 'Отмена',
    },
    [ACTIONS.BLOCK]: {
      title: 'Блокировка пользователя',
      message:
        'Вы уверены, что хотите заблокировать пользователя? Блокировка может быть отменена в любой момент.',
      onConfirm: () => {
        updateUserStatus(UserStatus.BLOCKED_OPERATOR);
        setModalState(null);
      },
      confirmBtnText: 'Заблокировать',
      cancelBtnText: 'Отмена',
    },
    [ACTIONS.RESET_PASSWORD]: {
      title: 'Сброс пароля',
      message:
        'Вы уверены, что хотите сбросить пароль?\nНа электронный адрес пользователя будет отправлена ссылка для задания нового пароля.',
      onConfirm: () => {
        resetPassword();
        setModalState(null);
      },
      confirmBtnText: 'Сбросить пароль',
      cancelBtnText: 'Отмена',
    },
  };

  const dataForBox =
    data.company?.type !== 'PARTICIPANT'
      ? [
          [
            {
              label: 'Дата регистрации',
              value: formatDate(data.create_ts),
            },
            {
              label: 'Контактные данные',
              value: (
                <div className="user-detail-info__contacts">
                  <div className="user-detail-info__phone">
                    <Phone />
                    {formatPhone(data.phone) ?? '-'}
                  </div>
                  <div className="user-detail-info__mail">
                    <Email />
                    {data.email ?? '-'}
                  </div>
                </div>
              ),
            },
            {
              label: 'Компания',
              value: (
                <div className="user-detail-info__company">
                  <div className="user-detail-info__company-name">{data.company?.name}</div>
                  <div className="user-detail-info__company-role">
                    {COMPANY_ROLES[data.company?.type] ?? '-'}
                  </div>
                </div>
              ),
            },
            {
              label: 'Должность',
              value: data.position,
            },
            {
              label: 'Предпочтительный трек',
              value: data.favorite_request_type
                ? TITLES[data.favorite_request_type.toLowerCase()]
                : '',
            },
          ],
        ]
      : [
          [
            {
              label: 'Дата регистрации',
              value: formatDate(data.create_ts),
            },
            {
              label: 'Контактные данные',
              value: (
                <div className="user-detail-info__contacts">
                  <div className="user-detail-info__phone">
                    <Phone />
                    {formatPhone(data.phone) ?? '-'}
                  </div>
                  <div className="user-detail-info__mail">
                    <Email />
                    {data.email ?? '-'}
                  </div>
                </div>
              ),
            },
            {
              label: 'Предпочтительный трек',
              value: data.favorite_request_type
                ? TITLES[data.favorite_request_type.toLowerCase()]
                : '',
            },
          ],
          [
            {
              label: 'Компания',
              value: (
                <div className="user-detail-info__company">
                  <div className="user-detail-info__row">
                    <div className="user-detail-info__company-name">{data.company?.name}</div>
                    <div className="user-detail-info__company-role">
                      {COMPANY_ROLES[data.company?.type].charAt(0).toUpperCase() +
                        COMPANY_ROLES[data.company?.type].slice(1) ?? '-'}
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: 'Должность',
              value: data.position,
            },
          ],
        ];

  const renderActions = (): JSX.Element[] | null => {
    if (data.status === UserStatus.DELETED) return null;

    const isActive = data.status === UserStatus.ACTIVE;
    const isOperator = data.company?.type === 'OPERATOR';

    const buttons = [
      <Button
        key="edit"
        icon={<Edit />}
        type="without-border"
        onClick={() => {
          navigate(pathname.replace('/details', '/edit'));
        }}
      />,
      <Button
        key="reset-password"
        icon={<Password />}
        type="without-border"
        onClick={() => {
          setModalState(ACTIONS.RESET_PASSWORD);
        }}
      />,
    ];

    if (isOperator) return buttons;

    if (isActive)
      buttons.push(
        <Button
          key="block"
          icon={<Block />}
          type="without-border"
          onClick={() => {
            setModalState(ACTIONS.BLOCK);
          }}
        />
      );
    else
      buttons.push(
        <Button
          key="activate"
          icon={<Activate />}
          type="without-border"
          onClick={() => {
            updateUserStatus(UserStatus.ACTIVE);
          }}
        />
      );

    buttons.push(
      <Button
        key="delete"
        icon={<Trash />}
        type="without-border"
        onClick={() => {
          setModalState(ACTIONS.DELETE);
        }}
      />
    );
    return buttons;
  };

  const handleEditPermissions = async (permissions: IUserPermissions[]): Promise<void> => {
    await OperatorService.putParticipantUserTypePermissions(permissions);
    loadUser();
  };

  return (
    <DetailLayout
      title="Просмотр пользователя"
      className="user-detail"
      mainInfoClassName={cn(
        'user-detail-info',
        data.company?.type === 'PARTICIPANT' && 'user-detail-info--fit-content'
      )}
      badgeParams={MEMBER_STATUSES_PARAMS[data.status]}
      extraInfoClassName="user-detail-table"
      prevLink={prevLink}
      mainInfo={
        isEdited ? (
          <CreateUserForm
            // @ts-expect-error check types
            data={{
              ...data,
              company: { label: data.company?.name, value: data.company?.id },
              favorite_request_type: data.favorite_request_type
                ? {
                    value: data.favorite_request_type,
                    label: TITLES[data.favorite_request_type.toLowerCase()],
                  }
                : null,
            }}
            onClose={(newData?: object) => {
              setData((prevData) => ({ ...prevData, ...newData }));
              navigate(prevLink as To);
            }}
          />
        ) : (
          <>
            <InfoBox
              title={getFullname(data.last_name, data.first_name, data.patronymic)}
              data={dataForBox}
            />
            <div className="user-detail-info__actions">{renderActions()}</div>
            {modalState && (
              <Notification
                className="admin-user-notification"
                isOpen
                onCancel={() => {
                  setModalState(null);
                }}
                {...notificationParams[modalState]}
              />
            )}
          </>
        )
      }
      extraInfo={
        data.company?.type === 'PARTICIPANT' && (
          <Rights
            data={data.customers || []}
            onEdit={handleEditPermissions}
            participantId={data?.company?.id.toString()}
          />
        )
      }
    />
  );
};
