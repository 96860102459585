import React from 'react';
import PageLayout from '@layouts/PageLayout/PageLayout';
import Tabs from '@components/Tabs/Tabs';
import { ProposalsListReport } from './ProposalsListReport/ProposalsListReport';
import { ROUTES } from '@routes';

export const ReportsPage: React.FC = () => {
  return (
    <PageLayout>
      <Tabs tabs={tabs} />
    </PageLayout>
  );
};

const tabs = [
  {
    title: 'Список предложений по участнику',
    component: <ProposalsListReport />,
    path: ROUTES.REPORTS + ROUTES.PROPOSALS_LIST_REPORT,
  },
];
