import React from 'react';
import { HttpClient } from '@api/HttpClient';
import { removeEmptyProps, transformObjectKeys } from '@utils';
import { formatDateFromMSCtoUTC } from '@date-time';
import type * as TableTypes from '@components/Table/types';
import { IDictionary } from '@models/base.model';
import { DELAY_REQUEST, INITIAL_PAGINATION } from '@constants';
import useDebounce from '@hooks';

export interface IUseTableWithParams<T = unknown> {
  startDate: Date | undefined | null;
  endDate: Date | undefined | null;
  setDateRange: (range: Array<Date | undefined | null>) => void;
  userNames: IDictionary[];
  setUserNames: (statuses: IDictionary[]) => void;
  actions: IDictionary[];
  setActions: (actions: IDictionary[]) => void;
  requestId: string;
  setRequestId: (str: string) => void;
  data: T[];
  fetchCurrentPage: (tableState: TableTypes.DataFetcherParams) => void;
  isLoading: boolean;
  controlledPageCount: number;
  pageIndex: number;
  totalCount: number;
  prepareFiltersForRequests: () => Partial<IFiltesrForRequests>;
}

export interface IFiltesrForRequests {
  create_ts: {
    start: string;
    end: string;
  };
  username: string[];
  action: string[];
  request_id: string;
}

export function useTableWithParams<T = unknown>(
  url: string,
  mounted: boolean
): IUseTableWithParams<T> {
  const [loadedData, setData] = React.useState<{ data: T[]; isLoading: boolean }>({
    data: [],
    isLoading: false,
  });

  const [dateRange, setDateRange] = React.useState<Array<Date | null | undefined>>([null, null]);
  const [userNames, setUserNames] = React.useState<IDictionary[]>([]);
  const [actions, setActions] = React.useState<IDictionary[]>([]);
  const [startDate, endDate] = dateRange;
  const [requestId, setRequestId] = React.useState('');

  const pagination = React.useRef(INITIAL_PAGINATION);

  const prepareFiltersForRequests = (): Partial<IFiltesrForRequests> => {
    return removeEmptyProps({
      create_ts: {
        start: formatDateFromMSCtoUTC(startDate),
        end: formatDateFromMSCtoUTC(endDate),
      },
      username: userNames.map(({ label }) => label),
      action: actions.map(({ label }) => label),
      request_id: requestId,
    });
  };

  const fetchData = ({ pageIndex, pageSize }: TableTypes.DataFetcherParams): void => {
    setData({ ...loadedData, isLoading: true });

    const body = prepareFiltersForRequests();

    HttpClient.fetch(url, {
      params: {
        page: pageIndex + 1,
        page_size: pageSize,
      },
      body,
    })
      .then((resp: TableTypes.GetTablePagesResponse<unknown>) => {
        // @ts-expect-error Types of parameters are incompatible.
        const prepared = resp.items.map(transformObjectKeys);
        setData({ isLoading: false, data: prepared as T[] });
        pagination.current = { ...resp, page: resp.page - 1 };
      })
      .catch((e) => {
        setData({ isLoading: false, data: [] });
        pagination.current = INITIAL_PAGINATION;
      });
  };

  const fetchCurrentPage = (tableState: TableTypes.DataFetcherParams): void => {
    pagination.current.page = tableState.pageIndex;
    pagination.current.size = tableState.pageSize;
    fetchData(tableState);
  };

  const fetchFirstPage = (): void => {
    if (!mounted) return;
    // начинаем поиск с первой страницы
    pagination.current.page = 0;
    fetchData({ pageIndex: 0, pageSize: pagination.current.size });
  };

  React.useEffect(fetchFirstPage, [startDate, endDate, userNames, actions]);

  useDebounce(fetchFirstPage, [requestId], DELAY_REQUEST);

  return {
    startDate,
    endDate,
    setDateRange,
    userNames,
    setUserNames,
    actions,
    setActions,
    requestId,
    setRequestId,
    data: loadedData.data,
    fetchCurrentPage,
    prepareFiltersForRequests,
    isLoading: loadedData.isLoading,
    controlledPageCount: pagination.current.pages,
    pageIndex: pagination.current.page,
    totalCount: pagination.current.total,
  };
}
