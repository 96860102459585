import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

const PartOfNameSchema = Yup.string()
  .test(
    '1-50 letters',
    'От 1 до 50 символов',
    // @ts-expect-error check types
    (item) => {
      return item && item?.toString().length <= 50 && item?.toString().length >= 1;
    }
  )
  .test(
    'only letters',
    'Допустим ввод букв кириллицы и тире в середине',
    // @ts-expect-error check types
    (item) => {
      return item && /^[а-яА-ЯЁё]+(-[а-яА-ЯЁё-]+)?$/.test(item);
    }
  )
  .required(REQUIRED_MESSAGE);

export const UserSchema = Yup.object().shape({
  lastName: PartOfNameSchema,
  firstName: PartOfNameSchema,
  patronymic: Yup.string()
    .nullable()
    .test('1-50 letters', 'От 1 до 50 символов', (item) => {
      return item ? item.toString().length <= 50 && item.toString().length >= 0 : true;
    })
    .test('only letters and dash', 'Допустим ввод букв кириллицы и тире в середине', (item) => {
      return item ? /^[а-яА-ЯЁё]+(-[а-яА-ЯЁё-]+)?$/.test(item) : true;
    }),
  favoriteRequestType: Yup.object().nullable(),
  position: Yup.string()
    .nullable()
    .test('1-100 letters', 'От 0 до 100 символов', (item) => {
      return item ? item.toString().length <= 100 && item.toString().length >= 0 : true;
    })
    .test(
      'letters, numbers, brackets, dash',
      'Допустим ввод букв, цифр, скобок и дефисов',
      (item) => {
        return item ? /^[a-zA-Zа-яА-ЯЁё0-9()\-\s]+$/.test(item) : true;
      }
    ),
});
