import React, { useRef } from 'react';
import cn from 'classnames';
import Tooltip from '../Tooltip/Tooltip';

import { ReactComponent as Close } from '@shared/icons/close.svg';

import './Textarea.scss';

export interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isClearable?: boolean;
  wrapperClassName?: string;
  labelClassName?: string;
  autoSize?: boolean;
  error?: string;
  size?: 'medium' | 'large';
  showTooltipError?: boolean;
  label?: string;
  resizable?: boolean;
}

export const Textarea: React.FC<IProps> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const {
    className,
    value,
    onChange,
    wrapperClassName,
    labelClassName,
    label,
    isClearable,
    required,
    disabled,
    error,
    autoSize,
    size = 'medium',
    showTooltipError = false,
    resizable = false,
    name,
    ...restProps
  } = props;
  const isEmpty = value === '' || value == null;

  const wrapperClass = cn('custom-textarea', `custom-textarea--${size}`, wrapperClassName, {
    'custom-textarea--required': required,
    'custom-textarea--empty': isEmpty,
    'custom-textarea--has-label': label,
    'custom-textarea--disabled': disabled,
    'custom-textarea--has-error': error,
    'custom-textarea--resizable': resizable,
  });
  const labelClass = cn('custom-textarea__label', labelClassName, {
    'custom-textarea__floating-label--float-up': !isEmpty,
  });

  const inputClass = cn('custom-textarea__field', className);

  const handleClear = (): void => {
    if (!ref.current || disabled) return;

    // https://stackoverflow.com/a/46012210
    // Генерируем событие изменения значения, чтобы в onChange передался корректный аргумент события
    const nativeValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      'value'
    )?.set;
    if (!nativeValueSetter) return;

    nativeValueSetter.call(ref.current, '');
    ref.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const renderBody = (): JSX.Element => {
    return (
      <>
        {label ? (
          <label className={cn('custom-input__label', labelClass)} htmlFor={name}>
            {label}
          </label>
        ) : null}
        <div className={inputClass}>
          {isClearable && value && (
            <div className="custom-textarea__wrapper-clear-button">
              <Close className="custom-textarea__clear-button" onClick={handleClear} />
            </div>
          )}
          <textarea
            {...restProps}
            name={name}
            ref={ref}
            value={value}
            disabled={disabled}
            onChange={(e) => {
              if (autoSize) {
                setHeightByContent(e.target);
              }
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className={wrapperClass}>
      {showTooltipError ? <Tooltip text={error ?? ''}>{renderBody()}</Tooltip> : renderBody()}

      {!showTooltipError && error && <span className="custom-textarea__error">{error}</span>}
    </div>
  );
};

function setHeightByContent(element: HTMLTextAreaElement): void {
  element.style.height = 'auto';
  element.style.height = element.scrollHeight + 'px';
}

export default Textarea;
