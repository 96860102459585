import type React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import { useTableWithParams, useMounted } from '@hooks';
import {
  renderStatus,
  renderName,
  renderDate,
  renderMaxAmount,
  renderProposalsCount,
  renderCreatorCompanyAndPerson,
  renderMinMaxAmount,
  renderMaturity,
} from '@functions';
import Filters from './Filters/Filters';
import type { IRequest } from '@shared/components/Requests/requests.model';
import { BOND_RATE_TYPES } from '@constants';
import type * as ReactTableTypes from 'react-table';

import './OperatorRequests.scss';

const operatorCreditsColumns = [
  {
    Header: '№ Запроса',
    accessor: 'id',
    width: 80,
  },
  {
    Header: 'Название',
    accessor: renderName,
    clipped: true,
  },
  {
    Header: 'Статус',
    accessor: renderStatus('status'),
    align: 'center',
    verticalAlign: 'middle',
    width: 100,
  },
  {
    Header: 'Начало',
    accessor: renderDate('startTs'),
    align: 'right',
    width: 100,
  },
  {
    Header: 'Завершение',
    accessor: renderDate('endTs'),
    align: 'right',
    width: 100,
  },
  {
    Header: 'Макс. объем',
    accessor: renderMaxAmount,
    width: 120,
  },

  {
    Header: 'Предложений',
    accessor: renderProposalsCount,
    align: 'right',
    width: 100,
  },
  {
    Header: 'Запрос открыл(а)',
    accessor: renderCreatorCompanyAndPerson,
    width: 160,
  },
] as ReactTableTypes.ColumnInterface[];
const operatorDepositsColumns = [...operatorCreditsColumns] as ReactTableTypes.ColumnInterface[];
const operatorBondsColumns = [
  {
    Header: '№ запроса',
    accessor: 'id',
    width: 80,
  },
  {
    Header: 'Название',
    accessor: renderName,
    clipped: true,
  },
  {
    Header: 'Статус',
    accessor: renderStatus('status'),
    align: 'center',
    verticalAlign: 'middle',
    width: 100,
  },
  {
    Header: 'Начало',
    accessor: renderDate('startTs'),
    width: 100,
  },
  {
    Header: 'Завершение',
    accessor: renderDate('endTs'),
    width: 100,
  },
  {
    Header: 'Объем выпуска',
    accessor: renderMinMaxAmount,
    width: 160,
  },
  {
    Header: 'Лет до погашения',
    accessor: renderMaturity,
    align: 'right',
    width: 120,
  },
  {
    Header: 'Флоатер',
    accessor: ({ rateType }: { rateType: string }) => BOND_RATE_TYPES[rateType] ?? '-',
    width: 100,
  },
  {
    Header: 'Предложений',
    accessor: renderProposalsCount,
    align: 'right',
    width: 100,
  },
  {
    Header: 'Запрос открыл(а)',
    accessor: renderCreatorCompanyAndPerson,
    width: 160,
  },
] as ReactTableTypes.ColumnInterface[];

const getActualColumns = (requestType: string): ReactTableTypes.ColumnInterface[] => {
  if (requestType === 'credit') return operatorCreditsColumns;
  if (requestType === 'deposit') return operatorDepositsColumns;
  if (requestType === 'bond') return operatorBondsColumns;
  return [];
};

const OperatorRequests: React.FC<{ requestType: string }> = ({
  requestType,
}: {
  requestType: string;
}) => {
  const mounted = useMounted();
  const navigate = useNavigate();

  let apiPath = '';
  if (requestType !== 'bond') apiPath = `/operator/${requestType}/requests/list/`;
  else apiPath = '/operator/bonds/requests/list/';

  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    ...filterParams
  } = useTableWithParams(apiPath, mounted, 'post', { search: 'request_id' });

  const handleRowClick = (row: ReactTableTypes.Row<object>): void => {
    navigate(
      location.pathname +
        (location.pathname.at(-1) === '/' ? '' : '/') +
        (row.original as { companyCreator: { id: string } })?.companyCreator?.id +
        '/requests/' +
        row.id
    );
  };

  const columns = getActualColumns(requestType);
  return (
    <div className="requests">
      <div className="requests__actions">
        {/** @ts-expect-error check types */}
        {<Filters className="request__filters" {...filterParams} />}
      </div>
      <Table
        columns={columns as TableTypes.Column[]}
        data={data as Array<TableTypes.CustomRowData<IRequest>>}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default OperatorRequests;
