import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

export const CompanyParticipantSchema = Yup.object().shape({
  name: Yup.string()
    .test(
      '1-100 letters',
      'От 1 до 100 символов',
      // @ts-expect-error check types
      (item) => {
        return item && item?.toString().length <= 100 && item?.toString().length >= 1;
      }
    )
    .test(
      'only letters',
      'Допустим ввод букв и спецсимволов',
      // @ts-expect-error check types
      (item) => {
        return item && !/\d/.test(item);
      }
    )
    .required(REQUIRED_MESSAGE),
  inn: Yup.string()
    .test(
      '10 numbers',
      'Поле из 10 цифр',
      // @ts-expect-error check types
      (item) => {
        return item && /^[\d+]{10}$/.test(item);
      }
    )
    .required(REQUIRED_MESSAGE),
  comment: Yup.string().trim().max(100, 'Максимум 100 символов').nullable(),
});
