export const STATUSES = {
  ACTIVE: 'Открыт',
  COMPLETED: 'Завершен',
  CANCELLED: 'Отменен',
  SUMMARIZING: 'Итоги',
} as const;

export const MEMBER_STATUSES = {
  ACTIVE: 'Активен',
  BLOCKED: 'Заблокирован',
} as const;

export const COMPANY_ROLES = {
  CUSTOMER: 'заказчик',
  PARTICIPANT: 'участник',
  OPERATOR: 'оператор',
} as Record<string, string>;

export const PROPOSAL_STATUSES: Record<string, string> = {
  NEW: 'Новое',
  SENT: 'Отправлено',
  CANCELLED: 'Отозвано',
};

export const RATE_TYPES: Record<string, string> = {
  FIX: 'Фикс.',
  FLOAT: 'Плав.',
};

export const RATE_TYPES_TITLE: Record<string, string> = {
  FIX: 'Фиксированная',
  FLOAT: 'Плавающая',
  BOTH: 'Обе ставки',
};

export const BOND_RATE_TYPES: Record<string, string> = {
  FIX: 'Фикс',
  OFZ: 'Флоатер',
  KEY_RATE: 'Флоатер',
  RUONIA: 'Флоатер',
};

export const BOND_SPREAD_TYPES_TITLES: Record<string, string> = {
  PROFIT: 'По доходности',
  RATE: 'По ставке',
};

export const PERIOD_TYPES: Record<string, string> = {
  MONTH: 'Месяцы',
  YEAR: 'Годы',
  DATE: 'Дата',
};

export const REQUEST_TYPE_CODES: Record<string, string> = {
  CREDIT: 'Кредит',
  DEPOSIT: 'Депозит',
  BOND: 'Облигация',
};

export const REQUEST_STATUSES_PARAMS = {
  DRAFT: {
    title: 'Редактируется',
    color: 'var(--status-draft--color)',
    backgroundColor: 'var(--status-draft--bckg-color)',
  },
  ACTIVE: {
    title: 'Открыт',
    color: 'var(--status-active--color)',
    backgroundColor: 'var(--status-active--bckg-color)',
  },
  CANCELLED: {
    title: 'Отменен',
    color: 'var(--status-cancelled--color)',
    backgroundColor: 'var(--status-cancelled--bckg-color)',
  },
  COMPLETED: {
    title: 'Завершен',
    color: 'var(--status-completed--color)',
    backgroundColor: 'var(--status-completed--bckg-color)',
  },
  SUMMARIZING: {
    title: 'Итоги',
    color: 'var(--status-summarizing--color)',
    backgroundColor: 'var(--status-summarizing--bckg-color)',
  },
} as Record<string, { title: string; color: string; backgroundColor: string }>;

export const TICKET_STATUSES_PARAMS = {
  NEW: {
    title: 'Новое',
    color: 'var(--status-ticket-new--color)',
    backgroundColor: 'var(--status-ticket-new--bckg-color)',
  },
  IN_PROGRESS: {
    title: 'В работе',
    color: 'var(--status-ticket-in-progress--color)',
    backgroundColor: 'var(--status-ticket-in-progress--bckg-color)',
  },
  DECLINED: {
    title: 'Отменено',
    color: 'var(--status-ticket-declined--color)',
    backgroundColor: 'var(--status-ticket-declined--bckg-color)',
  },
  DONE: {
    title: 'Завершено',
    color: 'var(--status-ticket-done--color)',
    backgroundColor: 'var(--status-ticket-done--bckg-color)',
  },
} as Record<string, { title: string; color: string; backgroundColor: string }>;

export const TICKET_STATUSES_OPTIONS = [
  { value: 'NEW', label: 'Новое' },
  { value: 'IN_PROGRESS', label: 'В работе' },
  { value: 'DECLINED', label: 'Завершено' },
  { value: 'DONE', label: 'Отменено' },
];

export const MEMBER_STATUSES_PARAMS = {
  ACTIVE: {
    title: 'Активен',
    color: 'var(--status-member-active--color)',
    backgroundColor: 'var(--status-member-active--bckg-color)',
  },
  // Оставил потому что на этот кастомный статус завязаны списки заказчиков\участников
  BLOCKED: {
    title: 'Заблокирован',
    color: 'var(--status-member-blocked--color)',
    backgroundColor: 'var(--status-member-blocked--bckg-color)',
  },
  BLOCKED_OPERATOR: {
    title: 'Заблокирован',
    color: 'var(--status-member-blocked--color)',
    backgroundColor: 'var(--status-member-blocked--bckg-color)',
  },
  BLOCKED_COMPANY: {
    title: 'Заблокирован компанией',
    color: 'var(--status-member-blocked--color)',
    backgroundColor: 'var(--status-member-blocked--bckg-color)',
  },
  DELETED: {
    title: 'Удален',
    color: 'var(--status-member-deleted--color)',
    backgroundColor: 'var(--status-member-deleted--bckg-color)',
  },
} as Record<string, { title: string; color: string; backgroundColor: string }>;

export const BLOCKED_STATUSES = ['DELETED', 'BLOCKED', 'BLOCKED_OPERATOR', 'BLOCKED_COMPANY'];

export const REPORT_TYPES = {
  SUMMARY: 'summary',
  COMPLETED: 'completed',
  MATRIX: 'matrix',
};

export const BOND_FLOATERS_TITLES = {
  OFZ: 'ОФЗ',
  KEY_RATE: 'КС',
  RUONIA: 'RUONIA',
} as Record<string, string>;

export const MIME_TYPES = {
  Excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const TITLES = {
  credit: 'Кредиты',
  deposit: 'Депозиты',
  bond: 'Облигации',
} as Record<string, string>;

export const TRACKS_ACCESS = {
  CREDIT: 'CREDIT',
  DEPOSIT: 'DEPOSIT',
  BONDS: 'BOND',
};

export const INITIAL_PAGINATION = { page: 0, size: 10, pages: 0, total: 0 };
export const DELAY_REQUEST = 800;

export const MAX_COUNT_ROWS_FOR_DOWLOAD = 5000;

export const TICKETS_STATUSES = {
  new: 'NEW',
  inProgress: 'IN_PROGRESS',
  declined: 'DECLINED',
  done: 'DONE',
};

export const REQUEST_TYPES_OPTION = [
  { label: 'Кредиты', value: 'CREDIT' },
  { label: 'Депозиты', value: 'DEPOSIT' },
  { label: 'Облигации', value: 'BOND' },
];

export const HTTP_ERROR_STATUS_CODES = {
  Bad_request: 400,
  Unauthorized: 401,
  Forbidden: 403,
  Not_found: 404,
  Conflict: 409,
  Unprocessable_entity: 422,
};

export const CONFIG_NAMES = {
  TICKET: 'new_ticket_html_text',
  REQUEST: 'new_request_invite_html_text',
  BOND: 'new_customer_bond_invite_html_text',
  WELCOME: 'welcome_html_text',
  OPERATOR_INFO: 'info_operator_html_text',
  CUSTOMER_INFO: 'info_customer_html_text',
  PARTICIPANT_INFO: 'info_participant_html_text',
  COMMUNICATIONS: 'buy_communications_module',
  MAX_CHANGES_PROPOSAL: 'proposal_max_changes_allowed',
};

export const DIALOG_STATUSES = {
  ACTIVE: 'ACTIVE',
  ARCHIVE: 'ARCHIVE',
};
