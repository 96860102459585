import React from 'react';
import { setHours, setMinutes, setSeconds, isValid, parse } from 'date-fns';
import ReactDatepicker, { type ReactDatePickerProps } from 'react-datepicker';
import ru from 'date-fns/locale/ru/index.js';
import cn from 'classnames';
import NumberInput from '../NumberInput/NumberInput';
import Button from '../Button/Button';
import { ReactComponent as ArrowLeft } from '@shared/icons/arrow-left-8-16.svg';
import { ReactComponent as ArrowRight } from '@shared/icons/arrow-right-8-16.svg';
import { ReactComponent as Calendar } from '@shared/icons/calendar.svg';
import { ReactComponent as Clock } from '@shared/icons/clock.svg';
import 'react-datepicker/dist/react-datepicker.css';
import MaskedTextInput from 'react-text-mask';
import { dateMask, timeMask } from '@masks';

import './Datepicker.scss';

enum TIME {
  hours = 'hours',
  minutes = 'minutes',
}

interface ICustomTimePickerProps {
  onChangeDate: (date: Date | null | undefined) => void;
  onClose: () => void;
  selectedValue?: Date | null;
}

const CustomTimePicker: React.FC<ICustomTimePickerProps> = ({
  onChangeDate,
  onClose = () => {},
  selectedValue = new Date(),
}) => {
  const [formState, setFormState] = React.useState({
    hours: selectedValue?.getHours() ?? 0,
    minutes: selectedValue?.getMinutes() ?? 0,
  });

  React.useEffect(() => {
    const t = {
      hours: selectedValue?.getHours() ?? 0,
      minutes: selectedValue?.getMinutes() ?? 0,
    };
    if (t.hours !== formState.hours || t.minutes !== formState.minutes) {
      setFormState(t);
    }
  }, [selectedValue]);

  const handleChange = (key: string, value: string | number): void => {
    setFormState({ ...formState, [key]: +value });
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const newDateTime = new Date(selectedValue as Date) || new Date(); // обновление ссылки
    newDateTime?.setHours(formState.hours);
    newDateTime?.setMinutes(formState.minutes);
    onChangeDate(newDateTime);
  }, [formState.hours, formState.minutes]);

  return (
    <div className="custom-timepicker">
      <div className="custom-timepicker__inputs">
        <NumberInput
          min={0}
          max={23}
          placeholder="ЧЧ"
          onChange={(val: string | number) => {
            handleChange(TIME.hours, val);
          }}
          value={formState.hours}
        />
        <NumberInput
          min={0}
          max={59}
          placeholder="ММ"
          onChange={(val: string | number) => {
            handleChange(TIME.minutes, val);
          }}
          value={formState.minutes}
        />
      </div>
      <div className="custom-timepicker__actions custom-timepicker__actions--divider">
        <Button
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          ОК
        </Button>
      </div>
    </div>
  );
};

export type CustomReactPickerProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
  showCustomTimeInputOnly?: boolean;
  customWrapperClassName?: string;
  value?: Date | null;
  onChange: (date: Date | null | undefined) => void;
  label?: string;
  size?: string;
  error?: string;
  mask?: Array<string | RegExp>;
};

const Datepicker: React.ForwardRefExoticComponent<CustomReactPickerProps> = React.forwardRef(
  (
    {
      locale,
      showTimeSelect,
      showTimeSelectOnly,
      showTimeInput,
      customTimeInput,
      showCustomTimeInputOnly,
      customWrapperClassName,
      wrapperClassName,
      popperClassName,
      formatWeekDay,
      showIcon,
      value,
      onChange,
      selected,
      label,
      error,
      size = 'medium',
      mask,
      dateFormat,
      ...rest
    }: CustomReactPickerProps,
    ref
  ) => {
    const innerRef = ref ?? React.useRef<ReactDatepicker>(null);

    // @ts-expect-error  Check prop types Property 'current' does not exist on type
    const openDatepicker = (): void => innerRef?.current?.setOpen(true);
    // @ts-expect-error  Check prop types Property 'current' does not exist on type
    const closeDatepiker = (): void => innerRef?.current?.setOpen(false);

    const combinedOptions = {
      showTimeSelect: !showCustomTimeInputOnly ? showTimeSelect : false,
      showTimeSelectOnly: !showCustomTimeInputOnly ? showTimeSelectOnly : true,
      showTimeInput: !showCustomTimeInputOnly ? showTimeInput : true,
      customTimeInput: showCustomTimeInputOnly && (
        <CustomTimePicker selectedValue={value} onChangeDate={onChange} onClose={closeDatepiker} />
      ),
      ...rest,
    };

    const wrapperClass = cn('custom-datepicker-wrapper', customWrapperClassName, {
      'custom-datepicker-wrapper--has-error': error,
    });

    const handleDateChange = (dates: Date | [Date, Date]): void => {
      if (Array.isArray(dates)) {
        onChange([
          dates[0],
          dates[1] ? setSeconds(setMinutes(setHours(dates[1], 23), 59), 59) : undefined,
        ] as unknown as Date);
      } else {
        onChange(dates);
      }
    };

    return (
      <div className={wrapperClass}>
        <label className="custom-datepicker-label" htmlFor={rest.name}>
          {label}
        </label>
        {/** @ts-expect-error  Check prop types */}
        <ReactDatepicker
          ref={innerRef}
          locale={ru}
          value={value}
          onChange={handleDateChange}
          selected={value}
          onChangeRaw={(e) => {
            // FIXME: разделить по форматам
            let newRaw = new Date(value as Date);
            const str = e.target?.value;
            // случай "только время"
            if (showCustomTimeInputOnly) {
              const hoursStr = str.substring(0, 2).replace('_', '');
              const minutesStr = str.substring(3, 5).replace('_', '');
              const hours = isNaN(+hoursStr) ? 0 : +hoursStr;
              const minutes = isNaN(+minutesStr) ? 0 : +minutesStr;
              if (hours < 24 && minutes < 60) {
                newRaw.setHours(hours);
                newRaw.setMinutes(minutes);
              }
              if (!isNaN(newRaw as unknown as number)) {
                onChange(newRaw);
              }
              // случай диапазона из двух дат
            } else if (str?.includes(' - ')) {
              const firstDate = parse(str.substring(0, 10), 'dd.MM.yyyy', new Date(), {
                locale: ru,
              });
              const secondDate = parse(
                str.substring(str.indexOf(' ') + 3) || '',
                'dd.MM.yyyy',
                new Date(),
                {
                  locale: ru,
                }
              );

              handleDateChange([
                isValid(firstDate) ? firstDate : null,
                isValid(secondDate) ? secondDate : null,
              ] as unknown as Date);
              // случай формата дата-время
            } else if (str?.includes(' ')) {
              const firstDateTimeStr = str.substring(0, str.indexOf(' '));
              const firstDate = parse(firstDateTimeStr, 'dd.MM.yyyy HH:mm', new Date(), {
                locale: ru,
              });

              onChange(isValid(firstDate) ? firstDate : null);
            } else {
              newRaw = new Date(str);
              if (!isNaN(newRaw as unknown as number)) {
                onChange(newRaw);
              }
            }
          }}
          customInput={
            <MaskedTextInput
              type="text"
              mask={mask || (showCustomTimeInputOnly ? timeMask : dateMask)}
            />
          }
          renderCustomHeader={(p) => {
            const date = new Date(p.date);
            return (
              <div className="custom-datepicker-header">
                <div className="custom-datepicker-header__label">{`${firstCharToUpperCase(
                  date.toLocaleString('default', { month: 'long' })
                )} 
            ${date.getFullYear()}`}</div>
                <div className="custom-datepicker-header__arrows">
                  <Button
                    className="custom-datepicker-header__button"
                    onClick={() => {
                      p.decreaseMonth();
                    }}
                    icon={<ArrowLeft />}
                  ></Button>
                  <Button
                    className="custom-datepicker-header__button"
                    onClick={() => {
                      p.increaseMonth();
                    }}
                    icon={<ArrowRight />}
                  ></Button>
                </div>
              </div>
            );
          }}
          dateFormat={dateFormat || (showCustomTimeInputOnly ? 'HH:mm' : 'dd.MM.yyyy')}
          placeholderText={showCustomTimeInputOnly ? 'ЧЧ:ММ ' : 'ДД.ММ.ГГГГ'}
          wrapperClassName={cn(
            'custom-datepicker',
            `custom-datepicker--${size}`,
            wrapperClassName,
            rest.disabled && 'custom-datepicker--disabled'
          )}
          popperClassName={cn('custom-datepicker-popper', popperClassName)}
          showIcon
          icon={
            <div className="react-datepicker__calendar-icon">
              {showCustomTimeInputOnly ? (
                <Clock
                  onClick={() => {
                    openDatepicker();
                  }}
                />
              ) : (
                <Calendar
                  onClick={() => {
                    openDatepicker();
                  }}
                />
              )}
            </div>
          }
          {...combinedOptions}
        />
        {error && <span className="custom-datepicker-wrapper__error">{error}</span>}
      </div>
    );
  }
);

Datepicker.displayName = 'Datepicker';

export default Datepicker;

const firstCharToUpperCase: (name: string) => string = (name: string) =>
  name.charAt(0).toUpperCase() + name.slice(1);
