import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';
const AT_LEAST_ONE_COMPANY_MESSAGE = 'Должен быть хотя бы один элемент';

export const TicketSchema = Yup.object().shape({
  title: Yup.string().trim().max(300, 'Ввод более 300 символов запрещен').nullable(),
  text: Yup.string()
    .trim()
    .min(25, 'Минимальная длина сообщения 25 символов')
    .max(1000, 'Ввод более 1000 символов запрещен')
    .required(REQUIRED_MESSAGE),
  companies: Yup.array().min(1, AT_LEAST_ONE_COMPANY_MESSAGE).required(REQUIRED_MESSAGE),
});
