import React from 'react';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { type To, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { CreateCustomerForm } from '../CreateCustomerForm/CreateCustomerForm';

import './CreateCustomerPage.scss';

export const CreateCustomerPage: React.FC = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();
  let prevLink = breadcrumbs.at(-2)?.key as unknown;
  if (!prevLink) prevLink = -1;

  return (
    <DetailLayout title="Добавление компании-заказчика" className="new-company-customer" showFooter>
      {' '}
      <CreateCustomerForm
        onClose={() => {
          navigate(prevLink as To);
        }}
      />
    </DetailLayout>
  );
};
