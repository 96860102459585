export const ROLES = {
  PARTICIPANT: 'participant',
  OPERATOR: 'operator',
  CUSTOMER: 'customer',
};

export const hasOperatorRole = (rolesArray: string[]): boolean => {
  return !!rolesArray?.length && rolesArray.some((item) => item === ROLES.OPERATOR);
};

export const hasCustomerRole = (rolesArray: string[]): boolean => {
  return !!rolesArray?.length && rolesArray.some((item) => item === ROLES.CUSTOMER);
};
export const hasParticipantRole = (rolesArray: string[]): boolean => {
  return !!rolesArray?.length && rolesArray.some((item) => item === ROLES.PARTICIPANT);
};
