import React from 'react';
import cn from 'classnames';
import Datepicker from '@components/Datepicker/Datepicker';
import Button from '@components/Button/Button';

import './CalendarDateTimeContainer.scss';

interface ICalendarDateTimeContainerProps {
  withDivider?: boolean;
  date: string | Date | string | null;
  onSave: (date: string | Date | undefined | null) => void;
  onCancel: () => void;
}
export const CalendarDateTimeContainer: React.FC<ICalendarDateTimeContainerProps> = ({
  withDivider = true,
  date,
  onSave,
  onCancel,
}: ICalendarDateTimeContainerProps) => {
  const [formState, setFormState] = React.useState<string | Date | null | undefined>(date);
  const initialState = React.useRef(date);

  return (
    <div className="custom-calendar-datetime-container">
      <Datepicker
        wrapperClassName="custom-calendar-datetime-container__input"
        selected={formState as Date}
        value={formState as Date}
        minDate={new Date()}
        onChange={setFormState}
        autoComplete="off"
      />
      <Datepicker
        wrapperClassName="custom-calendar-datetime-container__input"
        showCustomTimeInputOnly
        selected={formState as Date}
        value={formState as Date}
        minDate={new Date()}
        onChange={setFormState}
        autoComplete="off"
      />
      <div
        className={cn(
          'custom-calendar-datetime-container__actions',
          withDivider && 'custom-calendar-datetime-container__actions--divider'
        )}
      >
        <Button
          type="text"
          onClick={() => {
            setFormState(initialState.current);
            onCancel();
          }}
        >
          Отменить
        </Button>
        <Button
          type="text"
          onClick={() => {
            onSave(formState);
          }}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default CalendarDateTimeContainer;
