import React from 'react';
import Button from '@components/Button/Button';
import NotFound from '@components/NotFound/NotFound';
import Container from '@components/Container/Container';
import { IRowRenderer, List } from '@components/List/List';
import { ReactComponent as Plus } from '@shared/icons/plus.svg';
import { ReactComponent as EmptyArchive } from '@shared/icons/communicationsPage/empty-archive.svg';
import { IDialog } from '@models/communication.model';
import { ROUTES } from '@routes';
import { ILazyList } from './useLazyList';
import { ListItem } from './ListItem/ListItem';

import './ActiveDialogsList.scss';

export const ActiveDialogsList: React.FC<{
  title: string;
  readonly?: boolean;
  list: ILazyList<IDialog>;
  emptyDialogsList: JSX.Element;
  onStartCreate: () => void;
  path: string;
}> = ({ list, emptyDialogsList, title, onStartCreate, readonly, path }) => {
  const isEmptyActiveDialogs = !list.items?.length;

  const rowRenderer: IRowRenderer = ({ key, index, style }) => {
    return <ListItem key={key} data={list.items[index]} path={ROUTES.COMMUNICATIONS + path} />;
  };

  return (
    <Container direction="vertical" className="active-dialogs">
      <h4 className="title">{title}</h4>
      {isEmptyActiveDialogs ? (
        emptyDialogsList
      ) : (
        <List
          items={list.items}
          loadMoreRows={list.loadMoreRows}
          isRowLoaded={list.isItemLoaded}
          totalCount={list.totalItemsCount}
          rowRenderer={rowRenderer}
          noRowsRenderer={() => (
            <NotFound
              icon={<EmptyArchive />}
              message="Архив пуст"
              description="В вашем архиве пока нет диалогов"
            />
          )}
          wrapperClassName="active-dialogs__list"
        />
      )}
      {!readonly && (
        <div className="add">
          <Button
            type="without-border"
            icon={<Plus />}
            title="Создать диалог"
            onClick={() => {
              onStartCreate();
            }}
          />
        </div>
      )}
    </Container>
  );
};
