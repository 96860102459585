import type React from 'react';
import type * as TableTypes from '../../types';
import Button from '../../../Button/Button';
import Select from '../../../Select/Select';
import { ReactComponent as ArrowLeft } from '@shared/icons/arrow-left-8-16.svg';
import { ReactComponent as ArrowRight } from '@shared/icons/arrow-right-8-16.svg';
import { ReactComponent as ArrowLeftEnd } from '@shared/icons/arrow-left-end.svg';
import { ReactComponent as ArrowRightEnd } from '@shared/icons/arrow-right-end.svg';

import './style.scss';

export const TablePaginationButtons: React.FC<TableTypes.ITablePaginationButtons> = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  gotoPage,
  pageIndex,
  pageCount,
}) => (
  <div className="custom-pagination__buttons">
    <Button
      type="text"
      icon={<ArrowLeftEnd />}
      disabled={pageIndex === 0}
      onClick={() => {
        gotoPage(0);
      }}
    />
    <Button
      type="text"
      disabled={!canPreviousPage}
      icon={<ArrowLeft />}
      onClick={() => {
        previousPage();
      }}
    />
    <Button
      type="text"
      disabled={!canNextPage}
      icon={<ArrowRight />}
      onClick={() => {
        nextPage();
      }}
    />
    <Button
      type="text"
      icon={<ArrowRightEnd />}
      disabled={pageIndex === pageCount - 1}
      onClick={() => {
        gotoPage(pageCount - 1);
      }}
    />
  </div>
);

export const defaultPageSizes = [10, 25, 50, 100];

export const TablePagination: React.FC<TableTypes.TableProps> = ({
  data,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  setPageSize,
  gotoPage,
  pageCount,
  totalCount = data?.length ?? 0,
  state: { pageIndex, pageSize },
  customPageSizes = defaultPageSizes,
}) => {
  const currentPage = pageIndex + 1;
  const firstRowNumber = pageIndex * pageSize + 1;

  const lastRowNumber =
    (pageIndex + 1) * pageSize < totalCount ? (pageIndex + 1) * pageSize : totalCount;

  return (
    <div className="custom-pagination">
      <div className="custom-pagination-show-list">
        <label className="custom-pagination-show-list__label">
          Строк на страницу:
          <Select
            className="custom-pagination-show-list__select"
            value={{ label: pageSize }}
            onChange={(e) => {
              setPageSize(+(e as { label: number }).label);
            }}
            options={customPageSizes.map((option, i) => {
              return { value: i, label: option };
            })}
          />
        </label>
      </div>
      {`Строки ${firstRowNumber}-${lastRowNumber} из ${totalCount}`}
      <TablePaginationButtons
        {...{
          canPreviousPage,
          canNextPage,
          nextPage,
          previousPage,
          setPageSize,
          gotoPage,
          pageCount,
          pageIndex,
          currentPage,
        }}
      />
    </div>
  );
};

export default TablePagination;
