import React, { useState, useEffect } from 'react';
import { ReactComponent as Incoming } from '@shared/icons/communicationsPage/incoming.svg';
import { ReactComponent as Outcoming } from '@shared/icons/communicationsPage/outcoming.svg';
import { ReactComponent as Stats } from '@shared/icons/communicationsPage/stats.svg';
import { formatDateFromUTCtoMSC as formatDate, READABLE_DATETIME_FORMAT } from '@date-time';
import NotFound from '@components/NotFound/NotFound';
import { IDialogsReport, ICompanyStats } from '@models/communication.model';
import { CommunicationService } from '@services/communication/communication.service';

import './DialogsReport.scss';

export const DialogsReport: React.FC = () => {
  const [report, setReport] = useState<IDialogsReport | null>(null);

  const loadData = async (): Promise<void> => {
    const data = await CommunicationService.getDialogsReport();
    setReport(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const summaryData = [
    { label: 'Всего активных диалогов', value: report?.total_active || 0 },
    { label: 'Всего входящих', value: report?.total_active_input || 0 },
    { label: 'Всего исходящих', value: report?.total_active_output || 0 },
    { label: 'Диалогов в архиве', value: report?.total_archived || 0 },
    { label: 'В среднем на контакт', value: report?.avg_by_contact || 'Нет данных' },
  ];

  return (
    <div className="dialogs-report">
      <div className="dialogs-report__summary">
        {summaryData.map((el) => (
          <div className="dialogs-report__summary-cell" key={el.label}>
            <div className="dialogs-report__summary-label">{el.label}</div>
            <div className="dialogs-report__summary-value">{el.value}</div>
          </div>
        ))}
      </div>

      {report?.companies.length ? (
        <>
          <div className="dialogs-report__title">Статистика диалогов по контактам за сутки</div>
          {report?.companies.map((el) => <Row data={el} key={el.id} />)}
        </>
      ) : (
        <div className="dialogs-report__table dialogs-report__table--empty">
          <NotFound
            message="Статистика отсутствует"
            description="У вас пока нет ни одного диалога"
            icon={<Stats />}
          />
        </div>
      )}
    </div>
  );
};

const Row: React.FC<{ data: ICompanyStats }> = ({ data }) => {
  return (
    <div className="dialogs-report__row">
      <div className="company-name dialogs-report__cell dialogs-report__cell--main">
        {data.name}
      </div>
      <div className="last-dialog dialogs-report__cell dialogs-report__cell--label">
        {formatDate(data.last_dialog_date, READABLE_DATETIME_FORMAT)}
      </div>
      <div className="icon-input dialogs-report__cell dialogs-report__cell--icon">
        <div className="dialogs-report__icon dialogs-report__icon--incoming">
          <Incoming />
        </div>
      </div>
      <div className="input-count dialogs-report__cell dialogs-report__cell--main">
        {data.input}
      </div>
      <div className="input-count-label dialogs-report__cell dialogs-report__cell--label">
        Всего входящих
      </div>
      <div className="icon-output dialogs-report__cell dialogs-report__cell--icon">
        <div className="dialogs-report__icon dialogs-report__icon--outcoming">
          <Outcoming />
        </div>
      </div>
      <div className="output-count dialogs-report__cell dialogs-report__cell--main">
        {data.output}
      </div>
      <div className="output-count-label dialogs-report__cell dialogs-report__cell--label">
        Всего исходящих
      </div>
    </div>
  );
};
