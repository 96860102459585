import React, { JSX } from 'react';
import Button from '@components/Button/Button';
import Avatar from '@components/Avatar/Avatar';
import { formatDateFromUTCtoMSC as formatDate, ONLY_DATE_FORMAT } from '@date-time';
import type { ICustomerMainInfoForAdmin } from '../../operator.model';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Block } from '@shared/icons/block.svg';
import { ReactComponent as Activate } from '@shared/icons/activate.svg';
import { CreateCustomerForm } from '../../CreateCustomerForm/CreateCustomerForm';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';

import './MainInfo.scss';

interface IProps {
  data?: ICustomerMainInfoForAdmin;
  onEdit: () => void;
  onBlock: () => void;
  onActivate: () => void;
}

export const MainInfo: React.FC<IProps> = ({ data, onEdit, onBlock, onActivate }: IProps) => {
  if (!data) return null;
  const [isEdited, setEdited] = React.useState(false);

  const Brand = (): JSX.Element => {
    return (
      <div className="customer-detail-info__brand">
        {data.logo_file && <Avatar src={data.logo_file} title="Логотип" />}
        {data.main_color && (
          <Avatar
            backgroundColor={`#${data.main_color}`}
            title={`#${data.main_color}`}
            description="Основной цвет"
          />
        )}
        {data.additional_color && (
          <Avatar
            backgroundColor={`#${data.additional_color}`}
            title={`#${data.additional_color}`}
            description="Дополнительный цвет"
          />
        )}
      </div>
    );
  };

  const dataForBox = [
    [
      {
        label: 'Дата регистрации',
        value: formatDate(data.create_ts, ONLY_DATE_FORMAT),
      },
      {
        label: 'ИНН',
        value: data.inn,
      },
      {
        label: 'Комментарий',
        value: data.comment,
      },
      {
        label: 'Брендирование',
        value: <Brand />,
      },
    ],
  ];
  return (
    <>
      {isEdited ? (
        <CreateCustomerForm
          data={data}
          onClose={() => {
            setEdited(false);
            onEdit();
          }}
        />
      ) : (
        <>
          {' '}
          <InfoBox title={data.name ?? '-'} data={dataForBox} />
          <div className="customer-detail-info__actions">
            <Button
              type="without-border"
              icon={<Edit />}
              onClick={() => {
                setEdited(true);
              }}
            />
            <Button
              type="without-border"
              icon={data.is_blocked ? <Activate /> : <Block />}
              title={data.is_blocked ? 'Активировать' : 'Заблокировать'}
              onClick={() => {
                data.is_blocked ? onActivate() : onBlock();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
