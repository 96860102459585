import type React from 'react';
import cn from 'classnames';
import type * as ReactTable from 'react-table';

import Button from '@components/Button/Button';
import type * as TableTypes from '@components/Table/types';
import { ReactComponent as ExpandIcon } from '@shared/icons/arrow-down.svg';

import './TableCells.scss';

export const ExpandableRow = <T extends TableTypes.CustomRowData>({
  row,
  columnId,
  onExpand,
}: {
  row: ReactTable.Row<T>;
  columnId?: keyof T;
  onExpand?: (row: ReactTable.Row<T>, isExpanded: boolean) => void;
}): JSX.Element => {
  const toggleRowExpandedProps =
    row.getToggleRowExpandedProps() as ReactTable.TableExpandedToggleProps & {
      onClick: (e: React.MouseEvent) => void;
    };

  const handleExpandBtnClick = (e: React.MouseEvent): void => {
    onExpand?.(row, !row.isExpanded);
    toggleRowExpandedProps.onClick(e);
  };

  return (
    <div style={{ paddingLeft: `${row.depth * 16}px` }} className="custom-expand">
      {row.canExpand ? (
        <>
          <Button
            type="text"
            icon={
              <ExpandIcon
                className={cn('custom-expand__arrow', {
                  'custom-expand__arrow--rotate': row.isExpanded,
                })}
              />
            }
            className="custom-expand__button"
            {...toggleRowExpandedProps}
            onClick={handleExpandBtnClick}
            title="Открыть подстроку"
          />
          {
            // @ts-expect-error check types
            columnId && <span>{row.original[columnId]}</span>
          }
        </>
      ) : (
        <>{columnId && row.original[columnId]}</>
      )}
    </div>
  );
};
