import Container from '@components/Container/Container';
import Button from '@components/Button/Button';
import { ReactComponent as Plus } from '@shared/icons/plus.svg';
import Pills, { type ITab } from '@components/Pills/Pills';

import './MyProposals.scss';

interface IProps {
  onAdd?: () => void;
  tabs: ITab[];
  currentTabIndex: number;
}
export const MyProposals = ({
  onAdd,
  tabs,
  currentTabIndex,
}: IProps): JSX.Element => {
  return (
    <Container direction="vertical" className="paricipant-bond-detail__my-proposals">
      <Container.Header className="paricipant-bond-detail__my-proposals-title">
        Мои предложения
      </Container.Header>
      <Pills
        className="paricipant-bond-detail__my-proposals-list"
        tabs={tabs}
        vertical
        currentTab={currentTabIndex}
      />
      {onAdd && (
        <Button
          className="paricipant-bond-detail__add"
          type="without-border"
          icon={<Plus />}
          title="Добавить"
          onClick={() => {
            onAdd();
          }}
        />
      )}
    </Container>
  );
};
