import React, { SyntheticEvent, useState } from 'react';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useCurrentUserData } from '@services/user/userDataContext';
import { useParams } from 'react-router';
import { IDetailTicketInfo, TicketStatuses } from '@models/support.model';
import { HttpClient } from '@api/HttpClient';
import { IMessage } from 'types';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { Chat } from '@shared/components/Chat/Chat';
import { CommentField } from './CommentField/CommentField';
import Button from '@components/Button/Button';
import { formatPhone } from '@functions';
import { formatDateFromUTCtoMSC as formatDate, READABLE_DATETIME_FORMAT } from '@date-time';
import { INamedEntity } from '@models/base.model';
import { IRequestUserInfo } from '@models/user.model';
import { AuthUtil } from '@services/auth/auth.util';
import { TICKETS_STATUSES, TICKET_STATUSES_PARAMS } from '@constants';
import Notification from '@components/Modal/Notification/Notification';

import { ReactComponent as CrossGray } from '@shared/icons/close.svg';
import { ReactComponent as Accept } from '@shared/icons/accept.svg';
import { ReactComponent as Phone } from '@shared/icons/phone.svg';
import { ReactComponent as Email } from '@shared/icons/email.svg';
import { ReactComponent as ArrowRight } from '@shared/icons/arrow-right-8-16.svg';

import './TicketDetailPage.scss';

const EMPTY_TICKET = {
  comment: '',
  create_ts: '',
  creator: {} as IRequestUserInfo,
  creator_company: {} as INamedEntity,
  end_ts: '',
  is_read: false,
  id: 0,
  messages: [],
  status: 'NEW',
  text: '',
  title: '',
  from_operator: false,
  recipient_company: null,
} as IDetailTicketInfo;

interface IPatchTicket {
  comment: string;
  status: TicketStatuses;
}

type WarningStatus = 'DONE' | 'DECLINED';

export const TicketDetailPage: React.FC = () => {
  const { ticketId } = useParams();
  const currentUser = useCurrentUserData();
  const breadcrumbs = useBreadcrumbs();
  const [warningStatus, setWarningForStatus] = useState<WarningStatus | false>(false);
  const [data, setData] = React.useState<IDetailTicketInfo>(EMPTY_TICKET);

  const prevLink = breadcrumbs.at(-2)?.key;

  const prepareMessage = (message: IMessage, data: IDetailTicketInfo): IMessage => {
    const isOperator = currentUser?.id === message.creator_id;
    return {
      ...message,
      author: isOperator
        ? 'Оператор (Вы)'
        : AuthUtil.getFullNameAbbreviation(
            message.creator?.last_name ?? '',
            message.creator?.first_name ?? '',
            message.creator?.patronymic ?? ''
          ),
      isOperator,
    };
  };

  const loadTicket = (): void => {
    HttpClient.get<IDetailTicketInfo>(`operator/tickets/${ticketId}/`).then((res) => {
      const prepared = {
        ...res,
        messages: res.messages.map((message) => prepareMessage(message, res)),
      };
      setData(prepared);
    });
  };

  React.useEffect(() => {
    loadTicket();
  }, []);

  const renderCreator = (): JSX.Element => {
    return (
      <div className="ticket-info__field">
        <span>
          {AuthUtil.getFullNameAbbreviation(
            data.creator?.last_name ?? '',
            data.creator?.first_name ?? '',
            data.creator?.patronymic ?? ''
          )}
        </span>
        {data.creator_company?.name && (
          <span className="ticket-info__field-caption">{data.creator_company?.name}</span>
        )}
      </div>
    );
  };
  const renderRecipient = (): JSX.Element => {
    return (
      <div className="ticket-info__field">
        <span>{data.recipient_company?.name}</span>
      </div>
    );
  };

  const renderContactInfo = (): JSX.Element | string => {
    if (data && !data.creator?.phone && !data.creator_company?.name) return '-';
    return (
      <div className="ticket-info__field">
        {data.creator.phone && (
          <span>
            <Phone />
            {formatPhone(data.creator.phone)}
          </span>
        )}
        {data.creator_company.name && (
          <span>
            <Email />
            {data.creator.email}
          </span>
        )}
      </div>
    );
  };

  const FIELDS = [
    [
      {
        label: 'Дата и время создания',
        value: formatDate(data.create_ts, READABLE_DATETIME_FORMAT),
      },
      {
        label: 'Создал',
        value: renderCreator(),
        hidden: data.from_operator,
      },
      {
        label: 'Контактные данные',
        value: renderContactInfo(),
        hidden: data.from_operator,
      },
      {
        label: 'Получатель',
        value: renderRecipient(),
        hidden: !data.from_operator,
      },
      {
        label: 'Тема',
        value: data.title || '-',
      },
      {
        label: 'Текст обращения',
        value: data.text,
      },
    ],
  ];

  const updateTicket = (patch: Partial<IPatchTicket>): void => {
    HttpClient.patch<IDetailTicketInfo>(`operator/tickets/${ticketId}/`, patch).then((res) => {
      setData({ ...data, ...res });
    });
  };

  const updateComment = (comment: string): void => {
    setData({ ...data, comment });
    updateTicket({ comment });
  };

  const changeStatus =
    (status: TicketStatuses): ((s: SyntheticEvent) => void) =>
    (e: SyntheticEvent): void => {
      if (status === TICKETS_STATUSES.done || status === TICKETS_STATUSES.declined)
        setWarningForStatus(status as WarningStatus);
      else updateTicket({ status });
    };

  const isTicketClosed =
    data.status === TICKETS_STATUSES.declined || data.status === TICKETS_STATUSES.done;

  const getTicketClosedMessage = (): string => {
    if (!isTicketClosed) return '';

    return `Обращение ${
      data.status === TICKETS_STATUSES.declined ? 'отменено' : 'завершено'
    } ${formatDate(data.end_ts, READABLE_DATETIME_FORMAT)}`;
  };

  const sendMessage = async (text: string): Promise<void> => {
    const result = await HttpClient.post<{ text: string }, IMessage>(
      `common/tickets/${ticketId}/messages/`,
      { text }
    );

    setData({ ...data, messages: [prepareMessage(result, data), ...data.messages] });
  };

  const isNewTicket = data.status === TICKETS_STATUSES.new;
  const isProgressTicket = data.status === TICKETS_STATUSES.inProgress;

  return (
    <DetailLayout
      title={'Обращение №' + data.id}
      prevLink={prevLink}
      badgeParams={TICKET_STATUSES_PARAMS[data.status]}
      className="ticket-page"
      showFooter
      mainInfo={
        <div className="ticket-page__content ticket-info">
          <Chat
            messages={data.messages}
            onSendMessage={sendMessage}
            closingMessage={getTicketClosedMessage()}
          />
          <div className="ticket-info__main">
            <InfoBox title="Детали обращения" data={FIELDS} />
            <CommentField text={data.comment} updateComment={updateComment} />
            <div className="ticket-info__action">
              {isNewTicket && (
                <Button
                  onClick={changeStatus(TICKETS_STATUSES.inProgress as TicketStatuses)}
                  icon={<ArrowRight />}
                >
                  В работу
                </Button>
              )}
              {(isNewTicket || isProgressTicket) && (
                <Button
                  onClick={changeStatus(TICKETS_STATUSES.done as TicketStatuses)}
                  icon={<Accept />}
                >
                  Завершить
                </Button>
              )}
              {(isNewTicket || isProgressTicket) && (
                <Button
                  onClick={changeStatus(TICKETS_STATUSES.declined as TicketStatuses)}
                  className="ticket-info__action--decline"
                  icon={<CrossGray />}
                >
                  Отклонить
                </Button>
              )}
            </div>
          </div>
          {warningStatus && (
            <Notification
              isOpen
              title={NOTIFICATION_CONFIG[warningStatus].title}
              message={NOTIFICATION_CONFIG[warningStatus].text}
              onConfirm={() => {
                updateTicket({ status: warningStatus });
                setWarningForStatus(false);
              }}
              onCancel={() => {
                setWarningForStatus(false);
              }}
              confirmBtnText={NOTIFICATION_CONFIG[warningStatus].confirmBtnText}
              cancelBtnText={NOTIFICATION_CONFIG[warningStatus].cancelBtnText}
            />
          )}
        </div>
      }
    />
  );
};

const NOTIFICATION_CONFIG = {
  DONE: {
    title: 'Завершить обращение',
    text: 'Вы уверены, что хотите завершить обращение?',
    confirmBtnText: 'Завершить',
    cancelBtnText: 'Отменить',
  },
  DECLINED: {
    title: 'Отклонить обращение',
    text: 'Вы уверены, что хотите завершить отклонить? Убедитесь, что работа по обращению завершена.',
    confirmBtnText: 'Отклонить',
    cancelBtnText: 'Отмена',
  },
};
