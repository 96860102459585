import { IRecipient } from '@pages/operator/AdminPage/Operators/operator.model';
import Badge from '@components/Badge/Badge';
import {
  PROPOSAL_STATUSES,
  RATE_TYPES,
  REQUEST_STATUSES_PARAMS,
  MEMBER_STATUSES_PARAMS,
  TICKET_STATUSES_PARAMS,
  BOND_FLOATERS_TITLES,
} from '@constants';
import { Status, RateType } from '@models/base.model';
import { AuthUtil } from '@services/auth/auth.util';
import type { TableCell } from './types';
import {
  pluralYear,
  formatDateFromUTCtoMSC as formatDate,
  ONLY_DATE_FORMAT,
  ONLY_TIME_FORMAT,
} from '@date-time';
import { ReactComponent as Cross } from '@shared/icons/close-10-10.svg';
import Button from '@components/Button/Button';
import type * as ReactTable from 'react-table';
import { IRequest } from '@shared/components/Requests/requests.model';
import { IRequestBondModel } from '@pages/participant/BondDetailPage/request-bond.model';

export const renderStatus =
  (key: string) =>
  (data: Record<string, string>): TableCell =>
    Badge({ ...REQUEST_STATUSES_PARAMS[data[key]], size: 'small' });

export const renderMemberStatus = (isBlocked: boolean): TableCell => {
  const status = isBlocked ? 'BLOCKED' : 'ACTIVE';

  return Badge({ ...MEMBER_STATUSES_PARAMS[status], size: 'small' }) as TableCell;
};

export const renderUserStatus = (status: string): TableCell => {
  return Badge({ ...MEMBER_STATUSES_PARAMS[status], size: 'small' }) as TableCell;
};

export const renderTicketStatus = (status: string): TableCell => {
  return Badge({ ...TICKET_STATUSES_PARAMS[status], size: 'small' }) as TableCell;
};

export const renderCreatorCompany = ({
  creatorCompany,
}: {
  creatorCompany: { id: string; name: string };
}): TableCell => {
  return creatorCompany?.name;
};

export const renderName = ({ name }: { name: string }): TableCell => name;

export const renderDate = (key: string) =>
  function renderDateByKey(data: Record<string, string>): TableCell {
    if (data[key] === null) return '-';
    const date = new Date(data[key]);
    return (
      <div className="date-time-cell">
        <div>{formatDate(date, ONLY_DATE_FORMAT)}</div>
        <div className="time">{formatDate(date, ONLY_TIME_FORMAT)}</div>
      </div>
    );
  };

export const renderRecipients = (recipients: IRecipient[]): TableCell =>
  recipients?.length > 0 ? (
    <div className="recipient_block">
      {recipients.map((recipient) => (
        <li className="recipient" key={recipient.id}>
          <span>{recipient?.fio}</span>
          <span className="recipient__email">{recipient?.email}</span>
        </li>
      ))}
    </div>
  ) : (
    '-'
  );

// TODO: убрать лишнее
export const renderMaxAmount = ({ maxAmount }: { maxAmount: number }): TableCell =>
  maxAmount ? <>{(+maxAmount).toLocaleString()} &#8381;</> : '-';

export const renderAmount = ({ amount }: { amount: number | null }): TableCell =>
  amount ? <>{(+amount).toLocaleString()} &#8381;</> : '-';

export const renderMinMaxAmount = ({
  minAmount,
  maxAmount,
}: {
  minAmount: number;
  maxAmount: number;
}): TableCell => (
  <>
    от {renderAmount({ amount: minAmount })}
    <br />
    до {renderAmount({ amount: maxAmount })}
  </>
);

export const renderMaturity = ({
  maturityFrom,
  maturityTo,
}: {
  maturityFrom: number;
  maturityTo: number;
}): TableCell => `${maturityFrom} - ${pluralYear(maturityTo)}`;

export const renderPeriod = ({
  periodType,
  period,
  endDatePeriod,
}: {
  periodType: string;
  period: number;
  endDatePeriod: string;
}): TableCell => {
  if (periodType === 'MONTH') return `${period} мес.`;
  if (periodType === 'YEAR') return pluralYear(period);
  if (periodType === 'DATE') {
    const date = new Date(endDatePeriod);
    return `${formatDate(date, ONLY_DATE_FORMAT)}`;
  }
};

export const renderPeriodLong = ({
  periodType,
  period,
  endDatePeriod,
}: {
  periodType: string;
  period: number;
  endDatePeriod: string;
}): TableCell => {
  if (periodType === 'MONTH')
    return `${period} ${period === 1 ? 'месяц' : period > 1 && period < 5 ? 'месяца' : 'месяцев'}`;
  if (periodType === 'YEAR') return pluralYear(period);
  if (periodType === 'DATE') {
    const date = new Date(endDatePeriod);
    return `${formatDate(date, ONLY_DATE_FORMAT)}`;
  }
};

export const renderRate = ({
  rateType,
  rate,
}: {
  rateType: RateType;
  rate: number | null;
}): string => (rate ? (rateType === 'FLOAT' ? 'КС + ' : '') + '' + rate + '%' : '-');

export const renderRateLong = ({ rateType, rate }: { rateType: RateType; rate: number }): string =>
  rate ? (rateType === 'FLOAT' ? 'Ключевая ставка + ' : '') + '' + rate + '%' : '-';

export const renderRateType = ({ rateType }: { rateType: RateType }): TableCell =>
  RATE_TYPES[rateType];

export const renderProposalStatus = ({ proposalStatus }: { proposalStatus: string }): TableCell => (
  <div
    className={`proposal-status ${proposalStatus === 'CANCELLED' && 'proposal-status--cancelled'}`}
  >
    {PROPOSAL_STATUSES[proposalStatus ?? 'NEW']}
  </div>
);

export const renderProposalsCount = ({ proposalsCount }: { proposalsCount: number }): TableCell =>
  proposalsCount;

export const renderCreatorPerson = ({ creator }: { creator: IUser }): TableCell =>
  AuthUtil.getFullNameAbbreviation(
    creator?.lastName ?? '',
    creator?.firstName ?? '',
    creator?.patronymic ?? ''
  );

export const renderCloseRequest = (
  row: ReactTable.Row<IRequest | IRequestBondModel>,
  closeCallback: (data: IRequest) => void
): TableCell => (
  <Button
    className="requests__icon-close"
    type="text"
    onClick={(e) => {
      e.stopPropagation();
      closeCallback(row.original as IRequest);
    }}
    icon={<Cross />}
    disabled={
      (row.original as IRequest).proposalStatus === Status.Cancelled ||
      (row.original as IRequest).proposalStatus === Status.Sent ||
      (row.original as IRequestBondModel).status === Status.Cancelled ||
      (row.original as IRequestBondModel).status === Status.Sent ||
      (row.original as IRequestBondModel).status === Status.Summarizing ||
      (row.original as IRequestBondModel).status === Status.Completed ||
      (row.original as IRequest).requestStatus === Status.Summarizing ||
      (row.original as IRequest).requestStatus === Status.Completed
    }
  />
);

export const renderCreatorCompanyAndPerson = (data: {
  creator: IUser;
  companyCreator: ICompanyCreator;
}): TableCell => {
  const person = renderCreatorPerson(data);
  return (
    <div className="creator">
      <span>{data.companyCreator.name}</span>
      <span className="creator__name">{person}</span>
    </div>
  );
};

export const renderCoupon = ({
  rate_type: rateType,
  rate,
}: {
  rate_type: string;
  rate: number;
  bonus: number;
}): string => (rateType === 'FIX' ? rate + '%' : `${BOND_FLOATERS_TITLES[rateType]}  = ${rate}%`);

interface IUser {
  firstName?: string;
  id: string;
  lastName?: string;
  patronymic?: string;
}

interface ICompanyCreator {
  id: number;
  name: string;
}

export const renderProposalOffer = ({ put, call }: { put: number; call: number }): string => {
  const offerPut = put ? `PUT (${pluralYear(put)})` : '';
  const offerCall = call ? `CALL (${pluralYear(call)})` : '';
  return offerPut && offerCall ? [offerPut, offerCall].join(', ') : offerPut || offerCall || '';
};
export const renderOfferPutCallFromTo = ({
  put_from: putFrom,
  put_to: putTo,
  call_from: callFrom,
  call_to: callTo,
}: {
  put_from: number | null;
  put_to: number | null;
  call_from: number | null;
  call_to: number | null;
}): string => {
  const offerPut = putFrom || putTo ? `PUT (${putFrom} - ${pluralYear(putTo)})` : '';
  const offerCall = callFrom || callTo ? `CALL (${callFrom} - ${pluralYear(callTo)})` : '';
  const offer =
    offerPut && offerCall ? [offerPut, offerCall].join(', ') : offerPut || offerCall || '';
  return offer;
};

export const formatPhone = (phone?: string): string =>
  phone?.replace('+7', '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5') ??
  '';

export const getFullname = (
  lastName?: string,
  firstName?: string,
  patronymic?: string | null
): string => `${lastName || ''} ${firstName || ''} ${patronymic || ''}`;
