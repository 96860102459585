import React, { JSX } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Loader from '@components/Loader/Loader';
import { GlobalProvider } from '@services/GlobalContext';
import Router from '../router';
import Modal from 'react-modal';
import { KEYCLOAK_CONFIG } from '@services/auth/auth.const';

import { UserDataContextProvider } from '@services/user/userDataContext';
import { HttpClient } from '@api/HttpClient';
import AxiosLoaderProvider from './AxiosLoaderProvider';

import './App.scss';
import './style.scss';

Modal.setAppElement('#root');

function App(): JSX.Element {
  React.useEffect(() => {
    HttpClient.init();
  }, []);

  let savedTokens: Record<string, string>;
  async function clearOtherSessions(): Promise<void> {
    try {
      await HttpClient.get<string>('/common/users/login/');
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="App">
      <ReactKeycloakProvider
        authClient={KEYCLOAK_CONFIG}
        onTokens={(tokens) => {
          savedTokens = tokens;
          HttpClient.setTokens(tokens);
        }}
        onEvent={(event) => {
          if (event === 'onReady') {
            if (savedTokens) {
              clearOtherSessions();
            }
          }
        }}
      >
        <GlobalProvider>
          <AxiosLoaderProvider>
            <UserDataContextProvider>
              <>
                <Router />
                <Loader />
              </>
            </UserDataContextProvider>
          </AxiosLoaderProvider>
        </GlobalProvider>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
