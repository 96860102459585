import { ROUTES } from '@routes';
import { ROLES } from '@roles';
import { TRACKS_ACCESS } from '@constants';

export const menu = [
  {
    title: 'Кредиты',
    path: ROUTES.CREDITS,
    rights: [ROLES.OPERATOR, ROLES.CUSTOMER, ROLES.PARTICIPANT],
    track: TRACKS_ACCESS.CREDIT,
  },
  {
    title: 'Депозиты',
    path: ROUTES.DEPOSITS,
    rights: [ROLES.OPERATOR, ROLES.CUSTOMER, ROLES.PARTICIPANT],
    track: TRACKS_ACCESS.DEPOSIT,
  },
  {
    title: 'Облигации',
    path: ROUTES.BONDS,
    rights: [ROLES.OPERATOR, ROLES.CUSTOMER, ROLES.PARTICIPANT],
    track: TRACKS_ACCESS.BONDS,
  },
  {
    title: 'Коммуникации',
    path: ROUTES.COMMUNICATIONS,
    rights: [ROLES.CUSTOMER, ROLES.PARTICIPANT],
  },
  {
    title: 'Отчётность',
    path: ROUTES.REPORTS,
    rights: [ROLES.CUSTOMER],
  },
  {
    title: 'Администрирование',
    path: ROUTES.ADMINISTRATION,
    rights: [ROLES.OPERATOR],
  },
  {
    title: 'Поддержка',
    path: ROUTES.INBOX + ROUTES.TICKETS,
    rights: [ROLES.OPERATOR],
  },
];
