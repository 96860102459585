import React from 'react';
import cn from 'classnames';
import RangeDatepicker from '@components/RangeDatepicker/RangeDatepicker';
import { ReactComponent as Search } from '@shared/icons/search.svg';
import Select from '@components/Select/Select';
import { Input } from '@components/Input/Input';
import { IDictionary } from '@models/base.model';
import { REQUEST_TYPES_OPTION } from '@constants';

import './Filters.scss';

interface IProps {
  className?: string;
  companions: IDictionary[];
  filterCompanions: IDictionary[];
  setCompanions: (companions: IDictionary[]) => void;
  startDate?: Date;
  endDate?: Date;
  setDateRange: (range: Array<Date | undefined>) => void;
  requestTypes?: IDictionary[];
  setRequestTypes: (types: IDictionary[]) => void;
  searchStr?: string;
  setSearchStr: (str: string) => void;
}

const Filters: React.FC<IProps> = ({
  className,
  companions,
  filterCompanions,
  startDate,
  endDate,
  setDateRange,
  searchStr,
  setSearchStr,
  requestTypes,
  setRequestTypes,
  setCompanions,
}) => {
  return (
    <div className={cn('dialogs-filters', className)}>
      <RangeDatepicker
        placeholderText="Дата и время начала диалога"
        wrapperClassName="dialogs-filters__field"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          // @ts-expect-error check types
          setDateRange(update);
        }}
      />
      <Select
        value={filterCompanions}
        wrapperClassName="dialogs-filters__field"
        options={companions}
        isMulti
        isClearable
        placeholder="Собеседник"
        className="dialogs-filters__addressees"
        onChange={(s: unknown) => {
          setCompanions(s as IDictionary[]);
        }}
        onMenuClose={() => {}}
        onClear={() => {}}
      />
      <Select
        wrapperClassName="dialogs-filters__field"
        options={REQUEST_TYPES_OPTION}
        onChange={(e) => {
          setRequestTypes(e as IDictionary[]);
        }}
        value={requestTypes}
        placeholder="Трек"
        isClearable
        isMulti
      />
      <Input
        icon={<Search />}
        type="text"
        wrapperClassName="dialogs-filters__search"
        value={searchStr || ''}
        placeholder="Поиск по теме"
        onChange={(e: { target: { value: string } }) => {
          setSearchStr(e.target?.value);
        }}
      />
    </div>
  );
};

export default Filters;
