import React from 'react';
import Button from '@components/Button/Button';
import { formatDateFromUTCtoMSC as formatDate, ONLY_DATE_FORMAT } from '@date-time';
import { CreateCompanyParticipantForm } from '@pages/operator/CreateCompanyParticipantForm/CreateCompanyParticipantForm';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Block } from '@shared/icons/block.svg';
import { ReactComponent as Activate } from '@shared/icons/activate.svg';
import type { ICompanyParticipantMainInfoForAdmin } from '../../operator.model';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';

import './MainInfo.scss';

interface IProps {
  data?: ICompanyParticipantMainInfoForAdmin;
  onEdit: () => void;
  onBlock: () => void;
  onActivate: () => void;
}

export const MainInfo: React.FC<IProps> = ({ data, onEdit, onBlock, onActivate }: IProps) => {
  if (!data) return null;
  const [isEdited, setEdited] = React.useState(false);

  const dataForBox = [
    [
      {
        label: 'Дата регистрации',
        value: formatDate(data.create_ts, ONLY_DATE_FORMAT),
      },
      {
        label: 'ИНН',
        value: data.inn,
      },
      {
        label: 'Комментарий',
        value: data.comment,
      },
    ],
  ];

  return (
    <>
      {isEdited ? (
        <CreateCompanyParticipantForm
          data={data}
          onClose={() => {
            setEdited(false);
            onEdit();
          }}
        />
      ) : (
        <InfoBox title={data.name ?? '-'} data={dataForBox} />
      )}

      <div className="company-participant-detail-info__actions">
        <Button
          type="without-border"
          icon={<Edit />}
          onClick={() => {
            setEdited((prevState) => !prevState);
          }}
        />
        <Button
          type="without-border"
          icon={data.is_blocked ? <Activate /> : <Block />}
          title={data.is_blocked ? 'Активировать' : 'Заблокировать'}
          onClick={() => {
            data.is_blocked ? onActivate() : onBlock();
          }}
        />
      </div>
    </>
  );
};
