import React, { useState } from 'react';
import Button from '@components/Button/Button';
import { Input } from '@components/Input/Input';
import { ReactComponent as Plane } from '@shared/icons/communicationsPage/plane.svg';
import CommunicationService from '@services/communication/communication.service';
import { IMessage } from '@models/communication.model';

import './ChatFooter.scss';

const MAX_MESSAGE_LENGTH = 1000;

export const ChatFooter: React.FC<{ dialogId: number; onSend: (newMessage: IMessage) => void }> = (
  props
) => {
  const [text, setText] = useState('');
  const [error, setError] = useState('');

  const sendMessage = async (): Promise<void> => {
    const newMessage = await CommunicationService.createMessage({
      communication_dialog_id: props.dialogId,
      text,
    });
    props.onSend(newMessage);
  };

  const validate = (): boolean => {
    setError('');
    if (text.length === 0) {
      setError('Введите сообщение');
      return false;
    }
    if (text.length >= MAX_MESSAGE_LENGTH) {
      setError(`Максимальная длина ${MAX_MESSAGE_LENGTH} символов`);
      return false;
    }
    return true;
  };

  const handleSend = (): void => {
    if (validate()) {
      sendMessage();
      setText('');
    }
  };

  return (
    <div className="chat-footer dialogs__send">
      <Input
        size="large"
        value={text}
        placeholder="Напишите Ваше сообщение"
        wrapperClassName="chat-footer__message"
        onChange={(e) => {
          if (error) setError('');
          setText(e.target?.value);
        }}
        // @ts-expect-error check types
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
        error={error}
      />
      <Button
        onClick={() => {
          handleSend();
        }}
        icon={<Plane />}
        size="large"
      />
    </div>
  );
};
