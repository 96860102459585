import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import type ReactDatepicker from 'react-datepicker';
import { HttpClient } from '@api/HttpClient';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { DATEPICKER_DATETIME_FORMAT, formatDateFromMSCtoUTC, getMSCNow } from '@date-time';
import { BOND_FLOATERS_TITLES, TITLES, CONFIG_NAMES } from '@constants';
import { amountMask, dateMask, timeMask } from '@masks';
import Container from '@components/Container/Container';
import { Input } from '@components/Input/Input';
import Datepicker from '@components/Datepicker/Datepicker';
import Select from '@components/Select/Select';
import Checkbox from '@components/Checkbox/Checkbox';
import RadioGroup from '@components/RadioGroup';
import RadioButton from '@components/RadioGroup/RadioButton';
import Button from '@components/Button/Button';
import CalendarDateTimeContainer from '@components/CalendarDateTimeContainer/CalendarDateTimeContainer';
import { Hint } from '@components/Hint/Hint';
import { IDictionary, RateType, SpreadType } from '@models/base.model';
import { CreateBondRequestSchema } from './CreateBondRequestPage.validation';
import type { ICreateBondRequestBody, IFormControllers } from './bond-request.model';
import AutoCheckForm from '@shared/components/AutoCheckForm';
import ModalCheckParticipants from '../ModalCheckParticipants/ModalCheckParticipants';

import './CreateBondRequestPage.scss';

export const CreateBondRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const dateStartRef = React.useRef<ReactDatepicker>(null);
  const dateEndRef = React.useRef<ReactDatepicker>(null);
  const putFromRef = React.useRef<Input>(null);
  const putToRef = React.useRef<Input>(null);
  const callFromRef = React.useRef<Input>(null);
  const callToRef = React.useRef<Input>(null);
  const [isConfirmationCompleteOpen, setConfirmationCompleteOpen] = React.useState<boolean>(false);

  const initialDataFormControllers: IFormControllers = {
    put_checkbox: false,
    call_checkbox: false,
    floater_type: null,
  };

  const initialData: ICreateBondRequestBody & IFormControllers = {
    start_ts: getMSCNow(),
    name: '',
    max_amount: 1000000,
    min_amount: 1,
    comment_int: '',
    comment_ext: '',
    maturity_from: null,
    maturity_to: null,
    put_from: null,
    put_to: null,
    call_from: null,
    call_to: null,
    rate_type: RateType.Fix,
    spread_type: null,
    ...initialDataFormControllers,
    ...state,
    end_ts: state?.end_ts || getMSCNow(),
    ...((state?.put_from || state?.put_to) && { put_checkbox: true }),
    ...((state?.call_from || state?.call_to) && { call_checkbox: true }),
    ...(state?.rate_type &&
      state.rate_type !== RateType.Fix && {
        floater_type: { value: state.rate_type, label: BOND_FLOATERS_TITLES[state.rate_type] },
      }),
    has_amortization: false,
  };

  const goToBack = (): void => {
    navigate(pathname.replace('/create', ''));
  };

  const handleSubmit = (
    values: ICreateBondRequestBody & IFormControllers,
    participants: number[]
  ): void => {
    const body: ICreateBondRequestBody = {
      ...values,
      start_ts: formatDateFromMSCtoUTC(new Date(values.start_ts)) || '',
      end_ts: formatDateFromMSCtoUTC(new Date(values.end_ts)) || '',
      participants,
      // @ts-expect-error eslint-disable-next-line
      rate_type:
        values.rate_type !== RateType.Fix && values.floater_type
          ? values.floater_type.value
          : RateType.Fix,
      spread_type:
        values.rate_type === RateType.Float &&
        values.floater_type?.value === 'OFZ' &&
        values.spread_type === null
          ? SpreadType.Profit
          : values.spread_type,
    };
    HttpClient.post<ICreateBondRequestBody>('customer/bond/requests/', body).then(goToBack);
  };

  return (
    <DetailLayout
      title="Новый запрос"
      className="new-customer-bond-request"
      badgeParams={{
        title: TITLES.bond,
        backgroundColor: 'var(--gray-4-color)',
        color: 'var(--primary-text-color)',
        border: 'var(--container-border)',
      }}
      showFooter={false}
    >
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        validationSchema={CreateBondRequestSchema}
        initialValues={initialData}
        onSubmit={() => {}}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setValues,
          errors,
          touched,
          validateForm,
        }) => {
          return (
            <Form>
              <Container className="new-customer-bond-request__details-box">
                <div className="new-customer-bond-request__details">
                  <h4 className="new-customer-bond-request__block-title">Основные сведения</h4>
                  <Input
                    name="name"
                    size="large"
                    label="Название запроса*"
                    placeholder="Напишите название"
                    wrapperClassName="new-customer-bond-request__input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={(touched.name && errors.name) as string}
                  />
                  <Input
                    name="comment_ext"
                    size="large"
                    label="Комментарий (виден всем)"
                    placeholder="Напишите Ваш комментарий"
                    wrapperClassName="new-customer-bond-request__input"
                    onChange={handleChange}
                    value={values.comment_ext}
                    error={(touched.comment_ext && errors.comment_ext) as string}
                  />
                  <Input
                    name="comment_int"
                    size="large"
                    label="Комментарий (виден только вашей организации)"
                    placeholder="Напишите Ваш комментарий"
                    wrapperClassName="new-customer-bond-request__input"
                    onChange={handleChange}
                    value={values.comment_int}
                    error={(touched.comment_int && errors.comment_int) as string}
                  />
                  <div className="new-customer-bond-request__row">
                    <Datepicker
                      // @ts-expect-error Property 'ref' does not exist
                      ref={dateStartRef}
                      name="start_ts"
                      label="Дата и время начала"
                      size="large"
                      placeholderText="Укажите дату и время"
                      dateFormat={DATEPICKER_DATETIME_FORMAT}
                      mask={[...dateMask, ' ', ...timeMask]}
                      value={values.start_ts as unknown as Date}
                      customWrapperClassName="new-customer-bond-request__input"
                      selected={values.start_ts as unknown as Date}
                      error={(touched.start_ts && errors.start_ts) as string}
                      disabled
                      autoComplete="off"
                      calendarContainer={() => {
                        return (
                          <CalendarDateTimeContainer
                            date={values.start_ts}
                            onCancel={() => {
                              dateStartRef.current?.setOpen(false);
                            }}
                            onSave={(date: string | Date | undefined | null) => {
                              setFieldValue('start_ts', date);
                              dateStartRef.current?.setOpen(false);
                            }}
                          />
                        );
                      }}
                      onChange={(date) => {
                        setFieldValue('start_ts', date);
                      }}
                      onBlur={handleBlur}
                    />
                    <Datepicker
                      // @ts-expect-error Property 'ref' does not exist
                      ref={dateEndRef}
                      name="end_ts"
                      label="Дата и время закрытия*"
                      size="large"
                      placeholderText="Укажите дату и время"
                      dateFormat={DATEPICKER_DATETIME_FORMAT}
                      isClearable
                      mask={[...dateMask, ' ', ...timeMask]}
                      minDate={getMSCNow()}
                      value={values.end_ts as unknown as Date}
                      customWrapperClassName="new-customer-bond-request__input"
                      selected={values.end_ts as unknown as Date}
                      error={(touched.end_ts && errors.end_ts) as string}
                      autoComplete="off"
                      calendarContainer={() => {
                        return (
                          <CalendarDateTimeContainer
                            date={values.end_ts}
                            onCancel={() => {
                              dateEndRef.current?.setOpen(false);
                            }}
                            onSave={(date: string | Date | undefined | null) => {
                              setFieldValue('end_ts', date);
                              dateEndRef.current?.setOpen(false);
                            }}
                          />
                        );
                      }}
                      onChange={(e) => {
                        setFieldValue('end_ts', e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>

                  <h4 className="new-customer-bond-request__block-title new-customer-bond-request__block-title-parameters">
                    Параметры облигации
                  </h4>
                  <div className="new-customer-bond-request__row">
                    <Input
                      name="min_amount"
                      size="large"
                      label="Мин. объём выпуска*"
                      wrapperClassName="new-customer-bond-request__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.min_amount as unknown as string}
                      error={touched.min_amount ? (errors.min_amount as string) : undefined}
                      mask={amountMask}
                    />
                    <Input
                      name="max_amount"
                      size="large"
                      label="Макс. объём выпуска*"
                      wrapperClassName="new-customer-bond-request__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.max_amount as unknown as string}
                      error={touched.max_amount ? (errors.max_amount as string) : undefined}
                      mask={amountMask}
                    />
                  </div>
                  <div className="new-customer-bond-request__row">
                    <label className="new-customer-bond-request__label">
                      Срок облигации до погашения*:
                    </label>
                    <Input
                      type="number"
                      name="maturity_from"
                      size="large"
                      wrapperClassName="new-customer-bond-request__input"
                      placeholder="От, лет"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.maturity_from as unknown as string}
                      error={touched.maturity_from ? (errors.maturity_from as string) : undefined}
                    />
                    <Input
                      type="number"
                      name="maturity_to"
                      size="large"
                      wrapperClassName="new-customer-bond-request__input"
                      placeholder="До, лет"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.maturity_to as unknown as string}
                      error={touched.maturity_to ? (errors.maturity_to as string) : undefined}
                    />
                  </div>
                  <div className="new-customer-bond-request__row">
                    <Checkbox
                      name="put_checkbox"
                      label="PUT-оферта"
                      labelClassName="new-customer-bond-request__label"
                      onChange={() => {
                        putFromRef.current?.clearInput();
                        putToRef.current?.clearInput();
                        setValues((prevState) => ({
                          ...prevState,
                          put_checkbox: !prevState.put_checkbox,
                          put_from: null,
                          put_to: null,
                        }));
                      }}
                      checked={values.put_checkbox}
                    />
                    <Input
                      ref={putFromRef}
                      disabled={!values.put_checkbox}
                      type="number"
                      name="put_from"
                      size="large"
                      placeholder="Мин срок, лет"
                      wrapperClassName="new-customer-bond-request__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.put_from as unknown as string}
                      error={touched.put_from ? (errors.put_from as string) : undefined}
                    />
                    <Input
                      ref={putToRef}
                      disabled={!values.put_checkbox}
                      type="number"
                      name="put_to"
                      size="large"
                      placeholder="Макс срок, лет"
                      wrapperClassName="new-customer-bond-request__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.put_to as unknown as string}
                      error={touched.put_to ? (errors.put_to as string) : undefined}
                    />
                  </div>
                  <div className="new-customer-bond-request__row">
                    <Checkbox
                      name="call_checkbox"
                      label="CALL-оферта"
                      labelClassName="new-customer-bond-request__label"
                      onChange={() => {
                        callFromRef.current?.clearInput();
                        callToRef.current?.clearInput();
                        setValues((prevState) => ({
                          ...prevState,
                          call_checkbox: !prevState.call_checkbox,
                          call_from: null,
                          call_to: null,
                        }));
                      }}
                      checked={values.call_checkbox}
                    />
                    <Input
                      ref={callFromRef}
                      disabled={!values.call_checkbox}
                      type="number"
                      name="call_from"
                      size="large"
                      placeholder="Мин срок, лет"
                      wrapperClassName="new-customer-bond-request__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.call_from as unknown as string}
                      error={touched.call_from ? (errors.call_from as string) : undefined}
                    />
                    <Input
                      ref={callToRef}
                      disabled={!values.call_checkbox}
                      type="number"
                      name="call_to"
                      size="large"
                      placeholder="Макс срок, лет"
                      wrapperClassName="new-customer-bond-request__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.call_to as unknown as string}
                      error={touched.call_to ? (errors.call_to as string) : undefined}
                    />
                  </div>
                  <RadioGroup
                    label="Тип облигации:"
                    mode="native"
                    name="rate_type"
                    labelClassName="new-customer-bond-request__label-radio"
                    wrapperClassName="new-customer-bond-request__row"
                    className="new-customer-bond-request__row"
                    onChange={(value) => {
                      setFieldValue('floater_type', null);
                      setFieldValue('rate_type', value);
                    }}
                    value={values.rate_type}
                    error={(touched.rate_type && errors.rate_type) as string}
                  >
                    <RadioButton value="FIX" id="FIX">
                      Фиксированная ставка
                    </RadioButton>
                    <RadioButton
                      value="FLOAT"
                      id="FLOAT"
                      checked={values.rate_type !== RateType.Fix}
                    >
                      Флоатер
                    </RadioButton>
                  </RadioGroup>

                  <div className="new-customer-bond-request__row">
                    <Select
                      placeholder="Выберите тип плавающей ставки"
                      size="large"
                      name="floater_type"
                      label="Базис"
                      wrapperClassName="new-customer-bond-request__input"
                      options={basisOptions}
                      value={values.floater_type}
                      error={
                        ((touched.rate_type || touched.floater_type) &&
                          errors.floater_type) as string
                      }
                      onChange={(value) => {
                        setFieldValue('floater_type', value);
                      }}
                      isDisabled={values.rate_type === 'FIX'}
                    />
                  </div>

                  <div className="new-customer-bond-request__actual-hint">
                    Данные указываются вручную и являются актуальными только на момент подачи
                    предложения.
                  </div>
                  {values.rate_type === RateType.Float && values.floater_type?.value === 'OFZ' ? (
                    <RadioGroup
                      label="Спред к кривой ОФЗ:"
                      mode="native"
                      name="spread_type"
                      wrapperClassName="new-customer-bond-request__row"
                      className="new-customer-bond-request__row"
                      onChange={(value) => {
                        setFieldValue('spread_type', value);
                      }}
                      value={values.spread_type}
                      error={(touched.spread_type && errors.spread_type) as string}
                    >
                      <RadioButton
                        value={SpreadType.Profit}
                        id={SpreadType.Profit}
                        checked={values.spread_type !== SpreadType.Rate}
                      >
                        По доходности
                      </RadioButton>
                      <RadioButton value={SpreadType.Rate} id={SpreadType.Rate}>
                        По ставке
                      </RadioButton>
                    </RadioGroup>
                  ) : null}
                  <Checkbox
                    name="has_amortization"
                    checked={values.has_amortization}
                    onChange={(e) => {
                      setFieldValue('has_amortization', !values.has_amortization);
                    }}
                    label="Амортизация"
                  />
                  <div className="new-customer-bond-request__required-hint">
                    * Обязательные для заполнения поля
                  </div>
                </div>

                <Hint config={CONFIG_NAMES.BOND} className="new-customer-bond-request__hint" />
              </Container>

              <div className="new-customer-bond-request__actions">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={async () => {
                    const validate = await validateForm();
                    if (Object.keys(validate).length === 0) {
                      setConfirmationCompleteOpen(true);
                    }
                  }}
                  size="large"
                >
                  Опубликовать
                </Button>
                <Button
                  onClick={() => {
                    goToBack();
                  }}
                  type="warning"
                  size="large"
                >
                  Отменить
                </Button>
              </div>
              <AutoCheckForm
                title="Прервать создание запроса"
                message="Вы уверены, что хотите прервать создание запроса? Все данные будут утеряны."
                onConfirm={() => {
                  goToBack();
                }}
                confirmBtnText="Прервать"
                cancelBtnText="Отмена"
              />

              {isConfirmationCompleteOpen && (
                <ModalCheckParticipants
                  isOpen
                  type="bond"
                  onConfirm={(participants) => {
                    handleSubmit(values, participants);
                    setConfirmationCompleteOpen(false);
                  }}
                  onCancel={() => {
                    setConfirmationCompleteOpen(false);
                  }}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </DetailLayout>
  );
};

const basisOptions: IDictionary[] = Object.keys(BOND_FLOATERS_TITLES).map((value) => ({
  value,
  label: BOND_FLOATERS_TITLES[value],
}));
