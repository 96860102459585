import React from 'react';
import cn from 'classnames';
import { HttpClient } from '@api/HttpClient';
import { Formik, Form } from 'formik';
import Button from '@components/Button/Button';
import Container from '@components/Container/Container';
import FileLoader from '@components/ImageLoader/ImageLoader';
import { Input } from '@components/Input/Input';
import { hexMask } from '@masks';
import SimilarCompaniesChecker from '../components/SimilarCompaniesChecker/SimilarCompaniesChecker';
import type { ICreateCustomerForAdmin } from '../operator.model';
import { CreateCustomerSchema } from './CreateCustomerForm.validation';
import AutoCheckForm from '@shared/components/AutoCheckForm';

import './CreateCustomerForm.scss';

interface IProps {
  data?: ICreateCustomerForAdmin & { id?: number };
  onClose: () => void;
}
export const CreateCustomerForm: React.FC<IProps> = ({ data, onClose }) => {
  const isNew = !data;

  const [selectedInn, setSelectedInn] = React.useState<string>();

  const initialData: ICreateCustomerForAdmin = {
    name: '',
    inn: '',
    comment: '',
    logo_file: '',
    main_color: '',
    additional_color: '',
    ...data,
  };

  const handleSaveCustomer = (values: ICreateCustomerForAdmin): void => {
    const body: ICreateCustomerForAdmin = {
      ...values,
    };

    (isNew
      ? HttpClient.post('/operator/companies/customer/', body)
      : HttpClient.patch(`/operator/companies/customer/${data.id}/`, body)
    ).then(() => {
      onClose();
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        initialValues={initialData}
        validationSchema={CreateCustomerSchema}
        onSubmit={(values) => {
          if (initialData.inn !== values.inn) {
            setSelectedInn(values.inn);
          } else {
            handleSaveCustomer(values);
          }
        }}
      >
        {({ values, handleChange, handleBlur, setFieldValue, errors, touched, dirty, isValid }) => (
          <>
            <Form>
              <Container
                direction="vertical"
                className={cn(
                  'new-customer__details-box',
                  !isNew && 'new-customer__details-box--edited'
                )}
              >
                <div className="new-customer__row">
                  <div className="new-customer__col">
                    <Container.Header className="new-customer__block-title">
                      Основные сведения
                    </Container.Header>
                    <Input
                      name="name"
                      size="large"
                      label="Название*"
                      placeholder="Укажите краткое название компании"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={touched.name ? (errors.name as string) : undefined}
                    />
                    <Input
                      name="inn"
                      size="large"
                      label="ИНН*"
                      placeholder="Укажите ИНН"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.inn}
                      error={touched.inn ? (errors.inn as string) : undefined}
                    />
                    <Input
                      name="comment"
                      size="large"
                      label="Комментарий"
                      placeholder="Укажите Ваш комментарий"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                      error={touched.comment ? (errors.comment as string) : undefined}
                    />
                  </div>
                  <div className="new-customer__col">
                    <Container.Header className="new-customer__block-title">
                      Брендирование
                    </Container.Header>
                    <FileLoader
                      value={values.logo_file}
                      className="new-customer__logo"
                      label="Логотип компании (SVG или PNG):"
                      name="logo_file"
                      onLoad={(str) => {
                        setFieldValue('logo_file', str);
                      }}
                      error={touched.logo_file ? (errors.logo_file as string) : undefined}
                    />
                    <Input
                      name="main_color"
                      size="large"
                      label="Основной цвет (HEX-код)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      mask={hexMask}
                      value={values.main_color}
                      error={touched.main_color ? (errors.main_color as string) : undefined}
                    />
                    <Input
                      name="additional_color"
                      size="large"
                      label="Дополнительный цвет (HEX-код)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      mask={hexMask}
                      value={values.additional_color}
                      error={
                        touched.additional_color ? (errors.additional_color as string) : undefined
                      }
                    />
                  </div>
                </div>
                <div className="new-customer__required-hint">
                  * Обязательные для заполнения поля
                </div>
              </Container>

              <div className="new-customer__actions">
                <Button
                  type="primary"
                  htmlType="submit"
                  size={isNew ? 'large' : 'medium'}
                  className="new-customer__action-button"
                  disabled={!dirty}
                >
                  {isNew ? 'Добавить компанию' : 'Сохранить'}
                </Button>
                <Button
                  size={isNew ? 'large' : 'medium'}
                  type={isNew ? 'warning' : undefined}
                  className="new-customer__action-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Отменить
                </Button>
              </div>
              <AutoCheckForm
                title={`Прервать ${isNew ? 'создание' : 'редактирование'} компании`}
                message={`Вы уверены, что хотите прервать ${
                  isNew ? 'создание' : 'редактирование'
                } компании? Все данные будут утеряны.`}
                onConfirm={() => {
                  onClose();
                }}
                confirmBtnText="Прервать"
                cancelBtnText="Отмена"
              />
            </Form>

            <SimilarCompaniesChecker
              inn={selectedInn}
              onSubmit={() => {
                handleSaveCustomer(values);
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
};
