import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { HttpClient } from '@api/HttpClient';
import RangeDatepicker from '@components/RangeDatepicker/RangeDatepicker';
import { ReactComponent as Search } from '@shared/icons/search.svg';
import Select from '@components/Select/Select';
import { Input } from '@components/Input/Input';
import { IDictionary, IDictionaryCompany } from '@models/base.model';
import { TICKET_STATUSES_PARAMS } from '@constants';
import { CallbackFunctionVariadic } from 'types';

import './Filters.scss';

export interface IFiltersProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  setDateRange: CallbackFunctionVariadic;
  statuses: IDictionary[];
  setStatuses: CallbackFunctionVariadic;
  companies: IDictionaryCompany[];
  setCompanies: CallbackFunctionVariadic;
  ticketId: string;
  setTicketId: CallbackFunctionVariadic;
}

const Filters: React.FC<IFiltersProps> = ({
  className,
  startDate,
  endDate,
  setDateRange,
  statuses,
  setStatuses,
  ticketId,
  setTicketId,
  companies,
  setCompanies,
}) => {
  const [companyOptions, setCompanyOptions] = useState<IDictionaryCompany[]>([]);
  const [_statuses, _setStatuses] = React.useState<IDictionary[]>(statuses);
  const [_companies, _setCompanies] = React.useState<IDictionaryCompany[]>(companies);

  useEffect(() => {
    HttpClient.post<string[]>('operator/companies/by_types', [
      'CUSTOMER',
      'PARTICIPANT',
      'OPERATOR',
    ]).then((values) => {
      setCompanyOptions(values as unknown as IDictionaryCompany[]);
    });
  }, []);

  return (
    <div className={cn('tickets-filters', className)}>
      <RangeDatepicker
        wrapperClassName="tickets-filters__range"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
      />
      <Select
        options={TICKETS_STATUS_OPTIONS}
        isMulti
        isClearable
        placeholder="Выберите статус"
        value={_statuses}
        className="tickets-filters__statuses"
        onChange={(s: unknown) => {
          _setStatuses(s as IDictionary[]);
        }}
        onMenuClose={() => {
          setStatuses(_statuses);
        }}
        onClear={() => {
          setStatuses([]);
        }}
      />
      <Select
        options={companyOptions}
        isMulti
        isClearable
        placeholder="Выберите компанию"
        value={_companies.map((item) => ({ ...item, label: item.name, value: item.id }))}
        getOptionLabel={(item) => (item as IDictionaryCompany).name}
        getOptionValue={(item) => (item as IDictionaryCompany).id}
        className="tickets-filters__companies"
        onChange={(s: unknown) => {
          _setCompanies(s as IDictionaryCompany[]);
        }}
        onMenuClose={() => {
          setCompanies(_companies);
        }}
        onClear={() => {
          setCompanies([]);
        }}
      />
      <Input
        icon={<Search />}
        type="number"
        className="tickets-filters__request-id"
        value={ticketId}
        placeholder="Полный № обращения"
        onChange={(e: { target: { value: unknown } }) => {
          setTicketId(e.target.value);
        }}
      />
    </div>
  );
};

export default Filters;

const TICKETS_STATUS_OPTIONS: IDictionary[] = Object.keys(TICKET_STATUSES_PARAMS).map((key) => ({
  label: TICKET_STATUSES_PARAMS[key].title,
  value: key,
}));
