import type React from 'react';
import cn from 'classnames';
import Navbar from '@components/Navbar/Navbar';
import Footer from '@components/Footer/Footer';

import './PageLayout.scss';

interface IPageLayout {
  className?: string;
  siteHeader?: React.ReactNode;
  showFooter?: boolean;
  children?: React.ReactNode;
}

const PageLayout: React.FC<IPageLayout> = ({
  className,
  children,
  siteHeader,
  showFooter = true,
}) => {
  return (
    <>
      <Navbar>{siteHeader}</Navbar>
      <div className="scrollable" id="scrollable">
        <main className={cn('page-layout container-fluid flex-grow-1 px-4', className)}>
          <div className="page-content">{children}</div>
        </main>

        {showFooter && <Footer />}
      </div>
    </>
  );
};

export default PageLayout;
