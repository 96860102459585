import React from 'react';
import { useParams } from 'react-router-dom';
import { HttpClient } from '@api/HttpClient';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { TICKET_STATUSES_PARAMS } from '@constants';
import { formatDateFromUTCtoMSC as formatDate, READABLE_DATETIME_FORMAT } from '@date-time';
import Container from '@components/Container/Container';
import { useCurrentUserData } from '@services/user/userDataContext';
import { ITicketForUser } from '@models/support.model';
import { Chat } from '@shared/components/Chat/Chat';
import { IMessage } from '../../../../types';
import { hasOperatorRole } from '@roles';

import './UserTicketDetailPage.scss';

export const UserTicketDetailPage: React.FC = () => {
  const { ticketId } = useParams();
  const initialData = {} as ITicketForUser;
  const currentUser = useCurrentUserData();

  const [data, setData] = React.useState<ITicketForUser>(initialData);

  const loadTicket = async (): Promise<void> => {
    const res = await HttpClient.get<ITicketForUser>(`common/tickets/${ticketId}/`);

    const prepared = {
      ...res,
      messages: res.messages?.map((el) => ({
        ...el,
        isOperator: currentUser?.id !== el.creator_id && !hasOperatorRole(currentUser?.roles ?? []),
      })),
    };

    setData(prepared);

    if (!res.is_read) {
      HttpClient.patch(`common/tickets/${ticketId}/read/`).then(() => {
        currentUser?.updateOperatorMessagesCount();
      });
    }
  };

  React.useEffect(() => {
    loadTicket();
  }, [ticketId]);

  const sendMessage = async (text: string): Promise<void> => {
    const result = await HttpClient.post<{ text: string }, IMessage>(
      `common/tickets/${ticketId}/messages/`,
      { text }
    );

    setData({ ...data, messages: [result, ...data.messages] });
  };

  const closingMessage =
    data.status === 'DECLINED'
      ? `Обращение отменено ${formatDate(data.end_ts, READABLE_DATETIME_FORMAT)}`
      : data.status === 'DONE'
      ? `Обращение завершено ${formatDate(data.end_ts, READABLE_DATETIME_FORMAT)}`
      : undefined;

  return (
    <DetailLayout
      title={`Обращение №${data.id}`}
      className="user-ticket-detail"
      badgeParams={TICKET_STATUSES_PARAMS[data.status]}
      showFooter
      mainInfo={
        <div className="user-ticket-detail__row">
          <Chat
            className="user-ticket-detail__chat"
            messages={data.messages}
            onSendMessage={sendMessage}
            noMessagesText="К этому обращению пока не оставлено ни одного комментария"
            closingMessage={closingMessage}
          />
          <div className="user-ticket-detail__col">
            <Container.Header className="user-ticket-detail__title">
              Детали обращения
            </Container.Header>
            <Container.Field
              label="Дата и время создания"
              value={formatDate(data.create_ts, READABLE_DATETIME_FORMAT) || '-'}
            />
            <Container.Field label="Тема" value={data.title || '-'} />
            {/** TODO: обрезка сообщения */}
            <Container.Field label="Текст обращения" value={data.text || '-'} />
          </div>
        </div>
      }
    />
  );
};
