import { ROLES } from '@roles';
export interface IUserInfo {
  id: string;
  date: string;
  company: {
    id: number;
    name: string;
    inn: string;
    isBlocked: boolean;
    additionalColor: string | null;
    mainColor: string | null;
    logoFile: string | null;
    type: string;
    createTs: string;
  };
  email: string;
  firstName: string;
  lastName: string;
  logoFile: string | null;
  patronymic: string;
  position: string | null;
  phone: string;
  requestTypes: string[];
  roles: string[];
  status: string;
  favoriteRequestType: string | null;
  rulesConfirmTs: string;
}

export interface IRequestUserInfo {
  id: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  phone: string;
  email: string;
  position: string;
  status: string;
  company_role: keyof typeof ROLES;
  company_name: string;
}

export interface IPatchUserInfo {
  last_name: string;
  first_name: string;
  patronymic: string;
  position: string;
  favorite_request_type: string;
}

export enum UserStatus {
  DELETED = 'DELETED',
  BLOCKED_OPERATOR = 'BLOCKED_OPERATOR',
  BLOCKED_COMPANY = 'BLOCKED_COMPANY',
  ACTIVE = 'ACTIVE',
}
