import React from 'react';
import cn from 'classnames';
import RangeDatepicker from '@components/RangeDatepicker/RangeDatepicker';
import { ReactComponent as Search } from '@shared/icons/search.svg';
import { HttpClient } from '@api/HttpClient';
import Select from '@components/Select/Select';
import { Input } from '@components/Input/Input';
import { CallbackFunctionVariadic } from 'types';
import { IDictionary, IDictionaryCompany } from '@models/base.model';
import { STATUSES } from '@constants';

import './Filters.scss';

const options = Object.keys(STATUSES).map((key) => ({
  value: key,
  label: STATUSES[key as keyof typeof STATUSES],
}));

export interface IFiltersProps {
  className?: string;
  startDate: Date;
  endDate: Date;
  setDateRange: CallbackFunctionVariadic;
  statuses: IDictionary[];
  setStatuses: CallbackFunctionVariadic;
  companies: IDictionaryCompany[];
  setCompanies: CallbackFunctionVariadic;
  searchStr: string;
  setSearchStr: CallbackFunctionVariadic;
}

const Filters: React.FC<IFiltersProps> = ({
  className,
  startDate,
  endDate,
  setDateRange,
  statuses,
  setStatuses,
  companies,
  setCompanies,
  searchStr,
  setSearchStr,
}) => {
  const [companiesOptions, setCompaniesOptions] = React.useState<IDictionaryCompany[]>([]);
  const [_statuses, _setStatuses] = React.useState<IDictionary[]>(statuses);
  const [_companies, _setCompanies] = React.useState<IDictionaryCompany[]>(companies);

  React.useEffect(() => {
    HttpClient.get<IDictionaryCompany[]>('operator/companies/type/CUSTOMER/').then(
      setCompaniesOptions
    );
  }, []);

  return (
    <div className={cn('operator-requests-filters', className)}>
      <RangeDatepicker
        wrapperClassName="operator-requests-filters__range"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
      />
      <Select
        options={options}
        isMulti
        placeholder="Выберите статусы"
        value={_statuses}
        className="operator-requests-filters__statuses"
        onChange={(s: unknown) => {
          _setStatuses(s as IDictionary[]);
        }}
        onMenuClose={() => {
          setStatuses(_statuses);
        }}
        isClearable
        onClear={() => {
          setStatuses([]);
        }}
      />
      <Select
        options={companiesOptions}
        isMulti
        placeholder="Выберите компании"
        value={_companies.map((item) => ({ ...item, label: item.name, value: item.id }))}
        getOptionLabel={(item) => (item as IDictionaryCompany).name}
        getOptionValue={(item) => (item as IDictionaryCompany).id}
        className="operator-requests-filters__companies"
        onChange={(s: unknown) => {
          _setCompanies(s as IDictionaryCompany[]);
        }}
        onMenuClose={() => {
          setCompanies(_companies);
        }}
        isClearable
        onClear={() => {
          setCompanies([]);
        }}
      />
      <Input
        type="number"
        icon={<Search />}
        className="operator-requests-filters__request-id"
        value={searchStr}
        placeholder="Полный ID запроса"
        onChange={(e: { target: { value: unknown } }) => {
          setSearchStr(e.target?.value);
        }}
      />
    </div>
  );
};

export default Filters;
