import { useGlobalContext } from '@hooks';

import './Loader.scss';

const Loader = (): JSX.Element => {
  const { showLoader } = useGlobalContext();

  return (
    <>
      {showLoader && (
        <div className="loader-overlay">
          <div className="custom-loader">
            <svg viewBox="25 25 50 50" className="circular">
              <circle
                strokeMiterlimit="10"
                strokeWidth="3"
                fill="none"
                r="20"
                cy="50"
                cx="50"
                className="path"
              ></circle>
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
