import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import type ReactDatepicker from 'react-datepicker';
import { HttpClient } from '@api/HttpClient';
import { Formik, Form, FieldArray } from 'formik';
import { setNumberOrEmpty } from '@utils';
import { TITLES, PERIOD_TYPES, CONFIG_NAMES } from '@constants';
import {
  DATEPICKER_DATETIME_FORMAT,
  DATE_FNS_ONLY_DATE_FORMAT,
  formatDateFromMSCtoUTC,
  formatLocaleDate,
  getMSCNow,
} from '@date-time';
import { amountMask, dateMask, timeMask } from '@masks';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Button from '@components/Button/Button';
import Datepicker from '@components/Datepicker/Datepicker';
import { Input } from '@components/Input/Input';
import Select from '@components/Select/Select';
import RadioGroup from '@components/RadioGroup';
import RadioButton from '@components/RadioGroup/RadioButton';
import Checkbox from '@components/Checkbox/Checkbox';
import Container from '@components/Container/Container';
import { Hint } from '@components/Hint/Hint';
import CalendarDateTimeContainer from '@components/CalendarDateTimeContainer/CalendarDateTimeContainer';
import { PositionRow } from './PositionRow/PositionRow';
import type { ICreateRequestBody, IPosition } from './request.model';
import { RequestSchema } from './CreateRequestPage.validation';
import AutoCheckForm from '@shared/components/AutoCheckForm';
import { IFormControllers } from '../CreateBondRequestPage/bond-request.model';
import ModalCheckParticipants from '../ModalCheckParticipants/ModalCheckParticipants';

import './CreateRequestPage.scss';

export function CreateRequestPage(): JSX.Element {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const requestType = pathname.includes('/deposit')
    ? 'deposit'
    : pathname.includes('/bond')
    ? 'bond'
    : 'credit';

  const path = `/${requestType}s`;
  const dateStartRef = React.useRef<ReactDatepicker>(null);
  const dateEndRef = React.useRef<ReactDatepicker>(null);
  const [isConfirmationCompleteOpen, setConfirmationCompleteOpen] = React.useState<boolean>(false);

  const initialData = {
    name: '',
    comment_ext: '',
    comment_int: '',
    start_ts: getMSCNow(),
    max_amount: '',
    rate_type: 'BOTH',
    hide_for_participants: true,
    positions: [] as IPosition[],
    ...state,
    end_ts: state?.end_ts || getMSCNow(),
    ...(state?.positions && {
      positions: state.positions.map(
        (position: { period_type: string; end_date_period?: string }) => ({
          ...position,
          ...(position.end_date_period && { end_date_period: new Date(position.end_date_period) }),
          period_type: { value: position.period_type, label: PERIOD_TYPES[position.period_type] },
        })
      ),
    }),
  };

  const handleSubmit = (
    values: ICreateRequestBody & IFormControllers,
    participants: number[]
  ): void => {
    const bodyForRequest = {
      ...values,
      start_ts: formatDateFromMSCtoUTC(new Date(values.start_ts)),
      end_ts: formatDateFromMSCtoUTC(new Date(values.end_ts)),
      participants,
      max_amount: setNumberOrEmpty(values.max_amount),
      positions: values.positions.map((el: IPosition) => ({
        end_date_period: el.end_date_period
          ? formatLocaleDate(new Date(el.end_date_period), DATE_FNS_ONLY_DATE_FORMAT)
          : undefined,
        period_type: el.period_type?.value,
        amount: setNumberOrEmpty(el.amount),
        period: setNumberOrEmpty(el.period),
      })),
    };
    HttpClient.post(`/customer/${requestType}/requests/`, bodyForRequest).then(() => {
      navigate(path);
    });
  };

  return (
    <DetailLayout
      title="Новый запрос"
      className="new-request"
      badgeParams={{
        title: TITLES[requestType],
        backgroundColor: 'var(--gray-4-color)',
        color: 'var(--primary-text-color)',
        border: 'var(--container-border)',
      }}
      showFooter={false}
    >
      <Formik
        enableReinitialize={false}
        validateOnBlur
        validateOnChange
        validationSchema={RequestSchema}
        initialValues={initialData}
        onSubmit={() => {}}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setTouched,
          errors,
          dirty,
          touched,
          validateForm,
        }) => {
          return (
            <Form>
              <Container direction="vertical" className="new-request__details-box">
                <div className="new-request__details">
                  <h4 className="new-request__block-title">Детали запроса</h4>
                  <Input
                    name="name"
                    size="large"
                    label="Название запроса*"
                    placeholder="Напишите название"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={(touched.name && errors.name) as string}
                  />
                  <Input
                    name="comment_ext"
                    size="large"
                    label="Комментарий (виден всем)"
                    placeholder="Напишите Ваш комментарий"
                    onChange={handleChange}
                    value={values.comment_ext}
                    error={(touched.comment_ext && errors.comment_ext) as string}
                  />
                  <Input
                    name="comment_int"
                    size="large"
                    label="Комментарий (виден только вашей организации)"
                    placeholder="Напишите Ваш комментарий"
                    onChange={handleChange}
                    value={values.comment_int}
                    error={(touched.comment_int && errors.comment_int) as string}
                  />
                  <div className="new-request__row">
                    <Datepicker
                      // @ts-expect-error Property 'ref' does not exist
                      ref={dateStartRef}
                      name="start_ts"
                      label="Дата и время начала"
                      size="large"
                      dateFormat={DATEPICKER_DATETIME_FORMAT}
                      mask={[...dateMask, ' ', ...timeMask]}
                      placeholderText="Укажите дату и время"
                      value={values.start_ts as unknown as Date}
                      customWrapperClassName="new-request__date"
                      selected={values.start_ts as unknown as Date}
                      error={(touched.start_ts && errors.start_ts) as string}
                      disabled
                      autoComplete="off"
                      calendarContainer={() => {
                        return (
                          <CalendarDateTimeContainer
                            date={values.start_ts}
                            onCancel={() => {
                              dateStartRef.current?.setOpen(false);
                            }}
                            onSave={(date: string | Date | undefined | null) => {
                              setFieldValue('start_ts', date);
                              dateStartRef.current?.setOpen(false);
                            }}
                          />
                        );
                      }}
                      onChange={(date) => {
                        setFieldValue('start_ts', date);
                      }}
                      onBlur={handleBlur}
                    />
                    <Datepicker
                      // @ts-expect-error Property 'ref' does not exist
                      ref={dateEndRef}
                      name="end_ts"
                      label="Дата и время закрытия*"
                      size="large"
                      placeholderText="Укажите дату и время"
                      dateFormat={DATEPICKER_DATETIME_FORMAT}
                      mask={[...dateMask, ' ', ...timeMask]}
                      minDate={new Date()}
                      value={values.end_ts as unknown as Date}
                      customWrapperClassName="new-request__date"
                      selected={values.end_ts as unknown as Date}
                      error={(touched.end_ts && errors.end_ts) as string}
                      autoComplete="off"
                      isClearable
                      calendarContainer={() => {
                        return (
                          <CalendarDateTimeContainer
                            date={values.end_ts}
                            onCancel={() => {
                              dateEndRef.current?.setOpen(false);
                            }}
                            onSave={(e: string | Date | undefined | null) => {
                              setFieldValue('end_ts', e);
                              dateEndRef.current?.setOpen(false);
                            }}
                          />
                        );
                      }}
                      onChange={(e) => {
                        setFieldValue('end_ts', e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <RadioGroup
                      label="Тип ставки:"
                      mode="native"
                      name="rate_type"
                      wrapperClassName="new-request__rate-type"
                      className="new-request__rate-type-inputs"
                      onChange={(e) => {
                        setFieldValue('rate_type', e);
                      }}
                      value={values.rate_type}
                      error={(touched.rate_type && errors.rate_type) as string}
                    >
                      <RadioButton value="FIX" id="FIX">
                        Фиксированная
                      </RadioButton>
                      <RadioButton value="FLOAT" id="FLOAT">
                        Плавающая
                      </RadioButton>
                      <RadioButton value="BOTH" id="BOTH">
                        Обе
                      </RadioButton>
                    </RadioGroup>
                  </div>
                  <div className="new-request__row">
                    <Input
                      name="max_amount"
                      mask={amountMask}
                      size="large"
                      onChange={(e) => {
                        setFieldValue('max_amount', setNumberOrEmpty(e.target.value));
                      }}
                      onBlur={handleBlur}
                      value={values.max_amount}
                      wrapperClassName="new-request__amount"
                      label="Максимальный объем по запросу*"
                      error={(touched.max_amount && errors.max_amount) as string}
                    />
                    <Select
                      options={[
                        {
                          value: 1,
                          label: 'Рубли',
                        },
                      ]}
                      name="currency"
                      value={{
                        value: 1,
                        label: 'Рубли',
                      }}
                      size="large"
                      onChange={() => {}}
                      isDisabled
                      label="Валюта"
                      placeholder="Выберите валюту"
                      wrapperClassName="new-request__currency"
                    />
                  </div>
                  <Checkbox
                    name="hide_for_participants"
                    label="Скрыть список предложений для участников"
                    onChange={() => {
                      setFieldValue('hide_for_participants', !values.hide_for_participants);
                    }}
                    checked={values.hide_for_participants}
                  />
                  <Checkbox
                    name="prohibition_rate_increase"
                    label={
                      requestType === 'credit'
                        ? 'Установить запрет увеличения ставки'
                        : 'Установить запрет уменьшения ставки'
                    }
                    onChange={() => {
                      setFieldValue('prohibition_rate_increase', !values.prohibition_rate_increase);
                    }}
                    checked={values.prohibition_rate_increase}
                  />
                  <div className="new-request__required-hint">
                    * Обязательные для заполнения поля
                  </div>
                </div>
                <Hint config={CONFIG_NAMES.REQUEST} className="new-request__hint" />
              </Container>
              <Container direction="vertical" className="new-request__positions-box">
                <FieldArray
                  name="positions"
                  render={(arrayHelpers) => {
                    const addPosition = (): void => {
                      arrayHelpers.push({
                        period_type: '',
                        period: 0,
                        end_date_period: undefined,
                        amount: values.max_amount,
                        key: uuidv4(),
                      });
                    };
                    const remove = (index: number): void => {
                      arrayHelpers.remove(index);
                    };
                    const addSomePositions = (): void => {
                      arrayHelpers.push({
                        period_type: { label: 'Месяцы', value: 'MONTH' },
                        period: 3,
                        end_date_period: undefined,
                        amount: values.max_amount,
                        key: uuidv4(),
                      });
                      arrayHelpers.push({
                        period_type: { label: 'Месяцы', value: 'MONTH' },
                        period: 6,
                        end_date_period: undefined,
                        amount: values.max_amount,
                        key: uuidv4(),
                      });
                      arrayHelpers.push({
                        period_type: { label: 'Месяцы', value: 'MONTH' },
                        period: 9,
                        end_date_period: undefined,
                        amount: values.max_amount,
                        key: uuidv4(),
                      });
                      arrayHelpers.push({
                        period_type: { label: 'Месяцы', value: 'MONTH' },
                        period: 12,
                        end_date_period: undefined,
                        amount: values.max_amount,
                        key: uuidv4(),
                      });
                    };
                    const changePosition = async (
                      index: number,
                      field: string,
                      value: unknown
                    ): Promise<void> => {
                      await setFieldValue(`positions.${index}.${field}`, value);
                    };
                    return (
                      <>
                        <div className="new-request__positions-actions">
                          <h4 className="new-request__block-title">Позиции запроса</h4>
                          <Button onClick={addPosition}>Добавить позицию</Button>
                          <Button onClick={addSomePositions}>
                            Добавить позиции на 3, 6, 9 и 12 мес.
                          </Button>
                        </div>
                        {values.positions?.some(
                          (position: IPosition) => position?.period_type?.value === 'DATE'
                        ) && (
                          <span className="new-request__positions-date-warning">
                            При выборе типа срока &quot;Дата&quot; необходимо учитывать часовые
                            пояса участников
                          </span>
                        )}
                        {!Array.isArray(errors.positions) && (
                          <span className="new-request__positions-error">
                            {(touched.positions && errors.positions) as string}
                          </span>
                        )}
                        <ol
                          className="new-request__positions-list"
                          onClick={() => {
                            setTouched({ ...touched, positions: true });
                          }}
                        >
                          {values.positions?.map((el: IPosition & { key: string }, i: number) => (
                            <PositionRow
                              key={el.key}
                              index={i}
                              position={el}
                              onRemove={remove}
                              onChange={changePosition}
                              onBlur={handleBlur}
                              error={
                                touched.positions && Array.isArray(errors.positions)
                                  ? (errors.positions[i] as Record<string, string>)
                                  : undefined
                              }
                            />
                          ))}
                        </ol>
                      </>
                    );
                  }}
                />
              </Container>
              <div className="new-request__actions">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={async () => {
                    const validate = await validateForm();
                    if (Object.keys(validate).length === 0) {
                      setConfirmationCompleteOpen(true);
                    }
                  }}
                >
                  Опубликовать
                </Button>
                <Button
                  onClick={() => {
                    navigate(path);
                  }}
                >
                  Отменить
                </Button>
              </div>
              <AutoCheckForm
                title="Прервать создание запроса"
                message="Вы уверены, что хотите прервать создание запроса? Все данные будут утеряны."
                onConfirm={() => {
                  navigate(path);
                }}
                confirmBtnText="Прервать"
                cancelBtnText="Отмена"
              />

              {isConfirmationCompleteOpen && (
                <ModalCheckParticipants
                  isOpen
                  type={requestType}
                  onConfirm={(participants) => {
                    handleSubmit(values, participants);
                    setConfirmationCompleteOpen(false);
                  }}
                  onCancel={() => {
                    setConfirmationCompleteOpen(false);
                  }}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </DetailLayout>
  );
}
