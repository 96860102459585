export class AuthUtil {
  static defaultMapFn(word: string, index: number): string {
    const fullName = word.charAt(0).toUpperCase() + word.slice(1);
    return index ? `${fullName.substring(0, 1)}.` : fullName;
  }

  static getFullNameAbbreviation(
    lastName: string,
    name: string,
    patronymic: string,
    separator = ' ',
    mapFn = AuthUtil.defaultMapFn
  ): string {
    return [lastName, name, patronymic]
      .filter((item) => !!item)
      .map(mapFn)
      .join(separator);
  }
}
