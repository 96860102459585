import type React from 'react';
import { ReactComponent as PoweredIcon } from '@shared/icons/powered.svg';

import './Footer.scss';

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="navbar custom-footer text-center text-lg-start bg-light text-muted px-3 py-3">
      <div className="container-fluid text-center text-md-start">
        <div className="custom-footer-content">
          <div className="custom-footer-copyright text-center">{`© ${year} Finsage`}</div>
          <div className="custom-footer-notification">Система работает по Московскому времени</div>
          <div className="custom-footer-links d-flex ">
            <a
              href={process.env.REACT_APP_PERSONAL_DATA_PROCESSING_POLICY}
              target="_blank"
              rel="noreferrer"
            >
              Политика обработки персональных данных
            </a>

            <a href={process.env.REACT_APP_TERMS_OF_USE} target="_blank" rel="noreferrer">
              Условия использования
            </a>
          </div>
        </div>
        <div style={{ width: '114px', height: '38px' }}>
          <PoweredIcon />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
