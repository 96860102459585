import PageLayout from '@layouts/PageLayout/PageLayout';
import Tabs from '@components/Tabs/Tabs';
import React, { type JSX } from 'react';
import type { ITab } from '../../../../types';
import { ROUTES } from '@routes';
import Customers from './Customers/Customers';
import CompaniesParticipants from './CompaniesParticipants/CompaniesParticipants';
import Log from './Log/Log';
import Users from './Users/Users';
import Operators from './Operators/Operators';

import './AdminPage.scss';
import LogsService from '@services/logs/logs.service';
import { ROUTE_LOGS } from '@services/logs/logs.const';

export function AdminPage(): JSX.Element {

  React.useEffect(() => {
    LogsService.saveLogs(ROUTE_LOGS.ADMIN);
  }, []);

  return (
    <PageLayout className="admin-page">
      <Tabs tabs={getTabs()} />
    </PageLayout>
  );
}

const getTabs = (): ITab[] => {
  return [
    {
      title: 'Заказчики',
      component: <Customers />,
      path: ROUTES.ADMINISTRATION + ROUTES.CUSTOMERS,
    },
    {
      title: 'Участники',
      component: <CompaniesParticipants />,
      path: ROUTES.ADMINISTRATION + ROUTES.PARTICIPANTS,
    },
    {
      title: 'Операторы',
      component: <Operators />,
      path: ROUTES.ADMINISTRATION + ROUTES.OPERATORS,
    },
    {
      title: 'Пользователи',
      component: <Users />,
      path: ROUTES.ADMINISTRATION + ROUTES.USERS,
    },
    {
      title: 'Журнал',
      component: <Log />,
      path: ROUTES.ADMINISTRATION + ROUTES.LOG,
    },
    {
      title: 'Сообщения',
      component: <>Сообщения</>,
      path: ROUTES.ADMINISTRATION + ROUTES.TICKETS,
      disabled: true,
    },
    {
      title: 'Настройки',
      component: <>Настройки</>,
      path: ROUTES.ADMINISTRATION + ROUTES.SETTINGS,
      disabled: true,
    },
  ];
};
