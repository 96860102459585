import React from 'react';
import Container from '@components/Container/Container';
import type * as ReactTable from 'react-table';
import { useParams } from 'react-router-dom';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import { cloneDeep } from '@utils';
import { MEMBER_STATUSES_PARAMS } from '@constants';
import Badge from '@components/Badge/Badge';
import type * as TableTypes from '@components/Table/types';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Plus } from '@shared/icons/plus.svg';
import { ReactComponent as IconTrash } from '@shared/icons/trash.svg';
import {
  requestTypeIndexByCode,
  renderPermissionCheckbox,
  PERMISSIONS,
} from '@pages/operator/utils';
import type {
  IRequestType,
  ICustomerOfParticipantUserForAdmin,
  IUserPermissions,
} from '../../operator.model';
import { IDictionary } from '@models/base.model';
import OperatorService from '@services/operator/operator.service';
import AddCompanyModal from '../../components/AddCompanyModal/AddCompanyModal';

import './Rights.scss';

interface IProps {
  participantId: string;
  data: ICustomerOfParticipantUserForAdmin[];
  onEdit: (data: IUserPermissions[]) => void;
}
export const Rights: React.FC<IProps> = ({
  data = [],
  onEdit = () => {},
  participantId,
}: IProps) => {
  const getInitialData = (): ICustomerOfParticipantUserForAdmin[] => cloneDeep(data);

  const { userId } = useParams();
  const [isEdited, setEdited] = React.useState(false);
  const [editedData, setEditedData] = React.useState<ICustomerOfParticipantUserForAdmin[]>([]);
  const [customers, setCustomers] = React.useState<IDictionary[]>([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  React.useEffect(() => {
    setEditedData(getInitialData());
  }, [data]);

  React.useEffect(() => {
    if (isOpenModal) loadCustomers();
  }, [isOpenModal]);

  const loadCustomers = async (): Promise<void> => {
    try {
      const existedCustomersIds = data.map((el) => el.id);

      const responseData = await OperatorService.getParticipantCustomers(participantId);
      setCustomers(
        responseData
          ?.filter((el) => !existedCustomersIds.includes(+el.id))
          ?.map((el) => ({ label: el.name, value: el.id }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleSwitchEdit = (): void => {
    if (isEdited) {
      // Отмена редактирования без сохранения
      setEditedData(getInitialData());
    }

    setEdited((prevState) => !prevState);
  };

  const preparePermissions = (
    dataToEdit: ICustomerOfParticipantUserForAdmin[]
  ): IUserPermissions[] => {
    const dataForUpdatePermission: IUserPermissions[] = [];

    dataToEdit.forEach((company) => {
      company.request_types.forEach((type) => {
        dataForUpdatePermission.push({
          customer_company_id: company.id,
          user_id: userId || '',
          request_type: type.code,
          is_active: type.is_active,
        });
      });
    });
    return dataForUpdatePermission;
  };

  const handleSave = (): void => {
    if (!userId) return;
    const dataForUpdatePermission = preparePermissions(editedData);
    isEdited && onEdit(dataForUpdatePermission);
    setEdited(false);
  };

  const handleCancel = (): void => {
    setEditedData(getInitialData());
    setEdited(false);
  };

  const handleChangePermission = (
    customerIndex: number,
    code: IRequestType['code'],
    newVal: boolean
  ): void => {
    if (!isEdited) return;
    const columnIndex = requestTypeIndexByCode(editedData[customerIndex].request_types, code);
    editedData[customerIndex].request_types[columnIndex].is_active = newVal;

    setEditedData([...editedData]);
  };

  const handleRemoveRow = (rowId: number): void => {
    setEditedData(editedData.filter((row) => row.id !== rowId));
  };

  const handleAddRow = (row: ICustomerOfParticipantUserForAdmin): void => {
    const dataForUpdatePermission = preparePermissions([...editedData, row]);
    onEdit(dataForUpdatePermission);
  };

  const columns: TableTypes.Column[] = React.useMemo(
    () =>
      [
        {
          Header: 'Название',
          accessor: (row: ICustomerOfParticipantUserForAdmin) => {
            return (
              <div className="customer-companies-table__member">
                <div className="customer-companies-table__name">{row.name}</div>
                <div className="customer-companies-table__inn">{row.inn}</div>
                {row.is_blocked && <Badge {...MEMBER_STATUSES_PARAMS.BLOCKED} size="tiny" />}
              </div>
            );
          },
          width: 200,
        },
        {
          Header: 'Кредиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.CREDIT, isEdited),
          width: 80,
          align: 'center',
        },
        {
          Header: 'Депозиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.DEPOSIT, isEdited),
          width: 80,
          align: 'center',
        },
        {
          Header: 'Облигации',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.BOND, isEdited),
          width: 80,
          align: 'center',
        },
        {
          id: 'remove',
          Cell: ({ row }: { row: ReactTable.Row<ICustomerOfParticipantUserForAdmin> }) => (
            <Button
              type="text"
              onClick={() => {
                handleRemoveRow(row.original.id);
              }}
              className="customer-detail-participants-table__remove-button"
              icon={<IconTrash />}
              disabled={!isEdited}
            />
          ),
          width: 300,
          align: 'right',
        },
      ] as TableTypes.Column[],
    [editedData, isEdited]
  );

  return (
    <>
      <Container.Header className="user-detail-table__title">Права доступа</Container.Header>
      <Table
        data={editedData}
        columns={columns}
        withAlternating
        className="user-detail-table__grid"
      />
      <div className="user-detail-table__actions">
        {(!isEdited && (
          <Button
            type="without-border"
            icon={<Edit />}
            title="Редактировать"
            onClick={handleSwitchEdit}
          />
        )) ||
          null}
        <Button
          type="without-border"
          icon={<Plus />}
          title="Добавить заказчика"
          onClick={() => {
            setIsOpenModal(true);
          }}
        />
      </div>

      {isEdited && (
        <div className="user-detail-table__edit-actions">
          <Button type="primary" onClick={handleSave}>
            Сохранить
          </Button>
          <Button type="warning" onClick={handleCancel}>
            Отменить
          </Button>
        </div>
      )}
      {isOpenModal && (
        <AddCompanyModal
          title="Добавить заказчика"
          description="Выберите компанию и укажите начальные права доступа к трекам."
          selectLabel="Компания-заказчик"
          companies={customers}
          onSubmit={(company: ICustomerOfParticipantUserForAdmin) => {
            handleAddRow(company);
            setIsOpenModal(false);
          }}
          onCancel={() => {
            setIsOpenModal(false);
          }}
        />
      )}
    </>
  );
};
