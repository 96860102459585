import React from 'react';
import { renderAmount, renderProposalOffer, renderCoupon } from '@functions';
import { pluralYear } from '@date-time';
import { ReactComponent as IconChat } from '@shared/icons/chat.svg';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import Container from '@components/Container/Container';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Backward } from '@shared/icons/backward.svg';
import Button from '@components/Button/Button';

import type { IProposalForBond } from '../request-bond.model';

import './ProposalDetail.scss';

interface IProps {
  data?: IProposalForBond;
  onEdit: () => void;
  onCancel: () => void;
  onCommunicate: () => Promise<void>;
  canEditMyProposal: boolean;
}

export const ProposalDetail: React.FC<IProps> = ({
  data,
  onEdit,
  onCancel,
  onCommunicate,
  canEditMyProposal,
}: IProps) => {
  if (!data) return null;

  const dataForBox = [
    [
      {
        label: 'Объем выпуска',
        value: renderAmount(data),
      },
      {
        label: 'Срок облигации до погашения',
        value: pluralYear(data.maturity),
      },
      {
        label: 'Оферта',
        value: renderProposalOffer(data),
      },
      {
        label: 'Величина спреда',
        value: `${data.spread_value}‱`,
        hidden: data.spread_value === null,
      },
      {
        label: 'Купон',
        value: (
          <div>
            <div>{renderCoupon(data)}</div>
            <div className="participant-bond-detail__hint">На момент подачи предложения</div>
          </div>
        ),
      },
      {
        label: 'Купонный период',
        value: `${data.coupon_periodicity} дней`,
      },
    ],
    [
      {
        label: 'Амортизация',
        value: data.amortization_details,
        hidden: data.amortization_details === null,
      },
      {
        label: 'Комментарий',
        value: data.comment,
      },
    ],
  ];

  return (
    <Container className="participant-bond-detail__current-proposal">
      <InfoBox title="Детали предложения" data={dataForBox} />
      {canEditMyProposal && (
        <div className="participant-bond-detail__nav-actions">
          <Button
            icon={<Edit />}
            type="without-border"
            onClick={() => {
              onEdit();
            }}
          />
          <Button
            icon={<Backward />}
            type="without-border"
            onClick={() => {
              onCancel();
            }}
          />
          <Button
            type="text"
            icon={<IconChat />}
            onClick={() => {
              onCommunicate();
            }}
          />
        </div>
      )}
    </Container>
  );
};
