import React from 'react';
import Container from '@components/Container/Container';
import type * as ReactTable from 'react-table';
import Button from '@components/Button/Button';
import Table, { ExpandableRow } from '@components/Table';
import { cloneDeep } from '@utils';
import { getFullname } from '@functions';
import { MEMBER_STATUSES_PARAMS } from '@constants';
import Badge from '@components/Badge/Badge';
import type * as TableTypes from '@components/Table/types';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import {
  requestTypeIndexByCode,
  renderPermissionCheckbox,
  PERMISSIONS,
} from '@pages/operator/utils';
import type { ICustomerCompanyForAdmin, IRequestType } from '../../operator.model';

import './CustomersTable.scss';

interface IProps {
  data?: ICustomerCompanyForAdmin[];
  onEdit: (data: ICustomerCompanyForAdmin[]) => void;
}

export const CustomersTable: React.FC<IProps> = ({ data = [], onEdit }: IProps) => {
  const getInitialData = (): ICustomerCompanyForAdmin[] => addSubRows(cloneDeep(data));
  const [isEdited, setEdited] = React.useState(false);
  const [editedData, setEditedData] = React.useState<ICustomerCompanyForAdmin[]>([]);

  React.useEffect(() => {
    setEditedData(getInitialData());
  }, [data]);

  const handleChangePermission = (
    customerIndex: number,
    code: IRequestType['code'],
    newVal: boolean
  ): void => {
    if (!isEdited) return;
    const columnIndex = requestTypeIndexByCode(editedData[customerIndex].request_types, code);
    editedData[customerIndex].request_types[columnIndex].is_active = newVal;

    setEditedData([...editedData]);
  };

  const handleSwitchEdit = (): void => {
    if (isEdited) {
      // Отмена редактирования без сохр
      setEditedData(getInitialData());
    }

    setEdited((prevState) => !prevState);
  };

  const handleSave = (): void => {
    isEdited && onEdit(editedData);
    setEdited(false);
  };

  const handleCancel = (): void => {
    setEditedData(getInitialData());
    setEdited(false);
  };

  const columns: TableTypes.Column[] = React.useMemo(
    () =>
      [
        {
          Header: 'Название',
          accessor: ({ name, inn, is_blocked: isBlocked }: ICustomerCompanyForAdmin) => {
            return (
              <div className="customer-companies-table__member">
                <div className="customer-companies-table__name">{name}</div>
                <div className="customer-companies-table__inn">{inn}</div>
                {isBlocked && <Badge {...MEMBER_STATUSES_PARAMS.BLOCKED} size="tiny" />}
              </div>
            );
          },
          width: 200,
        },
        {
          Header: 'К-во участников',
          accessor: 'users_count',
          width: 128,
          align: 'right',
        },
        {
          Header: 'Кредиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.CREDIT, isEdited),
          width: 80,
          align: 'center',
        },
        {
          Header: 'Депозиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.DEPOSIT, isEdited),
          width: 80,
          align: 'center',
        },
        {
          Header: 'Облигации',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.BOND, isEdited),
          width: 80,
          align: 'center',
        },
        {
          id: 'id',
          Cell: ({ row }: { row: ReactTable.Row }) => {
            return <ExpandableRow row={row} />;
          },
          width: 300,
          align: 'right',
        },
      ] as TableTypes.Column[],
    [editedData, isEdited]
  );

  return (
    <Container direction="vertical" className="customer-companies-table">
      <Container.Header className="customer-companies-table__header">
        Компании-заказчики
      </Container.Header>
      <Table
        data={editedData}
        columns={columns}
        withAlternating
        className="customer-companies-table__grid"
      />
      <div className="customer-companies-table__actions">
        {(editedData?.length && (
          <Button
            type="without-border"
            icon={<Edit />}
            title={isEdited ? 'Отменить' : 'Редактировать'}
            onClick={handleSwitchEdit}
          />
        )) ||
          null}
      </div>

      {isEdited && (
        <div className="customer-companies-table__edit-actions">
          <Button type="primary" onClick={handleSave}>
            Сохранить
          </Button>
          <Button type="warning" onClick={handleCancel}>
            Отменить
          </Button>
        </div>
      )}
    </Container>
  );
};

const addSubRows = (
  customerCompaniesData: ICustomerCompanyForAdmin[]
): ICustomerCompanyForAdmin[] =>
  customerCompaniesData.map((el) => ({
    ...el,
    ...(el.users?.length && {
      subRows: [
        {
          render: () => (
            <div key={el.id} className="custom-tbody__tr customer-companies-table-expandable-row">
              <div className="customer-companies-table-expandable-row__title">Заказчики</div>
              {el.users.map((user, index) => (
                <div className="customer-companies-table-expandable-row__user" key={index}>
                  {getFullname(user.last_name, user.first_name, user.patronymic)}
                  {user.status === 'DELETED' ? ' (Удален)' : ''}
                </div>
              ))}
            </div>
          ),
        },
      ],
    }),
  }));
