/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from 'react';
import type { GetTablePagesResponse } from '@components/Table/types';

// TODO: расставить типы

function debounce<T>(
  func: (args: T | undefined) => void,
  ms: number
): (args: T | undefined) => void {
  let timeout: NodeJS.Timeout;
  return function (rest) {
    clearTimeout(timeout);
    // @ts-expect-error arguments
    timeout = setTimeout(() => func.apply(this, arguments), ms);
  };
}

export class Pagination {
  pageNumber = 0;
  maxRowCount = 10;
  totalCount = 100;

  calcPageNumberByIndex(itemIndex: number): number {
    return itemIndex < 0 ? 1 : 1 + Math.trunc(itemIndex / this.maxRowCount);
  }
}

export function useLazyList<T extends object>(
  apiLoadItems: (apiLoadItems: any) => Promise<GetTablePagesResponse<T>>
) {
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [items, setItems] = useState<T[]>([]);

  let loadingPages: { [pageNumber: number]: any } = [];
  let pagination: Pagination = new Pagination();

  const reversedItems = useMemo(() => [...items].reverse(), [items]);

  useEffect(() => {
    loadMoreRows();
  }, []);

  const isItemLoaded = ({ index }: { index: number }) => {
    return index >= 0 && index < totalItemsCount && !!items[index];
  };

  let loadMorePromise: Promise<void> | undefined;
  let finishLoadMorePromise = () => {};

  const loadMoreRows = async (params?: {
    startIndex: number;
    stopIndex: number;
  }): Promise<void> => {
    loadItemsWithDebounce(params);
    if (!loadMorePromise) {
      loadMorePromise = new Promise((resolve) => {
        finishLoadMorePromise = resolve;
      });
    }
    return loadMorePromise;
  };

  function loadItems(params?: { startIndex: number; stopIndex: number }): Promise<void> {
    const startIndex = (params && params.startIndex) || 0;
    const stopIndex = (params && params.stopIndex) || 0;
    const startPage = pagination.calcPageNumberByIndex(startIndex);
    const stopPage = pagination.calcPageNumberByIndex(stopIndex);

    const promises: Array<Promise<GetTablePagesResponse<T>>> = [];
    for (let i = startPage; i <= stopPage; i++) {
      promises.push(
        loadPage({
          pageNumber: i,
          maxRowCount: pagination.maxRowCount,
        })
      );
    }

    const promise = Promise.all(promises).then((pages: GetTablePagesResponse<T>[]) => {
      let newItems: Array<T> = [];
      for (let i = 0; i < pages.length; i++) {
        newItems = newItems.concat(pages[i].items); // ключ
      }
      setTotalItemsCount(pages[0].total || 0); // ключ
      addItems(newItems, pagination.maxRowCount * (startPage - 1));

      finishLoadMorePromise && finishLoadMorePromise();
      loadMorePromise = undefined;
    });

    return promise;
  }

  const loadItemsWithDebounce = debounce<{ startIndex: number; stopIndex: number }>(loadItems, 300);

  const loadPage = async (params: {
    pageNumber: number;
    maxRowCount: number;
  }): Promise<GetTablePagesResponse<T>> => {
    const { pageNumber } = params;

    if (!loadingPages[pageNumber]) {
      loadingPages[pageNumber] = apiLoadItems(params).then((result) => {
        delete loadingPages[pageNumber];
        return result;
      });
    }

    return loadingPages[pageNumber];
  };

  const addItems = (additionalItems: T[], startIndex: number): void => {
    if (!additionalItems.length) return;

    const newItems = startIndex ? [...items] : [];

    additionalItems.forEach((additionalItem, index) => {
      newItems[startIndex + index] = { ...additionalItem };
    });

    setItems(newItems);
  };

  const updateItems = (): void => {
    setItems([...items]);
  };

  return {
    items,
    reversedItems,
    isItemLoaded,
    loadMoreRows,
    updateItems,
    totalItemsCount,
  };
}

export interface ILazyList<T> {
  items: T[];
  reversedItems: T[];
  loadMoreRows: () => Promise<unknown>;
  isItemLoaded: (data: { index: number }) => boolean;
  updateItems: () => void;
  totalItemsCount: number;
}

export function useReversedLazyList<T extends object>(
  apiLoadItems: (apiLoadItems: any) => Promise<GetTablePagesResponse<T>>
) {
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [items, setItems] = useState<T[]>([]);

  let loadingPages: { [pageNumber: number]: any } = [];
  let pagination: Pagination = new Pagination();

  useEffect(() => {
    loadMoreRows();
  }, []);

  let loadMorePromise: Promise<void> | undefined;
  let finishLoadMorePromise = () => {};

  const loadMoreRows = async (params?: {
    startIndex: number;
    stopIndex: number;
  }): Promise<void> => {
    loadItemsWithDebounce(params);
    if (!loadMorePromise) {
      loadMorePromise = new Promise((resolve) => {
        finishLoadMorePromise = resolve;
      });
    }
    return loadMorePromise;
  };

  async function loadItems(params?: { startIndex: number; stopIndex: number }): Promise<void> {
    const startIndex = (params && params.startIndex) || 0;
    const stopIndex = (params && params.stopIndex) || 0;
    const startPage = pagination.calcPageNumberByIndex(startIndex);
    const stopPage = pagination.calcPageNumberByIndex(stopIndex);
    const promises: Array<Promise<GetTablePagesResponse<T>>> = [];
    for (let i = startPage; i <= stopPage; i++) {
      promises.push(
        loadPage({
          pageNumber: i,
          maxRowCount: pagination.maxRowCount,
        })
      );
    }

    const promise = await Promise.all(promises).then((pages: GetTablePagesResponse<T>[]) => {
      let newItems: Array<T> = [];
      for (let i = 0; i < pages.length; i++) {
        newItems = newItems.concat(pages[i].items); // ключ
      }
      setTotalItemsCount(pages[0]?.total || 0); // ключ
      addItems(newItems, pagination.maxRowCount * (startPage - 1), pages[0]?.total);
      finishLoadMorePromise && finishLoadMorePromise();
      loadMorePromise = undefined;
    });

    return promise;
  }

  const loadItemsWithDebounce = debounce<{ startIndex: number; stopIndex: number }>(loadItems, 300);

  const loadPage = async (params: {
    pageNumber: number;
    maxRowCount: number;
  }): Promise<GetTablePagesResponse<T>> => {
    const { pageNumber } = params;

    if (!loadingPages[pageNumber]) {
      loadingPages[pageNumber] = apiLoadItems(params).then((result) => {
        delete loadingPages[pageNumber];
        return result;
      });
    }

    return loadingPages[pageNumber];
  };

  const addItems = (additionalItems: T[], startIndex: number, total: number): void => {
    const newItems = startIndex ? [...items] : []; // если открываем новый пустой диалог - очищаем массив

    additionalItems.forEach((additionalItem, i) => {
      // новые элементы помещаем перед уже существующими в конце массива
      const indexToPlace = total - 1 - i - startIndex;
      newItems[indexToPlace] = { ...additionalItem };
    });
    setItems(newItems);
  };

  const loadedStartItem = items.find((el) => !!el);
  // @ts-expect-error check
  const loadedStartIndex = items.indexOf(loadedStartItem) || 0;

  return {
    items,
    loadMoreRows,
    totalItemsCount,
    loadedStartIndex,
    itemsPerPage: pagination.maxRowCount,
  };
}

export interface IReversedLazyList<T> {
  items: T[];
  loadMoreRows: () => Promise<unknown>;
  totalItemsCount: number;
  loadedStartIndex: number;
  itemsPerPage: number;
}
