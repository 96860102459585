import React from 'react';
import cn from 'classnames';
import type * as ReactTable from 'react-table';
import type * as TableTypes from '../../types';

import './style.scss';
import ClippedText from '@components/ClippedText/ClippedText';

export const TableBody = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TableTypes.TableProps>
>(
  (
    {
      page = [],
      rows,
      withAlternating,
      withPagination,
      withColumnBodySeparator,
      getTableBodyProps = () => {},
      prepareRow = () => {},
      onRowClick,
      onRowDoubleClick,
      onRowFocus,
      onRowBlur,
      toggleAllRowsSelected,
    },
    refTableBody
  ) => (
    <div
      className={cn('custom-tbody', { 'custom-tbody--alternating': withAlternating })}
      {...getTableBodyProps()}
      ref={refTableBody}
    >
      {(withPagination === true ? page : rows).map(
        (row: ReactTable.Row<TableTypes.CustomRowData<object>>) => {
          prepareRow(row);
          // @ts-expect-error check types
          if (row.original.render) return row.original.render(row);
          const { key: rowKey, ...otherRowProps } = row.getRowProps();
          return (
            <div
              key={rowKey}
              {...otherRowProps}
              className={cn('custom-tbody__tr', {
                'custom-tbody__tr--clickable': !!onRowClick,
                'custom-tbody__tr--selected': row.isSelected,
              })}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                toggleAllRowsSelected(false);
                row.toggleRowSelected();

                onRowClick !== undefined && onRowClick(row, e);
              }}
              onDoubleClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                onRowDoubleClick !== undefined && onRowDoubleClick(row, e);
              }}
              onFocus={(e: React.FocusEvent<HTMLDivElement>) => {
                onRowFocus !== undefined && onRowFocus(row, e);
              }}
              onBlur={(e: React.FocusEvent<HTMLDivElement>) => {
                onRowBlur !== undefined && onRowBlur(row, e);
              }}
            >
              {row.cells.map((cell) => {
                const {
                  verticalAlign = 'middle',
                  align = 'left',
                  fixed,
                  clipped = false,
                } = cell.column;
                const { key, ...otherCellProps } = cell.getCellProps();
                return (
                  <div
                    key={key}
                    className={cn('custom-tbody__td', 'custom-cell', {
                      [`custom-cell--${align}`]: align,
                      'custom-cell--fixed': fixed,
                      [`custom-cell--${verticalAlign}`]: verticalAlign,
                      'custom-tbody__td--bordered': withColumnBodySeparator,
                    })}
                    {...otherCellProps}
                  >
                    {clipped ? <ClippedText content={cell.render('Cell')} /> : cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        }
      )}
    </div>
  )
);

TableBody.displayName = 'TableBody';

export default TableBody;
