export const amountMask = [
  { mask: '' },
  {
    mask: 'num ₽',
    unmask: true,
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ' ',
        min: 1,
        scale: 0,
      },
    },
  },
];

export const amountProposalMask = [
  { mask: '' },
  {
    mask: 'num ₽',
    unmask: true,
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ' ',
        min: 0,
        scale: 0,
      },
    },
  },
];

export const fixRateMask = [
  { mask: '' },
  {
    mask: 'num %',
    lazy: false,
    digitsOptional: false,
    radix: ',',
    mapToRadix: ['.'],
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ' ',
        min: 0,
        scale: 3,
      },
    },
  },
];

export const simpleRateMask = {
  mask: 'num',
  lazy: false,
  digitsOptional: false,
  radix: ',',
  mapToRadix: ['.'],
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ' ',
      min: 0,
      scale: 3,
    },
  },
};

export const positiveNumberMask = {
  mask: Number,
  lazy: false,
  min: 0,
  scale: 0,
};

export const spreadMask = {
  mask: Number,
  lazy: false,
  digitsOptional: false,
  radix: '.',
  mapToRadix: ['.'],
  thousandsSeparator: ' ',
  min: 0,
  scale: 2,
};

export const floatRateMask = {
  mask: 'Ключевая ставка + num %',
  lazy: false,
  digitsOptional: false,
  radix: ',',
  mapToRadix: ['.'],
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ' ',
      min: 0,
      scale: 3,
    },
  },
};

export const hexMask = {
  mask: '#cccccc',
  lazy: false,
  definitions: {
    c: /[A-Fa-f0-9]/,
  },
};
export const phoneMask = {
  mask: '+{7} (000) 000-00-00',
  lazy: false,
  definitions: {
    0: /[0-9]/,
  },
};

export const dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
export const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];
