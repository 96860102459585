import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { download } from '@utils';
import { HttpClient } from '@api/HttpClient';
import { ROUTES } from '@routes';
import { AuthUtil } from '@services/auth/auth.util';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import {
  REQUEST_TYPE_CODES,
  REQUEST_STATUSES_PARAMS,
  RATE_TYPES_TITLE,
  REPORT_TYPES,
  MIME_TYPES,
} from '@constants';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { ReactComponent as IconChat } from '@shared/icons/chat.svg';
import { CreateDialogModal } from '@shared/components/CreateDialogModal/CreateDialogModal';
import { renderAmount, renderPeriod, renderRate } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { RateType } from '@models/base.model';
import { useCurrentUserData } from '@services/user/userDataContext';
import type { IRequestAtomicPage, IProposal } from './atomic.model';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import { NoCommunicationsModal } from '@pages/customer/shared/NoCommunicationsModal/NoCommunicationsModal';
import { CommunicationService } from '@services/communication/communication.service';

import './RequestAtomicPage.scss';

export const RequestAtomicPage: React.FC = () => {
  const { requestId, positionId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useCurrentUserData();

  const requestType = location.pathname.includes('/deposit') ? 'deposit' : 'credit';

  const [data, setData] = React.useState<IRequestAtomicPage>();
  const [recipient, setRecipient] = React.useState<ISimilarCompany | null>(null);
  const [isOpenNoCommunications, setIsOpenNoCommunications] = React.useState(false);

  React.useEffect(() => {
    HttpClient.get<IRequestAtomicPage>(
      `customer/${requestType}/requests/${requestId}/positions/${positionId}/`
    ).then((request) => {
      request.proposals.forEach((proposal, index) => {
        proposal.index = index + 1;
        return proposal;
      });
      setData(request);
    });
  }, [requestId, positionId, requestType]);

  const downloadData = async (): Promise<void> => {
    const data = await HttpClient.get<string>(
      `customer/${requestType}/requests/${requestId}/positions/${positionId}/reports/${REPORT_TYPES.SUMMARY}/`,
      {
        headers: {
          Accept: MIME_TYPES.Excel,
        },
        responseType: 'blob',
      }
    );
    const blob = new Blob([data], {
      type: MIME_TYPES.Excel,
    });
    download(blob, `Позиция_запроса_${requestId}_${positionId}.xlsx`);
  };

  const dataForBox = data
    ? [
        [
          {
            label: 'Услуга',
            value: REQUEST_TYPE_CODES[data.request_type?.code],
          },
          {
            label: 'Даты размещения запроса',
            value: `с ${formatDate(data.start_ts)} по ${formatDate(data.end_ts)}`,
          },
          {
            label: 'Максимальный объем',
            value: renderAmount({ amount: data.max_amount }),
          },
          {
            label: 'Ставка',
            value: RATE_TYPES_TITLE[data.rate_type],
          },
          {
            label: 'Срок',
            value: renderPeriod({
              period: data.period as number,
              periodType: data.period_type,
              endDatePeriod: data.end_date_period as string,
            }),
          },
        ],
        [
          {
            label: 'Комментарий (внешний)',
            value: data.comment_ext,
          },
          {
            label: 'Комментарий (внутренний)',
            value: data.comment_int,
          },
          {
            label: 'Создан',
            value: `${AuthUtil.getFullNameAbbreviation(
              data.creator?.last_name || '',
              data.creator?.first_name || '',
              data.creator?.patronymic || ''
            )} 
      ${formatDate(data.create_ts)}`,
          },
        ],
      ]
    : [[], []];

  if (data?.status === 'COMPLETED') {
    dataForBox[1].push({
      label: 'Завершен',
      value: formatDate(data.end_ts),
    });
    dataForBox[1].push({
      label: 'Комментарий при завершении',
      value: data.comment_result,
    });
  }

  const getDialogId = async (row: IProposal): Promise<void> => {
    const result = await CommunicationService.searchActiveDialogByParams({
      recipient_company_id: row.company?.id,
      request_type: requestType.toUpperCase(),
      request_position_id: positionId,
    });
    if (result.dialog_id) navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${result.dialog_id}`);
    else setRecipient(row.company as ISimilarCompany);
  };

  const COLUMNS = (rateType: RateType): TableTypes.Column[] => [
    {
      Header: '№',
      accessor: 'index',
    },
    {
      Header: 'Ставка',
      accessor: (row: unknown) => <>{renderRate({ rate: (row as IProposal).rate, rateType })}</>,
    },
    {
      Header: 'Объём',
      accessor: (row: unknown) => renderAmount(row as IProposal),
      align: 'right',
    },
    {
      Header: 'Участник',
      accessor: 'company.name',
    },
    {
      Header: 'Комментарий участника',
      accessor: 'comment',
    },
    {
      id: 'id', // @ts-expect-error check type
      accessor: (row): JSX.Element => (
        <Button
          type="text"
          icon={<IconChat />}
          onClick={() => {
            if (user?.canCommunicate) {
              getDialogId(row);
            } else {
              setIsOpenNoCommunications(true);
            }
          }}
        />
      ),
      align: 'right',
    },
  ];

  return (
    <DetailLayout
      title="Детализация запроса"
      className="request-atomic"
      mainInfoClassName="request-atomic-info"
      extraInfoClassName="request-atomic-table"
      badgeParams={data && REQUEST_STATUSES_PARAMS[data.status]}
      mainInfo={data && <InfoBox title={data.request_name || '-'} data={dataForBox} />}
      extraInfo={
        data && (
          <>
            <div className="request-atomic-table__row">
              <div className="request-atomic-table__title">Предложения</div>

              <Button
                className="request-atomic-table__download"
                onClick={downloadData}
                icon={<IconFile />}
              >
                Выгрузить
              </Button>
            </div>
            <Table
              className="request-atomic-table__grid"
              columns={COLUMNS(data.rate_type)}
              data={data.proposals || []}
              notFoundMessage="Список предложений пуст"
              notFoundDescription="К этому запросу пока не оставлено ни одного предложения."
            />
          </>
        )
      }
    >
      <div className="request-atomic__empty-box" />
      {recipient && (
        <CreateDialogModal
          isGroup={false}
          requestType={requestType}
          recipientCompanies={[recipient]}
          requestPositionId={positionId}
          onClose={() => {
            setRecipient(null);
          }}
        />
      )}
      {isOpenNoCommunications && (
        <NoCommunicationsModal
          onClose={() => {
            setIsOpenNoCommunications(false);
          }}
          disabledNavigate={!data}
        />
      )}
    </DetailLayout>
  );
};
