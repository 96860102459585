import { useEffect } from 'react';
import { useGlobalContext } from '@hooks';
import { HttpClient } from '@api/HttpClient';

const AxiosLoaderProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { setShowLoaderHandler } = useGlobalContext();
  useEffect(() => {
    HttpClient.setLoadHandler(setShowLoaderHandler);
  }, []);
  return children;
};

export default AxiosLoaderProvider;
