import React, { useEffect, useState } from 'react';
import Textarea from '@components/Textarea/Textarea';

import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as Cross } from '@shared/icons/plus.svg';
import { ReactComponent as Accept } from '@shared/icons/accept.svg';

import './CommentField.scss';

interface IProps {
  text: string;
  updateComment: (comment: string) => void;
}

export const CommentField: React.FC<IProps> = ({ text, updateComment }) => {
  const [isNoteEditing, editNote] = useState(false);
  const [comment, setComment] = React.useState<string>(text);
  const [error, setError] = React.useState<string>('');

  useEffect(() => {
    setComment(text);
  }, [text]);

  const validate = (): string | null => {
    if (comment.length > 300) return 'Максимум 300 символов';
    return null;
  };

  const acceptNoteChanges = (): void => {
    const error = validate();
    if (error) setError(error);
    else {
      setError('');
      editNote(false);
      updateComment(comment);
    }
  };

  const cancelNoteChanges = (): void => {
    editNote(false);
    setError('');
    setComment(text);
  };

  return (
    <div className="ticket-comment-block">
      <label htmlFor="note">
        Заметка оператора
        <div className="ticket-comment-block__actions">
          {isNoteEditing ? (
            <>
              <Cross className="ticket-comment-block__close-icon" onClick={cancelNoteChanges} />
              <Accept onClick={acceptNoteChanges} />
            </>
          ) : (
            <Edit
              onClick={(): void => {
                editNote(true);
              }}
            />
          )}
        </div>
      </label>
      {isNoteEditing ? (
        <Textarea
          resizable
          error={error}
          name="note"
          onChange={(e) => {
            setComment(e.target.value);
          }}
          isClearable
          autoSize={false}
        >
          {comment}
        </Textarea>
      ) : (
        <span className="ticket-comment-block__comment">{comment || '-'}</span>
      )}
    </div>
  );
};
