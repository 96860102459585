import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes';
import Button from '@components/Button/Button';
import Textarea from '@components/Textarea/Textarea';
import Scrollbar from '@components/Scrollbar/Scrollbar';
import { HttpClient } from '@api/HttpClient';
import { Modal } from '@components/Modal/Modal';
import { TITLES, HTTP_ERROR_STATUS_CODES } from '@constants';
import type { IRequestDetailParticipants, IPosition } from '@models/request.model';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import Notification from '@components/Modal/Notification/Notification';
import CommunicationService from '@services/communication/communication.service';
import { IExtendedDialog } from '@models/communication.model';

import './CreateDialogModal.scss';

const MAX_MESSAGE_LENGTH = 1000;

export const CreateDialogModal: React.FC<{
  isGroup: boolean;
  requestType: string;
  recipientCompanies?: ISimilarCompany[];
  requestId?: number;
  groupRequestName?: string;
  requestPositionId?: number | string;
  requestBondId?: number;
  positions?: IPosition[];
  onClose: () => void;
}> = ({
  isGroup,
  recipientCompanies,
  requestId,
  groupRequestName,
  requestPositionId,
  requestBondId,
  positions,
  onClose,
  requestType,
}) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [recipients, setRecipients] = useState<ISimilarCompany[]>(recipientCompanies || []);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const loadRecipients = async (): Promise<void> => {
    const resp = await HttpClient.get<IRequestDetailParticipants>(
      `customer/${requestType}/requests/${requestId || requestBondId}/${
        requestType === 'bond' ? 'participants' : 'details'
      }/`
    );
    if (requestType === 'bond') {
      setRecipients(resp as unknown as ISimilarCompany[]);
    } else {
      setRecipients(resp.participants as unknown as ISimilarCompany[]);
    }
  };

  useEffect(() => {
    if (!recipientCompanies) loadRecipients();
  }, [recipientCompanies]);

  const topic = isGroup
    ? groupRequestName
    : `Коммуникация по запросу №${
        requestType === 'bond' ? requestBondId : requestPositionId || requestId
      }, трек "${TITLES[requestType]}"`;

  const createDialog = async (): Promise<IExtendedDialog[]> => {
    const newDialogs = await CommunicationService.createDialog({
      recipient_company_ids: recipients?.map((el) => el.id.toString()),
      title: topic,
      request_type: requestType.toUpperCase(),
      request_id: requestId,
      request_bond_id: requestBondId,
      request_position_id: requestPositionId,
      message,
    });
    return newDialogs;
  };

  const validate = (): boolean => {
    setError('');
    if (message.length === 0) {
      setError('Введите сообщение');
      return false;
    }
    if (message.length >= MAX_MESSAGE_LENGTH) {
      setError(`Максимальная длина ${MAX_MESSAGE_LENGTH} символов`);
      return false;
    }
    return true;
  };

  // TODO: запросить позиции, елси они не были загружены через первую вкладку
  const requestsList = isGroup
    ? positions?.map((el) => el.id).join(', ')
    : requestBondId && requestType === 'bond'
    ? requestBondId
    : requestPositionId && (requestType === 'deposit' || requestType === 'credit')
    ? requestPositionId
    : requestId;

  return (
    <>
      <Modal isOpen className="communication-create-dialog-modal">
        <Modal.Header>
          <h3 className="communication-create-dialog-modal__title">Создать коммуникацию</h3>
        </Modal.Header>
        <Scrollbar autoHeight={true} autoHeightMax="500px">
          <div className="communication-create-dialog-modal__content">
            <div className="communication-create-dialog-modal__recipient-label">
              {requestBondId ? 'Адресаты:' : !isGroup ? 'Адресат:' : 'Адресаты:'}
            </div>
            <div className="communication-create-dialog-modal__recipients">
              {requestBondId
                ? recipients?.map((el) => <p key={el.id}>{el.name}</p>)
                : !isGroup
                ? recipients?.[0].name
                : recipients?.map((el) => <p key={el.id}>{el.name}</p>)}
            </div>
            <div className="communication-create-dialog-modal__request-label">
              {!isGroup ? 'Запрос:' : 'Запросы:'}
            </div>
            <div className="communication-create-dialog-modal__requests">{requestsList}</div>
          </div>
        </Scrollbar>
        <Textarea
          wrapperClassName="communication-create-dialog-modal__field"
          label="Сообщение *"
          placeholder="Напишите Ваше сообщение"
          size="large"
          required
          rows={4}
          resizable={true}
          error={error}
          onChange={(e) => {
            if (error) setError('');
            setMessage(e.target?.value);
          }}
          isClearable
          value={message}
        />
        <Modal.Actions className="communication-create-dialog-modal__actions">
          <Button
            type="primary"
            size="large"
            onClick={async () => {
              if (validate()) {
                try {
                  const dialogs = await createDialog();
                  onClose();
                  if (dialogs?.length === 1)
                    navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${dialogs[0].id}`);
                  else navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS);
                } catch (e: unknown) {
                  // @ts-expect-error check types
                  if (e.response.status === HTTP_ERROR_STATUS_CODES.Bad_request) {
                    setIsOpenErrorModal(true);
                  }
                }
              }
            }}
            fullWidth
          >
            Создать
          </Button>
          <Button
            size="large"
            onClick={() => {
              onClose();
            }}
            fullWidth
          >
            Отмена
          </Button>
        </Modal.Actions>
      </Modal>
      {isOpenErrorModal && (
        <Notification
          isOpen
          title="Невозможно создать"
          message="Невозможно создать коммуникацию, так как отсутствует пользователь."
          confirmBtnText="Закрыть"
          onCancel={() => {
            setIsOpenErrorModal(false);
          }}
        />
      )}
    </>
  );
};
