export interface IDictionary {
  label: string;
  value: string | number;
}
export interface INamedEntity {
  id: string;
  name: string;
}
export interface IDictionaryCompany extends INamedEntity {
  inn: string;
}

export enum Status {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Summarizing = 'SUMMARIZING',
  Deleted = 'DELETED',
  Sent = 'SENT',
}

export enum PeriodType {
  Month = 'MONTH',
  Year = 'YEAR',
  Date = 'DATE',
}

export enum RateType {
  Fix = 'FIX',
  Float = 'FLOAT',
}

export enum SpreadType {
  Profit = 'PROFIT',
  Rate = 'RATE',
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC',
}
