import React from 'react';
import { getBase64 } from '@utils';
import Button from '../Button/Button';
import Avatar from '../Avatar/Avatar';
import { ReactComponent as Trash } from '@shared/icons/trash.svg';
import { ReactComponent as Change } from '@shared/icons/change.svg';

import cn from 'classnames';

import './ImageLoader.scss';

interface IProps {
  className?: string;
  name: string;
  label?: string;
  onLoad: (src: string) => void;
  value?: string;
  error?: string;
}

const ALLOWED_FILE_TYPES = ['image/png', 'image/svg+xml'];
const MAX_FILE_SIZE = 10 * 1024 ** 2;

export const ImageLoader: React.FC<IProps> = ({
  className,
  label = 'Загрузите файл',
  name,
  onLoad,
  error,
  value,
}) => {
  const ref = React.useRef<HTMLInputElement | null>(null);

  const [fileValidationError, setFileValidationError] = React.useState('');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target?.files?.[0];

    if (!file) {
      setFileValidationError('Файл не загружен');
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setFileValidationError('Недопустимый формат файла');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setFileValidationError('Размер файла больше 10Мб');
      return;
    }

    getBase64(file).then((str) => {
      setFileValidationError('');
      onLoad(str);
    });
  };

  return (
    <div className={cn('custom-file-loader', className)}>
      <label htmlFor={name}>{label}</label>
      <div className="custom-file-loader__image-wrapper">
        {value ? (
          <Avatar src={value} />
        ) : (
          <div className="custom-file-loader__no-image">Изображение не выбрано</div>
        )}
      </div>

      <input
        style={{ display: 'none' }}
        accept="image/png, image/svg+xml"
        ref={ref}
        type="file"
        name={name}
        onChange={handleFileChange}
      />
      {!value && (
        <Button
          size="large"
          onClick={() => {
            ref.current?.click();
          }}
        >
          Выбрать
        </Button>
      )}
      {value && (
        <div className="custom-file-loader__actions">
          <Button
            className="custom-file-loader__change"
            size="large"
            icon={<Change />}
            type="without-border"
            onClick={() => {
              ref.current?.click();
            }}
          />
          <Button
            className="custom-file-loader__trash"
            icon={<Trash />}
            size="large"
            type="without-border"
            onClick={() => {
              onLoad('');
              setFileValidationError('');
            }}
          />
        </div>
      )}
      {(error || fileValidationError) && (
        <span className="custom-file-loader__error">{error || fileValidationError}</span>
      )}
    </div>
  );
};

export default ImageLoader;
