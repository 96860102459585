export const INCORRECT_PROFILE_PHONE = 'Phone - incorrect';
export const KEY_INCORRECT = 'KEY INCORRECT';
export const KEY_NOT_FOUND = 'Key not found';
export const TOO_MANY_SMS = 'Too many SMS!';

export const EXPIRATION_TIME = 180; // 180 сек - 3 минуты
export const PASSWORD_REG_EXP =
  /(?=^.{8,}$)(?=.*\d)(?=.*\W+)(?=.*[`~!@#$%^&*()\-=_+"№;:?'[\]{}\\/,.<>])(?![.\n])(?=.*[А-ЯЁA-Z])(?=.*[а-яёa-z]).*$/;

export enum EOtpType {
  SetPassEmail = 'SET_PASS_EMAIL',
  ChangePassEmail = 'CHANGE_PASS_EMAIL',
  SmsConfirm = 'SMS_CONFIRM',
}

export enum EState {
  ForgotPassword = 'FORGOT_PASSWORD',
  SentLink = 'SENT_LINK',
  ConfirmPassword = 'CONFIRM_PASSWORD',
  CreatePassword = 'CREATE_PASSWORD',
  PasswordSuccess = 'PASSWORD_SUCCESS',
  Register = 'REGISTER',
  Agreement = 'AGREEMENT',
}
