import { useEffect, useState, type JSX } from 'react';
import { useNavigate } from 'react-router';

import Button from '@components/Button/Button';
import Container from '@components/Container/Container';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { useCurrentUserData } from '@services/user/userDataContext';
import Notification, {
  IProps as INotificationProps,
} from '@components/Modal/Notification/Notification';
import { getFullname } from '@functions';
import { hasOperatorRole } from '@roles';
import { ReactComponent as Avatar } from '@shared/icons/avatar.svg';
import { HttpClient } from '@api/HttpClient';
import { PersonalBlock } from './PersonalBlock/PersonalBlock';

import './ProfilePage.scss';
import LogsService from '@services/logs/logs.service';
import { ROUTE_LOGS } from '@services/logs/logs.const';

enum NotificationState {
  delete = 'delete',
  confirmDelete = 'confirmDelete',
  resetPassword = 'resetPassword',
}

export function ProfilePage(): JSX.Element {
  const userInfo = useCurrentUserData();
  const navigate = useNavigate();
  const [notification, openNotification] = useState<NotificationState | null>();
  const [ticketNumber, setTicketNumber] = useState<number>();
  const isOperator = userInfo?.roles && hasOperatorRole(userInfo?.roles);

  useEffect(() => {
    if (!isOperator) {
      LogsService.saveLogs(ROUTE_LOGS.PROFILE);
    }
  }, []);

  const resetPassword = (): void => {
    if (!userInfo?.email) return;

    HttpClient.post<{ email: string }>('common/recovery_password/', {
      email: userInfo.email,
    });
  };

  const generateTicketToSupport = async (): Promise<number> => {
    const { id } = await HttpClient.post<null, { id: number }>('common/tickets/delete_profile');
    return id;
  };

  const goToTicket = (): void => {
    const path = `/support/tickets/${ticketNumber}`;
    navigate(path);
  };

  const notificationParams: Record<string, Partial<INotificationProps>> = {
    [NotificationState.delete]: {
      title: 'Удаление профиля',
      message:
        'Обращение в техническую поддержку будет создано автоматически. Удаление профиля не может быть отменено.',
      confirmBtnText: 'Продолжить',
      onConfirm: async () => {
        const number = await generateTicketToSupport();
        setTicketNumber(number);
        openNotification(NotificationState.confirmDelete);
      },
    },
    [NotificationState.confirmDelete]: {
      title: 'Удаление профиля',
      message: (
        <span>
          Обращение <b>№{ticketNumber}</b> создано. Вы можете перейти в раздел поддержки для
          просмотра и отслеживания обращения.
        </span>
      ),
      confirmBtnText: 'Перейти',
      onConfirm: goToTicket,
    },
    [NotificationState.resetPassword]: {
      title: 'Смена пароля',
      message: `На указанную в профиле почту будет отправлено сообщение со ссылкой на страницу для задания нового пароля. 
      Новый пароль необходимо подтвердить через СМС.`,
      confirmBtnText: 'Сменить пароль',
      onConfirm: () => {
        resetPassword();
        openNotification(null);
      },
    },
  };

  return (
    <PageLayout className="profile-page">
      <div className="profile-page__main">
        <Avatar />
        <div className="profile-page__names">
          <h3 className="profile-page__name">
            {getFullname(userInfo?.lastName, userInfo?.firstName, userInfo?.patronymic)}
          </h3>
          <span className="profile-page__company">{userInfo?.company?.name ?? ''}</span>
        </div>
      </div>
      <div className="profile-page__row">
        <PersonalBlock userInfo={userInfo} />
        <Container direction="vertical" className="profile-page__box">
          <Container.Header>Безопасность</Container.Header>
          <div className="profile-page__security">
            <div>
              <Button
                disabled={!userInfo?.email}
                onClick={() => {
                  openNotification(NotificationState.resetPassword);
                }}
              >
                Сменить пароль
              </Button>
              <span>Требуется доступ к почте и подтверждение по СМС.</span>
            </div>
            {!isOperator && (
              <div>
                <Button
                  onClick={() => {
                    openNotification(NotificationState.delete);
                  }}
                >
                  Удалить профиль
                </Button>
                <span>Требуется обращение в техническую поддержку.</span>
              </div>
            )}
          </div>
        </Container>
      </div>
      {notification && (
        <Notification
          title="Удаление профиля"
          className="admin-user-notification"
          isOpen
          onCancel={() => {
            openNotification(null);
          }}
          cancelBtnText="Отмена"
          {...notificationParams[notification]}
        />
      )}
    </PageLayout>
  );
}

export default ProfilePage;
