/* eslint-disable */
import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

export const getProposalSchema = (maxAmount: number) =>
  Yup.object().shape({
    rate: Yup.number()
      .test('1-5 letters', 'От 1 до 5 символов', (item) => {
        const length = item?.toString().replaceAll('.', '').length || 0;

        return length >= 1 && length <= 5;
      })
      .required(REQUIRED_MESSAGE),
    amount: Yup.number()
      .test('max 15 letters', 'Максимум 15 символов', (item) => {
        const length = item?.toString().length || 0;

        return length <= 15;
      })
      .test(
        'amount less than max amount',
        'Объём не может превосходить объём в запросе',

        (item) => {
          // @ts-expect-error check types
          return item <= maxAmount;
        }
      )
      .required(REQUIRED_MESSAGE),
    comment: Yup.string().when(['rate', 'amount'], {
      is: (rate: number, amount: number) => rate === 0 || amount === 0,
      then: (schema) => schema.required(REQUIRED_MESSAGE),
      otherwise: (schema) => schema.nullable(),
    }),
  });
