import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Container from '@components/Container/Container';
import Button from '@components/Button/Button';
import Badge from '@components/Badge/Badge';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import { ReactComponent as Edit } from '@shared/icons/edit.svg';
import { ReactComponent as IconChevronRight } from '@shared/icons/arrow-right-8-16.svg';
import { cloneDeep } from '@utils';
import { getFullname } from '@functions';
import { BLOCKED_STATUSES, MEMBER_STATUSES_PARAMS } from '@constants';
import {
  requestTypeIndexByCode,
  renderPermissionCheckbox,
  PERMISSIONS,
} from '@pages/operator/utils';
import type { IRequestType, IUserOfCustomerForAdmin } from '../../operator.model';

import './UsersTable.scss';

interface IProps {
  data?: IUserOfCustomerForAdmin[];
  onEdit: (data: IUserOfCustomerForAdmin[]) => void;
}

export const UsersTable: React.FC<IProps> = ({ data = [], onEdit }: IProps) => {
  const getInitialData = (): IUserOfCustomerForAdmin[] => cloneDeep(data);
  const [isEdited, setEdited] = React.useState(false);
  const [editedData, setEditedData] = React.useState<IUserOfCustomerForAdmin[]>([]);

  React.useEffect(() => {
    setEditedData(getInitialData());
  }, [data]);

  const handleChangePermission = (
    userIndex: number,
    code: IRequestType['code'],
    newVal: boolean
  ): void => {
    if (!isEdited) return;
    const columnIndex = requestTypeIndexByCode(editedData[userIndex].request_types, code);
    editedData[userIndex].request_types[columnIndex].is_active = newVal;

    setEditedData([...editedData]);
  };

  const handleSwitchEdit = (): void => {
    if (isEdited) {
      // Отмена редактирования без сохр
      setEditedData(getInitialData());
    }

    setEdited((prevState) => !prevState);
  };

  const handleSave = (): void => {
    isEdited && onEdit(editedData);
    setEdited(false);
  };

  const handleCancel = (): void => {
    setEditedData(getInitialData());
    setEdited(false);
  };

  const navigate = useNavigate();

  const navigateToUser = (row: { id: string }): void => {
    const userId = row.id;
    if (!userId) return;

    navigate(`/administration/users/${userId}/details`, { replace: true });
  };

  const navButton = (row: unknown): JSX.Element => (
    <Button
      className="customer-detail-users-table__button-next"
      type="text"
      icon={<IconChevronRight />}
      onClick={() => {
        navigateToUser(row as { id: string });
      }}
    />
  );
  const columns: TableTypes.Column[] = React.useMemo(
    () =>
      [
        {
          Header: 'Пользователь',
          accessor: ({
            first_name: firstName,
            last_name: lastName,
            patronymic,
            position,
            status,
          }: IUserOfCustomerForAdmin) => {
            const isBlocked = BLOCKED_STATUSES.includes(status);

            return (
              <div
                className={cn(
                  'customer-detail-users-table__person',
                  isBlocked && 'customer-detail-users-table__person--blocked'
                )}
              >
                <div className="customer-detail-users-table__name">
                  {getFullname(lastName, firstName, patronymic)}
                  {isBlocked && <Badge {...MEMBER_STATUSES_PARAMS[status]} size="small" />}
                </div>
                <div className="customer-detail-users-table__position">{position}</div>
              </div>
            );
          },
          width: 280,
        },
        {
          Header: 'Кредиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.CREDIT, isEdited),
          width: 120,
          align: 'center',
        },
        {
          Header: 'Депозиты',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.DEPOSIT, isEdited),
          width: 120,
          align: 'center',
        },
        {
          Header: 'Облигации',
          accessor: renderPermissionCheckbox(handleChangePermission, PERMISSIONS.BOND, isEdited),
          width: 120,
          align: 'center',
        },
        {
          Header: 'Коммуникации',
          accessor: renderPermissionCheckbox(
            handleChangePermission,
            PERMISSIONS.COMMUNICATION,
            isEdited
          ),
          width: 120,
          align: 'center',
        },
        {
          id: 'id',
          accessor: navButton,
          width: 300,
          align: 'right',
        },
      ] as TableTypes.Column[],
    [editedData, isEdited]
  );

  return (
    <>
      <Container.Header className="customer-detail-users-table__header">
        Пользователи
      </Container.Header>
      <Table
        data={editedData}
        columns={columns}
        withAlternating
        className="customer-detail-users-table__grid"
      />
      <div className="customer-detail-users-table__actions">
        {(editedData?.length && (
          <Button
            type="without-border"
            icon={<Edit />}
            title={isEdited ? 'Отменить' : 'Редактировать'}
            onClick={handleSwitchEdit}
          />
        )) ||
          null}
      </div>

      {isEdited && (
        <div className="customer-companies-table__edit-actions">
          <Button type="primary" onClick={handleSave}>
            Сохранить
          </Button>
          <Button type="warning" onClick={handleCancel}>
            Отменить
          </Button>
        </div>
      )}
    </>
  );
};
