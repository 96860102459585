import React from 'react';
import cn from 'classnames';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { useCurrentUserData } from '@services/user/userDataContext';
import { IMessage } from '@models/communication.model';

import './Message.scss';

interface IMessageProps {
  data: IMessage;
  style?: React.CSSProperties;
  className?: string;
}

export const Message: React.FC<IMessageProps> = ({ data, style, className }) => {
  if (!data) return null;

  const user = useCurrentUserData();
  const incoming = data.creator.id !== user?.id;
  const fromMyCompany = data.creator_company.id === user?.company?.id;

  const visibleName = !incoming
    ? 'Вы'
    : fromMyCompany
    ? `${data.creator.last_name} ${data.creator.first_name} (${data.creator_company.name})`
    : data.creator_company.name;

  return (
    <article
      style={style}
      className={cn('dialog-message', className, {
        'dialog-message--incoming': incoming,
        'dialog-message--outcoming': !incoming,
        'dialog-message--my-company': fromMyCompany,
        'dialog-message--partner': !fromMyCompany,
      })}
    >
      <div className="dialog-message__name">{visibleName}</div>
      <div className="dialog-message__text">{data.text}</div>
      <time className="dialog-message__date">{formatDate(data.create_ts)}</time>
    </article>
  );
};
