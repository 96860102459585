import type React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import PageLayout from '@layouts/PageLayout/PageLayout';
import Container from '@components/Container/Container';
import Button from '@components/Button/Button';
import Breadcrumbs from '@components/Breadcrumbs/Breadcrumbs';
import Badge from '@components/Badge/Badge';
import { ReactComponent as IconArrowLeft } from '@shared/icons/arrow-left.svg';

import './DetailLayout.scss';

interface IProps {
  className?: string;
  titleClassName?: string;
  mainInfoClassName?: string;
  extraInfoClassName?: string;
  mainInfo?: React.ReactNode;
  extraInfo?: React.ReactNode;
  children?: React.ReactNode;
  badgeParams?: object | null;
  title?: string;
  showFooter?: boolean;
  prevLink?: string | number;
}

const DetailLayout: React.FC<IProps> = ({
  className,
  titleClassName,
  mainInfoClassName,
  extraInfoClassName,
  mainInfo,
  extraInfo,
  badgeParams = undefined,
  title,
  children,
  prevLink,
  showFooter = true,
}: IProps) => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();

  let _prevLink = prevLink ?? (breadcrumbs.at(-3)?.key as unknown);
  if (!_prevLink) _prevLink = -1;

  return (
    <PageLayout
      className={cn('detail-layout', className)}
      siteHeader={
        <div className="detail-layout__nav">
          <Button
            type="without-border"
            className="detail-layout__back-btn"
            onClick={() => {
              navigate(_prevLink as string);
            }}
            icon={<IconArrowLeft />}
          />
          {/** @ts-expect-error check params */}
          {badgeParams && <Badge {...badgeParams} size="large" />}
          <h3 className={cn('detail-layout__title', titleClassName)}>{title}</h3>
        </div>
      }
      showFooter={showFooter}
    >
      <Breadcrumbs />
      {mainInfo && (
        <Container
          className={cn('detail-layout__main-info', mainInfoClassName)}
          direction="vertical"
        >
          {mainInfo}
        </Container>
      )}

      {extraInfo && (
        <Container
          className={cn('detail-layout__extra-info', extraInfoClassName)}
          direction="vertical"
        >
          {extraInfo}
        </Container>
      )}

      {children}
    </PageLayout>
  );
};

export default DetailLayout;
