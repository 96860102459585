import Checkbox from '@components/Checkbox/Checkbox';
import { IRequestType } from './operator.model';

export const requestTypeIndexByCode = (
  requestTypes: IRequestType[],
  code: IRequestType['code']
): number => requestTypes.findIndex((item) => item.code === code);

export const PERMISSIONS: Record<IRequestType['code'], IRequestType['code']> = {
  DEPOSIT: 'DEPOSIT',
  CREDIT: 'CREDIT',
  BOND: 'BOND',
  COMMUNICATION: 'COMMUNICATION',
};

export const PERMISSION_LIST = [
  { label: 'Депозиты', value: PERMISSIONS.DEPOSIT },
  { label: 'Кредиты', value: PERMISSIONS.CREDIT },
  { label: 'Облигации', value: PERMISSIONS.BOND },
];

export const PERMISSION_DEFAULT_DATA: IRequestType[] = [
  {
    code: PERMISSIONS.DEPOSIT,
    is_active: false,
  },
  {
    code: PERMISSIONS.CREDIT,
    is_active: false,
  },
  {
    code: PERMISSIONS.BOND,
    is_active: false,
  },
];

export const renderPermissionCheckbox =
  (
    handleChangePermission: renderChangePermissionFunc,
    code: IRequestType['code'],
    isEdited: boolean
  ): renderCheckboxFunc =>
  // eslint-disable-next-line react/display-name
  ({ id, request_types: requestTypes }: rowProps, rowIndex: number): React.ReactNode => {
    if (!requestTypes) return null;

    const columnIndex = requestTypeIndexByCode(requestTypes, code);
    const isChecked = requestTypes[columnIndex]?.is_active;

    return (
      <Checkbox
        onChange={() => {
          handleChangePermission(rowIndex, code, !isChecked);
        }}
        name={`${id}-${code}`}
        disabled={!isEdited}
        checked={isChecked}
      />
    );
  };

interface rowProps {
  id: number;
  request_types: IRequestType[];
}
type renderCheckboxFunc = (row: rowProps, rowIndex: number) => React.ReactNode;
type renderChangePermissionFunc = (
  rowIndex: number,
  code: IRequestType['code'],
  newVal: boolean
) => void;
