import React from 'react';
import { Formik, Form } from 'formik';
import Button from '@components/Button/Button';
import Textarea from '@components/Textarea/Textarea';
import { Input } from '@components/Input/Input';
import Checkbox from '@components/Checkbox/Checkbox';
import { HttpClient } from '@api/HttpClient';
import { setFloatFieldValue } from '@utils';
import { BOND_SPREAD_TYPES_TITLES, BOND_FLOATERS_TITLES } from '@constants';
import { amountMask, simpleRateMask, positiveNumberMask, spreadMask } from '@masks';
import { IDictionary, RateType, SpreadType } from '@models/base.model';
import type { ICreateProposalForBond } from '../BondDetailPage/request-bond.model';
import { BondProposalShema } from './CreateBondProposal.validation';
import AutoCheckForm from '@shared/components/AutoCheckForm';

import './CreateBondProposal.scss';

interface IProps {
  data?: object;
  spreadType: SpreadType | null;
  rateType: string;
  hasAmortization: boolean;
  requestId?: number;
  canEditMyProposal?: boolean;
  onCancel: () => void;
  onSave: (proposal: ICreateProposalForBond) => void;
}

type ICreateProposalForm = ICreateProposalForBond & {
  put_checkbox: boolean;
  call_checkbox: boolean;
  floater_type: IDictionary | null;
  spread_value: number | null;
  amortization_details: string;
  hasAmortization: boolean;
};

export function CreateBondProposal({
  data,
  spreadType,
  rateType,
  hasAmortization,
  requestId,
  canEditMyProposal,
  onSave,
  onCancel,
}: IProps): JSX.Element {
  const putRef = React.useRef<Input>(null);
  const callRef = React.useRef<Input>(null);

  const initialValues = {
    rate: 0,
    rate_type: rateType,
    hasAmortization,
    amount: 0,
    amount_garant: 0,
    maturity: 0,
    comment: '',
    call: null,
    put: null,
    spread_value: null,
    put_checkbox: false,
    call_checkbox: false,
    coupon_periodicity: 0,
    amortization_details: '',
    ...data,
  } as ICreateProposalForm;

  const handleSaveProposal = async (values: ICreateProposalForm): Promise<void> => {
    const {
      call_checkbox: callCheckbox,
      put_checkbox: putCheckbox,
      floater_type: floaterType,
      ...rest
    } = values;
    if (!values) return;

    const proposalId = values.id;

    const body = {
      ...rest,
      request_bond_id: requestId,
      rate_type: rateType,
      put: putCheckbox ? values.put : null,
      call: callCheckbox ? values.call : null,
    };

    const proposal = await (proposalId
      ? HttpClient.patch<Nullable<ICreateProposalForBond>, ICreateProposalForBond>(
          `participant/bond/proposal/${proposalId}/`,
          body
        )
      : HttpClient.post<Nullable<ICreateProposalForBond>, ICreateProposalForBond>(
          'participant/bond/proposal/',
          body
        ));

    onSave(proposal);
  };

  const suffix =
    rateType === 'OFZ'
      ? `кривой 
    ${BOND_FLOATERS_TITLES[rateType]} ${
      spreadType &&
      BOND_SPREAD_TYPES_TITLES[spreadType].charAt(0).toLowerCase() +
        BOND_SPREAD_TYPES_TITLES[spreadType].slice(1)
    }`
      : BOND_FLOATERS_TITLES[rateType];
  const spreadLabel = 'Спред к ' + suffix + '*';

  return (
    <Formik
      enableReinitialize={false}
      validateOnBlur
      validateOnChange
      initialValues={initialValues}
      validationSchema={BondProposalShema}
      onSubmit={handleSaveProposal}
    >
      {({ values, handleChange, errors, handleBlur, setFieldValue, touched, dirty, setValues }) => {
        return (
          <Form>
            <div className="create-bond-proposal">
              <div className="create-bond-proposal__columns">
                <div className="create-bond-proposal__col">
                  <div className="create-bond-proposal__row">
                    <Input
                      name="amount"
                      size="large"
                      label="Объем выпуска*"
                      wrapperClassName="create-bond-proposal__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount as unknown as string}
                      error={touched.amount ? (errors.amount as string) : undefined}
                      mask={amountMask}
                    />
                    <Input
                      name="amount_garant"
                      size="large"
                      label="Объем гарантированного участия*"
                      wrapperClassName="create-bond-proposal__input create-bond-proposal__input--long-label"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount_garant as unknown as string}
                      error={touched.amount_garant ? (errors.amount_garant as string) : undefined}
                      mask={amountMask}
                    />
                  </div>
                  <Input
                    mask={positiveNumberMask}
                    name="maturity"
                    size="large"
                    label="Срок облигации до погашения*"
                    labelClassName="create-bond-proposal__input-minicol"
                    wrapperClassName="create-bond-proposal__input create-bond-proposal__input--label-left"
                    placeholder="Укажите срок, лет"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.maturity as unknown as string}
                    error={touched.maturity ? (errors.maturity as string) : undefined}
                  />
                  <div className="create-bond-proposal__row">
                    <Checkbox
                      name="put_checkbox"
                      className="create-bond-proposal__input-minicol"
                      label="PUT-оферта"
                      onChange={() => {
                        putRef.current?.clearInput();
                        setValues((prevState) => ({
                          ...prevState,
                          put_checkbox: !prevState.put_checkbox,
                          put: null,
                        }));
                      }}
                      checked={values.put_checkbox}
                    />
                    <Input
                      ref={putRef}
                      disabled={!values.put_checkbox}
                      mask={positiveNumberMask}
                      name="put"
                      size="large"
                      placeholder="Срок погашения, лет"
                      wrapperClassName="create-bond-proposal__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.put as unknown as string}
                      error={touched.put ? (errors.put as string) : undefined}
                    />
                  </div>
                  <div className="create-bond-proposal__row">
                    <Checkbox
                      className="create-bond-proposal__input-minicol"
                      name="call_checkbox"
                      label="CALL-оферта"
                      onChange={() => {
                        callRef.current?.clearInput();
                        setValues((prevState) => ({
                          ...prevState,
                          call_checkbox: !prevState.call_checkbox,
                          call: null,
                        }));
                      }}
                      checked={values.call_checkbox}
                    />
                    <Input
                      ref={callRef}
                      disabled={!values.call_checkbox}
                      mask={positiveNumberMask}
                      name="call"
                      size="large"
                      placeholder="Срок погашения, лет"
                      wrapperClassName="create-bond-proposal__input create-bond-proposal__input-col"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.call as unknown as string}
                      error={touched.call ? (errors.call as string) : undefined}
                    />
                  </div>
                  {rateType !== RateType.Fix ? (
                    <div className="create-bond-proposal__row">
                      <Input
                        name="spread_value"
                        size="large"
                        label={spreadLabel}
                        placeholder="Величина спреда, ‱"
                        wrapperClassName="create-bond-proposal__input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.spread_value as unknown as string}
                        error={touched.spread_value ? (errors.spread_value as string) : undefined}
                        mask={spreadMask}
                      />
                      <Input
                        mask={simpleRateMask}
                        name="rate"
                        size="large"
                        label="Ставка*, %"
                        placeholder="Величина ставки, %"
                        wrapperClassName="create-bond-proposal__input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={setFloatFieldValue(values.rate)}
                        error={touched.rate || touched.rate ? (errors.rate as string) : undefined}
                      />
                    </div>
                  ) : (
                    <Input
                      mask={simpleRateMask}
                      name="rate"
                      size="large"
                      label="Ставка*, %"
                      placeholder="Величина ставки, %"
                      wrapperClassName="create-bond-proposal__input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={setFloatFieldValue(values.rate)}
                      error={touched.rate || touched.rate ? (errors.rate as string) : undefined}
                    />
                  )}
                  <div className="create-bond-proposal__note">
                    Данные указываются вручную и являются актуальными только на момент подачи
                    предложения.
                  </div>
                  <Input
                    label="Периодичность купона, дней*"
                    mask={positiveNumberMask}
                    name="coupon_periodicity"
                    size="large"
                    placeholder="Укажите периодичность"
                    wrapperClassName="create-bond-proposal__input"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coupon_periodicity as unknown as string}
                    error={
                      touched.coupon_periodicity ? (errors.coupon_periodicity as string) : undefined
                    }
                  />
                  {hasAmortization && (
                    <Textarea
                      name="amortization_details"
                      label="Амортизация*"
                      value={values.amortization_details}
                      placeholder="Сведения об амортизации"
                      size="large"
                      isClearable
                      className="create-bond-proposal__comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.amortization_details
                          ? (errors.amortization_details as string)
                          : undefined
                      }
                      resizable
                    />
                  )}
                </div>

                <div className="create-bond-proposal__col">
                  <Textarea
                    name="comment"
                    label="Комментарий"
                    value={values.comment as unknown as string}
                    error={touched.comment ? (errors.comment as string) : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isClearable
                    className="create-bond-proposal__comment"
                    size="large"
                    placeholder="Напишите Ваш комментарий"
                    resizable
                  />
                </div>
              </div>

              <div className="create-bond-proposal__required-hint">
                * Обязательные для заполнения поля
              </div>

              <div className="create-bond-proposal__actions">
                {canEditMyProposal && (
                  <Button type="primary" htmlType="submit">
                    {values.id ? 'Сохранить' : 'Опубликовать'}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                  type="warning"
                >
                  Отменить
                </Button>
              </div>
              <AutoCheckForm
                title="Прервать создание предложения"
                message="Вы уверены, что хотите прервать создание предложения? Все данные будут утеряны."
                onConfirm={() => {
                  onCancel();
                }}
                confirmBtnText="Прервать"
                cancelBtnText="Отмена"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
