import React from 'react';
import { BOND_RATE_TYPES, BOND_SPREAD_TYPES_TITLES, BOND_FLOATERS_TITLES } from '@constants';
import { renderMinMaxAmount, renderMaturity, renderOfferPutCallFromTo } from '@functions';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { InfoBox } from '@shared/components/InfoBox/InfoBox';
import { Status, RateType } from '@models/base.model';
import { AuthUtil } from '@services/auth/auth.util';
import { IRequestBondModel } from '../detail.model';

import './MainInfo.scss';

interface IProps {
  data?: IRequestBondModel;
}
export const MainInfo: React.FC<IProps> = ({ data }: IProps) => {
  if (!data) return null;
  const dataForBox = [
    [
      {
        label: 'Объём выпуска',
        value: renderMinMaxAmount({ minAmount: data.min_amount, maxAmount: data.max_amount }),
      },
      {
        label: 'Срок облигации до погашения',
        value: renderMaturity({ maturityFrom: data.maturity_from, maturityTo: data.maturity_to }),
      },
      {
        label: 'Оферта',
        value: renderOfferPutCallFromTo(data),
      },
      {
        label: 'Купон',
        value:
          BOND_RATE_TYPES[data.rate_type] +
          (data.rate_type !== RateType.Fix ? ` (${BOND_FLOATERS_TITLES[data.rate_type]})` : ''),
      },
      {
        label: 'Спред к кривой ОФЗ',
        value: BOND_SPREAD_TYPES_TITLES[data.spread_type],
        hidden: data.rate_type !== 'OFZ',
      },
      {
        label: 'Амортизация',
        value: data.has_amortization ? 'Есть' : 'Нет',
      },
    ],
    [
      {
        label: 'Услуга',
        value: 'Облигации',
      },
      { label: 'Комментарий (внешний)', value: data.comment_ext },
      { label: 'Комментарий (внутренний)', value: data.comment_int },
      {
        label: 'Даты размещения запроса',
        value: `с ${formatDate(data.start_ts)} по ${formatDate(data.end_ts)}`,
      },
      {
        label: 'Создан',
        value: `${AuthUtil.getFullNameAbbreviation(
          data.creator?.last_name || '',
          data.creator?.first_name || '',
          data.creator?.patronymic || ''
        )} ${formatDate(data.start_ts)}`,
      },
    ],
  ];

  if (data?.status === Status.Completed) {
    dataForBox[1].push({ label: 'Комментарий при завершении', value: data.comment_result });
  }

  return <InfoBox className="customer-bond-detail__info-box" title={data.name} data={dataForBox} />;
};
