import React from 'react';
import Tabs from '@components/Tabs/Tabs';
import { useLocation } from 'react-router-dom';
import PageLayout from '@layouts/PageLayout/PageLayout';
import { ROUTES } from '@routes';
import type { ITab } from '../../../../types';
import { ParticipantRequests } from './ParticipantRequests';
import { Customers } from './Customers/Customers';

import './MainPage.scss';

export const ParticipantMainPage: React.FC = () => {
  const { pathname } = useLocation();

  const requestType = pathname.includes('/deposit')
    ? 'deposit'
    : pathname.includes('/bond')
    ? 'bond'
    : 'credit';

  const path = `/${requestType}s`;
  const requestsPath = requestType !== 'bond' ? path + ROUTES.POSITIONS : path + ROUTES.REQUESTS;

  const getTabs = (path: string): ITab[] => {
    return [
      {
        title: 'Запросы',
        component: <ParticipantRequests />,
        path: requestsPath,
      },
      {
        title: 'Заказчики',
        component: <Customers />,
        path: path + ROUTES.CUSTOMERS,
      },
    ];
  };

  return (
    <PageLayout className="main-page">
      <Tabs tabs={getTabs(path)} />
    </PageLayout>
  );
};
