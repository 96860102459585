import type React from 'react';
import cn from 'classnames';

import './Button.scss';

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type?: 'primary' | 'secondary' | 'link' | 'text' | 'without-border' | 'warning' | 'brand';
  size?: 'large' | 'medium' | 'small';
  icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  iconPosition?: 'left' | 'right';
  fullWidth?: boolean;
  htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  buttonRef?: React.RefObject<HTMLButtonElement>;
  loading?: boolean;
  brand?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    icon,
    iconPosition = 'left',
    children,
    htmlType,
    buttonRef,
    fullWidth,
    type = 'secondary',
    size = 'medium',
    className,
    loading,
    disabled,
    ...buttonProps
  } = props;

  const buttonClassName = cn('button', className, `button--size-${size}`, {
    'button--primary': type === 'primary',
    'button--secondary': type === 'secondary',
    'button--link': type === 'link',
    'button--text': type === 'text',
    'button--without-border': type === 'without-border',
    'button--warning': type === 'warning',
    'button--full-width': fullWidth,
    'button--brand': type === 'brand',
  });

  const resultDisabled = disabled ?? loading;

  return (
    <button
      type={htmlType}
      ref={buttonRef}
      className={buttonClassName}
      disabled={resultDisabled}
      {...buttonProps}
    >
      {icon && iconPosition === 'left' && icon}
      {children}
      {icon && iconPosition === 'right' && icon}
    </button>
  );
};

Button.defaultProps = {
  htmlType: 'button',
};

export default Button;
