import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

const onlyPositiveNumbers = Yup.number().integer('Целое число').min(1, 'Число больше 0');

export const BondProposalShema = Yup.object().shape({
  amount: onlyPositiveNumbers.required(REQUIRED_MESSAGE),
  amount_garant: onlyPositiveNumbers
    .test('amount_garant < amount', 'Превосходит объём выпуска', function (value) {
      // @ts-expect-error eslint-disable-next-line
      return value <= this.options.context.amount;
    })
    .required(REQUIRED_MESSAGE),

  maturity: onlyPositiveNumbers.required(REQUIRED_MESSAGE),
  put: onlyPositiveNumbers
    .nullable()
    .test('need checkout', REQUIRED_MESSAGE, (item, context) =>
      context.parent.put_checkbox ? !!item : true
    ),
  call: onlyPositiveNumbers
    .nullable()
    .test('need checkout', REQUIRED_MESSAGE, (item, context) =>
      context.parent.call_checkbox ? !!item : true
    ),
  rate_type: Yup.string().required(REQUIRED_MESSAGE),
  spread_value: Yup.number().when('rate_type', {
    is: (rateType?: string) => rateType !== 'FIX',
    then: () => Yup.number().min(0, 'Число больше или равно 0').required(REQUIRED_MESSAGE),
    otherwise: (schema) => schema.nullable(),
  }),
  rate: Yup.number().min(1, 'Число больше 0').required(REQUIRED_MESSAGE),
  coupon_periodicity: onlyPositiveNumbers.required(REQUIRED_MESSAGE),
  amortization_details: Yup.string().when('hasAmortization', {
    is: (hasAmortization: boolean) => hasAmortization,
    then: (schema) => {
      return schema
        .trim()
        .nullable()
        .trim()
        .max(300, 'Ввод более 300 символов запрещен')
        .required(REQUIRED_MESSAGE);
    },
    otherwise: (schema) => schema.nullable(),
  }),
  comment: Yup.string().trim().nullable().trim().max(300, 'Ввод более 300 символов запрещен'),
});
