import React from 'react';
import { formatDateFromUTCtoMSC as formatDate } from '@date-time';
import { Link } from 'react-router-dom';
import { ReactComponent as PaperClip } from '@shared/icons/communicationsPage/paperclip.svg';
import { hasCustomerRole, hasParticipantRole } from '@roles';
import { useCurrentUserData } from '@services/user/userDataContext';
import { IDialogDetails } from '@models/communication.model';

import './ChatHeader.scss';

interface IChatHeaderProps {
  data?: IDialogDetails;
}
export const ChatHeader: React.FC<IChatHeaderProps> = ({ data }) => {
  const userInfo = useCurrentUserData();
  const isCustomer = hasCustomerRole(userInfo?.roles ?? []);
  const isParticipant = hasParticipantRole(userInfo?.roles ?? []);
  const groupLink =
    data && isCustomer
      ? `/${data.request_type}s/requests/${data.request_id}`?.toLowerCase()
      : data && isParticipant
      ? `/${data.request_type}s`.toLowerCase()
      : '';

  return (
    <>
      <div className="chat-header">
        <div className="chat-header__main">
          <div className="chat-header__name">{data?.conversation_partner?.name}</div>
          <time className="chat-header__date">{formatDate(data?.create_ts)}</time>
        </div>

        <div className="chat-header__topic">{data?.title || 'Без темы'}</div>
      </div>
      {data?.is_group && (
        <div className="linked-requests">
          <PaperClip className="linked-requests__icon" />
          Запросы:
          <ul className="linked-requests__list">
            {data.position_ids.map((el, index) => (
              <span key={el}>
                <Link
                  to={`${groupLink}/positions/${el}`}
                  target="_blank"
                  className="linked-requests__link"
                >
                  {el}
                </Link>
                {index + 1 < data.position_ids.length ? ', ' : ''}
              </span>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
