import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';

export const FormForReportSchema = Yup.object().shape({
  request_type: Yup.object().nullable().required(REQUIRED_MESSAGE),
  company: Yup.object().nullable().required(REQUIRED_MESSAGE),
  range: Yup.array()
    .test('range is filled', REQUIRED_MESSAGE, (range?: Array<Date | null>) => {
      return range ? !!range[0] && !!range[1] : false;
    })
    .required(REQUIRED_MESSAGE),
});
