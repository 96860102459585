import React from 'react';
import { HttpClient } from '@api/HttpClient';
import Notification from '@components/Modal/Notification/Notification';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { useParams } from 'react-router-dom';
import { MEMBER_STATUSES_PARAMS } from '@constants';
import { MainInfo } from './MainInfo/MainInfo';
import { UsersTable } from './UsersTable/UsersTable';
import { ParticipantsTable } from './ParticipantsTable/ParticipantsTable';
import type {
  ICustomerMainInfoForAdmin,
  IUserOfCustomerForAdmin,
  IParticipantOfCustomerForAdmin,
  ICustomerRequestTypePermission,
  IUserRequestTypePermission,
} from '../operator.model';

import './CustomerDetailPage.scss';

export const CustomerDetailPage: React.FC = () => {
  const { customerId } = useParams();

  const [isBlockModalOpen, setIsBlockModalOpen] = React.useState<boolean>(false);

  const [mainData, setMainData] = React.useState<ICustomerMainInfoForAdmin>(
    {} as ICustomerMainInfoForAdmin
  );
  const [users, setUsers] = React.useState<IUserOfCustomerForAdmin[]>([]);
  const [participants, setParticipants] = React.useState<IParticipantOfCustomerForAdmin[]>([]);

  const loadParticipants = (): void => {
    HttpClient.get<IParticipantOfCustomerForAdmin[]>(
      `operator/companies/customers/${customerId}/participants/`
    ).then(setParticipants);
  };

  const loadMainInfo = (): void => {
    HttpClient.get<ICustomerMainInfoForAdmin>(
      `operator/companies/customers/${customerId}/base/`
    ).then(setMainData);
  };

  const loadUsers = (): void => {
    HttpClient.get<IUserOfCustomerForAdmin[]>(
      `operator/companies/customers/${customerId}/users/`
    ).then(setUsers);
  };

  React.useEffect(() => {
    loadMainInfo();
    loadUsers();
    loadParticipants();
  }, [customerId]);

  const handleSaveParticipantsTable = (data: IParticipantOfCustomerForAdmin[]): void => {
    if (!customerId) return;

    const dataForUpdatePermission: ICustomerRequestTypePermission[] = [];

    data.forEach((company) => {
      company.request_types.forEach((type) => {
        dataForUpdatePermission.push({
          participant_company_id: company.id,
          request_type: type.code,
          is_active: type.is_active,
        });
      });
    });

    HttpClient.put<ICustomerRequestTypePermission[]>(
      `operator/companies/${customerId}/request_type_permissions/`,
      dataForUpdatePermission
    ).then(() => {
      loadParticipants();
    });
  };

  const handleSaveUsersTable = (data: IUserOfCustomerForAdmin[]): void => {
    if (!customerId) return;

    const dataForUpdatePermission: IUserRequestTypePermission[] = [];

    data.forEach((user) => {
      user.request_types.forEach((type) => {
        dataForUpdatePermission.push({
          customer_company_id: +customerId,
          customer_user_id: user.id,
          request_type: type.code,
          is_active: type.is_active,
        });
      });
    });

    HttpClient.put<IUserRequestTypePermission[]>(
      'operator/users/request_type_permissions/',
      dataForUpdatePermission
    ).then(() => {
      setUsers(data);
    });
  };

  const handleBlockCompany = (): void => {
    const companyId = mainData.id;
    HttpClient.patch(`operator/companies/${companyId}/BLOCKED/`).then(() => {
      setMainData({ ...mainData, is_blocked: true });
    });
  };

  const handleUnblockCompany = (): void => {
    const companyId = mainData.id;
    HttpClient.patch(`operator/companies/${companyId}/ACTIVE/`).then(() => {
      setMainData({ ...mainData, is_blocked: false });
    });
  };

  const customerStatus = mainData.is_blocked ? 'BLOCKED' : 'ACTIVE';

  return (
    <>
      <DetailLayout
        title="Просмотр компании-заказчика"
        className="customer-detail"
        mainInfoClassName="customer-detail-info"
        extraInfoClassName="customer-detail-users-table"
        badgeParams={MEMBER_STATUSES_PARAMS[customerStatus]}
        mainInfo={
          <MainInfo
            data={mainData}
            onEdit={loadMainInfo}
            onBlock={() => {
              setIsBlockModalOpen(true);
            }}
            onActivate={() => {
              handleUnblockCompany();
            }}
          />
        }
        extraInfo={<UsersTable data={users} onEdit={handleSaveUsersTable} />}
      >
        <ParticipantsTable data={participants} onEdit={handleSaveParticipantsTable} />
      </DetailLayout>

      {isBlockModalOpen && (
        <Notification
          isOpen
          title="Блокировка компании"
          message="Вы уверены, что хотите заблокировать компанию? Блокировка затронет всех привязанных пользователей."
          confirmBtnText="Заблокировать"
          cancelBtnText="Отмена"
          onConfirm={() => {
            handleBlockCompany();
            setIsBlockModalOpen(false);
          }}
          onCancel={() => {
            setIsBlockModalOpen(false);
          }}
        />
      )}
    </>
  );
};
