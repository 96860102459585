import { HttpClient } from '@api/HttpClient';


export class LogsService {

    public static async saveLogs(details: string): Promise<void> {
        await HttpClient.post('common/logs/', { details });
    }
}

export default LogsService;
