import { TRACKS_ACCESS } from '@constants';

export const ROUTES = {
  DEFAULT: '/',
  CREDITS: '/credits',
  REQUESTS: '/requests',
  POSITIONS: '/positions',
  PARTICIPANTS: '/participants',
  CUSTOMERS: '/customers',
  OPERATORS: '/operators',
  USERS: '/users',
  LOG: '/log',
  DEPOSITS: '/deposits',
  BONDS: '/bonds',
  TICKETS: '/tickets',
  ADMINISTRATION: '/administration',
  SETTINGS: '/settings',
  SUPPORT: '/support',
  INBOX: '/inbox',
  INFO: '/info',
  PROFILE: '/profile',
  PRIVACY_POLICY: '/privacy-policy',
  PERSONAL_DATA_PROCESSING_POLICY: '/personal-data-processing-policy',
  TERMS_OF_USE: '/terms-of-use',
  RESET_PASSWORD: '/reset-password',
  SET_PASSWORD: '/set-password',
  COMMUNICATIONS: '/communications',
  DIALOGS: '/dialogs',
  ARCHIVE: '/archive',
  REPORT: '/report',
  INFO_FIELD: '/info-field',
  REPORTS: '/reports',
  PROPOSALS_LIST_REPORT: '/proposals-list-report',
} as Record<string, string>;

export const ROUTES_WITH_BREADCRUMBS = [
  { path: ROUTES.DEFAULT, breadcrumb: null },
  { path: ROUTES.CREDITS, breadcrumb: 'Кредиты' },
  { path: ROUTES.CREDITS + ROUTES.REQUESTS, breadcrumb: 'Запросы' },
  {
    path: ROUTES.CREDITS + '/:customerId',
    breadcrumb: 'Запросы',
  },
  {
    path: ROUTES.CREDITS + '/:customerId' + ROUTES.REQUESTS,
    breadcrumb: null,
  },
  { path: ROUTES.DEPOSITS, breadcrumb: 'Депозиты' },
  { path: ROUTES.DEPOSITS + ROUTES.REQUESTS, breadcrumb: 'Запросы' },
  { path: ROUTES.CREDITS + ROUTES.PARTICIPANTS, breadcrumb: 'Участники' },
  {
    path: ROUTES.CREDITS + ROUTES.REQUESTS + '/:requestId',
    breadcrumb: 'Детализация группирующего запроса',
  },
  {
    path: ROUTES.CREDITS + ROUTES.REQUESTS + '/:requestId/details',
    breadcrumb: 'Таблица предложений',
  },
  {
    path: ROUTES.CREDITS + ROUTES.REQUESTS + '/:requestId' + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  {
    path: ROUTES.CREDITS + ROUTES.REQUESTS + '/:requestId' + ROUTES.POSITIONS,
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.CREDITS + ROUTES.REQUESTS + '/:requestId' + ROUTES.POSITIONS + '/:positionId',
    breadcrumb: null,
  },
  {
    path: ROUTES.CREDITS + ROUTES.REQUESTS + '/create',
    breadcrumb: 'Новый запрос',
  },
  { path: ROUTES.DEPOSITS, breadcrumb: 'Депозиты' },
  { path: ROUTES.DEPOSITS + ROUTES.REQUESTS, breadcrumb: 'Запросы' },
  {
    path: ROUTES.DEPOSITS + '/:customerId',
    breadcrumb: 'Запросы',
  },
  {
    path: ROUTES.DEPOSITS + '/:customerId' + ROUTES.REQUESTS,
    breadcrumb: null,
  },
  { path: ROUTES.DEPOSITS + ROUTES.PARTICIPANTS, breadcrumb: 'Участники' },
  {
    path: ROUTES.DEPOSITS + ROUTES.REQUESTS + '/:requestId',
    breadcrumb: 'Детализация группирующего запроса',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.REQUESTS + '/:requestId/details',
    breadcrumb: 'Таблица предложений',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.REQUESTS + '/:requestId' + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.REQUESTS + '/:requestId' + ROUTES.POSITIONS,
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.REQUESTS + '/:requestId' + ROUTES.POSITIONS + '/:positionId',
    breadcrumb: null,
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.REQUESTS + '/create',
    breadcrumb: 'Новый запрос',
  },
  // для участника
  { path: ROUTES.CREDITS + ROUTES.POSITIONS, breadcrumb: 'Запросы' },
  { path: ROUTES.CREDITS + ROUTES.PARTICIPANTS, breadcrumb: 'Участники' },
  {
    path: ROUTES.CREDITS + ROUTES.POSITIONS + '/:requestPositionId',
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.CREDITS + ROUTES.POSITIONS + '/:requestPositionId/proposal',
    breadcrumb: null,
  },
  {
    path: ROUTES.CREDITS + ROUTES.POSITIONS + '/:requestPositionId/proposal/:proposalId',
    breadcrumb: null,
  },
  {
    path: ROUTES.CREDITS + ROUTES.POSITIONS + '/:requestPositionId/proposal/:proposalId/edit',
    breadcrumb: 'Редактирование предложения',
  },
  {
    path: ROUTES.CREDITS + ROUTES.POSITIONS + '/:requestPositionId/create',
    breadcrumb: 'Создание предложения',
  },
  //
  { path: ROUTES.DEPOSITS + ROUTES.POSITIONS, breadcrumb: 'Запросы' },
  { path: ROUTES.DEPOSITS + ROUTES.PARTICIPANTS, breadcrumb: 'Участники' },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId',
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/proposal',
    breadcrumb: null,
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/proposal/:proposalId',
    breadcrumb: null,
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/proposal/:proposalId/edit',
    breadcrumb: 'Редактирование предложения',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/create',
    breadcrumb: 'Создание предложения',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/proposal/:proposalId',
    breadcrumb: null,
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/proposal/:proposalId/edit',
    breadcrumb: 'Редактирование предложения',
  },
  {
    path: ROUTES.DEPOSITS + ROUTES.POSITIONS + '/:requestPositionId/create',
    breadcrumb: 'Создание предложения',
  },
  // облигации
  {
    path: ROUTES.BONDS,
    breadcrumb: 'Облигации',
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS,
    breadcrumb: 'Запросы',
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/create',
    breadcrumb: 'Новый запрос',
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/:requestId',
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/:requestId' + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/:requestId/proposals',
    breadcrumb: null,
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/:requestId/proposals/create',
    breadcrumb: null,
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/:requestId/proposals/:proposalId',
    breadcrumb: null,
  },
  {
    path: ROUTES.BONDS + ROUTES.REQUESTS + '/:requestId/proposals/:proposalId/edit',
    breadcrumb: null,
  },
  {
    path: ROUTES.BONDS + '/:customerId',
    breadcrumb: 'Запросы',
  },
  {
    path: ROUTES.BONDS + '/:customerId' + ROUTES.REQUESTS,
    breadcrumb: null,
  },
  {
    path: ROUTES.BONDS + '/:customerId' + ROUTES.REQUESTS + '/:requestId',
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.BONDS + '/:customerId' + ROUTES.REQUESTS + '/:requestId' + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  // администрация
  {
    path: ROUTES.ADMINISTRATION,
    breadcrumb: 'Администрирование',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.PARTICIPANTS + '/create',
    breadcrumb: 'Добавление компании-участника',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.PARTICIPANTS + '/:participantId',
    breadcrumb: 'Просмотр компании-участника',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.CUSTOMERS,
    breadcrumb: 'Заказчики',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.CUSTOMERS + '/create',
    breadcrumb: 'Добавление компании-заказчика',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.CUSTOMERS + '/:customerId',
    breadcrumb: 'Просмотр компании-заказчика',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.CUSTOMERS + '/:customerId/edit',
    breadcrumb: null,
  },
  {
    path: ROUTES.CREDITS + '/:customerId' + ROUTES.REQUESTS + '/:requestId',
    breadcrumb: 'Детализация группирующего запроса',
  },
  {
    path: ROUTES.CREDITS + '/:customerId' + ROUTES.REQUESTS + '/:requestId/details',
    breadcrumb: 'Таблица предложений',
  },
  {
    path: ROUTES.CREDITS + '/:customerId' + ROUTES.REQUESTS + '/:requestId' + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  {
    path:
      ROUTES.CREDITS +
      '/:customerId' +
      ROUTES.REQUESTS +
      '/:requestId/' +
      ROUTES.POSITIONS +
      '/:requestPositionId',
    breadcrumb: 'Детализация запроса',
  },
  {
    path: ROUTES.DEPOSITS + '/:customerId' + ROUTES.REQUESTS + '/:requestId',
    breadcrumb: 'Детализация группирующего запроса',
  },
  {
    path: ROUTES.DEPOSITS + '/:customerId' + ROUTES.REQUESTS + '/:requestId/details',
    breadcrumb: 'Таблица предложений',
  },
  {
    path: ROUTES.DEPOSITS + '/:customerId' + ROUTES.REQUESTS + '/:requestId' + ROUTES.PARTICIPANTS,
    breadcrumb: 'Участники',
  },
  {
    path:
      ROUTES.DEPOSITS +
      '/:customerId' +
      ROUTES.REQUESTS +
      '/:requestId/' +
      ROUTES.POSITIONS +
      '/:requestPositionId',
    breadcrumb: 'Детализация запроса',
  },
  //
  {
    path: ROUTES.ADMINISTRATION + ROUTES.USERS,
    breadcrumb: 'Пользователи',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.USERS + '/create',
    breadcrumb: 'Добавление пользователя',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.USERS + '/:userId',
    breadcrumb: null,
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.USERS + '/:userId/details',
    breadcrumb: 'Просмотр пользователя',
  },
  {
    path: ROUTES.ADMINISTRATION + ROUTES.USERS + '/:userId/edit',
    breadcrumb: 'Редактирование пользователя',
  },
  {
    path: ROUTES.INBOX,
    breadcrumb: 'Техническая поддержка',
  },
  {
    path: ROUTES.INBOX + ROUTES.TICKETS,
    breadcrumb: 'Обращения',
  },
  {
    path: ROUTES.INBOX + ROUTES.TICKETS + '/create',
    breadcrumb: 'Новое обращение',
  },
  {
    path: ROUTES.INBOX + ROUTES.TICKETS + '/:ticketId',
    breadcrumb: 'Просмотр обращения',
  },
  {
    path: ROUTES.SUPPORT,
    breadcrumb: 'Техническая поддержка',
  },
  {
    path: ROUTES.SUPPORT + ROUTES.TICKETS,
    breadcrumb: 'Обращения',
  },
  {
    path: ROUTES.SUPPORT + ROUTES.TICKETS + '/create',
    breadcrumb: 'Новое обращение',
  },
  {
    path: ROUTES.SUPPORT + ROUTES.TICKETS + '/:ticketId',
    breadcrumb: 'Просмотр обращения',
  },
];

// TODO: использовать на страницах?
export function useRequestType(pathname: string): string {
  return pathname.includes(ROUTES.DEPOSITS)
    ? TRACKS_ACCESS.DEPOSIT
    : pathname.includes(ROUTES.BONDS)
    ? TRACKS_ACCESS.BONDS
    : pathname.includes(ROUTES.CREDITS)
    ? TRACKS_ACCESS.CREDIT
    : '';
}
