import plural from 'plural-ru';
import { formatToTimeZone, parseFromTimeZone, convertToTimeZone } from 'date-fns-timezone';
import { format as formatDateTimeFns } from 'date-fns';

export const pluralYear = (countOfYears: number | null): string =>
  countOfYears ? plural(countOfYears, '%d год', '%d года', '%d лет') : '';

export const formatDateFromUTCtoMSC = (
  date?: Date | string,
  format = DATETIME_FORMAT
): string | undefined => {
  if (!date) return;
  return formatToTimeZone(date, format, { timeZone: 'Europe/Moscow' });
};

export const formatDateFromMSCtoUTC = (date?: Date | null): string | undefined => {
  if (date) {
    // читаем время, которое ввел пользователь, как есть ("локальное")
    // прочитанное время считаем московским, вне зависимости от реальной таймзоны пользователя (по ТЗ)
    const result = parseFromTimeZone(
      date.toLocaleDateString('ru') + ' ' + date.toLocaleTimeString('ru'),
      'DD.MM.YYYY HH:mm:ss',
      {
        timeZone: 'Europe/Moscow',
      }
    );
    return result.toISOString();
  } else {
    return undefined;
  }
};

export const getMSCNow = (): Date => {
  return convertToTimeZone(new Date(), { timeZone: 'Europe/Moscow' });
};

export const formatLocaleDate = (date?: Date | null, format = DATE_FNS_DATETIME_FORMAT): string => {
  if (!date) return '';
  return formatDateTimeFns(date, format);
};

// форматы для date-fns-timezone
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const READABLE_DATETIME_FORMAT = 'DD.MM.YYYY в HH:mm';
export const ONLY_DATE_FORMAT = 'DD.MM.YYYY';
export const ONLY_TIME_FORMAT = 'HH:mm';

// форматы для date-fns
export const DATE_FNS_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const DATE_FNS_ONLY_DATE_FORMAT = 'yyyy-MM-dd';

// форматы для datepicker и rangedatepicker
export const DATEPICKER_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';
