import React from 'react';
import cn from 'classnames';
import { useKeycloak } from '@react-keycloak/web';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as FinsageLogo } from '../../logo.svg';
import { ReactComponent as Support } from '@shared/icons/support.svg';
import { ReactComponent as Question } from '@shared/icons/question.svg';
import { ReactComponent as Person } from '@shared/icons/person.svg';
import { menu } from './menu';
import { ROUTES } from '@routes';
import { ROLES, hasCustomerRole } from '@roles';
import AuthService from '@services/auth/auth.service';
import { AuthUtil } from '@services/auth/auth.util';
import { getMenuByAccess, getMenuByTracks } from '@utils';
import Tooltip from '../Tooltip/Tooltip';
import Badge from '../Badge/Badge';

import { useCurrentUserData } from '@services/user/userDataContext';

import './Navbar.scss';

function Navbar({ children }: { children: React.ReactNode }): JSX.Element {
  const location = useLocation();
  const pathname = location.pathname;
  const userInfo = useCurrentUserData();
  const userGroups = userInfo?.roles ?? [];

  const AVAILABLE_MENU = React.useMemo(() => {
    let newMenu = getMenuByAccess(menu, userGroups);
    if (hasCustomerRole(userGroups)) newMenu = getMenuByTracks(newMenu, userInfo?.requestTypes);
    return newMenu;
  }, [userGroups]);

  const logoFile = userInfo?.company?.logoFile;
  return (
    <nav className="custom-navbar navbar fixed-top navbar-expand-lg navbar-light px-4 py-2">
      <div className="container-fluid">
        {children !== undefined ? (
          <>
            {children}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <CustomLinks />
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="custom-navbar__logo">
              <Link to={ROUTES.DEFAULT}>
                {logoFile ? <img src={logoFile} alt="logo" /> : <FinsageLogo />}
              </Link>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto">
                {AVAILABLE_MENU.map((el) => {
                  const isActive = pathname.includes(el.path);
                  return (
                    <li className="nav-item" key={el.path}>
                      <Link to={el.path} className={cn('nav-link', isActive && 'active')}>
                        {el.title}
                        {el.path.includes(ROUTES.COMMUNICATIONS) &&
                          !!userInfo?.unreadDialogsCount &&
                          userInfo?.unreadDialogsCount > 0 && (
                            <Badge
                              className="counter"
                              title={
                                userInfo?.unreadDialogsCount <= 9
                                  ? userInfo?.unreadDialogsCount.toString()
                                  : '9+'
                              }
                              backgroundColor="var(--negative-100-color)"
                              size="small"
                              color="var(--white-color)"
                            />
                          )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <CustomLinks />
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;

const CustomLinks: React.FC = () => {
  const { keycloak } = useKeycloak();

  const userInfo = useCurrentUserData();
  const location = useLocation();
  const pathname = location.pathname;
  const userGroups = userInfo?.roles ?? [];

  return (
    <div className="custom-links">
      {(userGroups.includes(ROLES.CUSTOMER) || userGroups.includes(ROLES.PARTICIPANT)) && (
        <div
          className={cn(
            'custom-links__wrapper',
            pathname === ROUTES.SUPPORT + ROUTES.TICKETS && 'active'
          )}
        >
          <Link to={ROUTES.SUPPORT + ROUTES.TICKETS} className="nav-link">
            <Tooltip text="Поддержка">
              <div className="custom-links__support">
                <Support />
                {userInfo?.unreadOperatorMessagesCount !== 0 && (
                  <div className="custom-links__support-badge"></div>
                )}
              </div>
            </Tooltip>
          </Link>
        </div>
      )}

      <div className={cn('custom-links__wrapper', pathname === ROUTES.INFO && 'active')}>
        <Link to={ROUTES.INFO} className="nav-link">
          <Tooltip text="Справка">
            <Question />
          </Tooltip>
        </Link>
      </div>

      {AuthService.isAuthenticated(keycloak) ? (
        <>
          <div className={cn('custom-links__wrapper', pathname === ROUTES.PROFILE && 'active')}>
            <Link to={ROUTES.PROFILE} className="nav-link custom-links__person">
              <Person />
            </Link>
          </div>

          <div className="credentials">
            <div className="credentials__name">
              {AuthUtil.getFullNameAbbreviation(
                userInfo?.lastName ?? '',
                userInfo?.firstName ?? '',
                userInfo?.patronymic ?? ''
              )}
            </div>
            <span className="credentials__company">{userInfo?.company?.name}</span>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
