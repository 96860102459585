import { IMenuItem } from './types';
import { ROUTES } from '@routes';

export const snakeToCamel = (string: string): string => {
  return string
    .split('_')
    .map((str: string, i) => {
      if (i === 0) return str;
      return upperFirst(str);
    })
    .join('');
};

const upperFirst = (string: string): string => {
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
};

const isObject = (obj: unknown): boolean => {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
};

export const transformObjectKeys = (
  obj: Record<string, string | Record<string, string>>
): Record<string, string | Record<string, string>> => {
  const keys = Object.keys(obj);
  const p = keys.reduce((acc, key) => {
    let value = null;
    if (isObject(obj[key])) {
      value = transformObjectKeys(obj[key] as Record<string, string>);
    } else value = obj[key];
    return { ...acc, [snakeToCamel(key)]: value };
  }, {});
  return p;
};

export function download(data: Blob, filename: string): void {
  const a = document.createElement('a');
  const file = new Blob([data]);
  a.innerText = '';
  a.href = URL.createObjectURL(file);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export const setNumberOrEmpty = (value: unknown): number | null =>
  Number.isNaN(value) ? null : +(value as string);

export const setFloatFieldValue = (value: unknown): string =>
  Number.isNaN(value) ? '' : (value as number).toString().replace('.', ',');

export const removeEmptyProps = (obj: Record<string, unknown>): Record<string, unknown> => {
  const newObj: Record<string, unknown> = {};
  for (const key in obj) {
    const fValue = obj[key];
    if (
      fValue === '' ||
      fValue === undefined ||
      fValue === null ||
      (Array.isArray(fValue) && !fValue.length)
    )
      continue;
    newObj[key] = fValue;
  }
  return newObj;
};

export async function getBase64(file?: File): Promise<string> {
  if (!file) return await Promise.resolve('');

  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const src = reader.result as string;
      resolve(src);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export const getMenuByAccess = (menu: IMenuItem[], userGroups?: string[]): IMenuItem[] => {
  return userGroups
    ? menu.filter((menuItem) => {
        return userGroups.some((s) => menuItem.rights.includes(s));
      })
    : [];
};

export const getMenuByTracks = (menu: IMenuItem[], userAvailableTracks?: string[]): IMenuItem[] =>
  userAvailableTracks
    ? menu.filter(
        (menuItem) =>
          userAvailableTracks.includes(menuItem.track ?? '') ||
          menuItem.path === ROUTES.COMMUNICATIONS || // заказчики всегда видят раздел
          menuItem.path === ROUTES.REPORTS // заказчики всегда видят раздел
      )
    : [];

export function cloneDeep<T>(src: T): T {
  return JSON.parse(JSON.stringify(src));
}

export const recursiveTimeout = (fn: () => Promise<void>, interval: number): void => {
  const executeFunction = async (): Promise<void> => {
    try {
      await fn();
    } finally {
      setTimeout(executeFunction, interval);
    }
  };

  setTimeout(executeFunction, interval);
};
