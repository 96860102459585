import type React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@components/Table';
import Filters from '@shared/components/SearchFilters/SearchFilters';
import type * as TableTypes from '@components/Table/types';
import { useTableWithParams, useMounted } from '@hooks';
import { formatPhone, getFullname } from '@functions';
import type { IOperatorForAdmin } from './operator.model';
import { ROUTES } from '@routes';

import './Operators.scss';

const Operators: React.FC = () => {
  const mounted = useMounted();
  const navigate = useNavigate();

  const {
    data,
    fetchCurrentPage,
    isLoading,
    controlledPageCount,
    pageIndex,
    totalCount,
    searchStr,
    setSearchStr,
  } = useTableWithParams('/operator/users/type/operator/', mounted, 'get');

  return (
    <div className="admin-operators">
      <div className="admin-operators__actions">
        <Filters
          className="admin-operators__filters"
          searchStr={searchStr}
          setSearchStr={setSearchStr}
        />
      </div>
      <Table
        columns={columns as TableTypes.Column[]}
        data={data as Array<TableTypes.CustomRowData<IOperatorForAdmin>>}
        onFetchData={fetchCurrentPage}
        withPagination
        isLoading={isLoading}
        controlledPageCount={controlledPageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        withAlternating
        onRowClick={(row) => {
          // @ts-expect-error check types
          navigate(ROUTES.ADMINISTRATION + ROUTES.USERS + '/' + row.original.id + '/details');
        }}
      />
    </div>
  );
};

export default Operators;

const columns = [
  {
    Header: 'ФИО',
    accessor: ({
      firstName,
      lastName,
      patronymic,
    }: {
      firstName: string;
      lastName: string;
      patronymic: string;
    }) => getFullname(lastName, firstName, patronymic),
  },
  {
    Header: 'Номер телефона',
    accessor: ({ phone }: { phone: string }) => formatPhone(phone),
  },
  {
    Header: 'Электронная почта',
    accessor: 'email',
  },
];
