import React from 'react';
import { useParams, useNavigate, To } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { HttpClient } from '@api/HttpClient';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { REQUEST_STATUSES_PARAMS, BOND_FLOATERS_TITLES } from '@constants';
import { Status } from '@models/base.model';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import { renderAmount, renderProposalOffer, renderCoupon } from '@functions';
import { pluralYear } from '@date-time';
import { Notification } from '@components/Modal/Notification/Notification';
import Container from '@components/Container/Container';
import { ROUTES } from '@routes';
import { CreateDialogModal } from '@shared/components/CreateDialogModal/CreateDialogModal';
import { RequestModalCancel } from '@shared/components/RequestModalCancel/RequestModalCancel';

import type { IRequestBondModel } from './request-bond.model';
import { MyProposals } from './MyProposals/MyProposals';
import { ProposalDetail } from './ProposalDetail/ProposalDetail';
import { CreateBondProposal } from '../CreateBondProposal/CreateBondProposal';
import { EmptyProposals } from '../components/EmptyProposals/EmptyProposals';
import { MainInfo } from './MainInfo/MainInfo';
import { CommunicationService } from '@services/communication/communication.service';

import './BondDetailPage.scss';

export const ParticipantBondDetailPage: React.FC = () => {
  const { requestId } = useParams();
  const [data, setData] = React.useState<IRequestBondModel>({} as IRequestBondModel);
  const [isOpen, setIsOpen] = React.useState(false);

  const { proposalId } = useParams();
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();

  const [showModalCancel, setShowModalCancel] = React.useState(false);
  const [recipient, setRecipient] = React.useState<ISimilarCompany | null>(null);

  let prevLink = breadcrumbs[3]?.key as unknown;
  if (!prevLink) prevLink = -1;

  const loadData = (): void => {
    HttpClient.get<IRequestBondModel>(`participant/bond/requests/${requestId}/`).then((data) => {
      setData(data);
    });
  };
  const cancelProposal = async (): Promise<void> => {
    await HttpClient.patch<IRequestBondModel>(
      `participant/bond/proposal/${proposalId}/cancel/`
    ).then((data) => {
      setData(data);
    });
  };

  const getDialogId = async (): Promise<void> => {
    const result = await CommunicationService.searchActiveDialogByParams({
      recipient_company_id: data.company_creator.id,
      request_type: 'BOND',
      request_bond_id: requestId,
    });
    if (result.dialog_id) navigate(ROUTES.COMMUNICATIONS + ROUTES.DIALOGS + `/${result.dialog_id}`);
    else setRecipient(data.company_creator as unknown as ISimilarCompany);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const refuseRequests = async (comment: string): Promise<void> => {
    await HttpClient.post<{ comment: string; all_positions: boolean }>(
      `participant/bond/requests/${requestId}/decline/`,
      { comment, all_positions: false }
    );
    loadData();
  };

  const tabs = React.useMemo(
    () =>
      data?.proposals?.map((p) => ({
        id: p.id,
        title: (
          <div className="bond-proposal-pill">
            <div className="bond-proposal-pill__amount">{renderAmount({ amount: p.amount })}</div>
            <div className="bond-proposal-pill__offer">
              {`${pluralYear(p.maturity)},${
                p.spread_value ? ` ${p.spread_value}‱,` : ''
              } ${renderCoupon(p)}${p.put || p.call ? `, ${renderProposalOffer(p)}` : ''}`}
            </div>
          </div>
        ),
        path: `${breadcrumbs[3]?.key}/proposals/${p.id}`,
      })) ?? [],
    [data]
  );

  const currentTab = tabs?.find((t) => proposalId && t.id === +proposalId);
  const currentTabIndex = currentTab ? tabs.indexOf(currentTab) : 0;
  const currentProposal = proposalId
    ? data?.proposals?.find((p) => p.id === +proposalId)
    : undefined;

  const hasProposals = data?.proposals?.filter((el) => !el.fake).length !== 0;

  let tabState = '';
  if (
    location.pathname.includes('/create') ||
    (location.pathname.includes('/edit') && hasProposals)
  )
    tabState = 'form';
  else if (hasProposals) tabState = 'my_proposal';
  else tabState = 'empty';

  const canEditMyProposal = data?.status === 'ACTIVE';
  const dataForEditing = currentProposal
    ? {
        ...currentProposal,
        put_checkbox: currentProposal.put !== null,
        call_checkbox: currentProposal.call !== null,
        rate_type: currentProposal.rate_type === 'FIX' ? 'FIX' : 'FLOAT',
        floater_type:
          currentProposal.rate_type === 'FIX'
            ? null
            : {
                label: BOND_FLOATERS_TITLES[currentProposal.rate_type],
                value: currentProposal.rate_type,
              },
      }
    : undefined;

  const components = {
    form: (
      <Container direction="vertical" className="create-bond-proposal">
        <Container.Header className="create-bond-proposal__title">Моё предложение</Container.Header>
        <CreateBondProposal
          data={dataForEditing}
          spreadType={data?.spread_type}
          hasAmortization={data.has_amortization}
          rateType={data.rate_type}
          canEditMyProposal={canEditMyProposal}
          onSave={() => {
            navigate(prevLink as To);
            loadData();
          }}
          onCancel={() => {
            navigate(prevLink as To);
          }}
          requestId={requestId ? +requestId : -1}
        />
      </Container>
    ),
    my_proposal: (
      <>
        <MyProposals
          tabs={tabs}
          currentTabIndex={currentTabIndex}
          onAdd={
            data.status !== Status.Active
              ? undefined
              : () => {
                  navigate(`/bonds/requests/${requestId}/proposals/create`);
                }
          }
        />
        <ProposalDetail
          data={currentProposal}
          canEditMyProposal={canEditMyProposal}
          onCommunicate={getDialogId}
          onEdit={() => {
            navigate(`/bonds/requests/${requestId}/proposals/${proposalId}/edit`);
          }}
          onCancel={() => {
            setIsOpen(true);
          }}
        />
      </>
    ),
    empty: (
      <Container direction="vertical">
        <EmptyProposals
          hasFakeProposal={data.proposals?.some((item) => item.fake)}
          canEditMyProposal={canEditMyProposal}
          message="Нет предложений"
          description="Вы пока не оставили ни одного предложения к запросу"
          onSendProposal={() => {
            navigate(`/bonds/requests/${requestId}/proposals/create`);
          }}
          onRefuseProposal={() => {
            setShowModalCancel(true);
          }}
        />
      </Container>
    ),
  } as Record<string, unknown>;

  const Cmp = (components[tabState] as React.ReactNode) ?? null;
  return (
    <>
      <DetailLayout
        title="Детализация запроса"
        className="participant-bond-detail"
        mainInfoClassName="participant-bond-detail-info"
        badgeParams={data && REQUEST_STATUSES_PARAMS[data?.status]}
        mainInfo={<MainInfo data={data} />}
      >
        <div className="participant-bond-detail-extra">{Cmp}</div>
      </DetailLayout>

      {isOpen && (
        <Notification
          isOpen
          title="Отозвать предложение"
          message="Вы уверены, что хотите отозвать предложение? Для повторной подачи необходимо подать новое предложение."
          onConfirm={() => {
            cancelProposal().then(() => {
              setIsOpen(false);
              navigate(prevLink as To);
              loadData();
            });
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
          confirmBtnText="Отозвать"
          cancelBtnText="Отмена"
        />
      )}

      {showModalCancel && (
        <RequestModalCancel
          onConfirm={(comment) => {
            refuseRequests(comment);
            setShowModalCancel(false);
          }}
          onCancel={() => {
            setShowModalCancel(false);
          }}
        />
      )}
      {recipient && (
        <CreateDialogModal
          isGroup={false}
          requestType="bond"
          recipientCompanies={[recipient]}
          requestBondId={data.id}
          onClose={() => {
            setRecipient(null);
          }}
        />
      )}
    </>
  );
};
