import { getMSCNow } from '@date-time';
import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Поле обязательное';
const CURRENT_TIME_MESSAGE = 'Дата должна быть позже текущей';
const onlyPositiveNumbers = Yup.number()
  .integer('Целое число')
  .min(1, 'Число больше 0')
  .max(999999999999999, 'Максимум 15 символов');

export const CreateBondRequestSchema = Yup.object().shape({
  name: Yup.string().trim().max(50, 'Ввод более 50 символов запрещен').required(REQUIRED_MESSAGE),
  comment_ext: Yup.string().trim().max(300, 'Ввод более 300 символов запрещен'),
  comment_int: Yup.string().trim().max(300, 'Ввод более 300 символов запрещен'),
  end_ts: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('wrong current date', CURRENT_TIME_MESSAGE, (item) => new Date(item) > getMSCNow()),
  rate_type: Yup.string().required(REQUIRED_MESSAGE),
  min_amount: onlyPositiveNumbers
    .required(REQUIRED_MESSAGE)
    .test('less then', 'Меньше макс. объёма', (item, context) =>
      item && context.parent.max_amount ? item <= context.parent.max_amount : true
    ),
  max_amount: onlyPositiveNumbers.required(REQUIRED_MESSAGE),
  maturity_from: onlyPositiveNumbers
    .required(REQUIRED_MESSAGE)
    .test('less then', 'Меньше макс. срока', (item, context) =>
      item && context.parent.maturity_to ? item <= context.parent.maturity_to : true
    ),
  maturity_to: onlyPositiveNumbers.required(REQUIRED_MESSAGE),
  put_from: onlyPositiveNumbers
    .nullable()
    .test('need checkout', REQUIRED_MESSAGE, (item, context) =>
      context.parent.put_checkbox ? !!item : true
    )
    .test('less then', 'Меньше макс. срока', (item, context) =>
      item && context.parent.put_to ? item <= context.parent.put_to : true
    ),
  put_to: onlyPositiveNumbers
    .nullable()
    .test('need checkout', REQUIRED_MESSAGE, (item, context) =>
      context.parent.put_checkbox ? !!item : true
    ),
  call_from: onlyPositiveNumbers
    .nullable()
    .test('need checkout', REQUIRED_MESSAGE, (item, context) =>
      context.parent.call_checkbox ? !!item : true
    )
    .test('less then', 'Меньше макс. срока', (item, context) =>
      item && context.parent.call_to ? item <= context.parent.call_to : true
    ),
  call_to: onlyPositiveNumbers
    .nullable()
    .test('need checkout', REQUIRED_MESSAGE, (item, context) =>
      context.parent.call_checkbox ? !!item : true
    ),
  floater_type: Yup.object()
    .nullable()
    .test('need select', 'Тип ставки не выбран', (item, context) =>
      context.parent.rate_type === 'FIX' ? true : !!item
    ),
});
