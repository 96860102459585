import React from 'react';
import { useParams } from 'react-router-dom';
import type * as ReactTable from 'react-table';
import { HttpClient } from '@api/HttpClient';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import DetailLayout from '@layouts/DetailLayout/DetailLayout';
import { REQUEST_STATUSES_PARAMS, BOND_RATE_TYPES, MIME_TYPES } from '@constants';
import Pills from '@components/Pills/Pills';
import { ROUTES } from '@routes';
import { download } from '@utils';
import { IRequestBondModel } from './detail.model';
import { renderCoupon, renderAmount, renderProposalOffer } from '@functions';
import { formatDateFromUTCtoMSC as formatDate, ONLY_DATE_FORMAT } from '@date-time';
import { ISimilarCompany } from '@pages/operator/components/SimilarCompaniesChecker/SimilarCompaniesChecker.modal';
import Button from '@components/Button/Button';
import Table from '@components/Table';
import type * as TableTypes from '@components/Table/types';
import { ReactComponent as IconFile } from '@shared/icons/file.svg';
import { MainInfo } from './MainInfo/MainInfo';

import './BondDetailPage.scss';

export const OperatorBondDetailPage: React.FC = () => {
  const breadcrumbs = useBreadcrumbs();
  const { operatorId, requestId } = useParams();
  const [data, setData] = React.useState<IRequestBondModel>({} as IRequestBondModel);
  const [participantsData, setParticipantsData] = React.useState<ISimilarCompany[]>([]);

  const isParticipantsTab = location.pathname.includes(ROUTES.PARTICIPANTS) ? 'participants' : '';

  const currentTabName: 'participants' | 'proposals' = location.pathname.includes(
    ROUTES.PARTICIPANTS
  )
    ? 'participants'
    : 'proposals';

  const currentTabNumber = isParticipantsTab ? 1 : 0;

  React.useEffect(() => {
    HttpClient.get<IRequestBondModel>(`operator/bonds/requests/${requestId}/`).then(setData);
    loadParticipants();
  }, [operatorId, requestId]);

  const loadParticipants = (): void => {
    HttpClient.get<ISimilarCompany[]>(`operator/bond/requests/${requestId}/participants/`).then(
      (bondRequest) => {
        setParticipantsData(bondRequest);
      }
    );
  };

  const downloadReport = async (): Promise<void> => {
    const file = await HttpClient.get<string>(`operator/bond/requests/${requestId}/report/`, {
      headers: {
        Accept: MIME_TYPES.Excel,
      },
      responseType: 'blob',
    });
    const blob = new Blob([file], {
      type: MIME_TYPES.Excel,
    });
    const fileName = `${data.name} от ${formatDate(data.start_ts, ONLY_DATE_FORMAT)}.xlsx`;
    download(blob, fileName);
  };

  const tabs = [
    {
      title: 'Таблица предложений',
      path: location.pathname.replace('/participants', ''),
    },
    {
      title: 'Участники',
      path: `${breadcrumbs[4].key}/participants`,
    },
  ];

  const participantsColumns: ReactTable.Column[] = [
    {
      Header: 'Название',
      accessor: 'name',
    },
    {
      Header: 'ИНН',
      accessor: 'inn',
    },
  ];
  const tabProps = {
    participants: {
      columns: participantsColumns,
      data: participantsData,
      className: 'operator-bond-detail__participants',
      notFoundMessage: 'Список участников пуст',
      notFoundDescription: 'К этому запросу пока не добавлено ни одного участника.',
    },
    proposals: {
      columns: COLUMNS,
      data: data?.proposals || [],
      className: 'operator-bond-detail__grid',
      notFoundMessage: 'Список предложений пуст',
      notFoundDescription: 'К этому запросу пока не оставлено ни одного предложения.',
    },
  };

  return (
    <DetailLayout
      title="Детализация запроса"
      className="operator-bond-detail"
      mainInfoClassName="operator-bond-detail-info"
      extraInfoClassName="operator-bond-detail__extra-info"
      badgeParams={data && REQUEST_STATUSES_PARAMS[data.status]}
      mainInfo={data && <MainInfo data={data} />}
      extraInfo={
        <>
          <div className="operator-bond-detail__title-row">
            <Pills currentTab={currentTabNumber} tabs={tabs} />
            <Button
              className="operator-bond-detail__download"
              onClick={downloadReport}
              icon={<IconFile />}
            >
              Выгрузить
            </Button>
          </div>
          <Table {...tabProps[currentTabName]} />
        </>
      }
    />
  );
};

const COLUMNS: TableTypes.Column[] = [
  {
    Header: '№',
    accessor: 'id',
    width: 48,
    Cell: ({ row }: { row: { index: number } }) => <>{row.index + 1}</>,
  },
  {
    Header: 'Участник',
    accessor: 'company.name',
    width: 160,
  },
  {
    Header: 'Объём выпуска',
    accessor: (row: unknown) => renderAmount(row as { amount: number }),
    width: 120,
    align: 'right',
  },
  {
    Header: 'Объём гар. участия',
    accessor: (row: unknown) => renderAmount(row as { amount: number }),
    width: 120,
    align: 'right',
  },
  {
    Header: 'Лет до погашения',
    accessor: 'maturity',
    width: 105,
    align: 'right',
  },
  {
    Header: 'Оферта',
    width: 120,
    accessor: (row: unknown) => renderProposalOffer(row as { put: number; call: number }),
  },
  {
    Header: 'Фикс/ Флоатер',
    width: 120,
    accessor: (({ rate_type: rateType }: { rate_type: string }) =>
      BOND_RATE_TYPES[rateType] || '-') as unknown as string,
  },
  {
    Header: 'Размер купона',
    width: 120,
    accessor: (row: unknown) =>
      renderCoupon(row as { rate_type: string; rate: number; bonus: number }),
  },
  {
    Header: 'Комментарий',
    width: 200,
    accessor: 'comment',
  },
];
